<template>
    <div class="grid grid-cols-[200px_repeat(4,_4fr)_repeat(1,_2fr)] border-b">
        <div class="border-r"></div>
        <div class="border-r text-center px-3 py-1 font-bold text-lg">1.Çeyrek</div>
        <div class="border-r text-center px-3 py-1 font-bold text-lg">2.Çeyrek</div>
        <div class="border-r text-center px-3 py-1 font-bold text-lg">3.Çeyrek</div>
        <div class="border-r text-center px-3 py-1 font-bold text-lg">4.Çeyrek</div>
        <div class="text-center px-3 py-1 font-bold text-lg">Yıl</div>
    </div>
    <div class="grid grid-cols-[200px_repeat(16,_1fr)_repeat(1,_2fr)] font-bold border-b bg-calypso text-white">
        <div class="px-3 py-1">{{ moment().format('Y') }}</div>
        <div class="text-center text-sm px-3 py-1" v-for="month in months">{{ month }}</div>
    </div>

    <div v-for="division in divisions" :key="division.id" class="border-b">
        <div class="grid grid-cols-[200px_repeat(16,_1fr)_repeat(1,_2fr)] font-bold hover:bg-calypso-light">
            <div class="border-r px-3 py-1 truncate">{{ division.name }}</div>
            <div v-for="a,index in Array(17)" :key="index" class="border-r border-b">
                <UICurrencyInput v-model.lazy="division_targets" :precision="{ min: 0, max: 0 }" :styled="false" size="sm" :options="{ hideCurrencySymbolOnFocus:false,currency: event_company.currency, locale:'tr' }" />
            </div>
        </div>
        
        <template v-for="team in teams.filter( x => x.division_id == division.id)" :key="team.id">
            <div class="grid grid-cols-[200px_repeat(16,_1fr)_repeat(1,_2fr)] text-gray-700 hover:bg-calypso-light">
                <div class="border-r px-3 py-1 pl-6 truncate text-sm">{{ team.name }}</div>
                <div v-for="a,index in Array(17)" :key="index" class="border-r border-b">
                    <UICurrencyInput v-model.lazy="division_targets" :precision="{ min: 0, max: 0 }" :styled="false" size="xs" :options="{ hideCurrencySymbolOnFocus:false,currency: event_company.currency, locale:'tr' }" />
                </div>
            </div>
            <template v-for="user in users.filter( x => x.team_id == team.id)" :key="team.id">
                <div class="grid grid-cols-[200px_repeat(16,_1fr)_repeat(1,_2fr)]">
                    <div class="border-r px-3 py-1 pl-10 text-gray-500 truncate text-sm">{{ user.full_name }}</div>
                    <div v-for="a,index in Array(17)" :key="index" class="border-r border-b">
                        <UICurrencyInput :precision="{ min: 0, max: 0 }" :styled="false" size="sm" :options="{ hideCurrencySymbolOnFocus:false,currency: event_company.currency, locale:'tr' }" />
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>


<script setup>
    import { computed, inject, ref, watch } from 'vue'
    import API from '@/api'
    import { useStore } from 'vuex'

    const moment = inject('moment')
    
    const helpers = inject('helpers')
    const emitter = inject('emitter')
    const store = useStore()

    const months = computed(() => {
        const months = []
        const dateStart = moment().startOf('year')
        const dateEnd = moment().startOf('year').add(12, 'month')
        while (dateEnd.diff(dateStart, 'months') > 0) {
            months.push(dateStart.format('MMM'))
            if(months.length % 4 == 3 ) {
                months.push('Toplam')
            }
            dateStart.add(1, 'month')
        }
        months.push('Toplam')
        return months
    })

    

    const event_company = computed(() => store.state.auth.event_company)
    
    const divisions = computed(() => store.state.content.divisions)
    const teams = computed(() => store.state.content.teams)
    const users = computed(() => store.state.content.users)

    const division_targets = ref([])
    const team_targets = ref([])
    const user_targets = ref([])

    const this_year = new Date().getFullYear()
    
    const fetch_data = () => {
        
        API.get('event-companies/'+event_company.value.id+'/targets', 
            { 
                year : this_year
            }
        ).then( response => {
            console.log(response.data)
            response.data.forEach( row => {
                if(row.target_for_type == 'DIVISION') {
                    if(!division_targets.value.includes(row.target_for_id)) {
                        division_targets.value[row.target_for_id] = []
                    }
                    division_targets.value[row.target_for_id][row.period] = row.amount
                }
                if(row.target_for_type == 'TEAM') {
                    if(!team_targets.value.includes(row.target_for_id)) {
                        team_targets.value[row.target_for_id] = []
                    }
                    team_targets.value[row.target_for_id][row.period] = row.amount
                }
                if(row.target_for_type == 'USER') {
                    if(!user_targets.value.includes(row.target_for_id)) {
                        user_targets.value[row.target_for_id] = []
                    }
                    user_targets.value[row.target_for_id][row.period] = row.amount
                }
                
            })

            console.log(division_targets.value)
            console.log(team_targets.value)
            console.log(user_targets.value)
            
        })
    }

    fetch_data()
    
    watch(() => division_targets.value, data => {
        console.log(data)
    }, { immediate : true})

</script>

<style>
.parent {
display: grid;
grid-template-columns: 300px repeat(4, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
}
</style>