<template>
	<TableView 
		ref="positions_table"
		title=""
		:table_titles="table_titles" 
		api_url="definition/positions" 
		:empty_state="empty_state"
		:sort_by="sort_by"
		custom_add_new_modal
		@add_new_open = "add_new_open"
		/>
</template>

<script setup>
	import { ref,computed,inject } from 'vue'
	import TableView from '@/views/TableView.vue'
    
	const emitter = inject('emitter')

	const positions_table = ref()
	const sort_by = 'name'

	const empty_state = {
        title:'Henüz hiç kullanıcı pozisyonu oluşturmamışsınız!',
        description:''
    }
    
    let table_titles = [
		{
			title:'Pozisyon Adı',
            sortable:true,
			id:'name',
            width:'w-2/5',
			class:"font-bold truncate"
		},
        {
			title:'Yetki Seviyesi',
            sortable:false,
			id:'scope_text',
            width:'w-1/5',
			class:""
		},
		{
			title:'',
			width:'w-1/5',
			type:'action'
		},
	]

	emitter.on("load_admin_positions", () => positions_table.value?.fetch_data(true))

	const add_new_open = id => {
		emitter.emit("update_admin_position", id)
	}

</script>