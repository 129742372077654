<template>
    <div class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
    	<div class="flex-1 flex justify-between sm:hidden">
      		<a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        		Previous
      		</a>
      		<a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        		Next
      		</a>
    	</div>
    	<div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-700">
                    <span class="font-medium">{{ paginator.total }}</span>
                     kayıt arasında 
                    <span class="font-medium">{{ paginator.from }}</span>
                    ile
                    <span class="font-medium">{{ paginator.to }}</span>
                    arası listeleniyor.
                </p>
            </div>
		<div>
			<nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
				<a @click="previousPage" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
					<span class="sr-only">Previous</span>
					<ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
				</a>
				<!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
				<a @click="changePage(data.label)" v-for="data,index in paginator.links" :key="index" :class="{ 'z-10 bg-calypso-light border-calypso-dark text-calypso': data.active, 'cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50':!data.active }" class="relative inline-flex items-center px-4 py-2 border text-sm font-medium">
					{{data.label }}
				</a>
                
                <a @click="nextPage" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
					<span class="sr-only">Next</span>
					<ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
				</a>
			</nav>
		</div>
    </div>
  	</div>
</template>

<script setup>
    import { computed, ref } from 'vue'
    import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

    const props = defineProps({
        paginator: Object,
        data_length: Number
    })

    let current_page = ref(1)
    let page_count = props.paginator.last_page

    const emit = defineEmits(['pagechange'])

    const changePage = (page) => {
        if(!Number(page)) return
        //current_page.value = page
        emit('pagechange',page)
    }

    const previousPage = () => {
        if(current_page.value == 1) return
        current_page.value--
        emit('pagechange',current_page.value)
    }

    const nextPage = () => {
        if(current_page.value == page_count.value) return
        current_page.value++
        emit('pagechange',current_page.value)
    }
</script>