

const state = {
	isAuthenticated:false,
    me:{},
    event_company:{},
    redirectAfterLogin:null,
    updateNeeded:false
}

// getters
const getters = {
    redirectAfterLogin (state) {
        return state.redirectAfterLogin;
    },
    eventCompany (state) {
        return state.event_company
    },
    me (state) {
        return state.me
    },
    vat_rates_select (state) {
        return state.event_company.vat_rates.map( rate => { return {value : rate.vat_rate, text : new Intl.NumberFormat('TR', {style: 'percent', maximumFractionDigits: 3,minimumFractionDigits: 2 }).format(rate.vat_rate) } })
   }
}

// actions
const actions = {
    
}
  
// mutations
const mutations = {
    loggedIn (state, data) {
        state.isAuthenticated = data
    },
    updateNeeded (state, data) {
        state.updateNeeded = data
    },
    markAllNotificationsRead (state, data) {
        state.me.unread_notifications_count = 0
    },
    setMe (state, data) {
        state.me = data
        
        let scope_id
        if(data.hasOwnProperty('position') && data.position.scope == 'DIVISION') {
            scope_id = data.division_id
        }
        if(data.hasOwnProperty('position') && data.position.scope == 'TEAM') {
            scope_id = data.team_id
        }
        if(data.hasOwnProperty('position') && data.position.scope == 'USER') {
            scope_id = data.id
        }

        state.scope = {
            scope : data.hasOwnProperty('position')?data.position.scope:'',
            scope_id : scope_id
        }
    },
    setEventCompany (state, data) {
        state.event_company = data
    },
    setCreditCards (state, data) {
        state.event_company.credit_cards = data
    },
    setRedirectAfterLogin (state, data) {
        state.redirectAfterLogin = data
    },
    setAccessToken (state, data) {
        state.access_token = data
    }
    
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}