<template>
    <Modal :title="title" 
        :isOpen="isOpen" 
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Onaya Gönder"
        @modal_closed="modal_closed"
        @submit="submit"
        size="md"
    >
    
        <template v-slot:modal_content>
            <UIAlert type="danger" class="my-2 flex-row" v-if="submit_error">
                <p>{{submit_error}}</p>
            </UIAlert>
            <UIFormRow>
                <UIRadioGroup :options="inpection_location_options" label="Nerede?" direction="horizontal" :errors="v$.location_type.$errors" v-model="form_data.location_type" />
            </UIFormRow>
            <UIFormRow>
                <UIInput label="Lokasyon" :errors="v$.location.$errors" v-model="form_data.location"/>
            </UIFormRow>
            <UIFormRow>
                <UITextarea :autogrow="false" label="Açıklama" v-model="form_data.note" class="h-16 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-gray-100 p-2"/>
            </UIFormRow>
            <UIFormRow>
                <UIDateRangePicker display="box" label="Hangi Tarihte?" :title="inpection_date_titles" :errors="v$.dates.$errors" v-model="form_data.dates"/>
            </UIFormRow>
            <UIFormRow>
                <UIRadioGroup :options="inpection_transport_options" :errors="v$.transportation_type.$errors" v-model="form_data.transportation_type" label="Ulaşım Şekli?" direction="horizontal" />
            </UIFormRow>
            <UIFormRow v-if="form_data.location_type == 'off_city'">
                <UIInput label="Konaklama Oteli" v-model="form_data.hotel_name"/>
            </UIFormRow>
        </template>
	</Modal>

</template>

<script setup>
    // Imports
	import {ref,watch,computed,inject} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'

    import useVuelidate from '@vuelidate/core'
    import { required } from '@/utils/i18n-validators'
    
    const moment = inject('moment')
    const emitter = inject('emitter')
    const store = useStore()

    // Props
	const props = defineProps({
        isOpen : Boolean,
        inspection : Object
    })
    console.log(props.inspection)

    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const fs_search_query = ref('')
    const fs_search_results = ref([])
    const isSubmitting = ref(false)
    const me = computed(() => store.state.auth.me)
    const ready2submit = computed( () => 
        form_data.value.location_type  && 
        form_data.value.location &&
        form_data.value.dates &&
        form_data.value.transportation_type ? true : false
    )
    const submit_error = ref(false)
    const inpection_location_options = ref([{value:'on_city',text:'Şehiriçi'},{value:'off_city',text:'Şehir Dışı'}])
    
    const inpection_transport_options = ref([
        {value:'company_car',text:'Şirket Aracı'},
        {value:'flight',text:'Uçak'},
        {value:'bus',text:'Otobüs'},
        {value:'train',text:'Tren'},
        {value:'personal_car',text:'Kendi Aracı'}
    ])
    const inpection_date_titles = ref("Başlangıç ve Bitiş Tarihi")

    // Data models
    const title = ref(props.inspection ? 'Keşif & Fuar Katılımını Güncelle' : 'Yeni Keşif & Fuar Katılımı')
    const form_data = ref(props.inspection || {
        location_type : 'off_city',
        transportation_type : 'company_car',
        dates : [moment().add(1,'day').format('YYYY-MM-DD'),moment().add(1,'week').format('YYYY-MM-DD')],
    })

    if(props.inspection) {
        form_data.value.dates = [props.inspection.starts_at, props.inspection.ends_at]
        form_data.value.location_type = props.inspection.needs_approve ? 'on_city' : 'off_city'
    }

    // Watchers
    watch(() => form_data.value.location_type, (data) => {
            
	},{ immediate: true })
    

    // Functions

    const rules = computed(() => ({
        location_type: { required },
        location: { required},
        dates: { required},
        transportation_type:{ required}
    }))

    const v$ = useVuelidate(rules, form_data)

	const modal_closed = () => emit('modal_closed')
	
    const submit = async () => {

        const result = await v$.value.$validate()
        if(!result) return

        let request
        isSubmitting.value = true
        if(props.inspection && props.inspection.status!='DECLINED') {
            request = API.put('inspections/' + form_data.value.id,form_data.value)
        } else {
            request = API.post('inspections',form_data.value)
        }
        
        request.then( response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description: response.data.message
            })
            isSubmitting.value = false
            modal_closed()
            emitter.emit('calendar_refetch_events')
            emitter.emit('load_inspections')
        }).catch( error => {
            isSubmitting.value = false
            submit_error.value = error.response.data.message
        })
    }



    const search_foursquare = (query,near) => {
        
        //fetch('https://api.foursquare.com/v2/venues/explore?client_id=BOABC3EAJIDHDL0GOLQCJBNEIXRV4YO2NY0DMXRX4GIVCJW5&client_secret=UEG5MYEQV0HDQRUX20OYK4KB4XZRW4SLSVBDBF1TOW4PDPPX&v=20180323&near='+near+'&query='+query,
        fetch('https://api.foursquare.com/v3/autocomplete?query=' + query,
         {
            method: 'GET',
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization':'fsq3S6p6/sH+sgre6QoDVZu2ANv4ANYKWbBACU18jXIdREs='
            }
        }).then(data => data.json())
        .then(data => {
            var results = data.results;
            console.log(results)

            fs_search_results.value = results.map(x=>{
					return {value:x.place.fsq_id,text:x.place.name}
				})
                //.sort((a, b) => a.value.localeCompare(b.value))
			


            return
            //$('#foursquare_results').removeClass('hide').html('<option value="">'+results.length+' sonuç bulundu</option>');
            $.each(results, function(index, result) {
                console.log(result.venue.location.cc+ ' => '+Cushy.helper.correctCC(result.venue.location.cc));
                console.log(result.venue.location.city+ ' => '+Cushy.helper.correctCN(result.venue.location.city));
                $('#foursquare_results').append('<option data-category="'+result.venue.categories[0].name+'" data-address="'+result.venue.location.address+'" data-cc="'+Cushy.helper.correctCC(result.venue.location.cc)+'" data-city="'+result.venue.location.city+'" data-postcode="'+result.venue.location.postalCode+'" data-geo="'+result.venue.location.lat+','+result.venue.location.lng+'" value="'+result.venue.name+'">['+result.venue.categories[0].name + '] ' + result.venue.name + '</option>')
            })
        })
        .catch(function() {
            // Code for handling errors
        });
    }

    const handler = location => {
        if(location.length < 4) return
        fs_search_query.value = location
        search_foursquare(location,'Turkey')
    }

    const location_change = location => {
        if(location == 'on_city') {
            inpection_transport_options.value = [
                {id:'bus',value:'Otobüs'},
                {id:'train',value:'Tren'},
                {id:'company_car',value:'Şirket Aracı'},
                {id:'personla_car',value:'Kendi Aracı'}
            ]
        } else {
            inpection_transport_options.value = [
                {id:'flight',value:'Uçak'},
                {id:'bus',value:'Otobüs'},
                {id:'train',value:'Tren'},
                {id:'company_car',value:'Şirket Aracı'},
                {id:'personla_car',value:'Kendi Aracı'}
            ]
        }
    }

</script>