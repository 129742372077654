<template>
    <div class="w-full text-sm  dark:bg-gray-700 dark:border-gray-600 dark:text-white overflow-hidden">
        <div class="px-4 py-2 font-semibold text-sm" v-if="title">{{title}}</div>
        <div v-for="item,k in items" :key="item.id" @click="click(item.id)"
            class="duration-300 block w-full px-4 py-2 border-b border-gray-100 cursor-pointer focus:outline-none focus:ring-2 focus:ring-calypso focus:text-calypso dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
            :class="{
                'font-semibold bg-calypso-light' : item.id == active,
                'hover:bg-gray-50 hover:text-calypso' : item.id !== active,
                'rounded-t' : k == 0 && !title,
                'rounded-b' : k == items.length - 1 
            }">
            {{ item.title }}
        </div>
        
    </div>
</template>

<script setup>
    const props = defineProps({
        items : Array,
        active: [Number,String],
        title: String
    })

    const emit = defineEmits(['click'])

    const click = id => {
		emit('click',id)
    }
</script>