<script setup>
    import { computed, ref, watch ,onBeforeMount, onMounted} from "vue"
    import draggable from 'vuedraggable'
    import API from "@/api"
    import { useStore } from "vuex"
    import _ from 'lodash'

    const store = useStore()

    const form_data = computed(() => store.state.form.active_form)
    const active_field = computed(() => store.state.form.active_field)
    const field = ref({})
    const flights = ref([])
    const new_option = ref({})

    const field_options = computed({
        get() {
            return active_field.value.options
            //return store.getters["form/getField"](active_field.id).options
        },
        set(data) {
			sort(data)
        }
    })

    const sort = (data) => {
        
        API.post("form/" + form_data.value.code + "/field/" + field.value.id + "/option/sort", {options : data.map( x => x.id )}).then(response=>{
            
			store.dispatch("form/updateField", {
					id: field.value.id,
					field: response.data.data,
				})
			store.dispatch('floating_alerts/addAlert', {
				type:'tip',
                title:'BAŞARILI!',
				description:response.data.message
			})
        })
        .catch( error => {
        
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
        })
    }

    watch(() => active_field.value, data => {
        field.value = _.cloneDeep(active_field.value)
    },{ immediate: true,deep:true })

    const add_flight = () => {
		
		API.post("form/" + form_data.value.code + "/field/" + field.value.id + "/flight_option",
			new_option.value
		)
			.then((response) => {
				store.dispatch("form/updateField", {
					id: field.value.id,
					field: response.data.data,
				})
				field.value = response.data.data
				store.dispatch("floating_alerts/addAlert", {
					type: "tip",
					title: "BAŞARILI!",
					description: "Seçenek eklendi.",
				})
				new_option.value = {}
			})
			.catch((error) => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
	}

    

	if(form_data.value.project_id) {
		API.get('project/' + form_data.value.project_id + '/flights').then( response => {
			flights.value = response.data.map(flight=>{return {value:flight.id,text:"("+ flight.outbound.departure_airport.code +") "+ flight.outbound.departure_airport.name+" - "+flight.outbound.airline_code}})
		})
	}
</script>

<template>
    <div class="flex gap-3 text-xs font-medium text-gray-500 uppercase mb-1">
				<div class="grow">Seçenek</div>
				<div class="w-16">Kontenjan</div>
				<div class="w-16">Boş</div>
				<div class="w-12"></div>
			</div>
			<draggable 
				@sort="()=>{}"
				group="form_fields" 
				item-key="id"
				v-model="field_options" 
				ghost-class="ghost"
				drag-class="bg-white"
				animation=300
			> 
				<template #item="{element}">
					<UIFormRow :data-option-id="element.id"  :key="element.id" class="flex gap-3" >
						<UIInput
							placeholder="Yeni seçenek ekle"
							class="grow"
							:modelValue.lazy="element.option_text"
							@change="event => update_option(event.target.value,element.id,'option_text')"
						/>
						<UIInput
							placeholder="Kontenjan"
							class="w-16 shrink-0"
							:modelValue.lazy="element.quota_allocated"
							@change="event => update_option(event.target.value,element.id,'quota_allocated')"
						/>
						<UIInput
							placeholder="Kontenjan"
							class="w-16 shrink-0"
							:modelValue.lazy="element.quota_available"
							@change="event => update_option(event.target.value,element.id,'quota_available')"
						/>
						<UIButton
							class="w-12 items-center"
							type="danger"
							text="Sil"
							@click="delete_option(element.id)"
						/>
					</UIFormRow>
				</template>
			</draggable>
			<!-- <UIFormRow v-for="option in field.options" :key="option.id" class="flex gap-3" >
				<UIInput
					placeholder="Yeni seçenek ekle"
					class="grow"
					:modelValue.lazy="option.option_text"
					@change="event => update_option(event.target.value,option.id,'option_text')"
				/>
				<UIInput
					placeholder="Kontenjan"
					class="w-16 shrink-0"
					:modelValue.lazy="option.quota_allocated"
					@change="event => update_option(event.target.value,option.id,'quota_allocated')"
				/>
				<UIInput
					placeholder="Kontenjan"
					class="w-16 shrink-0"
					:modelValue.lazy="option.quota_available"
					@change="event => update_option(event.target.value,option.id,'quota_available')"
				/>
				<UIButton
					class="w-12 items-center"
					type="danger"
					text="Sil"
					@click="delete_option(option.id)"
				/>
			</UIFormRow> -->
			<hr class="my-5" />
			<UIFormRow class="grid grid-cols-3 gap-3">
				<UISelect
				    title="Yeni uçuş ekle"
					display="box"
					:source="flights"
					class="grow"
					v-model="new_option.flight_id"
				/>
				<UIInput
					placeholder="Kontenjan"
					class="w-24"
					v-model="new_option.quota_available"
				/>
				<UIButton
					class="w-24 items-center"
					type="submit"
					text="Ekle"
					@click="add_flight"
				/>
			</UIFormRow>    
</template>