<template>
    <draggable 
        @add="add" 
        @sort="sort"
        @move="move"
        @start="start"
        group="form_sections" 
        item-key="id"
        v-model="form_sections" 
        ghost-class="ghost"
        drag-class="bg-white"
        animation=300
    >
        <template #item="{element}">
            <div :key="'form_section_'+element.id" :data-section-id="element.id" class="rounded mb-5">
                <div class="mb-2 p-2 border-b bg-slate-50 group">
                    <div class="flex items-center relative">
                        <h3 
                            class="mr-auto font-bold"
                            :style="'color:' + element.title_colour"
                            @click="show_form_section_detail(element.id)"
                        >
                        {{element.title}}
                        </h3>
                        <template v-if="!element.budget_category_id">
                            <div class="field_wrapper_actions duration-300 grid grid-cols-2 opacity-0 group-hover:opacity-100 ">
                                <UITooltip title="Bölümü kopyala" position="top" class="hidden">
                                    <div><DuplicateIcon class="w-9 p-2 opacity-80 hover:opacity-100 hover:bg-slate-100"/></div>
                                </UITooltip>
                                <UITooltip title="Bölümü sil" position="top">
                                    <div><TrashIcon class="w-9 p-2 opacity-80 hover:opacity-100 hover:bg-slate-100" @click.stop.prevent="delete_form_section(element.id)"/></div>
                                </UITooltip>
                            </div>
                        </template>
                    </div>
                    <small class="block font-normal opacity-50">{{element.help_text}}</small>
                </div>
                <FieldsDraggable :key="element.id" :section_id="element.id"/>
            </div>
        </template>
        <template #footer>
            <div class="p-4 text-center text-gray-500" v-if="form_sections.length == 0">Bu bölüm henüz boş!</div>
        </template>
    </draggable>
</template>

<script setup>
    import {computed,ref,watch,inject,onMounted} from 'vue'
    import draggable from 'vuedraggable'
    import API from '@/api'
    import { TrashIcon, PencilIcon, DuplicateIcon, HandIcon } from '@heroicons/vue/outline'
    import FieldsDraggable from './FieldsDraggable.vue'
    import { useStore } from 'vuex'
	
    const emitter = inject('emitter')
	const store = useStore()
    const props = defineProps({
        active_field:Object
    })

    const form_data = computed(() => store.state.form.active_form)
    const form_sections = ref([...[], ...store.state.form.active_form.sections])
        
    watch(() => form_data.value, data => {
        form_sections.value = [...[], ...store.state.form.active_form.sections]
    }, {deep:true})

    watch(() => form_sections.value, data => {
        //console.log(data)
    }, {deep:true})

    const add = e => {
       console.log( 'bölüm eklendi');
        API.post('form/'+form_data.value.code+'/field',{code:e.item.dataset.code,index:e.newDraggableIndex}).then(response=>{
            
        })
    }

    const sort = () => {
        
        API.post('form/' + form_data.value.code + '/sort', {sections : form_sections.value.map( x => x.id )}).then(response=>{
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
                title:'BAŞARILI!',
				description:response.data.message
			})
        })
        .catch( error => {
        
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
        })
    }

    const move = e => {
        console.log(e)
    }

    const start = e => {
        console.log(e)
    }

    const show_form_section_detail = id => {
        emitter.emit('show_form_section_detail',id)
    }
    
    const delete_form_section = id => {
        
        API.delete('form/'+ form_data.value.code +'/section/'+id).then(response=>{
            //display_section.value = 'field_list',
            form_sections.value=form_sections.value.filter(x=>x.id!=id)
           // store.dispatch('form/loadData', form_data.value.code)
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
                title:'BAŞARILI!',
				description:response.data.message
			})
        })
        .catch( error => {
          
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
        })
    }

</script>

<style scoped>
    .button {
        margin-top: 35px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        background-color: yellow;
    }
    .ghost > div, .ghost > h3 {
        visibility: hidden;
    }
    .list-group {
    min-height: 20px;
    }
    .list-group-item {
    cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
    .dragArea {
        min-height: 50px;
        outline: 1px dashed;
    }
</style>