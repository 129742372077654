const state = {
    list: [],
    current_client:null,
    paginator:null,
    users:[],
    statuses:[
        {value:'all',text:"Hepsi"},
        {value:'EXISTING',text:"Mevcut"},
        {value:'POTENTIAL',text:"Potansiyel"}
    ],
    temp_client:{}
}

// getters
const getters = {
    list (state) {
        return state.list;
    },
    list_select (state) {
        return state.select
        return state.list.map(client => { return {value : client.id, text : client.name, image : client.logo_full}}).sort((a, b) => a.text.localeCompare(b.text))
    },
    select_with_all (state) {
        return [...[{value:'all',text:'Tüm Müşteriler'}], ...state.select]
    },
    status: (state) => (status) => {
        return state.statuses.find((x) => x.id == status).value
    },
    select (state) {
		return state.select
	},
    select_code (state) {
		return state.list.map(client => { return {value : client.ccode, text : client.name, image : client.logo_full}}).sort((a, b) => a.text.localeCompare(b.text))
	},
}
  
// actions
const actions = {
    
}
  
// mutations
const mutations = {
    setCurrentClient(state, data) {
        state.current_client = data
    },
    setPaginator (state, data) {
        data.links.splice(0,1)
        data.links.splice(-1,1)
        state.paginator = data
    },
    setClients (state, data) {
        state.list = data
        state.select = data.map(client => { return {value : client.id, text : client.name, image : client.logo_full}}).sort((a, b) => a.text.localeCompare(b.text))
    },
    setFullClients (state, data) {
        state.data = data
        state.select = data.map(client => { return {value : client.id, text : client.name, image : client.logo_full}}).sort((a, b) => a.text.localeCompare(b.text))
    },
    setTempClient(state, data) {
        state.temp_client = {...state.temp_client, ...data}
    },
    setTempClientContacts(state, data) {
        state.temp_client.contacts = data
    },
    setTempClientNotes(state, data) {
        state.temp_client.notes = data
    },
    resetTempClient(state) {
        state.temp_client = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}