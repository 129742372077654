<template>
    <Modal 
        :close_on_outside_click=false :isOpen="isModalOpen" 
        @modal_closed="modal_closed" 
        @submit="submit"
        :title=" task && task.id ? 'To-Do\'yu Güncelle' : 'Yeni To-Do'"
        :isSubmitting="is_submitting"
        :action_button_text="task && task.id ? 'Güncelle' : 'Ekle'"
        :backdrop="false"
        size="xl"
        :colored_header="true"
        :ready2submit="ready2submit"
        position="bottom-right"
    >
        <template v-slot:modal_content>
            <UIAlert :titleText="submit_error.title" type="danger" v-if="submit_error.title" class="mt-2 flex-row">
                <p>{{ submit_error.description }}</p>
            </UIAlert>
            <div class="mb-5 mt-3">
                <UIInput v-model="task_title" font="bold" :styled="false" placeholder="Başlık..." class="!px-0 font-bold w-full border-none outline-none resize-none"/>
            </div>
            <div class="mt-2">
                <UITextarea :maxlength="500" :autogrow="false" v-model="task_desc" :styled="false" placeholder="Detayları buraya yazabilirsin" class="h-44 w-full pb-2 border-none outline-none text-sm text-gray-500 resize-none"/>
            </div>
            <div class="grid grid-cols-3 pt-3 border-t">
                <UIDatePicker label="Başlangıç Tarihi" title="Tarih Seç" :time="true" v-model="task_starts_at"/>
                <UIDatePicker label="Tamamlanma Tarihi" title="Tarih Seç" :time="true" v-model="task_deadline"/>
                <UISelect :search_enabled="false" label="Etiketle" tags multiple key="labels_filter" title="Etiket Seç" :source="labels" class="mr-5" v-model="task_labels"/>
            </div>
            <div class="grid grid-cols-2 border-b py-3">
                <UISelect label="Proje" key="project_filter" title="Proje Seç" :source="projects" class="mr-5" v-model="task_project"/>
                <UISelect class="mr-5" label="Sorumlu kişi(ler)" :use_arrow="false" :source="users" multiple tags only_icon icons_stacked icon_tooltip :tag_border="false" title="Kullanıcı Seç" v-model="task_responsibles"/>
            </div>
            
        </template>
        <template v-slot:modal_footer>
            
        </template>
    </Modal>
</template>

<script setup>
	import {ref,watch,computed,inject} from 'vue'
    import { useStore } from 'vuex'
    import Modal from '@/components/UI/Modal.vue';
    import API from '@/api'
    import { onKeyStroke } from '@vueuse/core'

    const emitter = inject('emitter')

    const props = defineProps({
        isOpen : Boolean,
        task : Object,
        project_code : String,
        section_id : Number
    })
    
    const store = useStore()
    const emit = defineEmits(['modal_closed'])
    
    const me = computed(() => store.state.auth.me)
    const users = computed(() => store.getters['content/users_select'])
    const projects = ref([])

    API.getProjectsByStatus(['LEAD','PREP','OPERATION','PRECLOSE']).then(response => {
        projects.value = response.data.map( project => { return {value : project.code, text : project.name, subtext : project.project_ref, image : project.client.logo_full } }).sort((a, b) => a.text.localeCompare(b.text))
    })

    const labels = computed(() => store.state.tasks.labels.map(x=>{
					return {value : x.id, text : x.name}
				}).sort( (a, b) => a.text.localeCompare(b.text) )
			)

    const is_submitting = ref(false)
    const submit_error = ref({
        title:null,
        description:null
    })
    const task_title = ref(props.task ? props.task.title : null)
    const task_desc = ref(props.task ? props.task.description : null)
    const task_starts_at = ref(props.task ? props.task.starts_at : null)
    const task_deadline = ref(props.task ? props.task.deadline_at : null)
    const task_responsibles = ref(props.task ? props.task.responsibles : Array(1).fill(me.value.id))
    const task_project = ref(props.project_code)
    
    const task_labels = ref(props.task ? props.task.labels.map( label => { return {value : label.id, text : label.name } }) : [])
    
    const ready2submit = computed(() => task_title.value && task_starts_at.value && task_deadline.value && task_responsibles.value ? true : false )

	let isModalOpen = ref(props.isOpen);

	watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    watch(() => task_responsibles.value, (data) => {
			console.log(data)
		},{ immediate: true }
    )

    

    const modal_closed = () =>{
		isModalOpen.value = false
		emit('modal_closed')
	}

    const submit = () => {
        is_submitting.value = true

        task_labels.value = task_labels.value.map(label => {
            console.log(label)
            if(typeof label == 'object') {
                return label.value
            }
            return label
        })

        const request_data = {
            title       : task_title.value,
            description : task_desc.value,
            deadline_at    : task_deadline.value,
            starts_at    : task_starts_at.value,
            labels      : task_labels.value,
            responsibles : task_responsibles.value,
            project_id  : task_project.value,
            section_id  : props.section_id
        }

        let request
        if(props.task) {
            request = API.put('tasks/' + props.task.id, request_data)
        } else {
            request = API.post('tasks', request_data)
        }
        
        request.then(response => {
            modal_closed()
            emitter.emit('load_tasks')
            if (request_data.project_id) {
                emitter.emit('project_load_tasks')
            }
            emitter.emit('load_tasks')
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description: props.task ? 'To-Do güncellendi!' : 'To-Do eklendi!'
            })
        })
        .catch((error) => {
            if (error.response) {
                
                submit_error.value = {
                    title:error.response.data.message,
                    description:error.response.data.errors.description[0]
                }
                is_submitting.value = false

                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                is_submitting.value = false
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                is_submitting.value = false
            }
            
        })
        
    }

    onKeyStroke('Escape', e => modal_closed())
    onKeyStroke('Esc', e => modal_closed())
</script>

<style scoped>

</style>