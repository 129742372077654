<template>
    <Modal 
        :isOpen="isModalOpen" 
        :title="'Checklist Bölüm Ekle'" 
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Kaydet"
        @modal_closed="modal_closed"
        @submit="submit"
    >
        <template v-slot:modal_content>
            <div class="grid grid-cols-2 gap-x-2">
                <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row col-span-2">
                    <p>{{ submit_error }}</p>
                </UIAlert>
                
                <UIFormRow class="col-span-2">
                    <UIInput label="Bölüm" v-model="form_data.name"/>
                </UIFormRow>
                
            </div>
        </template>
    </Modal>
</template>
  
<script setup>
	import {computed, ref, watch} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'

	const props = defineProps({
        isOpen : Boolean,
        checkListId : Number,
    })

    const emit = defineEmits(['modal_closed'])
    const store = useStore()
	const isModalOpen = ref(props.isOpen)
    const isSubmitting = ref(false)
    const submit_error = ref(false)

    const form_data = ref({})

    const ready2submit = computed( () => form_data.value.name ? true : false )

	watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    const modal_closed = () =>{
		isModalOpen.value = false
        isSubmitting.value = false
        emit('modal_closed')
	}

    const submit = () => {
        
        isSubmitting.value = true

        API.post('checklists/' + props.checkListId + '/section',form_data.value)
        .then(response => {
            isSubmitting.value = false
            modal_closed()
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:response.data.message,
            })
        })
        .catch((error) => {
            isSubmitting.value = false;
            submit_error.value = error.response.data.message
        })
    }
</script>