const state = {
    pending: [],
    completed: [],
    labels:[],
    sections:[],
    projects:[]
}
  
// getters
const getters = {
    list (state) {
        return state.all;
    },
    taskById: (state) => (id) => {
		return state.pending.find( x => x.id == id)
	},
}
  
// actions
const actions = {
    
}
  
// mutations
const mutations = {
    setTasks (state, data) {
        state[data.list] = data.tasks
        //state[data.list] = [...state[data.list],...data.tasks]
        state.sections = data.sections
        state.status = data.list
        return
        if(data.append) {
            state[data.list] = [...state[data.list],...data.tasks]
        } else {
            let sections = [
                {
                    name    : 'Recently Added',
                    id      : 0,
                    items   : []
                }
            ]
            data.tasks.forEach(task => {
                if(task.sections.length == 0) {
                    sections[0].items.push(task)
                } else {
                    task.sections.forEach(task_section => {
                        if(sections.filter( x => x.id == task_section.id).length == 0) {
                            task_section.items = []
                            sections.push(task_section)
                        }
                        sections.find( x => x.id == task_section.id).items.push(task)
                    })
                }
            });
            state[data.list] = sections
        }
        
    },
    setLabels (state, data) {
        state.labels = data
    },
    markAsCompleted(state, data) {
        let item = state.pending.find(x => x.id == data.id)
        item.completed_at = data.completed_at
        item.completed_by = data.completed_by
        state.completed.push(item)
        state.pending = state.pending.filter(x=>x.id != item.id)
    },
    setDate (state, data) {
        state.pending.forEach(section => {
            section.items.forEach(item => {
                if(item.id == data.id) {
                    item.deadline_at = data.date
                }
            })
        })
    },
    addSection (state, data) {
        let sections = 
            {
                name    : 'Yeni Bölüm',
                id      : 0,
                items   : []
            }
        state.pending.push(sections)
    },
    addItem (state, data) {
        let item = {
            "id":null,
            "section_id":data.section_id,
            "title":null,
            "description":null,
            "creator_id":data.user_id,
            "responsible_id":null,
            "completed_by_id":null,
            "project_id":null,
            "budget_item_id":null,
            "is_checklist":0,
            "starts_at":null,
            "deadline_at":null,
            "reminded_at":null,
            "created_at":null,
            "updated_at":null,
            "completed_at":null,
            "project":null,
        }
        const section = state.pending.find(x => x.id == data.section_id)
       
        section.items.push(item)
    },
    updateSectionName (state, data) {
        state.sections.find(x=>x.id == data.section_id).name = data.name
    },
    setProjects (state, data) {
        state.projects = data
    },
    updateItem (state, data) {
        let task = state.pending.find(x=>x.id == data.id)
        let index = state.pending.findIndex(x=>x.id == data.id)
        //state.pending[index] = {...task, ...data}
        state.pending[index] = data
    },
    updatePriority (state, data) {
        let index = state.pending.findIndex(x=>x.id == data.id)
        state.pending[index].priority = data.data
    },
    updateDeadlineAt (state, data) {
        let index = state.pending.findIndex(x=>x.id == data.id)
        state.pending[index].deadline_at = data.data
    },
    updateProject (state, data) {
        let index = state.pending.findIndex(x=>x.id == data.id)
        state.pending[index].project_id = data.data
        if(state.pending[index].short_project_name){
            state.pending[index].short_project_name = data.project.text.substring(0,25)
            state.pending[index].project_name = data.project.text
        }
    },
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}