<template>
    <UICard title="Veri Eşleştirme" class="w-1/2 mx-auto my-12">
        <template v-slot:header_tooltip>
            <div class="ml-auto font-normal text-sm" @click="cancel">Başa dön</div>
        </template>
        <div class="grid gap-2 grid-cols-2">
            <div class="col-span-2 mb-3">
                Lütfen checklist'ini kaydetmeden önce hangi sütunda hangi veriyi bulacağımızı belirle.
            </div>
            <template v-for="column_title,index in column_titles" :key="index">
                <div>
                    {{ column_title }}
                </div>
                <div>
                    <UISelect title="Sütun seç" display="box" :source="columns" v-model="data_mapping[index]"/>
                </div>
            </template>
            <div class="col-span-2 text-right">
                <UIButton text="Checklist'i Kaydet" type="submit" @click="submit_excel"  :loading="isSubmitting"  :disabled="!ready2submit_excel || isSubmitting"/>
            </div>
        </div>
    </UICard>
</template>

<script setup>
    import { ref, computed, onMounted, watch } from "vue"
    import { useStore } from 'vuex'
    import API from "@/api"

    // Props
	const props = defineProps({
		first_row_data: {
			type: Object,
			default: [],
		},
        checklist_id: String,
        excel_data: Object
	})

    const store = useStore()
    
    const columns = computed(()=>props.first_row_data)

    const isSubmitting=ref(false)
    const column_titles = ref(["Bölüm","Açıklama","Kaç gün önce başlar?","Kaç gün önce bitmeli?","Olma sıklığı","Bağımlı alan"])
    const data_mapping = ref([])
    const ready2submit_excel = computed(() => data_mapping.value[1] ? true : false)

    const emit = defineEmits(['cancel'])

	watch(() => data_mapping.value, data => {
		
	},{deep:true})


    const submit_excel = () => {
       
        isSubmitting.value = true
		API.post('checklists/' + props.checklist_id + '/upload', {
			mappings : data_mapping.value,
			data : props.excel_data
		})
		.then(response => {
			isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
                    type:'tip',
                    title:'BAŞARILI!',
                    description:response.data.message
                })
                cancel()
		})
		.catch(() => {
			isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
                    type:'danger',
                    title:'HATA!',
                    description:error.response.data.message
                })
		})
	}

    const cancel = () => {
        emit('cancel')
    }
</script>