<template>
    <Modal 
        :isOpen="true"
        title="Ödeme Talep Formu"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Gönder"
        @modal_closed="modal_closed"
        @submit="submit"
        size="lg"
    >
        <template v-slot:modal_content>
            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>
            <UIFormRow>
                <UISelect label="Hangi Proje?" title="Bir proje seç" :source="projects" display="box" :errors="v$.project_id.$errors" v-model="data.project_id"/>
            </UIFormRow>
            <UIFormRow>
                <UISelect label="Hangi Tedarikçi?" title="Bir tedarikçi seç" :source="suppliers" display="box" :errors="v$.supplier_id.$errors" v-model="data.supplier_id"/>
            </UIFormRow>
            <UIFormRow>
                <UIDatePicker label="Ödeme Yapılacak Tarih" title="Tarih Seç" display="box" :errors="v$.payment_date.$errors" v-model="data.payment_date"/>
            </UIFormRow>
            <UIFormRow class="flex space-x-3">
                <div class="w-2/5">
                    <UISelect :source="currencies_select" label="Para Birimi" title="Para Birimi" display="box" :errors="v$.currency.$errors" v-model="data.currency"/>
                </div>
                <div class="w-3/5">
                    <UICurrencyInput label="Ödenecek Tutar" :options="{ hideCurrencySymbolOnFocus:false,currency: data.currency, locale:'tr' }" :errors="v$.project_id.$errors"  v-model.lazy="data.amount"/>
                </div>
            </UIFormRow>
            <UIFormRow class="flex space-x-3">
                <UIInput label="Banka Adı" placeholder="" :errors="v$.bank_name.$errors" v-model="data.bank_name" class="w-2/5"/>
                <UIInput label="Hesap No (IBAN)" placeholder="" :errors="v$.bank_account_no.$errors" v-model="data.bank_account_no" class="w-3/5"/>
            </UIFormRow>
            <UIFormRow>
                <UITextarea :errors="v$.note.$errors" v-model="data.note" :maxlength="200" :autogrow="false" label="Açıklama" placeholder="Lütfen bu ödeme ile ilgili notlarını buraya yaz" class="h-24 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-slate-50 p-2"/>
            </UIFormRow>
            <hr>
            
        </template>
	</Modal>

</template>

<script setup>
    // Imports
	import {ref, computed, watch, inject } from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'

    import useVuelidate from '@vuelidate/core'
    import { required, minLength, requiredIf } from '@/utils/i18n-validators'
    
    const emitter = inject('emitter')

    // Props
	const props = defineProps({
        payment_request : Object
    })
    const isOpened = ref(false)

    const store = useStore()
    const isSubmitting = ref(false)
    const submit_error = ref(false)
    
    const ready2submit = computed( () => data.value.project_id !== null && data.value.supplier_id !== null && data.value.payment_date !== '' && data.value.amount !== '' && data.value.currency !== '' && data.value.bank_name !== '' && data.value.bank_account_no !== '' && data.value.note !== '')

    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const event_company = computed(() => store.state.auth.event_company)
    //const projects = computed(() => store.getters['projects/list_select'])
    const projects = ref([])
    const currencies_select = computed(() => store.getters['content/currencies_select'])
    const suppliers = ref([])

    API.getProjectsByStatus(['LEAD','PREP','OPERATION','PRECLOSE']).then(response => {
        projects.value = response.data.map( project => { return {value : project.code, text : project.name, subtext : project.project_ref, image : project.client.logo_full } }).sort((a, b) => a.text.localeCompare(b.text))
    })
    
    // Data models
    const data = ref({
        payment_request_id : props.payment_request ? (props.payment_request.status== 'DECLINED'?null:props.payment_request.id ) : null,
        project_id : props.payment_request ? props.payment_request.project_id : null,
        supplier_id : props.payment_request ? props.payment_request.supplier_id : null,
        bank_name : props.payment_request ? props.payment_request.bank_name : null,
        bank_account_no : props.payment_request ? props.payment_request.bank_account_no : null,
        payment_date : props.payment_request ? props.payment_request.payment_date : '',
        description : props.payment_request ? props.payment_request.description : '',
        currency : props.payment_request ? props.payment_request.currency : event_company.value.currency,
        amount : props.payment_request ? props.payment_request.amount : '',
        note : props.payment_request ? props.payment_request.note : '',
    })

    // Watchers
    
    watch(() => data.value.project_id, (x) => {
            if(!x) return
            if(data.value.project_id) {
                API.get('project/' + data.value.project_id + '/suppliers').then(response => {
                    suppliers.value = response.data.map(supplier => { return {value : supplier.id, text : supplier.name}}).sort((a, b) => a.text.localeCompare(b.text))
                })
            }
		},{ immediate: true, deep: true }
    )

    // Functions
    const modal_closed = () => emit('modal_closed')

    const rules = computed(() => ({
        supplier_id: { required },
        project_id: { required},
        currency:{required},
        payment_date:{required},
        amount:{required},
        bank_name:{required},
        bank_account_no:{required},
        note:{required}
    }))

    const v$ = useVuelidate(rules, data)

	const submit = async () => {

        const result = await v$.value.$validate()
        if(!result) return

        isSubmitting.value = true

        let request
        
        if(data.value.payment_request_id) {
            request = API.put('accounting/payment-requests/' + data.value.payment_request_id,data.value)
        } else {
            request = API.post('accounting/payment-requests',data.value)
        }
        
        request.then( response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:'Ödeme talebin işleme alındı.'
            })
            isSubmitting.value = false
            isOpened.value = false
            modal_closed()
            emitter.emit('load_payment_requests')
        }).catch( error => {
            console.log(error)
            isSubmitting.value = false
        })
    }

</script>