<template>
  	<TabGroup :selectedIndex="selectedTab" @change="changeTab">
		<TabList class="flex justify-between w-full my-4">
			<Tab
				v-for="title,k in titles"
				:key="title"
				v-slot="{ selected }" as="template"
			>
				<button
					class="text-sm wizard_step flex flex-col items-center justify-top outline-0"
					:class="{
						'first_step': k == 0,
						'last_step': k == titles.length - 1,
						'completed': step-1 > k,
						//'current': step-1 == k,
						'text-black': maxStep > k,
						'current' : selected,
						'pointer-events-none cursor-not-allowed' : maxStep - 1 < k && !update_mode,
						'pointer-events-auto cursor-pointer' : maxStep - 1 >= k
					}"
					:disabled="maxStep - 1 < k && !update_mode"
        		>
				<i class="pointer-events-none fa-light text-lg bg-white relative z-10" :class="{
					'fa-circle-1' : k+1 == 1,
					'fa-circle-2' : k+1 == 2,
					'fa-circle-3' : k+1 == 3,
					'fa-circle-4' : k+1 == 4,
					'fa-circle-5' : k+1 == 5,
					'fa-circle-6' : k+1 == 6,
					'fa-circle-7' : k+1 == 7,
					'fa-circle-8' : k+1 == 8,
					'fa-circle-9' : k+1 == 9
				}" v-if="k + 1 > step"></i>
				<i class="pointer-events-none fa-regular fa-circle-dot text-lg text-calypso bg-white relative z-10" v-if="k + 1 == step"></i>
				<i class="pointer-events-none fa-regular fa-circle-check text-lg text-orange-500 relative z-10" v-if="k + 1 < step"></i>
				<div class="pointer-events-none mx-2"
					:class="{
						'text-calypso font-bold' : step-1 == k,
						'text-orange-500' : step-1 > k
					}"
				>{{ title }}</div>
				</button>
			</Tab>
			
		</TabList>
		<h4 class="font-black text-xl mb-2" v-if="show_step_titles">Adım {{selectedTab+1}}: {{ titles[selectedTab] }}</h4>
		<TabPanels :selectedIndex="selectedTab">
			<slot :selectedIndex="selectedTab"></slot>
		</TabPanels>
	</TabGroup>
</template>

<script setup>
	import { ref,computed,watch } from 'vue'
	import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
	const props = defineProps({
		titles:Array,
		vertical:{
			type:Boolean,
			default:false
		},
		step:Number,
		show_step_titles:{
			type:Boolean,
			default:true
		},
		update_mode:{
			type:Boolean,
			default:false
		}
	})
	const emit = defineEmits(['tab_change'])
	const selectedTab = computed(()=>props.step - 1)
	const maxStep = ref(1)

	watch(() => props.step, data => {
		if(data > maxStep.value) {
			maxStep.value = data
		}
	},{ immediate: true })

	const changeTab = index => emit('tab_change', index)
	
</script>

<style scoped>
	.wizard_step:after {content: "";width: 44%;height: 1px;background-color: #ccc;position: absolute;top: 13px;right: 0px;}
	.wizard_step.first_step:before {background-color: transparent !important;}
	.wizard_step:before {content: "";width: 48%;height: 1px;background-color: #ccc;position: absolute;top: 13px;left: 0px;z-index: 1;}
	.wizard_step.last_step:after {background-color: transparent !important;}
	.wizard_step {position: relative;flex: 1 0 0px;}

	.wizard_step.completed:after,.wizard_step.completed:before { @apply bg-orange-500}
	.wizard_step.current:before { @apply bg-orange-500}
</style>
