<script setup>
    import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

    import { computed, onMounted, ref, watch, toRef } from "vue";
    import { ChevronDownIcon, CheckIcon, XIcon } from "@heroicons/vue/solid";
    import { TabPanel } from "@headlessui/vue";

    const props = defineProps({
        modelValue: [String, Number, Array, Object],
        title: String,
        label: String,
        id: String,
        source: Array,
        display: {
            type: String,
            default: "inline",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        use_arrow: {
            type: Boolean,
            default: true,
        },
        styled: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: "normal",
        },
    });

    //const checked_items = ref(props.modelValue)
    const checked_items = ref(props.modelValue || []);
    
    const emit = defineEmits(["update:modelValue"]);

    const rand_id = computed(
        () => "input_" + Math.floor(Math.random() * 1000000000)
    );
    const popover_button = ref(null);
    const isOpen = ref(false);
    const popover_v_position = ref("down");
    const popover_h_position = ref("left");
    const active_tab = ref(0);

    const tab_titles = computed(() => props.source.map(x=>x.title))

    const set_checked_items = () => {
        checked_items.value = [];
        if (typeof props.modelValue == "object") {
            props.modelValue.forEach( item => {
                checked_items.value.push(props.source.find( x => x.value == item));
            });
        } else if (
            typeof props.modelValue == "string" ||
            typeof props.modelValue == "number"
        ) {
            checked_items.value.push(
                props.source.find( x => x.value == props.modelValue )
            );
        }
        console.log(checked_items.value);
    };

    watch(
        () => props.modelValue,
        (data) => {
            console.log("watch",data)
            if (!data) return;
            set_checked_items();
        },
        { immediate: true }
    );

    const onchange = (data) => {
        if (data.checked) {
            checked_items.value.push(data.value);
        } else {
            checked_items.value = checked_items.value.filter(
                (item) => item !== data.value
            );
        }
        emit("update:modelValue", checked_items.value);
    };

    const select = (data, close) => {
        //console.log(data)
        if (!data) {
            checked_items.value = [];
            emit("update:modelValue", data);
            close();
            return;
        }

        let selected_item
        props.source.forEach( cat => {
            let option = cat.options.find( x => x.value == data)
            if(option) {
                selected_item = option
            }
        })
        checked_items.value = [selected_item];
        //checked_items.value[0].index = index;
        console.log(checked_items.value);
        emit("update:modelValue", data);
        close();
    };

    const reset = () => {
        checked_items.value = [];
        emit("update:modelValue", null);
    };

    const calculate_position = () => {
        const distance_to_bottom =
            window.innerHeight - popover_button.value.el.getBoundingClientRect().y;
        popover_v_position.value = distance_to_bottom < 400 ? "up" : "down";

        const distance_to_right =
            window.innerWidth - popover_button.value.el.getBoundingClientRect().x;
        popover_h_position.value = distance_to_right < 500 ? "right" : "left";
    };

    const show_popover = () => {
        //console.log('active_tab',active_tab.value)
        calculate_position();
        //isOpen.value = !isOpen.value
        if (isOpen.value) {
            //calculate_position()
        }
    };

    watch(
        () => popover_button.value,
        (data) => {
            if (!popover_button.value) return;
            setTimeout(calculate_position, 200);
            //calculate_position()
        },
        { immediate: true }
    );

    watch(
        () => isOpen.value,
        (data) => {
            //console.log('isOpen',data)
        },
        { immediate: true }
    );

    const changeTab = (index) => (active_tab.value = index);
</script>

<template>
    <div>
        <UILabel
            :label="label"
            :rand_id="id || rand_id"
            @click="show_popover"
        />
        <Popover class="relative" v-slot="{ open }">
            <PopoverButton
                ref="popover_button"
                class="flex items-center ring-2 ring-transparent truncate text-ellipsis max-w-full"
                :class="{
                    'w-full rounded-iq focus:outline-none focus:ring-calypso border border-gray-300 bg-gray-50 p-2 h-[42px]':
                        display == 'box',
                    'text-sm text-calypso font-bold': display == 'inline',
                    'text-xs': size == 'xs',
                    'text-sm': styled,
                }"
                @click="show_popover"
            >
                <div
                    class="flex"
                    :class="{ 'flex-wrap': multiple }"
                    v-if="checked_items.length > 0 && !multiple"
                >
                    <span
                        v-for="checked_item in checked_items"
                        :key="checked_item.value"
                        class="flex items-center"
                        :class="{
                            'p-1 border-slate-400 border rounded text-sm mb-1 mr-1':
                                multiple,
                        }"
                    >
                        <div
                            class="w-6 rounded-full overflow-hidden mr-1"
                            v-if="checked_item.image"
                        >
                            <img :src="checked_item.image" />
                        </div>
                        <span class="truncate">{{ checked_item.text }}</span>
                    </span>
                </div>
                <span v-if="checked_items.length == 0" class="text-slate-400">
                    {{ title }}
                </span>
                <span v-if="checked_items.length > 0 && multiple"
                    >{{ title }}
                    <span
                        class="mx-1 bg-calypso text-white px-1 rounded text-xs"
                        >{{ checked_items.length }}</span
                    ></span
                >
                <span
                    v-if="
                        checked_items.length > 0 &&
                        checked_items[0] &&
                        !multiple
                        && false
                    "
                    class="flex items-center"
                    ><img
                        :src="checked_items[0].image"
                        class="w-6 h-6 rounded-full mr-2"
                        v-if="checked_items[0].image"
                    />{{ checked_items[0].text }}</span
                >
                <ChevronDownIcon
                    v-if="use_arrow"
                    class="w-4 h-4 text-calypso duration-300"
                    :class="{
                        'ml-auto': display == 'box',
                        'transform rotate-180': isOpen,
                    }"
                />
                <XIcon
                    v-if="
                        checked_items.length > 0
                    " 
                    class="w-4 h-4 text-gray-500 hover:text-gray-700 duration-300 hover:bg-gray-200"
                    @click.stop="reset"
                />
            </PopoverButton>

            <div v-if="open">
                <transition
                    enter-active-class="transition duration-200 ease-out"
                    enter-from-class="translate-y-1 opacity-0"
                    enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="transition duration-150 ease-in"
                    leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="translate-y-1 opacity-0"
                >
                    <PopoverPanel
                        static
                        class="absolute z-10 shadow bg-white border border-gray-iq shadow-iq rounded-iq min-w-max"
                        v-slot="{ close }"
                        :class="{
                            'w-full': display == 'box',
                            'mt-2': display == 'inline',
                            '-translate-y-full -top-5':
                                popover_v_position == 'up',
                            'right-0': popover_h_position == 'right',
                        }"
                    >
                        <div
                            v-if="
                                display == 'inline' &&
                                popover_v_position == 'down'
                            "
                            class="-top-2 w-4 h-4 absolute bg-white border-r border-b border-gray-iq -rotate-135 transform"
                            :class="{
                                'right-5': popover_h_position == 'right',
                                'left-5': popover_h_position == 'left',
                            }"
                        ></div>
                            
                        <UITabs
                            :selected_index="active_tab"
                            :titles="tab_titles"
                            :vertical="true"
                            class="w-full px-2 sm:px-0"
                            @change="changeTab"
                        >
                            <TabPanel
                                v-for="(
                                    data_source, tab_titles_index
                                ) in source"
                                :key="tab_titles_index"
                                class="w-64"
                            >
                                <div class="max-h-80 overflow-y-auto py-1">
                                    <UIList
                                        v-if="
                                            data_source.options.length > 0 && multiple
                                        "
                                    >
                                        <UICheckbox
                                            v-for="(item, k) in data_source.options"
                                            :key="k"
                                            :inputValue="item.value"
                                            :label="item.text"
                                            @change="onchange"
                                            :checked="
                                                checked_items.filter(
                                                    (x) =>
                                                        x ==
                                                        item.value.toString()
                                                ).length > 0
                                            "
                                            class="hover:bg-teal pl-3 pr-10 py-2"
                                        />
                                    </UIList>
                                    <UIList
                                        v-if="
                                            data_source.options.length > 0 && !multiple
                                        "
                                    >
                                        <!--
                                        <li @click="select(null,close)" class="hover:bg-teal duration-500 pr-10 py-2 pl-4 pr-10 text-sm flex items-center relative cursor-pointer">
                                            {{title}}
                                            <span
                                                v-if="checked_items.length == 0"
                                                class="absolute inset-y-0 right-2 flex items-center pl-3 text-amber-600"
                                            >
                                                <CheckIcon class="w-5 h-5 text-calypso" aria-hidden="true" />
                                            </span>
                                        </li>
                                        -->
                                        <li
                                            v-for="(item, k) in data_source.options"
                                            :key="k"
                                            :data="item"
                                            @click="
                                                select(
                                                    item.value,
                                                    close
                                                )
                                            "
                                            class="hover:bg-teal duration-500 pr-10 py-2 pl-4 pr-10 text-sm flex items-center relative cursor-pointer"
                                        >
                                            <div
                                                class="w-8 rounded-full overflow-hidden mr-4"
                                                v-if="item.image"
                                            >
                                                <img :src="item.image" />
                                            </div>
                                            <div>
                                                <div>{{ item.text }}</div>
                                                <div
                                                    v-if="item.subtext"
                                                    class="text-xs text-slate-500"
                                                >
                                                    {{ item.subtext }}
                                                </div>
                                            </div>
                                            <span
                                                v-if="
                                                    checked_items[0] &&
                                                    checked_items[0].value ==
                                                        item.value
                                                "
                                                class="absolute inset-y-0 right-2 flex items-center pl-3 text-amber-600"
                                            >
                                                <CheckIcon
                                                    class="w-5 h-5 text-calypso"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </li>
                                    </UIList>
                                    <div
                                        v-if="data_source.length == 0"
                                        class="py-5 text-center text-sm"
                                    >
                                        Hiç sonuç bulunamadı
                                    </div>
                                </div>
                            </TabPanel>
                        </UITabs>

                        <div
                            v-if="
                                display == 'inline' &&
                                popover_v_position == 'up'
                            "
                            style="z-index: -1"
                            class="-bottom-2 left-5 w-4 h-4 absolute bg-white border-l border-t border-gray-iq -rotate-135 transform"
                        ></div>
                    </PopoverPanel>
                </transition>
            </div>
        </Popover>
    </div>
</template>
