import API from '@/api'

const state = {
    list: [],
    current_supplier:null,
    paginator:null,
    users:[],
    statuses:[
        {id:0,value:"Hepsi"},
        {id:1,value:"Mevcut"},
        {id:2,value:"Potansiyel"}
    ],
    cusisine_types:[
        {value:'',text:'Steakhouse'},
        {value:'',text:'Deniz Mahsulleri'},
        {value:'',text:'Dünya Mutfağı'},
        {value:'',text:'Japon Mutfağı'},
        {value:'',text:'İtalyan Mutfağı'},
        {value:'',text:'Osmanlı Mutfağı'},
        {value:'',text:'Türk Mutfağı'},
        {value:'',text:'Akdeniz Mutfağı'},
        {value:'',text:'Meksika Mutfağı'},
        {value:'',text:'Hint Mutfağı'},
        {value:'',text:'Ege Mutfağı'},
        {value:'',text:'Asya Mutfağı'},
        {value:'',text:'Lübnan Mutfağı'},
        {value:'',text:'Arap Mutfağı'},
        {value:'',text:'Füzyon Mutfağı'},
        {value:'',text:'Suşi'},
        {value:'',text:'Meyhane'},
        {value:'',text:'Wine Bar'}
    ],
    temp_supplier:{}
}

// getters
const getters = {
    list (state) {
        return state.list;
    },
    list_select (state) {
        return state.select
        return state.list.map(supplier => { return {value : supplier.id, text : supplier.name, image : supplier.logo_full}}).sort((a, b) => a.text.localeCompare(b.text))
    },
    status: (state) => (status) => {
        return state.statuses.find((x) => x.id == status).value
    }
}
  
// actions
const actions = {
    setCurrentSupplier ({ commit }, data) {
        commit('setCurrentSupplier', data)
    }
}
  
// mutations
const mutations = {
    setCurrentSupplier(state, data) {
        state.current_supplier = data
    },
    setPaginator (state, data) {
        data.links.splice(0,1)
        data.links.splice(-1,1)
        state.paginator = data
    },
    setSuppliers (state, data) {
        state.list = data
        state.select = data.map(supplier => { return {value : supplier.id, text : supplier.name, image : supplier.logo_full}}).sort((a, b) => a.text.localeCompare(b.text))
    },
    setFullSuppliers (state, data) {
        state.data = data
        state.select = data.map(supplier => { return {value : supplier.id, text : supplier.name, image : supplier.logo_full}}).sort((a, b) => a.text.localeCompare(b.text))
    },
    setTempSupplier(state, data) {
        state.temp_supplier = {...state.temp_supplier, ...data}
    },
    setTempSupplierContacts(state, data) {
        state.temp_supplier.contacts = data
    },
    setTempSupplierNotes(state, data) {
        state.temp_supplier.notes = data
    },
    resetTempSupplier(state) {
        state.temp_supplier = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}