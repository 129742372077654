<template>
    <FormSetup :form_data="form_data"/>
</template>

<script setup>
    import { computed , watch} from 'vue'
    import { useStore } from 'vuex'
    import FormSetup from '../form_detail/RSVPFormSetup.vue'

    const store = useStore()

    const form_data = computed(() => store.state.form.active_form)
    watch(() => form_data, data => {
        //console.log(data)
    },{ immediate: true,deep:true })
</script>