<template>
    <UIFireAlarm titleText="Push bildirim ayarın kapalı!" type="danger" v-if="!granted">
        <button type="button" class="text-sm ml-auto bg-red-600 rounded p-2 -mt-2 duration -300 hover:bg-red-800" @click="requestPushToken">Bildirimleri Aç</button>
    </UIFireAlarm>
    <UIConfirm ref="ref_alert" :destructive="true"/>
</template>

<script setup>
    import { computed,ref } from 'vue'
    import { useStore } from 'vuex'
	
	import WebPushNotificationManager from '@/composables/WebPushNotificationManager'
	
	
	const store = useStore()
    const ref_alert = ref()
	const granted = ref(Notification.permission == "granted")
    const me = computed(() => store.state.auth.me)
    const NM = new WebPushNotificationManager(me.value.code)

    const requestPushToken = () => {
        NM.request()
        .then(response => granted.value = true)
        .catch(error => {
            ref_alert.value.show({
                title: 'HATA',
                message: 'Push bildirim izni alınamadı!',
                submessage: 'Lütfen tekrar dene',
                okButton: 'Kapat',
                cancelButton:false
            })
        })
    }

</script>