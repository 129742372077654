import Driver from "driver.js"
import { nextTick } from 'vue'

let VueDriver = {}

VueDriver.steps = []

VueDriver.install = (app, options) => {
	
	VueDriver.driver = new Driver({})
	// driver.highlight('selector');

	app.directive("driver-step", {
		mounted(el, binding, vnode, oldVnode) {
			
			let step = {
				element: el,
				popover: {
					...(binding.value ? binding.value.popover || {} : {}),
				},
			}
			if (binding.value && binding.value.index != null) {
				console.log("We have an index!")
				VueDriver.steps.splice(binding.value.index, 0, step)
			} else {
				console.log("We have nothing :-(")
				VueDriver.steps.push(step)
			}
			if (binding.value && binding.value.onclick === true) {
				// This may cause a bug
				el.onclick = (event) => VueDriver.driver.highlight(step)
			}
			// console.dir(el)
			// console.log(binding.value)
			// console.log(vnode)
			// console.log(oldVnode)
		},
	})
	app.config.globalProperties.$driver = VueDriver.driver
	app.config.globalProperties.$startTour = (index) => {
		nextTick(() => {
			console.log(VueDriver.steps)
			VueDriver.driver.defineSteps([...VueDriver.steps])
			// console.log(VueDriver.steps)
			// console.log(VueDriver.driver.steps)
			VueDriver.driver.start(index)
		})
	}
}

export default VueDriver
