<script setup>
    import { computed, ref, watch ,onBeforeMount, onMounted} from "vue"
    import API from "@/api"
    import { useStore } from "vuex"
    import _ from 'lodash'

    const store = useStore()

    const form_data = computed(() => store.state.form.active_form)
    const active_field = computed(() => store.state.form.active_field)
    const new_row = ref("")
    const new_column = ref("")
    const rows = ref([])
    const columns = ref([])

    const add_row = () => {
        if(rows.value.includes(new_row.value) || !new_row.value) return
        rows.value.push(new_row.value)
        updateDB()
        new_row.value = ""
    }

    const add_column = () => {
        if(columns.value.includes(new_column.value) || !new_column.value) return
        columns.value.push(new_column.value)
        updateDB()
        new_column.value = ""
    }

    const delete_column = index => {
        columns.value.splice(index,1)
        updateDB()
    }

    const delete_row = index => {
        rows.value.splice(index,1)
        updateDB()
    }

    const updateDB = () => {
        let field = _.cloneDeep(active_field.value)
        if(!field.props) field.props = {}
        
        field.props.options = JSON.stringify({rows:rows.value,columns:columns.value})
        
        API.saveFormField(field.id, field)
			.then( response => {
				
			})
			.catch( error => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
	}

</script>

<template>
    <UIFormRow>
        <table class="w-full">
            <thead class="border-b">
                <tr>
                    <th></th>
                    <th v-for="column,index in columns" @click="delete_column(index)">{{ column }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row,index in rows" class="border-b">
                    <td class="py-2"  @click="delete_row(index)">{{ row }}</td>
                    <td v-for="column in columns" class="text-center"><input type="radio" :name="`col${index}`"></td>
                </tr>
            </tbody>
        </table>
    </UIFormRow>
    <hr class="my-5" />
    <UIFormRow class="flex gap-3 items-end">
        <UIInput
            label="Yeni satır ekle"
            class="grow"
            v-model="new_row"
        />
        <UIButton
            class="w-24 items-center"
            type="submit"
            text="Ekle"
            @click="add_row"
        />
    </UIFormRow>
    <hr class="my-5" />
    <UIFormRow class="flex gap-3 items-end">
        <UIInput
            label="Yeni sütun ekle"
            class="grow"
            v-model="new_column"
        />
        <UIButton
            class="w-24 items-center"
            type="submit"
            text="Ekle"
            @click="add_column"
        />
    </UIFormRow>
</template>