const Messages = {
    message: {
        accounting:'Accounting',
        hello: 'Hello',
        settings: 'Settings',
        live: 'Live',
        fullscreen: 'Full Screen',
        type_a_message:'Type a message',
        happeningnow_title:'Happening Now',
        new:'New',
        manage:'Manage',
        profile:{
            myprofile:'My Profile',
            myprofileinfo:'My Profile Info',
            myschedule:'My Schedule',
            myschedule_empty:'You have no sessions in your schedule.',
            mymeetings:'My Meetings',
            myconnections:'My Connections',
            mynotes:'My Notes',
            mynotes_empty:'You have no notes taken.'
        },
        notifications:{
            title:'Notifications',
            empty:'You have no notifications.'
        },
        inapp_messages:{
            title:'Messages',
            empty:'You have no messages.',
            attendee_blocked:'You\'ve blocked this attendee',
            unblock:'Unblock',
            block_this_attendee:'Block this attendee'
        },
        session:{
            start_session:'Start the session',
            end_session:'End session',
            poll:'Poll',
            cta:'Call-to-Action',
            manage_session:'Manage the session',
            start_broadcasting:'Go live',
            attendee_count:'Attendees in the sesssion',
            raise_hand:'Raise Hand',
            moderation_view:'Moderation View'
        }
    },
    request_types: {
        annual_leave : 'annual leave',
        compassionate_leave : 'compassionate leave',
        medical_leave : 'medical leave',
        post_event : 'post event leave',
        rest_leave : 'rest leave',
        operation : 'operation',
        inspection : 'inspection',
        expense : 'expense',
        advance_payment : 'advance payment',
        payment_request : 'payment request',
        cc_request : 'credit card request',
        sales_invoice_request : 'sales invoice request'
    },
    notifications:{
        BizdevCreated: '%{user} added a new lead called <b>%{project_name}</b>. 🔥🔥',
        BudgetCreated: '%{user} created a new budget called <b>%{budget_name}</b>.',
        ClientCreated: '%{user} created a new client called <b>%{client_name}</b>.',
        ApprovalRequested: '%{user} requests your approval for <b>%{sourcce}</b>.',
        ApprovalDeclined: '%{user} declined your approval request for <b>%{source}</b>.',
    }
}

export default Messages