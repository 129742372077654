<script setup>
    import { computed,reactive,ref,inject,watch } from 'vue'
	import { useStore } from 'vuex'
    import SelectNested from '../UI/SelectNestedv2.vue'

    const emit = defineEmits(['update:modelValue:scope','update:modelValue:scope_id'])

    const props = defineProps({
        scope:[String,Number,Array,Object],
        scope_id:[String,Number,Array,Object],
        title: String,
        label: String
    })
	
	const store = useStore()

    const me = computed(() => store.state.auth.me)
    const scope = computed(() => store.state.auth.scope)
    const this_value = ref( scope.value.scope + '|' + (scope.value.scope_id || 'all') )
    
    
	const users_select = computed(() => store.getters['content/users_select'])
	const teams_select = computed(() => store.getters['content/teams_select'])
	const divisions_select = computed(() => store.getters['content/divisions_select'])

    const teams_select_with_all = computed(() => [...[{value:'all',text:'Hepsi'}], ...teams_select.value])

    const users_nested_titles = ref([])
    const users_nested_data = ref([])
    const options = ref([])

    if(scope.value.scope == 'AGENCY') {
        users_nested_titles.value = ["Hepsi", "Bölümler","Takımlar","Kişiler"]

        users_nested_data.value = [
            [{value:'all',text:'Tüm bölümler, takımlar ve kişiler'}],
            divisions_select.value,
            teams_select.value,
            users_select.value
        ]
        options.value = [
            {
                value: 'AGENCY',
                text: 'Hepsi',
            },
            {
                value: 'DIVISION',
                text: 'Bölümler',
                children: divisions_select.value
            },
            {
                value: 'TEAM',
                text: 'Takımlar',
                children: teams_select.value
            },
            {
                value: 'USER',
                text: 'Kişiler',
                children: users_select.value
            },
        ];
    }
    if(scope.value.scope == 'DIVISION') { 
        users_nested_titles.value = ["Takımlar","Kişiler"]
	    users_nested_data.value = [
            teams_select.value.filter( x => x.division_id == scope.value.scope_id),
            users_select.value.filter( x => x.division_id == scope.value.scope_id)
        ]
    }
    if(scope.value.scope == 'TEAM') {
        users_nested_titles.value = ["Kişiler"]
	    users_nested_data.value = [users_select.value.filter(x=>x.team_id == scope.value.scope_id)]
    }
    if(scope.value.scope == 'USER') {
        users_nested_titles.value = ["Kişiler"]
	    users_nested_data.value = [users_select.value.filter(x=>x.id == scope.value.scope_id)]
    }
    

    const onChange = () => {
        emit('update:modelValue:scope', this_value.value)
        return
        const response = {}
        console.log(this_value.value)
        const data = this_value.value.split('|')
        if(data[0] == '0') {
            response.scope = 'AGENCY'
        }
        if(data[0] == '1') {
            response.scope = 'DIVISION'
        }
        if(data[0] == '2') {
            response.scope = 'TEAM'
        }
        if(data[0] == '3') {
            response.scope = 'USER'
        }
        response.id = data[1]
        emit('update:modelValue:scope', response.scope + '|' + response.id)
        emit('update:modelValue:scope_id', response.id)
    }
    
</script>

<template>
    <SelectNested :label="label" :title="title" :source="options" v-model="this_value" @update:modelValue="onChange"/>
</template>