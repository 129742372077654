<script setup>
	import { computed,ref,inject,watch, onMounted } from 'vue'
	
	const display_icon = ref(false)
    const is_popover_open = ref(false)

    const show_icon = () => {
        if(is_popover_open.value) return
        display_icon.value = true
    }

    const hide_icon = () => {
        if(is_popover_open.value) return
        display_icon.value = false
    }

    const close = () => {
        is_popover_open.value = false
        display_icon.value = false
    }

</script>

<template>
	<div class="flex group" @mouseover="show_icon" @mouseout="hide_icon">
        <slot name="text"></slot>
        <UIPopover class="ml-2 flex items-center" position="right" @open="is_popover_open = true" @close="close">
            <template v-slot:title>
                <i class="fa-regular text-sm fa-pen text-calypso duration-300" 
                :class="{
                    'opacity-0' : !display_icon,
                    'opacity-100' : display_icon
                }"/>
            </template>
            <template v-slot:content>
                <slot name="popover"></slot>
                <div>
                    <UIButton text="Kaydet" type="submit" @click="close"/>
                </div>
            </template>
        </UIPopover>
    </div>
</template>
