import Pusher from 'pusher-js';
import store from '@/store'
import API from '@/api'


Pusher.logToConsole = false;

let root_url, pusher, event_company_code

if(window.location.hostname == 'localhost') {
    root_url = 'http://localhost/api/v1/pusher/auth'
    //root_url = 'http://127.0.0.1:8000/api/v1/pusher/auth'
} else {
    root_url = 'https://xhqfa3htizxfzgkrv5fsopeiiu0uaazf.lambda-url.eu-west-2.on.aws/api/v1/pusher/auth'
}


const connect = (code) => {
    event_company_code = code
    pusher = new Pusher('986dbfda373da856144e', {
        cluster: 'eu',
        transport: "jsonp",
        //forceTLS: true,
        userAuthentication: {
            endpoint: root_url,
            headers: {
                'Authorization' : 'Bearer ' + store.state.auth.access_token,
                'Accept': 'application/json'
            }
        },
        channelAuthorization: {
            endpoint: root_url,
            headers: {
                'Authorization' : 'Bearer ' + store.state.auth.access_token,
                'Accept': 'application/json'
            }
        }
    })
    pusher.signin()
    pusher.bind('pusher:signin_success', e => {
        subscribe2channels()
    });
    
}

const subscribe2channels = () => {
    console.log(event_company_code);
    var channel = pusher.subscribe('private-' + event_company_code)

    channel.bind('my-event', function(data) {
        console.log(data)
    });

    channel.bind('data-created', function(data) {
        console.log('data-created',data)
        window.mitt.emit('ai_response',data.message)
        if(data.type=='projects/setLeads'){
            API.getLeads()
            return
        }
        store.commit(data.type,data.content);
    });

    var presence = pusher.subscribe('presence-' + event_company_code)
    presence.bind('pusher:subscription_succeeded', e => {
        store.commit('chat/setOnlineUsers',presence.members);
    });
    
    presence.bind('pusher:member_added', e => {
        store.commit('chat/setOnlineUsers',presence.members);
    });
    presence.bind('pusher:member_removed', e => {
        store.commit('chat/setOnlineUsers',presence.members);
    });
}





/*


var channel = pusher.subscribe('my-channel');

channel.bind('my-event', function(data) {
    console.log(data)
})
*/

//export default RT

export { 
    connect
};