<script setup>
    // Imports
	import {ref, watch, inject} from 'vue'
    import { useStore } from 'vuex'
	import Modal from '@/components/UI/Modal.vue'
    
    // Props
    const props = defineProps({
        isOpen:Boolean,
        option:Object
    })

    // Assignments
    const store = useStore()
    const emitter = inject('emitter')

    // Emits
    const emit = defineEmits(['modal_closed'])

    // App Vars
    const isModalOpen = ref(props.isOpen)
    const formFieldOption = ref(props.option)
    const contractTitle = ref('Sözleşme İçeriği')

    watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    watch(() => props.option, (data) => {
        formFieldOption.value = data
        contractTitle.value=data.option_text
		},{ immediate: true }
    )

    const modal_closed = () =>{
		isModalOpen.value = false
		emit('modal_closed')
	}
    
</script>

<template>
    <Modal
        :colored_header="true"
        :isOpen="isModalOpen"
        @modal_closed="modal_closed"
        :title="contractTitle"
        size="xl"
        :showSubmitButton="false"
    >
        <template v-slot:modal_content>
            <div  class="mt-5 list-style" v-html="formFieldOption.text">
                
            </div>
        </template>
    </Modal>
</template>