
<template>
	<UIFullscreen :title="active_checklist.name" :back_link="{url:'/admin/checklists',title:'Checklist\'lere geri dön'}">
        <template v-slot:menu>
			<div class="w-[200px] flex items-center">
				<UIMenu class="ml-auto" dotsize="lg" :options="menu_options" position="right" dot/>
			</div>
		</template>
		<template v-slot:content>
			<UISideNav
				v-if="!isChecklistEmpty && active_checklist.name && excel_data.length == 0"
			>
				<template v-slot:left>
					<div class="flex items-start px-3 mt-5">
						<UIPageTitle class="w-full" title="Bölümler"/>
						
						<UIPopover ref="new_section_popover" class="ml-2 flex items-center" position="right" @open="is_new_section_popover_open = true" @close="new_section_popover_closed">
							<template v-slot:title>
								<UIButton text="" type="dark" size="sm" icon="plus" class="ml-auto" />
							</template>
							<template v-slot:content>
								<div class="my-3 text-dark">
									<UIInput v-model="new_section_name" placeholder="Yeni Bölüm Adı" @keypress.enter="submit_new_section"/>
								</div>
								<div>
									<UIButton text="Kaydet" type="submit" @click="submit_new_section"/>
								</div>
							</template>
						</UIPopover>
					</div>
					
					<div class="px-3 my-2">
						<div class="text-xs mb-3">Bu checklist'te {{ active_checklist.sections.length }} bölüm var.</div>
						<div class="overflow-hidden text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
							<button 
								aria-current="true" 
								type="button" 
								class="flex items-center w-full px-4 py-2 font-medium text-left border-b border-gray-200 cursor-pointer focus:outline-none"
								v-for="section in active_checklist.sections"
								:class="{
									'text-white bg-calypso' : active_section && active_section.id == section.id,
									'hover:bg-gray-100 hover:text-calypso' : active_section && active_section.id !== section.id,
								}"
								@click="set_active_section(section.id)"
							>
								<div class="truncate">{{ section.section_title }}</div>
								<UIBadge :show="section_items_length(section.id)" class="bg-gray-400 text-white ml-auto"/>
							</button>
						</div>

						
					</div>
				</template>
				<template v-slot:main>
					<ChecklistSection v-if="excel_data.length == 0 && active_section" :active_checklist="active_checklist" :active_section="active_section" :active_sections_items="active_sections_items"/>
				</template>
			</UISideNav>
			<div 
				class="w-full"
				v-if="isChecklistEmpty && active_checklist.name && excel_data.length == 0"
			>
				<UIEmptyState
					class="mt-20"
					title="Bu checklist henüz boş!"
					description="Checklist'i oluşturmak için farklı seçeneklerin var."
					:actions="empty_state_actions"
				/>
			</div>
			<ExcelUpload 
				v-if="excel_data.length > 0" 
				:checklist_id="route.params.id" 
				:first_row_data="first_row_data" 
				@cancel="cancel_excel"
				:excel_data="excel_data" 
			/>
			<input type="file" ref="input_upload" class="hidden" @change="read_excel"/>
		</template>
    </UIFullscreen>
    
	<FormModal
		v-if="modalProps"
		v-model="data_to_update"
		:isSubmitting="isSubmitting"
		:modalProps="modalProps"
		:isOpen="isModalOpen"
		@submit="submit"
		@modal_closed="modal_closed"
	/>

	<ChecklistSectionModal :isOpen="isChecklistModalOpen" :checkListId="route.params.id" @modal_closed="modal_checklist_closed"/>
	
	<UIConfirm ref="ref_delete_checklist" :destructive="true"/>
</template>

<script setup>
	import { ref, computed, onMounted, watch, inject } from "vue"
	import FormModal from "@/modals/Form.vue"
	import ExcelUpload from './ExcelUpload.vue'
	import ChecklistSection from './ChecklistSection.vue'
	import readXlsxFile from 'read-excel-file'
	import API from "@/api"
	import ChecklistSectionModal from "@/modals/def/ChecklistSection.vue"
	
    import { useRoute, useRouter } from 'vue-router'
	import { useStore } from 'vuex'
    
	const emitter = inject('emitter')
	const route = useRoute()
	const router = useRouter()
	const store = useStore()
    
    // Vars
	const modalProps = computed(() => {
		return {
			title: Object.keys(data_to_update.value).length
				? "Checklist'i Güncelle"
				: "Yeni Checklist Ekle",
			cols: 1,
			schema: [
				{
					id: "name",
					component: "UIInput",
					label: "Checklist Adı",
					// component props:
					required: true,
				},
				{
					id: "name",
					component: "UIInput",
					label: "Hangi Bölüm?",
					// component props:
					required: true,
				},
			],
		}
	})

	const set_active_section = id => active_section.value = active_checklist.value.sections.find( x=> x.id == id)
	
	const ref_delete_checklist = ref()

    const excel_data = ref([])
	const first_row_data = ref([])
	const new_section_name = ref()
	
	const isModalOpen = ref(false)
	const isChecklistModalOpen = ref(false)
	const isSubmitting = ref(false)
	const data_to_update = ref({})
	const input_upload = ref(null)
	
	const active_checklist = ref({items:[],sections:[]})
	const active_section = ref(null)
	
	const active_sections_items = computed(() => {
		if(active_checklist.value.items.length == 0 || active_section.value == null) return []
		return active_checklist.value.items.filter( x => x.section_id == active_section.value.id )
	})

    const isChecklistEmpty = computed(() => active_checklist.value.items.length == 0 && active_checklist.value.sections.length == 0 )

	const modal_closed = () => {
		isModalOpen.value = false
	}

	const modal_checklist_closed=()=>{
		isModalOpen.value = false
		load_checklist()
	}

	const upload = () => input_upload.value.click()

	const newSection=()=>{
		isChecklistModalOpen.value=true
		load_checklist()
	}

	const read_excel = () => {
		if(input_upload.value.files.length == 0) return
		readXlsxFile(input_upload.value.files[0]).then((rows) => {
			excel_data.value = rows
			first_row_data.value = rows[0].map((x,index) => { return { value:index, text:x } })
		})
	}

	const cancel_excel = () => {
		excel_data.value = []
		first_row_data.value = []
		load_checklist()
	}
 
    const empty_state_actions = [
        {
            title: 'Yeni Bölüm Ekle',
            icon: 'plus',
			click:newSection
        },
        {
            title: 'Dosyadan Yükle',
            icon: 'upload',
			click: upload
        }
    ]

	const submit = (data) => {
		
		isSubmitting.value = true

		if (data.id) {
			API.put("checklists/" + data.id, data)
				.then((response) => {
					isModalOpen.value = false
					isSubmitting.value = false
					data_to_update.value = {}
					load_checklist()
				})
				.catch(() => {
					isSubmitting.value = false
				})
		} else {
			API.post("checklists", data)
				.then((response) => {
					isModalOpen.value = false
					isSubmitting.value = false
					data_to_update.value = {}
					load_checklist()
				})
				.catch(() => {
					isSubmitting.value = false
				})
		}
	}

	const load_checklist = post_load_active_section_id => {
		API.get("checklists/" + route.params.id).then(response => {
				active_checklist.value = response.data
				if(post_load_active_section_id) {
					set_active_section(post_load_active_section_id)
				} else {
					if(!active_section.value) {
						active_section.value = active_checklist.value.sections.length > 0 ? active_checklist.value.sections[0] : null
					}
				}
            }
		)
	}
    load_checklist()
	emitter.on('load_def_checklist', reset => {
		if(active_checklist.value.sections.length == 0 || reset) {
			active_section.value = null
		}
		
		load_checklist()
	})

	
	emitter.on('delete_checklist_item', id => {
		active_checklist.value.items = active_checklist.value.items.filter( x => x.id !== id )
	})

	

	const submit_new_section = () => {
		
        if(!new_section_name.value) return
        API.post('checklists/' + active_checklist.value.id + '/section',{name:new_section_name.value})
		.then(response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:response.data.message,
            })
            new_section_name.value = ""
            load_checklist(response.data.id)
			is_new_section_popover_open.value = false
			new_section_popover.value.hide_item()
        })
		.catch(error => {
			store.dispatch('floating_alerts/addAlert', {
				type:'warning',
				title:'HATA!',
				description:error.response.data.message,
			})
		})
    }

	const section_items_length = section_id => {
		if(active_checklist.value.items.length == 0) return 0
		return active_checklist.value.items.filter( x => x.section_id == section_id ).length
	}

	const delete_checklist = () => {
		ref_delete_checklist.value.show({
			title: 'Emin misin?',
			message: 'Bu checklist\'i silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Checklist\'i Sil',
		}).then(result => {
			if (result) {
				API.delete('checklists/' + active_checklist.value.id)
				.then(response => {
					store.dispatch('floating_alerts/addAlert', {
						type:'tip',
						title:'BAŞARILI!',
						description:response.data.message,
					})
                    router.push({name: 'AdminChecklists'})
                })
                .catch(error => {
					store.dispatch('floating_alerts/addAlert', {
						type:'warning',
						title:'HATA!',
						description:error.response.data.message,
						closeable:false
					})
				})
			} 
		})
    }

	const reset_checklist = () => {
		ref_delete_checklist.value.show({
			title: 'Emin misin?',
			message: 'Bu checklist\'i sıfırlamak üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Checklist\'i Sıfırla',
		}).then(result => {
			if (result) {
				API.delete('checklists/' + active_checklist.value.id, {reset:true})
				.then(response => {
					store.dispatch('floating_alerts/addAlert', {
						type:'tip',
						title:'BAŞARILI!',
						description:response.data.message,
					})
                    load_checklist()
                })
                .catch(error => {
					store.dispatch('floating_alerts/addAlert', {
						type:'warning',
						title:'HATA!',
						description:error.response.data.message,
					})
				})
			} 
		})
    }

	const menu_options = ref([
		{
			name:'',
			items:[
                /*{
					icon:'clone',
                    name: 'Kopyala',
					href : () => {
                        
                    }
				},*/
                {
					icon:'broom-wide',
                    name: 'Checklist İçeriğini Sıfırla',
					href : reset_checklist
				},
                {
					icon:'trash',
                    name: 'Checklist\'i Sil',
					href : delete_checklist,
                    danger : true
				}
			]
		}
	])

	const new_section_popover = ref()
	const is_new_section_popover_open = ref(false)
	const new_section_popover_closed = () => new_section_name.value = ''
	
</script>