<template>
    <template v-if="isAndroid">
		<div class="items-center flex w-4/5 mx-auto min-h-screen">
			<img src="https://play.google.com/intl/en_us/badges/static/images/badges/tr_badge_web_generic.png" class="w-full">
		</div>
	</template>

	<template v-else-if="isIOS">
		<div class="items-center flex w-4/5 mx-auto min-h-screen">
			<img src="https://upload.wikimedia.org/wikipedia/commons/3/3b/Download_on_the_App_Store_Badge_TR_RGB_blk.svg" class="w-full">
		</div>
	</template>
	
	<template v-else>
		<SplashScreen v-if="!app_ready"/>
		
		<App v-if="route_layout == 'normal' && app_ready"/>
		<Forward v-if="route_layout == 'standalone' && app_ready"/>
		<UIFloatingAlertList v-if="floating_alerts.length > 0 && app_ready"/>
		
		<div class="fixed top-5 inset-x-1/4 z-[100] flex flex-col items-center" v-if="update_needed">
			<UIAlert titleText="Uygulama güncellendi!" type="warning" class="min-w-min mb-5 shadow-lg" @click="reload_page">
				Sayfayı yeniden yüklemek için tıkla!
			</UIAlert>
		</div>
	</template>
</template>

<script setup>
	import { computed, ref, watch, onMounted } from 'vue'
	import { useStore } from 'vuex'
	import App from './App.vue';
	import Forward from './pages/Forward.vue';
	import SplashScreen from './SplashScreen.vue'
	
	import { useRoute } from 'vue-router'
	import API from '@/api'

	API.getCSRF()
	
	const store = useStore()
	const route = useRoute()
	const route_layout = ref('')
	const isAndroid = ref(false)
	const isIOS = ref(false)
	const title = computed(() => store.state.page_header.title)
	const exceptional_routes = ['PostEventSurveyForm','ApprovalDecision','Checkin']


	watch(
		() => route.meta,
		meta => {
			route_layout.value = meta.layout
			document.title = title.value || 'easy.events'
		},
		{ immediate: true }
	)

	const app_ready = computed(() => store.state.content.appReady)
	const floating_alerts = computed(() => Object.entries(store.state.floating_alerts.list))
	const update_needed = computed(() => store.state.auth.updateNeeded)

	const reload_page = () => window.location.reload(true)

	const getMobileOS = name => {
		if(exceptional_routes.includes(name)) {
			isAndroid.value = false
			isIOS.value = false
			return
		}
		const ua = navigator.userAgent
		if (/android/i.test(ua)) {
			isAndroid.value = true
		}
		else if (/iPad|iPhone|iPod/.test(ua)) {
			isIOS.value = true
		}
	}

	watch(() => route.name, name => {
		if(!name) return
		getMobileOS(name)
	},{ immediate: true })
</script>