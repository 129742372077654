<script setup>
	import { ref,computed, watch, inject,shallowRef, defineAsyncComponent } from 'vue'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'
	
	//const AMClientDetail = defineAsyncComponent(() => import('./ActionMenus/ClientDetail.vue') )
	//const AMClients = defineAsyncComponent(() => import('./ActionMenus/Clients.vue') )
	
	const store = useStore()
	const route = useRoute()

	const image = computed(() => store.state.page_header.image)
	const overtitle = computed(() => store.state.page_header.overtitle)
	const title = computed(() => store.state.page_header.title)
	const subtitle = computed(() => store.state.page_header.subtitle)
	
	const is_scrolled = ref(false)
	const activeAM = shallowRef('')

	document.addEventListener('scroll', function(e) {
		//is_scrolled.value = window.scrollY > 100 ? true : false
	});

	const pages_with_AM = ['Bizdev','Depot','Clients','ClientDetail','Suppliers','SupplierDetail','ProjectDetail','AdminChecklists','Contacts','Tasks','BizdevDetail','Calendar','AdminOrganisation','Reports']

	watch(() => title.value, data => {
        document.title = data
	},{ immediate: true })
	
	watch(() => route.name, (name) => {
		if(!name) {
			name = route.matched[0].name
		}
		if(name == 'Bizdev') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Bizdev.vue') )
		} else if(name == 'Depot') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Depot.vue') )
		} else if(name == 'Clients') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Clients.vue') )
		} else if(name == 'ClientDetail') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/ClientDetail.vue') )
		} else if(name == 'Suppliers') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Suppliers.vue') )
		} else if(name == 'SupplierDetail') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/SupplierDetail.vue') )
		} else if(name == 'ProjectDetail') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/ProjectDetail.vue') )
		} else if(name == 'AdminChecklists') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/AdminChecklists.vue') )
		} else if(name == 'Contacts') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Contacts.vue') )
		} else if(name == 'Tasks') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Tasks.vue') )
		} else if(name == 'BizdevDetail') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/BizdevDetail.vue') )
		} else if(name == 'Calendar') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Calendar.vue') )
		} else if(name == 'AdminOrganisation') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/AdminOrganisation.vue') )
		} else if(name == 'Reports') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Reports.vue') )
		} else if(name == 'Projects') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/Projects.vue') )
		} else if(name == 'AdminPositions') {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/AdminPositions.vue') )
		} else {
			activeAM.value = null
		}
		
		/*
		if(pages_with_AM.includes(name)) {
			activeAM.value = defineAsyncComponent(() => import('./ActionMenus/'+name+'.vue') )
		} else {
			activeAM.value = null
		}
		*/
	},{ immediate: true })

	

</script>
<template>
	<div class="px-7 pb-2 lg:flex lg:items-center lg:justify-between relative">
		<div class="flex-1 min-w-0 flex">
			<div class="transition duration-500 ease-in-out rounded-full overflow-hidden mr-4"
			:class="{
				'w-8 h-8 mt-1' : is_scrolled,
				'w-24 h-24 mt-2' : !is_scrolled
			}"
			v-if="image">
				<img :src="image" class="object-cover h-full">
			</div>
			<div class="max-w-[60%]"
				:class="{
					'mt-1' : is_scrolled,
					'mt-2' : !is_scrolled
				}"
			>
				<div class="text-gray-500 font-normal text-sm" v-if="overtitle" v-html="overtitle"></div>
				<h2 class="font-thin text-gray-900 sm:truncate uppercase transition duration-500 ease-in-out text-4xl leading-[3rem]">
					{{title}}
				</h2>
				<div class="text-gray-500 font-normal text-sm" v-html="subtitle" v-if="!is_scrolled"></div>
			</div>
			<div class="ml-auto">
				<div class="flex items-center h-full">
					<component :is="activeAM" v-if="activeAM"></component>
				</div>
			</div>
		</div>
	</div>
</template>