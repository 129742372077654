<template>
    <UIPageTitle title="Tedarikçiler"/>
	<UITable :titles="table_titles">
		<template v-slot:rows>
			<tr v-if="suppliers.length == 0" class="h-96 "><td colspan="6" class="text-center"><h1>Aradığın kriterlerde bir tedarikçi yok görünüyor</h1></td></tr>
			<tr v-for="supplier in suppliers" :key="supplier.id" class="hover:bg-teal cursor-pointer">
				<UITableCell>
					<div class="font-bold truncate">{{ supplier.name }} <i class="fa fa-eye ml-2" @click="gotosupplier(supplier.code)"></i></div>
				</UITableCell>
                <UITableCell>
					<div class="flex gap-2" v-for="similar in supplier.data">
                        <span class="truncate">{{ similar.name }}</span>
                        <span class="text-green-300">({{ similar.percent }} %)</span>
                    </div>
				</UITableCell>

                <UITableCell>
					<div class="font-bold truncate"> <i class="fa fa-merge ml-2 border p-1" @click="open_merge_modal(supplier.id)"></i></div>
				</UITableCell>
			</tr>
		</template>
	</UITable>

    <FormModal 
		key="merge"
		actionButtonText='Birleştir'
		:isSubmitting="isSubmitting" 
		:modalProps="mergeModalProps" 
		:isOpen="isMergeModalOpen" 
		@submit="submit_merge" 
		@modal_closed="close_merge_modal"
	/>
</template>

<script setup>
	import { ref,computed,inject } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import API from '@/api'
	import TableView from '@/views/TableView.vue'
    import FormModal from '@/modals/Form.vue'
    
    const store = useStore()
	const emitter = inject('emitter')
    const router = useRouter()

    const isSubmitting = ref(false)
    const isMergeModalOpen = ref(false)


    const selected_supplier = ref(null)

    const suppliers= ref([])
    const similar_suppliers= ref([])
	const isModalOpen = ref(false)

	const empty_state = {
        title:'Benzer tedarikçi listesinde eşleşen veri bulunamadı!',
        description:''
	}

    let table_titles = [
		{
			title:'Tedarikçi Adı',
		},
        {
			title:'Benzer Veriler',
		},{
			title:'',
		}
	]

	const modal_closed =() => {
		isModalOpen.value = false
    }

    const gotosupplier = code => {
		router.push({name: 'SupplierDetail', params: { code: code }})
	}

    const getSuppliers = () =>{
        API.get('similarity/suppliers').then(response=>{
            suppliers.value=response.data.filter(x=>x.is_similarity)
            console.log(suppliers.value);
        })
    }

    getSuppliers()

    const open_merge_modal = id => {
        
        let supplierIndex=suppliers.value.findIndex(x=>x.id==id)
        if (supplierIndex>=0) {
           
            selected_supplier.value = id
            similar_suppliers.value=suppliers.value[supplierIndex].data.map(x=>{return {value:x.id,text:x.name}})
            console.log(similar_suppliers.value);

            isMergeModalOpen.value=true
        }
        
    }

    const close_merge_modal = () => {
        isMergeModalOpen.value = false
        isSubmitting.value = false
    }

    const submit_merge = data => {
        isSubmitting.value = true
        data.merge_from = selected_supplier.value
       
        API.put('merge/suppliers', data).then(response => {
			close_merge_modal()
            suppliers.value=suppliers.value.filter(x=>x.id!=data.merge_from && x.id!=data.merge_to)
            isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
				title:'BAŞARILI!',
				description:response.data.message
			})
		}).catch(error => {
			
            isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
				title:'HATA!',
				description:error.response.data.message
			})
		})
	}

    const mergeModalProps = computed(() => {return {
		title:'Tedarikçileri Birleştir',
		cols:1,
		header:{
			type:'tip',
			text:'Bu tedarikçiye ait tüm bilgiler aşağıda seçeceğin tedarikçiye aktarılacaktır.',
			title:'Bilgi'
		},
		schema: [
			{
				id: 'merge_to',
				component: 'UISelect',
				label: 'Hangi tedarkçi ile birleştirmek istiyorsun?',
				required: true,
				props:{
					title:'Tedarikçi seç',
					source:similar_suppliers.value,
					display:'box'
				}
			}
		]
	}})

</script>
