<template>
  	<div class="bg-white border-r min-w-70 flex justify-top flex-col items-center h-screen sticky top-0 z-15" id="menu">
        <div class="my-5" id="a">
            <router-link :to="{ name: 'Welcome'}">
                <img class="h-auto w-12" :src="nav_logo" :alt="event_company.name" />
            </router-link>
        </div>
		<ul class="mb-auto">
            <li class="mb-1">
                <UITooltip title="Teklif Yönetimi" position="right">
                    <router-link :to="{ name: 'Bizdev'}" class="menu-link">
                        <CurrencyDollarIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip title="Projeler" position="right">
                    <router-link :to="{ name: 'Projects'}" class="menu-link">
                        <TicketIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip title="Yapılacaklar" position="right">
                    <router-link :to="{ name: 'Tasks',params:{type:'list'}}" class="menu-link">
                        <ClipboardListIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip title="Takvim" position="right">
                    <router-link :to="{ name: 'Calendar'}" class="menu-link">
                        <CalendarIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip title="Raporlar" position="right">
                    <router-link :to="{ name: 'Reports'}" class="menu-link">
                        <ChartPieIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip :title="$t('message.accounting')" position="right">
                    <router-link :to="{ name: 'Accounting'}" class="menu-link">
                        <LibraryIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip title="Müşteriler" position="right">
                    <router-link :to="{ name: 'Clients'}" class="menu-link">
                        <OfficeBuildingIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip title="Tedarikçiler" position="right">
                    <router-link :to="{ name: 'Suppliers'}" class="menu-link">
                        <TruckIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1">
                <UITooltip title="Kontaktlar" position="right">
                    <router-link :to="{ name: 'Contacts'}" class="menu-link">
                        <IdentificationIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
            <li class="mb-1 hidden">
                <UITooltip title="Depo" position="right">
                    <router-link :to="{ name: 'Depot'}" class="menu-link">
                        <CubeIcon class="h-7 w-7"/>
                    </router-link>
                </UITooltip>
            </li>
        </ul>
        <div class="mb-1" v-if="me.is_admin == 1">
            <UITooltip title="Yönetim Ekranı" position="right">
                <router-link :to="{ name: 'Admin'}" class="menu-link">
                    <CogIcon class="h-7 w-7"/>
                </router-link>
            </UITooltip>
        </div>
        <div class="mb-3">
            <UITooltip title="Profilim" position="right">
                <router-link :to="{ name: 'Profile'}" class="menu-link">
                    <img :src="me.profile_picture_full"  class="w-10 h-10 rounded-full">
                </router-link>
            </UITooltip>
        </div>
        <div class="mb-1">
            <UITooltip title="Çıkış" position="right">
                <a @click="logout" class="menu-link">
                    <LogoutIcon class="h-7 w-7"/>
                </a>
            </UITooltip>
        </div>
	</div>
</template>

<style scoped>
    a:hover+span{
        background-color: black;
        display: block;
    }
    
    .menu-link {
        @apply w-14 h-14 flex items-center justify-center rounded mx-auto text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-100 cursor-pointer
    }
    .router-link-active {
        @apply bg-calypso-light
    }
    :global(#menu path) {
        stroke-width:1.2;
    }
    #menu span:after {content: "";width: 10px;height: 10px;transform: rotate(45deg);background: black;position: absolute;left: -4px;top: 10px;z-index: -1;}
</style>

<script setup>
    import { ref,computed,onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'

    import {
        CalendarIcon,
        ChartPieIcon,
        CubeIcon,
        ClipboardListIcon,
        CurrencyDollarIcon,
        CogIcon,
        IdentificationIcon,
        LibraryIcon,
        OfficeBuildingIcon,
        TruckIcon,
        TicketIcon,
        LogoutIcon
    } from '@heroicons/vue/outline'

    const store = useStore()
    const router = useRouter()
    
    const domain = computed(()=>window.location.hostname)

    

    onMounted(() => {
		//driver.highlight('#a')
	})
    
    const me = computed(() => store.state.auth.me)
    const event_company = computed(() => store.state.auth.event_company) 

    const nav_logo = computed(() => domain.value != 'localhost' ? event_company.value.square_logo : 'https://easyevents-website.pages.dev/favicon.png')

    const logout = () => {
        localStorage.removeItem('user')
        router.push({name: 'Login'})
    }
</script>