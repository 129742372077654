<script setup>
    import { computed, ref, watch ,onBeforeMount, onMounted} from "vue"
    import API from "@/api"
    import { useStore } from "vuex"
    import _ from 'lodash'

    const store = useStore()

    const active_field = computed(() => store.state.form.active_field)
    const field = ref(_.cloneDeep(active_field.value))
    const options = ref([{value:'any',text:'Mobil + Sabit Telefon'},{value:'mobile',text:'Sadece Mobil Telefon'},{value:'landline',text:'Sadece Sabit Telefon'},{value:'international',text:'Uluslararası Telefon'}])

    const save = () => {
        console.log(field)
        return
        
        
        API.saveFormField(field.id, field)
			.then( response => {
				
			})
			.catch( error => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
	}

</script>

<template>
    <UIRadioGroup :options="options" label="Telefon Tipi?" v-model="field.props.type" />
    <UIButton
        type="submit"
        text="Kaydet"
        class="mt-3"
        @click="save"
    />
</template>