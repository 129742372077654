import { createI18n } from 'vue-i18n'
import tr from './i18n_translations/tr'
import en from './i18n_translations/en'

const Messages = {
	tr: tr,
    en: en,
	ja: {
	  	message: {
			hello: 'こんにちは',
			fullscreen: '全画面表示',
            accounting:'会計'
	  	}
	},
	es: {
		message: {
			hello: '¡Hola!',
			fullscreen: 'Pantalla Completa',
            accounting:'Contabilidad'
	  	}
	},
	fr: {
		message: {
			hello: 'Bonjour',
			fullscreen: 'Plein écran',
            accounting:'Comptabilité'
	  	}
	},
	de: {
		message: {
			hello: 'Hallo',
			live: 'Live',
			fullscreen: 'Vollbild',
			type_a_message:'Schreibe eine Nachricht',
			happeningnow_title:'Jetzt Geschieht',
            accounting:'Buchhaltung'
			
	  	}
	}
}

const i18n = createI18n({
	locale: 'tr',
	fallbackLocale: 'en',
	messages : Messages,
	globalInjection: true,
	warnHtmlMessage:false,
	legacy: false
})





export default i18n