const state = {
    current:null,
    budgets: [],
    flights: [],
    files: [],
    notes:[],
    calls:[],
    todos:[],
    products: [],
    pricing_periods: [],
    checklist_items:[],
    timeline: [],
    cc_requests:[],
    cc_requests_paginator:null,
    cc_requests_total:null,
    purchase_invoices:[],
    purchase_invoices_paginator:null,
    purchase_invoices_total:null,
    sales_invoices:[],
    sales_invoices_paginator:null,
    sales_invoices_total:null,
    sales_invoice_requests:[],
    sales_invoice_requests_paginator:null,
    sales_invoice_requests_total:null,
    expenses:[],
    expenses_paginator:null,
    expenses_total:null,
    advance_payments:[],
    advance_payments_paginator:null,
    advance_payments_total:null,
    payment_requests:[],
    payment_requests_paginator:null,
    payment_requests_total:null,
    payments:[],
    payments_paginator:null,
    payments_total:null,
    payments_received:[],
    payments_received_paginator:null,
    payments_received_total:null,
    sponsors:[],
}

// getters
const getters = {
    project (state) {
        return state.current;
    },
    budgets (state) {
        return state.budgets;
    },
    flights (state) {
        return state.flights;
    },
    timeline (state) {
        return state.timeline;
    },
    getChecklistItem: (state) => (data) => {
        if(data) {
            const item = state.current.checklist.find(x => x.id == data)
            if(item) return item
        }
        
        return {
            description : '',
            deadline_at : null,
            completed_at : null,
            completed_by : {
                full_name : ''
            },
            responsible : {
                full_name : ''
            },
            note : null
        }
    },
    
}
  
// actions
const actions = {
    updateChecklistItem ({ commit }, data) {
        commit('updateChecklistItem', data)
    },
    newChecklistItem ({ commit }, data) {
        commit('newChecklistItem', data)
    },
    deleteChecklistItem ({ commit }, data) {
        commit('deleteChecklistItem', data)
    }
}
  
// mutations
const mutations = {
    setData(state, data) {
        if(data.field.includes('paginator')) {
            data.response.links.splice(0,1)
            data.response.links.splice(-1,1)
        }
        state[data.field.replaceAll("-","_")] = data.response
    },
    updateChecklistItem(state, data) {
        // let itemIndex = state.current.checklist.findIndex(x=>x.id == data.id)
        // state.current.checklist[itemIndex] = data.value
        state.current.checklist.forEach((section, index) => {
            var itemIndex= section.items.findIndex(x=>x.id == data.id)
            if(itemIndex>=0){ 
                state.current.checklist[index].items[itemIndex] = data.value
                console.log(state.current.checklist[index].items[itemIndex]);
            }
        });
    },

    deleteChecklistItem(state, data) {
        
        //state.current.checklist = state.current.checklist.filter(i=>i.id!=data.id)

        state.current.checklist.forEach((section, index) => {
            state.current.checklist[index].items = section.items.filter(i=>i.id!=data.id)
        });
    },

    newChecklistItem(state, data) {
        
        //state.current.checklist.push(data)

        var sectionIndex= state.current.checklist.findIndex(x=>x.id == data.id)
        if(sectionIndex>=0){ 
            state.current.checklist[sectionIndex]=data
        }else{
            state.current.checklist.push(data)
        }
    },
    setCurrent(state, data) {
        state.current = data
    },
    setCurrentStatus(state, data) {
        state.current.current_status = data
    },
    setStatusInfo(state, data) {
        state.current.status_info = data
    },
    setBudgets (state, data) {
        state.budgets = data
    },
    setFlights (state, data) {
        state.flights = data
    },
    setPricingPeriods (state, data) {
        state.pricing_periods = data
    },
    setProducts (state, data) {
        state.products = data
    },
    setFiles (state, data) {
        state.files = data
    },
    setNotes (state, data) {
        state.notes = data
    },
    setCalls (state, data) {
        state.calls = data
    },
    setTodos (state, data) {
        state.todos = data
    },
    setTimeline (state, data) {
        state.timeline = data
    },
    setLeadCloseDate (state, data) {
        state.current.lead_close_date = data
    },
    setClientResponseDate (state, data) {
        state.current.client_response_date = data
    },
    addProjectContact(state, data) {
        state.current.contacts.push(data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}