<template>
    <div :data-item-id="field.id" class="p-2 border-2 border-gray-200 hover:border-calypso hover:bg-calypso-light  group rounded mb-2 relative rounded cursor-pointer"> 
        <div class="field_wrapper_actions duration-300 grid grid-cols-2 absolute right-0 top-0 border-l rounded-bl border-b bg-slate-50 border-slate-100 opacity-0 group-hover:opacity-100 z-10">
           
			<UITooltip title="Kopyala" position="top">
				<div><DuplicateIcon class="w-9 p-2 opacity-80 hover:opacity-100 hover:bg-slate-100"/></div>
			</UITooltip>
            <UITooltip title="Sil" position="top">
				<div><TrashIcon class="w-9 p-2 opacity-80 hover:opacity-100 hover:bg-slate-100" @click.stop.prevent="delete_field"/></div>
			</UITooltip>
            <!--<PencilIcon class="w-9 p-2 opacity-80 hover:opacity-100 hover:bg-slate-100"/>
			
            <UITooltip title="Taşı" position="top">
				<div><HandIcon class="w-9 p-2 opacity-80 hover:opacity-100 hover:bg-slate-100"/></div>
			</UITooltip>-->
        </div>
        <div class="mb-2 font-bold text-sm flex items-center">
            <span :style="'color:' + field.title_colour">{{field.title}}</span>
            <div class="ml-auto gap-1 flex mr-20">
                <UITooltip title="Zorunlu Alan" position="top" v-if="field.is_required">
                    <UIBadge show="Z" class="uppercase bg-red-600" />
                </UITooltip>
                <UITooltip title="Gizli Alan" position="top" v-if="field.is_hidden">
                    <UIBadge show="G" class="uppercase bg-red-700" />
                </UITooltip>
                <UITooltip title="Salt Okunur" position="top" v-if="field.is_readonly">
                    <UIBadge show="RO" class="uppercase bg-slate-600" />
                </UITooltip>
                <UITooltip title="Veritabanı İlişkisi" position="top" v-if="field.default_value_type == 'DB'">
                    <UIBadge :show="'DB : ' + get_db_column_title(field.default_value_db)" class="uppercase bg-yellow-600" />
                </UITooltip>
                <UIBadge :show="'DEP'" class="uppercase bg-blue-600"  v-if="field.deps && field.deps.length > 0" />
            </div>
        </div>
        
        <!--
        <div v-if="field.type == 'not'" v-html="field.data"></div>
        
        <div v-if="field.type == 'radio'" :class="field.direction == 'H' ? 'flex gap-2':''">
            <label class="cursor-pointer mr-2" v-for="option,k in field.options" :key="k" :class="field.direction == 'H' ? '':'mb-2 block'">
                <span class="flex" :class="option.desc ? 'items-start':'items-center'">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="w-5 h-5" :class=" k==0 ? 'checked' : '', option.desc ? 'mt-1':'' ">
                            <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
                                <circle cx="10" cy="10" r="10" class="radio_circle" stroke-width="1.5"></circle>
                                <circle class="radio_dot" id="a" cx="10" cy="10" r="5"></circle>
                            </g>
                        </svg>
                    </span>
                    <span class="ml-2">
                        {{ option.option_text }}
                        <div v-if="option.desc" class="text-sm text-gray-500">{{ option.desc }}</div>
                    </span>
                </span>
            </label>
            
        </div>

        <div v-if="field.type == 'drop_down'" :class="field.direction == 'H' ? 'flex gap-2':''">
            <select>
                <option>Seçiniz</option>
                <option v-for="option,k in field.options" :key="k">{{option.option_text}}</option>
            </select>
            
        </div>

        <div v-if="field.type == 'input_box'">
            <input type="text" class="w-full border p-2 pointer-events-none" :placeholder="field.placeholder">
        </div>

        <div v-if="field.type == 'text_area'">
            <textarea class="w-full border pointer-events-none"></textarea>
        </div>

        <div v-if="field.type == 'check_box'" :class="field.direction == 'H' ? 'flex gap-2':''">
            <template v-if="field.options && field.options.length > 0">
                <Checkbox v-for="item,k in field.options" :key="k" :checked="k==0?true:false" :data="item" class="hover:bg-teal mr-2 pointer-events-none"/>
            </template>
            <div v-if="!field.options || field.options.length == 0" class="p-4 text-sm text-center text-gray-500">
                Bu checkbox'a hiç seçenek eklenmemiş!
            </div>
        </div>

        <div v-if="field.type == 'date'">
            <UIDatePicker/>
        </div>
        -->
    </div>
</template>

<script setup>
    import {computed,ref,watch} from 'vue'
    import Checkbox from '@/components/UI/form/Checkbox.vue'
    import { TrashIcon, PencilIcon, DuplicateIcon, HandIcon } from '@heroicons/vue/outline'
    import { useStore } from 'vuex'
	
	const store = useStore()
    const props = defineProps({
        field:Object
    })
    
	const emit = defineEmits(['delete'])

    const db_columns = computed(() => store.state.form.active_form.db_columns)

    const get_db_column_title = slug => db_columns.value && db_columns.value.find(x => x.title_slugged == slug) ? db_columns.value.find(x => x.title_slugged == slug).title_original : ''
    
    
    const delete_field = () =>{
        emit('delete',props.field.id)
    }
</script>

<style scoped>
    svg.checked .radio_circle  {
        stroke: #00a4bd;
    }
    svg.checked .radio_dot  {
        fill: #00a4bd;
    }
    .radio_circle {
        stroke: #cbd6e2;
        stroke-width: 2px;
    }
    label:hover .radio_circle {
        fill: #eaf0f6;
    }

    .blocker {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        opacity: 0;
    }

	:global(.field_wrapper_actions path) {
        stroke-width:1.2;
    }
	:global(.field_wrapper_actions svg:hover path) {
        stroke-width:2;
    }
</style>