<template>
    <div ref="target">
      	<UILabel :label="label" :rand_id="id || rand_id" :isFocused="isOpen" @click="show_cal"/>
      	<Popover class="relative">
          	<PopoverButton ref="date_picker_button" v-slot="{ isOpen }" @click="show_cal" 
              	class="flex items-center text-sm"
              	:class="{
					'w-full rounded-iq focus:outline-none focus:ring-calypso border border-gray-300 bg-gray-50 p-2 h-[42px]' : display == 'box',
					'text-calypso font-bold' : display == 'inline' && selected_date_text !== title,
					'text-gray-500' : selected_date_text == title
              	}"
          	>
              	<CalendarIcon class="w-4 h-4 text-calypso mr-1" /> {{selected_date_text}} <ChevronDownIcon class="w-4 h-4 text-calypso duration-300" :class="{'ml-auto':display == 'box', 'transform rotate-180':isOpen}" /> 
          	</PopoverButton>

          	<transition
          		v-if="isOpen"
				enter-active-class="transition duration-200 ease-out"
				enter-from-class="translate-y-1 opacity-0"
				enter-to-class="translate-y-0 opacity-100"
				leave-active-class="transition duration-150 ease-in"
				leave-from-class="translate-y-0 opacity-100"
				leave-to-class="translate-y-1 opacity-0"
          	>
              	<div>
                  	<PopoverPanel 
                      	class="absolute z-10 mt-2 shadow bg-white border border-gray-iq shadow-iq rounded-iq"
                      	:class="{
                          	'-translate-y-full -top-5' : popover_position == 'up',
							'w-56' : !shortcuts,
							'w-96' : shortcuts
                      	}"
                      	static
                  	>
                      	<div class="-top-2 left-5 w-4 h-4 absolute bg-white border-r border-b border-gray-iq -rotate-135 transform -z-1" v-if="popover_position == 'down'"></div>
                      	<div :class="{
                          	'mt-3 pb-1' : popover_position == 'down',
                          	'mb-3 pt-1' : popover_position == 'up',
							'flex' : shortcuts
                      	}">
							<div v-if="shortcuts" class="mt-2 w-40">

								<div v-for="shortcut in date_range_shortcuts" class="px-3 py-1 hover:bg-calypso-light flex items-center cursor-pointer" @click="set_date(shortcut.date, true)"
								:class="{
									'border-l-4 border-calypso bg-calypso-light' : selected_date == shortcut.date
 								}"
								>
									<svg v-if="shortcut.icon" width="24" height="24" viewBox="0 0 24 24" class="text-lime-600 mr-2" aria-hidden="true" focusable="false"><g fill="currentColor" fill-rule="evenodd"><path fill-rule="nonzero" d="M6 4.5h12A1.5 1.5 0 0 1 19.5 6v2.5h-15V6A1.5 1.5 0 0 1 6 4.5z" opacity=".1"></path><path fill-rule="nonzero" d="M6 4h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H6zm1 3h10a.5.5 0 1 1 0 1H7a.5.5 0 0 1 0-1z"></path><text font-size="9" transform="translate(4 2)" font-weight="500"><tspan x="8" y="15" text-anchor="middle">{{ moment().format('DD')}}</tspan></text></g></svg>
									<span class="text-sm">{{ shortcut.title }}</span>
								</div>
							</div>


                          	<input ref="date_picker" :id="rand_id" type="hidden">
                      	</div>
                      	<div class="-bottom-2 left-5 w-4 h-4 absolute bg-white border-l border-t border-gray-iq -rotate-135 transform -z-1" v-if="popover_position == 'up'"></div>
                  	</PopoverPanel>
              	</div>
          	</transition>
      	</Popover>
  	</div>
</template>

<script setup>
	import { ref,computed,inject,watch, onUnmounted } from 'vue';
	import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
	import { onClickOutside } from '@vueuse/core'
	import flatpickr from "flatpickr"
	import { Turkish } from "flatpickr/dist/l10n/tr.js"
	import 'flatpickr/dist/themes/light.css';
	import { ChevronDownIcon } from '@heroicons/vue/solid'
	import { CalendarIcon, ClockIcon } from '@heroicons/vue/outline'
	const moment = inject('moment')

	const props = defineProps({
		modelValue:Array,
		shortcuts : {
			type : Boolean,
			default : false
		},
		title: String,
		label: String,
		id : String,
		display : {
			type : String,
			default : 'inline'
		},
		minDate : String,
		maxDate : String,
	})

	let picker

	const setPickerOption = (key,value) => {
		if(!picker) return
		picker.set(key,value)
	}

	const rand_id = computed(()=>'date_input_' + (Math.floor(Math.random() * 1000000000)))
	const emit = defineEmits(['update:modelValue','focus'])
	const target = ref(null)
	const date_picker = ref(null)
	const date_picker_button = ref(null)
	const popover_position = ref('down')

	const selected_date_text = ref(props.title)
	const selected_date = ref(props.title)
	const isOpen = ref(false)	
	const minDate = ref('')
	const maxDate = ref('')

	watch(() =>  props.minDate, data => {
		//minDate.value = data || !props.modelValue ? 'today' : null
		minDate.value = data
		setPickerOption('minDate', minDate.value)
    }, {immediate:true})

	watch(() =>  props.maxDate, data => {
		maxDate.value = data
		setPickerOption('maxDate', maxDate.value);
    }, {immediate:true})

	const close_modal = () => {
		isOpen.value = false
		picker = null
	}

  	onClickOutside(target, close_modal)

	const set_date = (date, needs_emit=false) => {
		if(!date || date == "" || date.length < 2) return
		
		//date.sort((a, b) => moment(a).isBefore(moment(b)))
		date.sort((a, b) => a > b)

		if(moment(date[0]).isSame(date[1],'year')) {
			if(moment(date[0]).isSame(date[1],'month')) {
				selected_date_text.value = (moment(date[0]).format('DD') + '-' + moment(date[1]).format('DD')) + ' ' + moment(date[0]).format('MMMM YYYY')
			} else {
				selected_date_text.value = (moment(date[0]).format('DD MMMM') + '-' + moment(date[1]).format('DD MMMM')) + ' ' + moment(date[0]).format('YYYY')
			}
		} else {
			selected_date_text.value = moment(date[0]).format('DD MMMM YYYY') + '-' + moment(date[1]).format('DD MMMM YYYY')
		}


			
		
		selected_date.value = [ moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')]
		if(picker) {
			picker.setDate([ moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')])
		}
		if(needs_emit) {
			emit('update:modelValue',selected_date.value)
			close_modal()
		}
	}

  	if(props.modelValue && props.modelValue.length > 0 ) set_date(props.modelValue)
  	watch(() =>  props.modelValue, data => {  
      	//set_date(props.modelValue ? moment(props.modelValue) : null,false)
  	}, {immediate:true})

  	

  	const calculate_position = () => {
      	const distance_to_bottom = window.innerHeight - date_picker_button.value.el.getBoundingClientRect().y
      	popover_position.value = distance_to_bottom < 400 ? 'up' : 'down'
  	}

	const set_picker = () => {
		if(picker) return
		
		picker = flatpickr(date_picker.value, {
					inline:true,
					monthSelectorType:'static',
					locale:Turkish,
					mode : 'range',
					defaultDate: props.modelValue,
					minDate: minDate.value,
					maxDate: maxDate.value,
					static : true,
					onChange: (selectedDates, dateStr, instance) => {
						set_date(selectedDates,true)
						if(selectedDates.length == 2) {
							close_modal()
						}
					},
				})
	}

	const show_cal = () => {
		emit('focus',!isOpen.value)
		if(isOpen.value) {
			close_modal()
			return
		}
		isOpen.value = true
		calculate_position()
		setTimeout(set_picker,10)
	}

	const date_range_shortcuts = ref([
		{
			date	: [moment().startOf('week'),moment().endOf('week')],
			title	: 'Bu hafta',
		},
		{
			date	: [moment().startOf('month'),moment().endOf('month')],
			title	: 'Bu ay'
		},
		{
			date	: [moment().startOf('year'),moment().endOf('year')],
			title	: 'Bu yıl'
		},
		{
			date	: [moment().quarter(1).startOf('quarter'), moment().quarter(1).endOf('quarter')],
			title	: '1.Çeyrek'
		},
		{
			date	: [moment().quarter(2).startOf('quarter'), moment().quarter(2).endOf('quarter')],
			title	: '2.Çeyrek'
		},
		{
			date	: [moment().quarter(3).startOf('quarter'), moment().quarter(3).endOf('quarter')],
			title	: '3.Çeyrek'
		},
		{
			date	: [moment().quarter(4).startOf('quarter'), moment().quarter(4).endOf('quarter')],
			title	: '4.Çeyrek'
		},
		{
			date	: [moment(), moment().add(1,'month')],
			title	: 'Önümüzdeki 1 Ay'
		},
		{
			date	: [moment(), moment().add(3,'month')],
			title	: 'Önümüzdeki 3 Ay'
		},
		{
			date	: [moment().subtract(1,'month'), moment()],
			title	: 'Geçmiş 1 Ay'
		},
		{
			date	: [moment().subtract(3,'month'),moment()],
			title	: 'Geçmiş 3 Ay'
		},
	])
  
</script>

<style scoped>
  .flatpickr-input {
      display: none;
  }
  :global(.flatpickr-months .flatpickr-month) {
      background-color: #fff;
  }

  :global(.flatpickr-weekdays) {
      background-color: #fff;
  }

  :global(span.flatpickr-weekday) {
      background-color: #fff;
  }

  :global(.flatpickr-days) {
      border: none;
      width: 200px;
  }

  :global(.flatpickr-day) {
      height: 28.50px;
      line-height: 26.50px;
  }

  :global(.flatpickr-calendar.inline) {
      box-shadow: none;
      width: 222px !important;
  }

  :global(.flatpickr-innerContainer) {
      border: none;
      width: 200px;
      margin: 0 10px;
  }
  :global(.flatpickr-current-month span.cur-month:hover) {
      background-color: transparent;
  }

  :global(.flatpickr-current-month .numInputWrapper) {
      width:7ch;
  }

  :global(.dayContainer) {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
  }

  :global(.flatpickr-current-month){
      font-size: 1rem;
  }

  :global(.numInputWrapper:hover) {
      background: transparent;
  }

  :global(.flatpickr-calendar.hasTime .flatpickr-time) {
      border: none !important;
      border-top: 1px solid #cbd6e2 !important;
      margin-top: 5px;
      padding-top: 5px;
  }

  :global(.flatpickr-time input:hover,.flatpickr-time input:focus) {
      background: transparent !important;
  }

</style>