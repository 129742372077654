<template>
    <Modal 
        :isOpen="isModalOpen"
        title="Bütçe Grubu Ekle"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Kaydet"
        @modal_closed="modal_closed"
        @submit="submit"
        size="6xl"
    >
        <template v-slot:modal_content>

            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>

            <UIFormRow class="p-2">
                <UIInput label="Grup Adı" display="box" v-model="budget_data.name"/>
            </UIFormRow>

            <div class="grid grid-cols-2">
                <div>
                    <UIFormRow class="p-2">
                        <UIInput  label="Grup Adı (İngilizce)" display="box" v-model="budget_data.name_en"/>
                    </UIFormRow>
                </div>
                <div>
                    <UIFormRow class="p-2">
                        <UISelect  :source="budget_categories" display="box" label="Kategori Seç" v-model="budget_data.category_id"/>
                    </UIFormRow>
                </div>
            </div>
           
            <div class="grid grid-cols-2">
                <div class="p-2">
                    <h4>Tüm Bütçe Kalemleri</h4>
                    <div class="border p-2 overflow-y-auto h-96">
                        <ul class="list-none">
                            <li class="border-b-2 mb-2 mt-2 pb-1" v-for="definitions_item in definitions_items">
                                <div class="grid grid-cols-3 ">
                                    <div>{{definitions_item.name}}</div>
                                    <div>{{definitions_item.category.name}}</div>
                                    <div class="">
                                        <span v-if="budget_items.find(x=>x.id==definitions_item.id)">
                                            <UIButton icon="check" type="warning" class="float-right" disabled="true" text="Aktarıldı" size="sm" @click="add_item(definitions_item.id)" />
                                        </span>
                                        <span v-else>
                                            <UIButton icon="arrow-right" type="success" class="float-right" text="Aktar" size="sm" @click="add_item(definitions_item.id)" />
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-2">
                    <h4>Gruptaki Bütçe Kalemleri</h4>
                    <div class="border p-2 overflow-y-auto h-96">
                        <ul class="list-none">
                            <li class="border-b-2 mb-2 mt-2 pb-1" v-for="budget_item,index in budget_items">
                                <div class="grid grid-cols-3 ">
                                    <div>{{budget_item.name}}</div>
                                    <div>{{budget_item.category.name}}</div>
                                    <div class=""><UIButton icon="trash" type="danger" class="float-right" text="Sil" size="sm" @click="remove_item(index)" /></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
	</Modal>
</template>

<script setup>
    // Imports
	import {ref, computed, watch, inject } from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'
    
	const props = defineProps({
        budget_group:Object,
        isOpen:Boolean,
    })

    
    const emit = defineEmits(['modal_closed'])
    const store = useStore()
    const emitter = inject('emitter')
    const isSubmitting = ref(false)
    const isModalOpen = ref(props.isOpen);
    const submit_error = ref(false)
    const budget_group = ref(null)

    const budget_categories = computed(() => store.state.content.budget_categories.map(category => { return {value : category.id, text : category.name}}).sort((a, b) => a.text.localeCompare(b.text)))
    const definitions_items = ref([])
    const budget_items = ref([])
    
    const budet_items = () =>{
        API.get('definition/budget-items').then(response => {
            definitions_items.value = response.data
        })
    }

    budet_items()

    watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    watch(() => props.budget_group, (data) => {
        budget_group.value = data
        budget_items.value=[]
        if(!data){
            return
        }

        budget_group.value = data
        budget_items.value=[]
        budget_group.value.items.forEach(item =>{
            let budget_item=definitions_items.value.find(x=>x.id==item.item_id)
            if(budget_item!=undefined){
                budget_items.value.push(budget_item)
            }
        })

		},{ immediate: true,deep:true }
    )

    const budget_data = ref({})

    watch(() => budget_group.value, (blade) => {
        
        if(!blade){
            budget_data.value={}

            return
        }
       
        budget_data.value={
            name:budget_group.value.name,
            name_en:budget_group.value.name_en,
            category_id:budget_group.value.category_id
        }
		},{ immediate: true }
    )

    const ready2submit = computed( () => budget_data.value.name ? true : false )
    
    const modal_closed = () =>{
		isModalOpen.value = false
		emit('modal_closed')
	}

    const add_item = (id) =>{
        let definitions_item=definitions_items.value.find(x=>x.id==id);
        let budget_item=budget_items.value.find(x=>x.id==id);

        if(budget_item==undefined){
            budget_items.value.push(definitions_item)
        }
    }

    const remove_item = (index) =>{
        budget_items.value.splice(index,1);
    }
        
    const submit = () => {
        isSubmitting.value = true

        budget_data.value.items=budget_items.value.map(x=>x.id);
    
        let request
        if(props.budget_group) {
            request = API.put('definition/budget-groups/' + props.budget_group.id, budget_data.value)
        } else {
            request = API.post('definition/budget-groups',budget_data.value)
        }

        request.then( response => {
            isSubmitting.value = false
            submit_error.value = null
            modal_closed()
            emitter.emit('load_budget_group_lists')
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description: response.data.message
            })
        }).catch( error => {
            submit_error.value = error.response?.data.message
            isSubmitting.value = false
        })
    }

</script>