<template>
    <div class="grid grid-cols-4 gap-4">
		<UICard title="Paraşüt" class="shadow hover:shadow-lg" @click="openpopup">
			<img alt="Paraşüt logo" class="h-16 my-2" src="/assets/images/parasut.svg"/>
			Paraşüt hesabını bağlayarak muhasebe verileri senkronize edebilirsin.
			<div>
				<UIBadge show="BAĞLI" class="uppercase bg-red-600 mr-2"/>
			</div>
		</UICard>
		<UICard title="Quickbooks" class="shadow hover:shadow-lg">
			<img alt="Quickbooks logo" class="h-16 my-2" src="/assets/images/quickbooks.svg"/>
			Quickbooks hesabını bağlayarak muhasebe verileri senkronize edebilirsin.
		</UICard>
		<UICard title="Zoho Accounting" class="shadow hover:shadow-lg">
			<img alt="Zoho logo" class="h-16 my-2" src="/assets/images/zoho.svg"/>
			Zoho hesabını bağlayarak muhasebe verileri senkronize edebilirsin.
		</UICard>
		<UICard title="Xero Accounting" class="shadow hover:shadow-lg">
			<img alt="Xero logo" class="h-16 my-2" src="/assets/images/xero.svg"/>
			Xero hesabını bağlayarak muhasebe verileri senkronize edebilirsin.
		</UICard>
	</div>
</template>

<script setup>
    import { ref } from 'vue'
    
	function popupCenter(url, title, w, h) {
        const hasSpace = window.matchMedia(`(min-width: ${w + 20}px) and (min-height: ${h + 20}px)`).matches;
        const isDef = v => typeof v !== 'undefined';
        const screenX = isDef(window.screenX) ? window.screenX : window.screenLeft;
        const screenY = isDef(window.screenY) ? window.screenY : window.screenTop;
        const outerWidth = isDef(window.outerWidth) ? window.outerWidth : document.documentElement.clientWidth;
        const outerHeight = isDef(window.outerHeight) ? window.outerHeight : document.documentElement.clientHeight - 22;
        const targetWidth = hasSpace ? w : null;
        const targetHeight = hasSpace ? h : null;
        const V = screenX < 0 ? window.screen.width + screenX : screenX;
        const left = parseInt(V + (outerWidth - targetWidth) / 2, 10);
        const right = parseInt(screenY + (outerHeight - targetHeight) / 2.5, 10);
        const features = [];

        if (targetWidth !== null) {
            features.push(`width=${targetWidth}`);
        }

        if (targetHeight !== null) {
            features.push(`height=${targetHeight}`);
        }

        features.push(`left=${left}`);
        features.push(`top=${right}`);
        features.push('scrollbars=1');

        const newWindow = window.open(url, title, features.join(','));

        if (window.focus) {
            newWindow.focus();
        }

        return newWindow;
    }

    const openpopup = () => {
        
        //const url = 'https://api.parasut.com/oauth/authorize?client_id=0G-YoY5S4dZU2Qvt6cQTvNdjgTieCy_F6mFmkS0-YtI&redirect_uri=urn:ietf:wg:oauth:2.0:oob&response_type=code'
		//const url = 'https://api.parasut.com/oauth/authorize?client_id=ABC&redirect_uri=urn:ietf:wg:oauth:2.0:oob&response_type=code'
        const url = 'https://api.parasut.com/oauth/authorize?client_id=2gow4sYt2Rtl6dyjVos_xSfhW-qehWV4zLB-v4CmEtU&redirect_uri=https%3A%2F%2Fapi.easy.events%2Fv1%2Fparasut%2Foauth%2Ftoken&response_type=code'
		
		popupCenter(url, 'parasut', 600, 900)
        
    }
    
</script>