import Moment from 'moment/min/moment-with-locales'
import { extendMoment } from 'moment-range';
 
const moment = extendMoment(Moment)

export function format_two_dates(startDate, endDate) {
    // Create moment objects for start and end dates
    const start = moment(startDate);
    const end = moment(endDate);

    // Check if the event is on the same day
    if (start.isSame(end, 'day')) {
        return start.format('DD MMMM YYYY');
    }
    // Check if the event spans more than one day but within the same month
    else if (start.isSame(end, 'month')) {
        return `${start.format('DD')}-${end.format('DD MMMM YYYY')}`;
    }
    // Check if the event spans more than one day and across different months but within the same year
    else if (start.isSame(end, 'year')) {
        return `${start.format('DD MMMM')}-${end.format('DD MMMM YYYY')}`;
    }
    // If the event spans different years
    else {
        return `${start.format('DD MMMM YYYY')}-${end.format('DD MMMM YYYY')}`;
    }
}