import { createPopper } from "@popperjs/core"

const create_popper = (source, tooltip, position) => {
	return createPopper(source, tooltip, {
		placement: position,
		positionFixed: true,
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 10],
				},
			},
			{
				name: "flip",
				options: {
					flipVariations: false, // true by default
				},
			},
		],
	})
}

export default {
	install: (app) => {
		app.directive("tooltip", (el, binding) => {
			let tooltipDiv, popperInstance

			el.addEventListener("mouseover", (e) => {
				if(tooltipDiv) return
				tooltipDiv = document.createElement("div")
				let tooltipDivArrow = document.createElement("div")
				tooltipDivArrow.dataset.popperArrow = true
				tooltipDivArrow.classList.add("tooltip_arrow")
				
				tooltipDiv.appendChild(tooltipDivArrow)

				tooltipDiv.classList.add(
					"rounded",
					"px-2",
					"text-sm",
					"font-bold",
					"text-white",
					"py-1",
					"absolute",
					"bg-black",
					"z-50",
					"transition",
					
					"absolute",
					"pointer-events-none",
					"invisible",
					"tooltip_arroww",
					"w-fit",
					"max-w-md",
					"origin-bottom",
					"opacity-0"
				)
				
				const newContent = document.createTextNode(binding.value)

				tooltipDiv.appendChild(newContent)
				//el.parentNode.insertBefore(tooltipDiv, el.nextSibling)
				document.body.appendChild(tooltipDiv)

				popperInstance = create_popper(el, tooltipDiv, "top", {
					modifiers: [
					  {
						name: 'arrow',
						options: {
						  element: tooltipDivArrow,
						},
					  },
					],
				  })

				setTimeout(() => {
					tooltipDiv.classList.remove('opacity-0')
					tooltipDiv.classList.remove('scale-0')
					tooltipDiv.classList.remove('invisible')
					popperInstance.forceUpdate()
				}, 300);

				return
				
				const tooltip = tooltipDiv.getBoundingClientRect()
				const source = el.getBoundingClientRect()
				const tooltip_left = source.left - ((tooltip.width - source.width)/2)
				
				tooltipDiv.style.top = (source.top - 10 - tooltip.height) + "px"
				tooltipDiv.style.left = tooltip_left + "px"

				tooltipDiv.classList.add('scale-0')
				tooltipDiv.classList.add('duration-300')
				
				tooltipDiv.classList.remove('invisible')
				tooltipDiv.classList.remove('opacity-0')
				tooltipDiv.classList.remove('scale-0')
				
			})

			el.addEventListener("mouseout", (e) => {
				if(!tooltipDiv) return
				//tooltipDiv.remove()
				
				tooltipDiv.classList.add('opacity-0')
				tooltipDiv.classList.add('scale-0')

				setTimeout(() => {
					tooltipDiv.remove()
					tooltipDiv = null
				}, 300);
			})

			const observer = new MutationObserver(mutations => {
				mutations.forEach(function (mutation) {
					const elementRemoved = Array.from(mutation.removedNodes).some(element => element == el);
					if (elementRemoved && tooltipDiv) {
						tooltipDiv.remove()
						tooltipDiv = null
					}
				});
			});
			observer.observe(el.parentNode, {
				childList: true
			});
		})
	},
}
