<template>
    <nav class="flex mx-auto px-7 pt-3">
        <ol class="inline-flex items-center space-x-1">
            <li class="inline-flex items-center">
                <router-link :to="{ name: 'Welcome'}" class="text-sm font-medium text-gray-700 hover:text-gray-900 opacity-50">Ana Sayfa</router-link>
            </li>
            
            <li v-for="bread in breads">
                <div class="flex items-center">
                    <svg class="w-6 h-6 text-gray-400 opacity-50" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    <router-link :to="{ name: bread.route_name}" class="text-sm font-medium text-gray-700 hover:text-gray-900 opacity-50">{{ bread.title }}</router-link>
                </div>
            </li>
            <li class="hidden">
            <div class="flex items-center pointer-events-none">
                <svg class="w-6 h-6 text-gray-400 opacity-50" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                <span class="text-gray-700 text-sm font-medium opacity-50">{{ document_title }}</span>
            </div>
            </li>
        </ol>
        <div class="flex ml-auto">
            <a @click="openNews" class="SGBF-open-651731ed04e0a3f1b943887a hover:bg-white p-2 rounded-full cursor-pointer transition duration-200 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                </svg>
            </a>
            <a @click="openSearchModal" class="hover:bg-white p-2 rounded-full cursor-pointer transition duration-200">
                <SearchIcon class="w-6 h-6"/>
            </a>
            <a @click="openNotificationsModal" class="relative hover:bg-white p-2 rounded-full cursor-pointer transition duration-200 relative">
                <span class="relative">
                    <BellIcon class="w-6 h-6"/>
                    <div class="flex text-white text-xxs absolute w-[19px] h-[19px] right-[-10px] top-[-8px] !bg-red-600 justify-center items-center overflow-hidden rounded-full" v-if="me.unread_notifications_count">{{me.unread_notifications_count}}</div>
                </span>
            </a>
        </div>
    </nav>
    <NotificationsModal :showSubmitButton="false" title="Bildirimler" size="lg" :isOpen="isNotificationsModalOpen" @modal_closed="closeNotificationsModal"/>
    <SearchModal :showSubmitButton="false" title=" " :isOpen="isSearchModalOpen" @modal_closed="closeSearchModal"/>
    
    
</template>

<script setup>
    import { useI18n } from 'vue-i18n'
    import { BellIcon, SearchIcon } from '@heroicons/vue/outline'
    import { ref,watch,onMounted,defineAsyncComponent,computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex'
    
    const NotificationsModal = defineAsyncComponent(() => import('../modals/Notifications.vue') )
    const SearchModal = defineAsyncComponent(() => import('../modals/Search.vue') )
    
    const router = useRouter()
    const store = useStore()
    const { t, locale } = useI18n({ useScope: 'global' })

    const isSearchModalOpen = ref(false)
    const isNotificationsModalOpen = ref(false)
    const document_title = ref('')
    
    
    const breads = ref([])
    const me = computed(() => store.state.auth.me)

    onMounted(() => {
        (function(l, e, n, b, a, i, s){
            s = l[n] = l[n] || {};
            s['push'] = function (m) {
                (l[n].q = l[n].q || []).push([].concat([].slice.call(arguments, 0)))
            }
            s['c'] = ['core', 'widget'];
            s['l'] = 1 * new Date();
            s['ak'] = b;
            let v = new Date().getMonth()+'.'+new Date().getDate();
            a = e.createElement('script'),
                i = e.getElementsByTagName('script')[0];
            a.async = 1;
            a.src = 'https://app.leanbe.ai/embed/leanbe.js?v='+v;
            a.onload = e => {
                setTimeout(() => {
                    if(document.querySelector('.SGBF-branding')) document.querySelector('.SGBF-branding').remove()
                    if(document.getElementById('SGBF-widget-header')) document.getElementById('SGBF-widget-header').style.borderRadius = '0px !important'
                    if(document.querySelector('.SGBF-widget-container')) document.querySelector('.SGBF-widget-container').style.borderRadius = '0px !important'    
                }, 3000);
            };
            i.parentNode.insertBefore(a, i);
        })(window, document, 'leanbe', 'f2def986-d220-4d32-b814-10a58b332e5f');
    })

    watch(() => router.currentRoute.value,  (data) => {
        breads.value = data.meta.breads || []
        document_title.value = document.title
    },{ immediate: true })

    const closeNotificationsModal = () => isNotificationsModalOpen.value = false
    const openNotificationsModal = () => isNotificationsModalOpen.value = true
    const closeSearchModal = () => isSearchModalOpen.value = false
    const openSearchModal = () => isSearchModalOpen.value = true

</script>


<style>
    :global(.SGBF-widget-container) {
        @apply shadow-lg bg-red-600
    }
    :global(.SGBF-branding) {
        display: none !important;
    }
    .SGBF-unread-notifications-count {
        right: -10px !important;
        top: -8px !important;
    }
</style>