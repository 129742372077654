import API from '@/api'
import _ from 'lodash'

const state = {
    current:{
        name:'',
        currency:'TRY',
        currencies:[],
        categories:[],
        items:[],
        project_id:null,
        total:{
            total_buy:0,
            total_sell_with_service_fee:0
        }
    },
    total:{},
    items:[],
    groups:[],
    categories:[],
    is_loading:false
}

// getters
const getters = {
    
}
  
// actions
const actions = {
    updateItemSupplierAction({ state, commit, rootState }, data) {
        console.log(data)
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        let supplier = state.current.items[item_index].suppliers.find(x=>x.id == data.supplier_id)
       
        let currency_buy = rootState.content.currencies.find(x=>x.shortcode == supplier.currency_buy)
        
        const new_params = {
            supplier_id : data.supplier_id,
            price_buy : supplier.price_buy,
            price_sell : supplier.price_sell,
            currency_buy : currency_buy,
            currency_buy_code : supplier.currency_buy,
            supplier : {
                name : supplier.supplier.name
            }
        }
        commit('updateItemSupplierMutation', {item_index:item_index, new_params:new_params})
    },
    loadBudget ({ commit }, id = null) {
        if(!id) {
            id = state.current.code
        }
        commit('setLoading',true)
        API.getBudget(id).then(response => {
            commit('setCurrent',response.data)
            commit('setLoading',false)
        })
    },
    updateItem ({ commit }, data) {
        commit('updateItem', data)
    },
    updateItemSuppliers ({ commit }, data) {
        commit('updateItemSuppliers', data)
    },
    updateItemTaxes ({ commit }, data) {
        commit('updateItemTaxes', data)
    },
    updateItemGroup ({ commit }, data) {
        commit('updateItemGroup', data)
    },
    updateItemSupplier ({ commit }, data) {
        commit('updateItemSupplier', data)
    },
    selectDeal ({ commit }, data) {
        commit('selectDeal', data)
    },
    removeDeal ({ commit }, data) {
        commit('removeDeal', data)
    },
    removeGroup ({ commit }, data) {
        commit('removeGroup', data)
    },
    removeCategory ({ commit }, data) {
        commit('removeCategory', data)
    },
    deleteItem ({ commit }, data) {
        commit('deleteItem', data)
    },
    updateDefItems ({ commit }, data) {
        commit('updateDefItems', data)
    },
    updateDefGroups ({ commit }, data) {
        commit('updateDefGroups', data)
    },

}
  
// mutations
const mutations = {
    updateItemSupplierMutation(state, data) {
        return
        state.current.items[data.item_index] = {
            ...state.current.items[data.item_index],
            ...data.new_params
        }
    },
    removeItemSupplier(state, item_id) {
        const item_index = state.current.items.findIndex(x=>x.id == item_id)
        
        state.current.items[item_index] = {
            ...state.current.items[item_index],
            ...{supplier_id : null, supplier : null}
        }
    },


    // ESKI

    changeSort(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.element)
        state.current.items[item_index].sort_order = data.newIndex
    },
    setData(state, data) {
        state[data.field.replaceAll("-","_")] = data.response
    },
    reset (state) {
        state.current = {name:'',currency:'TRY',currencies:[],categories:[],items:[],project_id:null}
        state.item = []
    },
    updateDefItems(state, data) {
        state.items = data
    },
    updateDefGroups(state, data) {
        state.groups = data
    },
    deleteItem(state, data) {
        state.current.items = state.current.items.filter(x=>x.id != data)
    },
    setItem(state, data) {
        let index = state.current.items.findIndex(x=>x.id == data.id)
        console.log(index)
        state.current.items[index] = data
    },
    selectDeal(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.item_id)
        state.current.items[item_index].deal_id = data.deal_id
    },
    removeDeal(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data)
        state.current.items[item_index].deal_id = null
    },
    updateItemLocal(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        let item = state.current.items.find(x=>x.id == data.id)
        
        state.current.items[item_index] = {...item, ...data.data}

        if(data.data.is_default=='1' && data.data.alt_group!=null){
            state.current.items.filter(x=>x.id != data.id && x.alt_group==data.data.alt_group).map(item=>{
                let index = state.current.items.findIndex(x=>x.id == item.id)
                state.current.items[index].is_default = '0'
            })
        }
    },
    updateGroup(state, data) {
        let group_index = state.current.groups.findIndex(x=>x.id == data.id)
        let group = state.current.groups.find(x=>x.id == data.id)
        
        state.current.groups[group_index] = {...group, ...data.data}
        console.log(state.current.groups[group_index]);
    },
    updateItem1(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        let item = state.current.items.find(x=>x.id == data.id)
        
        data.currency_buy_code   = data.currency_buy.shortcode
        data.currency_sell_code  = data.currency_sell.shortcode

        state.current.items[item_index] = {...item, ...data}
        
    },
    updateItem(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        let item = state.current.items.find(x=>x.id == data.id)
        
        state.current.items[item_index] = {...item, ...data.supplier}
        
    },
    updateItemSuppliers(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        let item = state.current.items.find(x=>x.id == data.id)
        
        state.current.items[item_index].suppliers = data.suppliers
        
    },updateItemTaxes(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        
        state.current.items[item_index].taxes = data.taxes
    },
    updateItemProfit(state, data) {
        //let item_index = state.current.items.findIndex(x=>x.id == data.id)
        let item = state.current.items.find(x=>x.id == data.id)
        item.profit = data.profit
    },
    updateItemGroup(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        state.current.items[item_index].in_group = {group_id:data.group_id}
        
    },
    updateItemSupplier_eski(state, data) {
        let item_index = state.current.items.findIndex(x=>x.id == data.id)
        let supplier = state.current.items[item_index].suppliers.find(x=>x.id == data.supplier_id)
        state.current.items[item_index].supplier_id = data.supplier_id
        state.current.items[item_index].price_buy = supplier.price_buy
        state.current.items[item_index].price_sell = supplier.price_sell
        
        state.current.items[item_index].currency_buy = state.current.currencies.find(x => x.detail.shortcode == supplier.currency_buy).detail
        state.current.items[item_index].currency_sell = state.current.currencies.find(x => x.detail.shortcode == supplier.currency_sell).detail

        
        
    },
    setLoading(state, data) {
        state.is_loading = data
    },
    setCurrent(state, data) {
        state.current = data
        state.total = data.total
        state.categories = data.categories.map(x => x.id)
        state.category_totals = data.categories.map(x => {
            return {
                id : x.id,
                total_buy : x.total_buy,
                total_sell : x.total_sell
            }
        })
    },

    // Draggable 
    moveItemInGroup(state, data) {
        let group = state.current.groups.find(x=>x.id == data.group_id)
        group.items.forEach(item => {
            if(data.newIndex < data.oldIndex && item.sort_order >= data.newIndex +1) item.sort_order++
            if(data.newIndex > data.oldIndex && (item.sort_order > data.oldIndex +1 && item.sort_order <= data.newIndex +1)) item.sort_order--
        })
        group.items.find(x=>x.item_id == data.element).sort_order = data.newIndex + 1
        group.items.sort((a,b) => a.sort_order - b.sort_order)
    },
    addItemToGroup(state, data) {
        if(!data) return
        const group = state.current.groups.find(x=>x.id == data.group_id)
        let arr = []
        data.items.forEach( (item, index) => {
            arr.push({
                group_id : data.group_id,
                item_id : item,
                sort_order : index + 1
            })
        })
        group.items = arr

        return
        
        group.items.push(data)
        group.items = _.uniqBy(group.items, 'item_id')
    },
    groupSetDefault(state, data) {
        const group = state.current.groups.find(x=>x.id == data.group_id)
        
        state.current.groups.forEach( (x,index) => {
            console.log(x)
            if(x.alt_group == group.alt_group) {
                state.current.groups[index].is_default = '0' 
            }
        })
        group.is_default = '1'
    },
    removeItemFromGroup(state, data) {
        console.log("removeItemFromGroup",data)
    },
    addItemToItemsList(state, data) {
        state.current.items.push(data)
    },
    addGroupToGroupsList(state, data) {
        state.current.groups.push(data)
    },
    addGroupToCategory(state, data) {
        let category = state.current.categories.find( x => x.id == data.category_id)
        category.items.push({"category_id":parseInt(data.category_id),"item_id":data.item_id,"type":"GROUP","sort_order":10})
    },
    addItemToCategory(state, data) {
        let item = state.current.items.find(x => x.id == parseInt(data.item_id))
        item.in_group = null
        let category = state.current.categories.find( x => x.id == data.category_id)
        category.items.push({"category_id":parseInt(data.category_id),"item_id":parseInt(data.item_id),"type":"ITEM","sort_order":10})
    },
    removeItemFromCategory(state, data) {
        console.log("removeItemFromCategory",data)
    },
    setCategoriesOrder(state, data) {
        state.categories = data
    },
    setCategoryTotal(state, data) {
        let category = state.category_totals.find(x => x.id == data.id)
        category.total_buy = data.total_buy
        category.total_sell = data.total_sell
    },
    setTotal(state, data) {
        //state.current.total = data
        state.total = data
    },
    removeGroup(state, data) {
        state.current.categories.forEach(cat => {
            _.remove(cat.items, item => {
                return item.item_id == data && item.type == 'GROUP';
            });
        })

        let group = state.current.groups.find(x => x.id == data)
        let group_item_id = group.items.map(x=>x.item_id)
        state.current.items = state.current.items.filter(x => !group_item_id.includes(x.id) )

        _.remove(state.current.groups, group => {
            return group.id == data;
        });
    },
    removeCategory(state, data) {
        let category2remove = state.current.categories.find(x => x.id == data)
        
        category2remove.items.forEach(category_item => {
            if(category_item.type == 'GROUP') {
                const group_id = category_item.item_id
                let group = state.current.groups.find(x => x.id == group_id)
                let group_item_ids = group ? group.items.map( x => x.item_id ) : []
                _.pull(state.current.items,group_item_ids)
                _.pull(state.current.groups,group_id)
                
                /*_.remove(state.current.items, item => {
                    return group_item_ids.includes(item.id)
                });

                _.remove(state.current.groups, group => {
                    return group.id == group_id;
                });*/

            }
            if(category_item.type == 'ITEM') {
                _.remove(state.current.items, item => {
                    return category_item.item_id == item.id;
                });
            }
        })

        _.remove(state.current.categories, category => {
            return category.id == category2remove.id;
        });

        _.remove(state.categories, category => {
            return category == category2remove.id;
        });
        _.remove(state.category_totals, category => {
            return category.id == category2remove.id;
        });
    },
    setCategories(state, data) {
        state.current.categories.forEach((cat,index) => {
            state.current.categories[index] = {...cat, ...data[index]}
        })
    },
    setCategoryItems(state, data) {
        let category = state.current.categories.find( x => x.id == data.id)

        //category.items = data.items
        category.items.forEach(item => {
            const index = data.items.findIndex( x => x.type == item.type && x.item_id == item.item_id)
            item.sort_order = index + 1
        })
        //category.items = category.items.sort((a,b) => a.sort_order < b.sort_order)
        category.items  = _.sortBy(category.items, 'sort_order')

    },
    deleteBudgetItem(state, data) {

        state.current.categories.forEach(cat => {
            _.remove(cat.items, function(x) {
                return x.item_id == data && x.type == 'ITEM';
            });
        })

        state.current.groups.forEach(group => {
            _.remove(group.items, function(x) {
                return x.item_id == data;
            });
        })
        
        return

        let item =  state.current.items.find(x => x.id == data)
        console.log(item)
        let cat =  state.current.categories.find(x => x.id == item.category_id)
        console.log(cat)

        //let index = cat.items.findIndex(x => x.item_id == data)

        _.remove(cat.items, function(x) {
            return x.item_id !== data;
        });

        return
        state.current.categories.forEach(cat => {
            cat.items = cat.items.filter( x => x.item_id !== data)
        })
        //state.current.items = state.current.items.filter(x => x.id !== data)
        return
        const indexx = state.current.items.findIndex(x => x.id == data)
        if(index) {
            console.log(index)
            delete state.current.items[index]
            //return
            //state.current.items = state.current.items.filter(x => x.id !== data)
        }
        
    }
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}