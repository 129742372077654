import API from '@/api'

const state = {
    active_form: {
        sections: [],
        db_columns: []
    },
    active_field: {}
}
  
// getters
const getters = {
    getField: (state) => (id) => {
        let searched_field
        state.active_form.sections.forEach((section,s) => {
            section.fields.forEach((field,f) => {
                if(field.id == id) {
                    searched_field = field
                }
            })
        })
        return searched_field
    },
    getSection: (state) => (id) => {
        //console.log(id)
        //console.log(state.active_form.sections.find(section => section.id == id))
        return state.active_form.sections.find(section => section.id == id)
    }
}
  
// actions
const actions = {
    loadData ({ commit }, data) {
        API.getFormData(data).then(response => {
            commit('setForm', response.data)
        })
    },
    updateField ({ commit }, data) {
        commit('setFieldData',data)
    },
    updateSection ({ commit }, data) {
        commit('setSectionData',data)
    },
    addOption ({ commit }, data) {
        commit('addOption',data)
    },
}
  
// mutations
const mutations = {
    setForm (state, data) {
        state.active_form = data
    },
    setFieldData (state, data) {
        state.active_form.sections.forEach((section,section_index) => {
            let field_index = section.fields.findIndex(x => x.id == data.id)
            let field = section.fields.find(x => x.id == data.id)
            if(field) {

            }
            if(field_index > -1) {
                state.active_form.sections[section_index].fields[field_index] = { ...state.active_form.sections[section_index].fields[field_index], ...data.field }
                if(data.id == state.active_field.id) {
                    state.active_field = data.field
                }
            }
        })
    },
    setSectionData (state, data) {
        let section_index = state.active_form.sections.findIndex(x => x.id == data.id)
        state.active_form.sections[section_index] = { ...state.active_form.sections[section_index], ...{
            help_text : data.help_text,
            is_hidden : data.is_hidden,
            title : data.title,
            title_colour : data.title_colour
        } }
        
    },
    addOption (state, data) {
        console.log(data)
        
        /*
        {"id":157,"form_field_id":250,"option_text":"asdasdasd","flight_id":null,"order":null,"quota_allocated":null,"quota_available":null,"deleted_at":null}
        */
        state.active_form.sections.forEach((section,s) => {
            let field = section.fields.findIndex(x => x.id == data.id)
            if(field) {
                state.active_form.sections[s].fields[field].options.push(
                    {
                        option_text : data.option.option_text,
                        quota_available: data.option.quota_available
                    }
                )
            }
        })
    },
    setActiveField (state, field_id) {
        if(field_id == null) {
            state.active_field = {}
            return
        }
        let searched_field
        state.active_form.sections.forEach((section,s) => {
            section.fields.forEach((field,f) => {
                if(field.id == field_id) {
                    searched_field = field
                }
            })
        })
        state.active_field = searched_field
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}