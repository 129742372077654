<script setup>
    import { computed, ref, watch, onBeforeMount, onMounted } from "vue";
    import API from "@/api";
    import { useStore } from "vuex";
    import _ from "lodash";

    const store = useStore();

    const active_field = computed(() => store.state.form.active_field);
    const field = ref(_.cloneDeep(active_field.value));
    
    const currencies = computed(() => store.getters['content/currencies_select'])

    const save = () => {
        API.saveFormField(field.value.id, field.value)
            .then( response => {})
            .catch( error => {
                store.dispatch("floating_alerts/addAlert", {
                    type: "danger",
                    title: "HATA!",
                    description: error.response.data.message,
                });
            });
    };
</script>

<template>
    <UISelect
        label="Para Birimi"
        title="Para Birimi Seç"
        display="box"
        :source="currencies"
        class="mb-5"
        v-model="field.props.currency"
    />
    <UIButton type="submit" text="Kaydet" class="mt-3" @click="save" />
</template>
