<template>
    <UIPageTitle title="Müşteriler"/>
	<UITable :titles="table_titles">
		<template v-slot:rows>
			<tr v-if="clients.length == 0" class="h-96 "><td colspan="6" class="text-center"><h1>Aradığın kriterlerde bir müşteri yok görünüyor</h1></td></tr>
			<tr v-for="client in clients" :key="client.id" class="hover:bg-teal cursor-pointer">
				<UITableCell>
					<div class="font-bold truncate">{{ client.name }} <i class="fa fa-eye ml-2" @click="gotoclient(client.code)"></i></div>
				</UITableCell>
                <UITableCell>
					<div class="flex gap-2" v-for="similar in client.data">
                        <span class="truncate">{{ similar.name }}</span>
                        <span class="text-green-300">({{ similar.percent }} %)</span>
                    </div>
				</UITableCell>

                <UITableCell>
					<div class="font-bold truncate"> <i class="fa fa-merge ml-2 border p-1" @click="open_merge_modal(client.id)"></i></div>
				</UITableCell>
			</tr>
		</template>
	</UITable>

    <FormModal 
		key="merge"
		actionButtonText='Birleştir'
		:isSubmitting="isSubmitting" 
		:modalProps="mergeModalProps" 
		:isOpen="isMergeModalOpen" 
		@submit="submit_merge" 
		@modal_closed="close_merge_modal"
	/>
</template>

<script setup>
	import { ref,computed,inject } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import API from '@/api'
	import TableView from '@/views/TableView.vue'
    import FormModal from '@/modals/Form.vue'
    
    const store = useStore()
	const emitter = inject('emitter')
    const router = useRouter()

    const isSubmitting = ref(false)
    const isMergeModalOpen = ref(false)

    const selected_client = ref(null)

    const clients= ref([])
    const similar_clients= ref([])
	const isModalOpen = ref(false)

	const empty_state = {
        title:'Benzer müşteri listesinde eşleşen veri bulunamadı!',
        description:''
	}

    let table_titles = [
		{
			title:'Müşteri Adı',
		},
        {
			title:'Benzer Veriler',
		},{
            title:'',
        }
	]

	const modal_closed =() => {
		isModalOpen.value = false
    }

    const gotoclient = (code) => router.push({name: 'ClientDetail', params: { code: code }})

    const getClients = () =>{
        API.get('similarity/clients').then(response=>{
            clients.value=response.data.filter(x=>x.is_similarity==1)
            console.log( clients.value);
        })
    }

    getClients()

    const open_merge_modal = id => {
        let clientIndex=clients.value.findIndex(x=>x.id==id)
        if (clientIndex>=0) {  
            selected_client.value = id
            similar_clients.value=clients.value[clientIndex].data.map(x=>{return {value:x.id,text:x.name}})

            isMergeModalOpen.value=true
        }
    }

    const close_merge_modal = () => {
        isMergeModalOpen.value = false
        isSubmitting.value = false
    }

    const submit_merge = data => {
        isSubmitting.value = true
        data.merge_from = [selected_client.value]
       
        API.put('merge/clients', data).then(response => {
			close_merge_modal()
            isSubmitting.value = false
            clients.value=clients.value.filter(x=>x.id!=data.merge_from && x.id!=data.merge_to)
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
				title:'BAŞARILI!',
				description:response.data.message
			})
		}).catch(error => {
			
            isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
				title:'HATA!',
				description:error.response.data.message
			})
		})
	}

    const mergeModalProps = computed(() => {return {
		title:'Müşterileri Birleştir',
		cols:1,
		header:{
			type:'tip',
			text:'Bu müşteriye ait tüm bilgiler aşağıda seçeceğin müşteriye aktarılacaktır.',
			title:'Bilgi'
		},
		schema: [
			{
				id: 'merge_to',
				component: 'UISelect',
				label: 'Hangi müşteri ile birleştirmek istiyorsun?',
				required: true,
				props:{
					title:'Müşteri seç',
					source:similar_clients.value,
					display:'box'
				}
			}
		]
	}})

</script>
