<script setup>
	import { ref,computed } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'

	import API from '@/api'
    
    const store = useStore()
	
    const isSubmitting = ref(false)
    const event_company = computed(() => store.state.auth.event_company)
    //const footer_note = ref(event_company.value.budget_footer_note)
    const export_data=ref({
        export_main_color:event_company.value.export_main_color,
        export_secondary_color:event_company.value.export_secondary_color,
        budget_footer_note:event_company.value.budget_footer_note
    })
    
    const submit = () => {
        isSubmitting.value = true
        API.put('event-companies/' + event_company.value.id+'/export-setting', export_data.value)
        .then(response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:response.data.message
            })
            isSubmitting.value = false
        })
        .catch(error => {
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
            isSubmitting.value = false
        })
    }

</script>

<template>
	<UIPageTitle title="Dışa Aktarım"/>
    <div class="flex flex-wrap">
    
        <UICard class="w-1/2" title="Ana Renk">
            <UIFormRow>
                <UIInput type="color" v-model="export_data.export_main_color" :styled="false"/>
            </UIFormRow>
        </UICard>

        <UICard  class="w-1/2" title="Ara Renk">
            <UIFormRow>
                <UIInput type="color" v-model="export_data.export_secondary_color" :styled="false"/>
            </UIFormRow>
        </UICard>


        <div class="w-2/2 mt-3">
            <UITextarea label="Dip Not Metni" :autogrow="false" class="h-40" v-model="export_data.budget_footer_note"/>
            <div class="text-sm">Burada ekleyeceğin metin,  dışa aktaracağın Excel ve PDF bütçelerde kulanılacaktır.</div>
        </div>

        
        <div class="w-full mt-5 flex justify-end">
            <UIButton :loading="isSubmitting" type="submit" text="Kaydet" @click="submit"/>
        </div>
    </div>
</template>
