<script setup>

	import {ref, watch, computed, inject, defineAsyncComponent} from 'vue'
    import { useStore } from 'vuex'
    import { useRouter,useRoute } from 'vue-router'
    import API from '@/api'
	import Aside from '@/components/UI/Aside.vue'
    import useVuelidate from '@vuelidate/core'
    import { required, requiredIf, email, url, minLength } from '@/utils/i18n-validators'
    //import NewContactModal from '@/modals/contact/ContactForm.vue'
    import BizdevDeal from '@/composables/BizdevDeal.js'
    import {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
    } from '@headlessui/vue'
    import { ChevronUpIcon } from '@heroicons/vue/solid'
    
    const NewContactModal = defineAsyncComponent(() => import('@/modals/contact/ContactForm.vue'))

    const props = defineProps({
        isOpen:Boolean,
        project:Object
    })

    const BDD = new BizdevDeal(props.project)

    const moment = inject('moment')
    const store = useStore()
    const router = useRouter()
    const emit = defineEmits(['modal_closed'])

    const submit_error = ref({
        title:null,
        description:null
    })

    const title = computed(() => {
        let title
        if(props.project) {
            if(props.project.current_status_group == 'LEAD') {
                title = 'İş Fırsatını Güncelle'
            } else {
                title = 'Projeyi Güncelle'
            }
        } else {
            title = 'Yeni İş Fırsatı'
        }
        return title
    })
    const action_button_text = ref(props.project ? 'Güncelle' : 'Ekle')

    const cities = ref([])

    const contacts = computed(() => store.state.content.contacts.filter( x => {
        return x.contact_type == 'C' && x.contact_for == project.value.client
    }).map( type => { return {value : type.id, text : type.name, subtext: type.position.name} }).sort( (a, b) => a.text.localeCompare(b.text) ))
    
    const me = computed(() => store.state.auth.me)
    const my_division = computed(() => store.state.content.divisions.find( x => x.id == me.value.division_id))
    
    const stages = computed(() => store.state.auth.event_company.bizdev_stages.map( x => { return {value:'LEAD-'+x.id, text:x.name}}))
    const clients = computed(() => store.getters['clients/list_select'])
    const countries = computed(() => store.getters['content/countries_select'])
    
    
    const project_types = computed(() => {
        if(me.value.position.scope == 'AGENCY') {
            return store.getters['content/project_types_select']
        }
        return store.state.content.project_types.filter( x => x.divisions.map( y => y.id).includes(my_division.value.id)).map( type => { return {value : type.id, text : type.name, budget_type : type.budget_type} }).sort( (a, b) => a.text.localeCompare(b.text) )
    })
    const contact_positions = computed(() => store.getters['content/contact_positions_select'])
    const currencies = computed(() => store.getters['content/currencies_select'])
    const languages = computed(() => store.getters['content/languages_select'])
    
    //const users = computed(() => store.getters['content/users_select'])
    
    
    const users = ref([])
    
    API.get('leads/responsibles/' + me.value.id)
    .then( response => {
        users.value=response.data.map(item => { return {
            image : item.profile_picture_full,
            value:item.id,
            text:item.full_name
        }})
    })

    const user_lists = computed(() => store.state.content.users)

    const isNewContactModalOpen = ref(false)
    const isModalOpen = ref(props.isOpen)
    const lead_close_date_max = ref(null)
    const is_submitting = ref(false)
    const is_new_client = ref(false)
    const today = computed(() => moment().format('YYYY-MM-DD'))
    const is_manual_project_code = ref(() => {
        const type = project_types.value.find( x => x.value == project.value.type)
        if(!type) return false
        if(type.budget_type == 'buy_only') return true
        return false
    })

    const add_option = ref('Yeni kontakt ekle')
    const added_option = () => isNewContactModalOpen.value = true
    
    const project = ref(BDD.setValues())
    project.value.status = stages.value[0].value
    watch(() => props.project, data => {
        BDD.setProject(props.project)
        project.value = BDD.setValues()
    })

    const client_detail_for_contact_add = ref({})


    const rules = computed(() => ({
        project: {
            lead_close_date: { required : requiredIf(value => !props.project) },
            lead_value: { required : requiredIf(value => !props.project)  },
            name: { required },
            dates: { required },
            type: { required },
            client: { required },
            status: { required : requiredIf(value => !props.project) },
            city: { required },
            contact: { required : requiredIf(value => !is_new_client.value) },
            project_ref: { required : requiredIf(value => is_manual_project_code.value) },
            //new_contact_name: { required : requiredIf(value => !is_new_client.value) },
            new_contact: {
                name: { required : requiredIf(value => is_new_client.value) },
                email: { required : requiredIf(value => is_new_client.value), email },
                position_id: { required : requiredIf(value => is_new_client.value) }
            }
        }
    }))

    const v$ = useVuelidate(rules, {project})
    
    const ready2submit = computed(() => {
        return !v$.value.$invalid 
    })
    
    API.get('clients').then(response => store.commit('clients/setClients',response.data))

    const project_repeat = ref([
        {value:"single",text: 'Tek Etkinlik'},
        {value:"hybrid",text:'Hibrit'},
        {value:"virtual",text:'Sanal'}
    ])
    
    const project_format_types = ref([
        {value:"inperson",text: 'Fiziksel'},
        {value:"hybrid",text:'Hibrit'},
        {value:"virtual",text:'Sanal'}
    ])

    const project_fin_fee_types = ref([
        {value:"PERCENTAGE",text: 'Yüzde'},
        {value:"FIXED",text:'Fiks'}
    ])

	watch(() => props.isOpen, (data) => {
		isModalOpen.value = data
        submit_error.value = {
            title:null,
            description:null
        }
	},{ immediate: true })

    watch(() => project.value.dates, data => {
        lead_close_date_max.value = moment(project.value.dates[0]).format('YYYY-MM-DD')
    },{immediate:true,deep:true})
    
    watch(() => project.value.client, data => {
            if(!data) return

            let client = store.state.clients.list.find(client => client.id == data)
            
            if(!client) {
                is_new_client.value = true
                return
            }
            is_new_client.value = false
            API.get('client/id/' + client.id).then( response => {
                client = response.data
                client_detail_for_contact_add.value = { name : client.name, id:'C-' + client.id}
            

                if(!props.project) {
                    if(client.fin_fee_type) {
                        project.value.fin_fee_type = client.fin_fee_type
                        if(client.fin_fee_type == 'FIXED') {
                            project.value.fin_fee_fixed_amount = client.fin_fee_rate
                        } else {
                            project.value.fin_fee_percentage = client.fin_fee_rate
                        }
                        
                        project.value.fin_fee_currency = client.fin_fee_currency
                        if(client.profit_target) project.value.profit_target = client.profit_target
                    }
                }
            })

            
            
            return
			API.get('contacts',{type:'C',for:data}).then(response => {
                contacts.value = response.data.map(x=>{
                    return {text:x.name, subtext:x.position.name,value:x.id, image : x.profile_picture_full}
                })
            })
		},{ immediate: true }
    )

    watch(() => project.value.country, data => {
            if(!data) return

            API.get('definition/cities/' + data).then(response => {
                cities.value = response.data.map(x=>{
                    return {text:x.name, value:x.name}
                })
            })
		},{ immediate: true }
    )

    watch(() => project.value.responsible, data => {
            if(!data) return

            let selectUser=user_lists.value.find(x=>x.id==data)
            if(selectUser && selectUser.division!=null && selectUser.division.project_ref_type!='auto'){
                is_manual_project_code.value=true
            }else{
                is_manual_project_code.value=false
            }

		},{ immediate: true }
    )

    const modal_closed = () =>{
		isModalOpen.value = false
        is_submitting.value = false
		emit('modal_closed')
	}

    const submit = () => { 
        is_submitting.value = true
        let request
        if(props.project) {
            request = API.put('leads/' + props.project.code, project.value)
        } else {
            request = API.post('leads', project.value)
        }
        
        request
        .then(response => {
            modal_closed()
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description : response.data.message
            })
            if(props.project) {
                if(props.project.current_status_group == 'LEAD') {
                    API.getLeads()
                    router.push({name: 'BizdevDetail', params: { code: response.data.code }, force:true})
                } else {
                    API.getProjects()
                    router.push({name: 'ProjectDetail', params: { code: response.data.code, subpage:'main' }, force:true})
                }
            } else {
                API.getLeads()
                router.push({name: 'BizdevDetail', params: { code: response.data.code }, force:true})
            }
            
            
        })    
        .catch((error) => {
            
            is_submitting.value = false
            if (error.response) {
                submit_error.value = {
                    title : 'Bir hata oluştu!',
                    description : error.response.data.message
                }
                is_submitting.value = false
            } else if (error.request) {
                submit_error.value = {
                    title : 'Bir hata oluştu!',
                    description : 'Bir hata oluştu lütfen tekrar deneyin'
                }
            } else {
                submit_error.value = {
                    title : 'Bir hata oluştu!',
                    description : 'Bir hata oluştu lütfen tekrar deneyin'
                }
            }
            
        })
    }
</script>

<template>
    <Aside 
        :colored_header="true" 
        :isOpen="isModalOpen" 
        :ready2submit="ready2submit" 
        :isSubmitting="is_submitting" 
        @modal_closed="modal_closed" 
        @submit="submit" 
        :title="title" 
        :action_button_text="action_button_text" 
        size="xl"
    >
        <template v-slot:modal_content>
            <UIAlert :titleText="submit_error.title" type="danger"  class="my-2 flex-row" v-if="submit_error.description">
                <p>{{ submit_error.description }}</p>
            </UIAlert>
            <UIFormRow class="mt-4">
                <UIInput label="Projenin adı nedir?" v-model="project.name" :errors="v$.project.name.$errors"/>
            </UIFormRow>
            <UIFormRow class="flex space-x-2">
                <UISelect title_color="text-gray-700" display="box" :source="project_types" label="Ne tip bir projeden bahsediyoruz?" title="Proje tipi seç" v-model="project.type" class="w-2/3"/>
                <UIInput type="number" label="Yaklaşık katılımcı sayısı?" class="w-1/3" v-model="project.attendee_count"/>
            </UIFormRow>
            <UIFormRow>
                <UISelectAutoComplete allow_new display="box" :source="clients" title="Müşteri ara veya ekle" label="Hangi müşteri için?" v-model="project.client"/>
            </UIFormRow>
            <UIFormRow v-if="project.client && !is_new_client">
                <UISelect title_color="text-gray-700" help_text="İş fırsatını oluşturduktan sonra varsa diğer kontaktlarını ekleyebilirsin." display="box" key="lead_contacts" label="Birincil kontakt kim?" title="Kişiyi seç" :source="contacts" v-model="project.contact" :add_option="add_option" @add_option="added_option"/>
            </UIFormRow>
            <template v-if="project.client && is_new_client" >
                <UIAlert type="tip">
                    Bu yeni müşteri için bir kontakt kişi oluşturman gerekiyor.
                </UIAlert>
                <UIFormRow>
                    <UIInput label="Kontakt kişinin adı ve soyadı nedir?" v-model="project.new_contact.name"/>
                </UIFormRow>
                <UIFormRow class="flex space-x-2">
                    <UIInput label="E-posta adresi nedir?" v-model="v$.project.new_contact.email.$model" class="w-1/2":errors="v$.project.new_contact.email.$errors"/>
                    <UISelect title_color="text-gray-700" :source="contact_positions" label="Ünvanı nedir?" title="Pozisyon seç" display="box" v-model="project.new_contact.position_id" class="w-1/2 hidden"/>
                    <UISelectAutoComplete :allow_new="true" v-model="project.new_contact.position_id" :source="contact_positions" label="Ünvanı nedir?" title="Ünvan Seç" display="box" class="w-1/2" :errors="v$.project.new_contact.position_id.$errors"/>
                </UIFormRow>
            </template>
            <UIFormRow v-if="!props.project">
                <UISelect title_color="text-gray-700" :search_enabled="false" display="box" v-model="project.status" :source="stages" label="Şu anda hangi aşamada?" title="Aşama seç"/>
            </UIFormRow>
            <UIFormRow class="flex space-x-2">
                <UIDateRangePicker label="Proje tarihi nedir?" :title="'Başlangıç ve Bitiş Tarihi Seç'" v-model="project.dates" display="box" class="w-3/5"/>
                <UIInput label="Proje kodu nedir?" v-model="project.project_ref" class="w-1/2" v-if="is_manual_project_code"/>
            </UIFormRow>
            <UIFormRow>
                <UIDatePicker label="Teklif müşteriye ne zaman gönderilecek?" title="Tarih Seç" display="box" v-model="project.client_response_date"/>
            </UIFormRow>
            <UIFormRow class="flex space-x-2" v-if="!props.project">
                <UIDatePicker :shortcuts="false"  label="Tahmini neticelenme tarihi" title="Tarih Seç" display="box" class="w-2/5" v-model="project.lead_close_date"/>
                <UICurrencyInput help_text="Bu rakam bütçe oluşturulana kadar raporlamalarda kullanılacaktır." v-model:currency="project.currency" label="Tahmini proje büyüklüğü" :options="{ hideCurrencySymbolOnFocus:false,currency: project.currency, locale:'tr' }"  class="w-1/2" v-model.lazy="project.lead_value"/>
            </UIFormRow>
            <Disclosure v-slot="{ open }">
                <DisclosureButton class="py-2 text-left flex items-center font-bold">
                    Diğer Bilgiler 
                    <ChevronUpIcon
                        :class="!open ? 'rotate-180 transform' : ''"
                        class="h-5 w-5" 
                    />
                </DisclosureButton>
                <DisclosurePanel>
                    <UIFormRow class="flex space-x-2">
                        <UISelect title_color="text-gray-700" :source="countries" label="Ülke" title="Ülke" display="box" class="w-1/2" v-model="project.country"/>
                        <UISelect title_color="text-gray-700" :source="cities" label="Şehir" title="Şehir" display="box" class="w-1/2" v-model="project.city" v-if="cities.length > 0"/>
                        <UIInput label="Şehir" class="w-1/2" v-model="project.city" v-else/>
                    </UIFormRow>
                    <UIFormRow>
                        <UISelect title_color="text-gray-700" :source="users" label="Proje Yöneticisi" title="Kullanıcı Seç" display="box" v-model="project.responsible"/>
                    </UIFormRow>
                    <UIFormRow>
                        <UISelect title_color="text-gray-700" :source="languages" :tags="true" title="Proje Dilleri" label="Proje Dilleri" v-model="project.languages" :multiple="true" display="box"/>
                    </UIFormRow>
                    <UIFormRow class="hidden">
                        <UITextarea :autogrow="false" v-model="project.note" label="Proje Hakkında Not" class="h-24"/>
                    </UIFormRow>
                    <UIFormRow class="flex gap-5">
                        <UIRadioGroup class="shrink-0" direction="horizontal" key="project_fin_fee_type" label="Hizmet Bedeli Tipi" :options="project_fin_fee_types" v-model="project.fin_fee_type"/>
                    
                        <template v-if="project.fin_fee_type == 'PERCENTAGE'">
                            <UIInput type="number" label="Hizmet Bedeli Oranı" class="w-32 shrink-0" prepend="%" v-model="project.fin_fee_percentage"/>
                        </template>
                        <template class="flex space-x-2" v-else>
                            <UICurrencyInput label="Hizmet bedeli tutarı nedir?" :options="{ hideCurrencySymbolOnFocus:false,currency: project.currency, locale:'tr' }"  class="w-42" v-model.lazy="project.fin_fee_fixed_amount"/>
                        </template>
                    
                        <UIInput type="number" class="w-32 shrink-0" label="Proje Kârlılık Hedefi" prepend="%" v-model="project.profit_target"/>
                    </UIFormRow>
                </DisclosurePanel>
            </Disclosure>
        </template>
    </Aside>
    <NewContactModal v-if="isNewContactModalOpen" :isOpen="isNewContactModalOpen" @modal_closed="isNewContactModalOpen = false" :company="client_detail_for_contact_add"/>
</template>



<style scoped>
	
</style>