<script setup>
    // Imports
	import {ref, computed, inject} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'
    import { XIcon } from '@heroicons/vue/solid'
    import useVuelidate from '@vuelidate/core'
    import { required, minLength } from '@/utils/i18n-validators'
    
    const helpers = inject('helpers')
    const moment = inject('moment')
    const emitter = inject('emitter')

    // Props
	const props = defineProps({
        advance_payment : Object
    })
    
    const store = useStore()
    const isSubmitting = ref(false)
    const submit_error = ref(false)
    
    const ready2submit = computed( () => data.value.project_id && data.value.source && data.value.currency && data.value.requested_date && data.value.items.length > 0 && data.value.items.filter(x=>!x.amount || !x.description || !x.payment_method).length == 0 )
    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const event_company = computed(() => store.state.auth.event_company)
    //const projects = computed(() => store.getters['projects/list_select'])
    const projects = ref([])
    const currencies_select = computed(() => store.getters['content/currencies_select'])

    const items_cash = computed(() => data.value.items.filter(x=>x.payment_method == 'CASH'))
    const items_cc = computed(() => data.value.items.filter(x=>x.payment_method == 'CC'))
    
    const total_cash = computed(() => items_cash.value.reduce((sum, x) => sum + x.amount, 0))
    const total_cc = computed(() => items_cc.value.reduce((sum, x) => sum + x.amount, 0))
    const totals = computed(() => total_cash.value + total_cc.value )

    const expense_sources_select = computed(() => store.getters['content/expense_sources_select'])
    const expense_sources = ref([
		...[{
			"value" : "PROJECT",
			"text" : "Proje"
		}],
        ...expense_sources_select.value
	])
    
    API.getProjectsByStatus(['LEAD','PREP','OPERATION','PRECLOSE']).then(response => {
        projects.value = response.data.map( project => { return {value : project.code, text : project.name, subtext : project.project_ref, image : project.client.logo_full } }).sort((a, b) => a.text.localeCompare(b.text))
    })

    const payment_methods = [
		{
			"value" : "CASH",
			"text" : "Nakit"
		},
		{
			"value" : "CC",
			"text" : "Kredi Kartı"
		}
	]

    // Data models
    const data = ref({
        source : props.advance_payment ?props.advance_payment.source:'PROJECT',
        advance_payment_id  : props.advance_payment ?(props.advance_payment.status== 'DECLINED'?null:props.advance_payment.id ) : null,
        project_id : props.advance_payment ? props.advance_payment.project_id : null,
        requested_date : props.advance_payment ? props.advance_payment.requested_date : moment().add(1,'week').format('YYYY-MM-DD'),
        items : props.advance_payment ? props.advance_payment.items : [],
        description : props.advance_payment ? props.advance_payment.reason : '',
        currency : props.advance_payment ? props.advance_payment.currency : event_company.value.currency
    })

    // Watchers
    /*
    watch(() => props.inspection_id, (data) => {
            if(!data) return
			
		},{ immediate: true }
    )
    
    */

    // Functions

	const modal_closed = () => emit('modal_closed')
    const delete_item = id => data.value.items = data.value.items.filter((x,i)=> i !== id )
        
    const add_item = () => {
        data.value.items.push({
            description:'',
            amount:'',
            payment_method:'CASH'
        })
        
    }
    
    if(!props.advance_payment) {
        add_item() // Add first row
    }

    const rows_not_empty = (value) => data.value.items.filter(x=>!x.amount || !x.description || !x.payment_method).length == 0 
    const rules = computed(() => ({
        project_id:{ required },
        source:{ required },
        currency:{required},
        requested_date:{required},
        items: { minLengthValue: minLength(1), rows_not_empty }
    }))

    const v$ = useVuelidate(rules, data)

	const submit = async () => {

        const result = await v$.value.$validate()
        if(!result) return

        if (!(data.value.items.length > 0 && data.value.items.filter(x=>!x.amount || !x.description || !x.payment_method).length == 0)) {
            submit_error.value = 'Harcama Listesi alanlarındaki bilgileri doldurunuz';
            return;
        }
        
        isSubmitting.value = true

        let request
        
        if(data.value.advance_payment_id) {
            request = API.put('accounting/advance-payments/' + data.value.advance_payment_id,data.value)
        } else {
            request = API.post('accounting/advance-payments',data.value)
        }
        
        request.then( response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:'Avans talebin işleme alındı.',
            })
            isSubmitting.value = false
            
            modal_closed()
            emitter.emit('load_advance_payments')
        }).catch( error => {
            
            isSubmitting.value = false
            submit_error.value = error.response?.data.message
        })
    }

</script>


<template>
    <Modal 
        :isOpen="true"
        title="İş Avans Formu"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Onaya Gönder"
        @modal_closed="modal_closed"
        @submit="submit"
        size="3xl"
    >
        <template v-slot:modal_content>
            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>
            <UIFormRow class="flex space-x-3">
                <div class="w-1/2">
                    <UISelect :search_enabled="false" :source="expense_sources" label="Kullanım Yeri" title="Seç" display="box"  :errors="v$.source.$errors" v-model="data.source"/>
                </div>
                <div class="w-1/2">
                    <UISelect :disabled="data.source != 'PROJECT'" label="Hangi Proje?" title="Bir proje seç" :source="projects" display="box" :errors="v$.project_id.$errors" v-model="data.project_id"/>
                </div>
            </UIFormRow>
            <UIFormRow class="flex space-x-3">
                <div class="w-1/3">
                    <UIFormRow>
                        <UIDatePicker label="Talep Edilen Tarih?" :errors="v$.requested_date.$errors" title="Tarih Seç" display="box" v-model="data.requested_date"/>
                    </UIFormRow>
                    <UIFormRow class="mb-0">
                        <UISelect :source="currencies_select" label="Para Birimi" title="Para Birimi" display="box" :errors="v$.currency.$errors" v-model="data.currency"/>
                    </UIFormRow>
                </div>
                <div class="w-2/3">
                    <UITextarea v-model="data.description" :maxlength="500" :autogrow="false" label="Açıklama" placeholder="Lütfen avans talebinin gerekçesini yaz" class="h-32 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-gray-100 p-2"/>
                </div>
            </UIFormRow>
            <UIFormRow>
                
            </UIFormRow>
            <UIFormRow>
                <div class="font-bold">Öngörülen Avans Harcama Listesi</div>
            </UIFormRow>
            <div class="flex space-x-1 mb-2" >
                <div class="font-bold text-sm w-3/5">Açıklama</div>
                <div class="font-bold text-sm w-1/5">Tutar</div>
                <div class="font-bold text-sm w-1/5">Ödeme Şekli</div>
                <XIcon class="w-5 h-5 invisible"/>
            </div>
            <UIFormRow class="flex space-x-3 items-center" v-for="item,i in data.items" :key="'item_payment_row'+(Math.floor(Math.random() * 1000000000))">
                <UIInput class="w-3/5" placeholder="Yapacağın harcama açıklaması" v-model="item.description"/>
                <UICurrencyInput  :options="{ currency: data.currency, locale:'tr' }"  class="w-1/5" v-model.lazy="item.amount"/>
                <UISelect :key="'item_payment'+(Math.floor(Math.random() * 1000000000))" class="w-1/5" :source="payment_methods" title="Ödeme Şekli" display="box" v-model="item.payment_method"/>
                <UITooltip title="Satırı sil" position="top" :class="{
                    'invisible' : data.items.length == 1
                }">
                    <XIcon class="w-5 h-5 opacity-70 hover:opacity-100 cursor-pointer" @click="delete_item(i)"/>
                </UITooltip>
            </UIFormRow>
            
            <UIFormRow class="flex items-start">
                <button class="border px-2 py-1 hover:bg-slate-100" @click="add_item" v-if="data.items.length < 5">Yeni Satır</button>
                <div class="w-2/5 ml-auto bg-calypso-light rounded p-2">
                    <div class="flex">
                        <span>Nakit</span>
                        <span class="w-2/3 ml-auto text-right">{{ helpers.format(total_cash,data.currency) }}</span>
                    </div>
                    <div class="flex">
                        <span>Kredi Kartı</span>
                        <span class="w-2/3 ml-auto text-right">{{ helpers.format(total_cc,data.currency) }}</span>
                    </div>
                    <div class="flex">
                        <span class="font-bold">Toplam</span>
                        <span class="font-bold w-2/3 ml-auto text-right">{{ helpers.format(totals,data.currency) }}</span>
                    </div>
                </div>
            </UIFormRow>
        </template>
	</Modal>

</template>