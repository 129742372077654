<script setup>
    import { onMounted } from 'vue';

    onMounted(()=> {
        setTimeout(()=>{
			createBalloons(10);
		},5)
    })


    function random(num) {
		return Math.floor(Math.random()*num)
	}

	function getRandomStyles() {
		var r = random(255);
		var g = random(255);
		var b = random(255);
		var mt = random(200);
		var ml = random(50);
		var dur = random(5)+5;
        var delay = random(5)+5;
		return `
			background-color: rgba(${r},${g},${b},0.7);
			color: rgba(${r},${g},${b},0.7); 
			box-shadow: inset -7px -3px 10px rgba(${r-10},${g-10},${b-10},0.7);
			margin: ${mt}px 0 0 ${ml}px;
			animation: float ${dur}s ease-in infinite;
            animation-delay: ${delay}s;
		`
	}

	function createBalloons(num) {
		var balloonContainer = document.getElementById("balloon-container")
		for (var i = num; i > 0; i--) {
			var balloon = document.createElement("div");
			balloon.className = "balloon";
			balloon.style.cssText = getRandomStyles();
			balloonContainer.append(balloon);
		}
	}

    
</script>

<template>
    <div id="balloon-container"></div>
</template>


<style>
	

	#balloon-container {
		
		padding: 1em;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.balloon {
		height: 125px;
		width: 105px;
		border-radius: 75% 75% 70% 70%;
		position: relative;
	}

	.balloon:before {
		content: "";
		height: 75px;
		width: 1px;
		padding: 1px;
		background-color: #FDFD96;
		display: block;
		position: absolute;
		top: 125px;
		left: 0;
		right: 0;
		margin: auto;
	}

	.balloon:after {
		content: "▲";
		text-align: center;
		display: block;
		position: absolute;
		color: inherit;
		top: 120px;
		left: 0;
		right: 0;
		margin: auto;
	}

	@keyframes float {
		from {
			transform: translateY(100vh);
			opacity: 1;
		}
		to {
			transform: translateY(-300vh);
			opacity: 1;
		}
	}
</style>