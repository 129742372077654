<template>
    <div>
        <UILabel :label="label" :rand_id="id || rand_id" @click="show_popover"/> 
        <Popover class="relative" v-slot="{ open }" >
            <PopoverButton ref="popover_button"
                class="flex items-center ring-2 ring-transparent truncate text-ellipsis max-w-full"
                :class="{
                    'w-full rounded-iq focus:outline-none focus:ring-calypso border border-gray-300 bg-gray-50 p-2 h-[42px]' : display == 'box',
                    'text-sm text-calypso font-bold' : display == 'inline',
                    'text-xs' : size == 'xs',
                    'text-sm' : styled,
                }"
                @click="show_popover"
            >
                    <span v-if="!checked_item" class="text-slate-400">{{ title }}</span> 
                    <span v-if="checked_item" class="flex items-center">
                        <img :src="checked_item.image" class="w-6 h-6 rounded-full mr-2" v-if="checked_item.image"/>
                        {{ checked_item.text }}
                    </span> 
                    <ChevronDownIcon v-if="use_arrow" class="w-4 h-4 text-calypso duration-300" :class="{'ml-auto':display == 'box', 'transform rotate-180':isOpen}" /> 
            </PopoverButton>

            <div v-if="open">
                <transition
                    enter-active-class="transition duration-200 ease-out"
                    enter-from-class="translate-y-1 opacity-0"
                    enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="transition duration-150 ease-in"
                    leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="translate-y-1 opacity-0"
                >
                    <PopoverPanel
                        static
                        class="absolute z-10 shadow bg-white border border-gray-iq shadow-iq rounded-iq min-w-max" v-slot="{ close }"
                        :class="{
                            'w-full' : display == 'box',
                            'mt-2' : display == 'inline',
                            '-translate-y-full -top-5' : popover_v_position == 'up',
                            'right-0' : popover_h_position == 'right',
                        }"
                    >
                        <div v-if="display == 'inline' && popover_v_position == 'down'" 
                            class="-z-1 -top-2 w-4 h-4 absolute bg-white border-r border-b border-gray-iq -rotate-135 transform"
                            :class="{
                                'right-5' : popover_h_position == 'right',
                                'left-5' : popover_h_position == 'left',
                            }"
                        ></div>
                        
                        <div class="flex max-h-96">
                            <div class="w-32">
                                <div v-for="item,index in source" :key="index" @click="select(item,close)" @mouseover="activate(item.value)" class="flex px-4 py-2 items-center select-none hover:bg-gray-50"
                                    :class="{
                                        'text-calypso bg-calypso-light' : selected_root_item == item.value
                                    }"
                                >
                                    <span>{{ item.text }}</span>
                                    <ChevronRightIcon class="w-4 h-4 ml-auto" v-if="item.children && item.children.length > 0"/>
                                    <CheckIcon class="w-5 h-5 text-calypso ml-auto" v-if="item.value == checked_item.root && !item.children" />
                                </div>
                            </div>
                            <div class="w-64 overflow-y-scroll border-l" v-if="submenu.length > 0">
                                <div v-for="item,index in submenu" :key="index" @click="select(item,close)" class="flex px-3 py-2 items-center select-none hover:bg-gray-50">
                                    <div class="w-6 rounded-full overflow-hidden mr-3" v-if="item.image">
                                        <img :src="item.image">
                                    </div>
                                    <span class="text-sm">{{ item.text }}</span>
                                    <CheckIcon class="w-5 h-5 text-calypso ml-auto" v-if="item.value == checked_item.sub && checked_item.root == selected_root_item" />
                                </div>
                            </div>
                        </div>
                    </PopoverPanel>
                </transition>
            </div>
        </Popover>
    </div>
</template>


<script setup>
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
    
    import { computed,onMounted,ref,watch,toRef } from 'vue'
    import { ChevronDownIcon, CheckIcon, ChevronRightIcon } from '@heroicons/vue/solid'
    
    const props = defineProps({
        modelValue:[String,Number,Array,Object],
        title: String,
        label: String,
        id : String,
        source: Array,
        display : {
            type : String,
            default : 'inline'
        },
        use_arrow:{
            type:Boolean,
            default:true
        },
        styled:{
            type:Boolean,
            default:true
        },
        size:{
            type:String,
            default:'normal'
        },
    })

    const emit = defineEmits(['update:modelValue'])

    const rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))
    const popover_button = ref(null)
    const isOpen = ref(false)
    const popover_v_position = ref('down')
    const popover_h_position = ref('left')
    const selected_root_item = ref('AGENCY')
    const checked_item = ref({root:'AGENCY', sub:'AGENCY'})
    const checked_item_root = ref('AGENCY')
    
    const submenu = computed(() => {
        const a = props.source.find( x => x.value == selected_root_item.value)
        return a.children ? a.children : []
    })

    watch(() => props.modelValue, (data) => {
        return;
        if(!data) return
        data = data.split('|')
        selected_root_item.value = data[0]
        checked_item_root.value = data[0]

        const root = props.source.find( x => x.value == data[0])
        let sub
        if(root && root.children) {
            sub = root.children.find(x => x.value == data[1])
        }
        
        checked_item.value = {
            root : data[0],
            sub : data[1],
            text : (root && root.children) ? sub.text : root.text
        }
	},{ immediate: true })
    
    const select = (data, close) => {
        if(data.children && data.children.length > 0) {
            return
        }
        if(!data) {
            
            emit('update:modelValue',data.value)
            close()
            return    
        }
        checked_item.value = {
            sub : data.value,
            root : selected_root_item.value,
            text : data.text,
        }
        
        emit('update:modelValue', selected_root_item.value + '|' + data.value)
        close()
    }

    const calculate_position = () => {
		const distance_to_bottom = window.innerHeight - popover_button.value.el.getBoundingClientRect().y
        popover_v_position.value = distance_to_bottom < 400 ? 'up' : 'down'

        const distance_to_right = window.innerWidth - popover_button.value.el.getBoundingClientRect().x
        popover_h_position.value = distance_to_right < 500 ? 'right' : 'left'
        
	}

    const show_popover = () => {
        calculate_position()
	}
    
    watch(() => popover_button.value, () => {
        if(!popover_button.value) return
        setTimeout(calculate_position,200)
	},{ immediate: true })

    const activate = value => {
        selected_root_item.value = value
        checked_item_root.value = value
    }
    
</script>