<template>
    <div class="grid gap-x-2" :class="{
        'grid-cols-1' : cols == 1,
        'grid-cols-2' : cols == 2,
        'grid-cols-3' : cols == 3,
        'grid-cols-4' : cols == 4
    }">
        <div v-for="item,i in schema" :key="i" :class="{
            'col-span-full' : item.span == 1,
            'col-span-2' : item.span == 2,
            }">
            <UIFormRow v-if="evalPropOrAttr(item.showCondition)">
            <component :errors="v$[item.id].$errors" :is="item.component" :class="item.class" v-bind="item.props" :label="item.label" v-model="v$[item.id].$model" @search="search" :id="item.id" @update:modelValue="update" fg/>
            </UIFormRow>
        </div>
    </div>
</template>

<script setup>
    import {ref,watch,reactive} from 'vue'
    import useVuelidate from '@vuelidate/core'
    import { required } from '@/utils/i18n-validators'


    const props = defineProps({
        modelValue:{
            type:Object,
            default:{}
        },
        schema:Object,
        dynamicProps:{
            type:Array,
            default:[]
        },
        cols:{
            type:Number,
            default:1
        }
    })

    let v$

    const evalPropOrAttr = (propOrAttr) => {
        if(!propOrAttr) return true
        return eval('('+propOrAttr+')()')
        return (propOrAttr)()
        return

        if (propOrAttr in props.dynamicProps) return dynamicPropsEvaluated[propOrAttr]
        if (propOrAttr in this) return (this)[propOrAttr]
        return this.$attrs[propOrAttr]
    }

    const emit = defineEmits(['update:modelValue','ready2submit','search'])

    let formData = reactive(props.modelValue)
    
    const schema = ref(props.schema)
    const validation_rules = ref({})
    const set_validation_rules = () => {
        props.schema.forEach( (item,key) => {
            validation_rules.value[item.id] = {required : item.required && evalPropOrAttr(item.showCondition) ? required : false }
        })
        console.log(validation_rules.value)
        v$ = useVuelidate(validation_rules, formData)
    }
    set_validation_rules()
    //const v$ = useVuelidate(validation_rules, formData)
    

    watch(() => props.modelValue, data => {
            formData = data
		},{ immediate: true }
    )

    watch(() => props.schema, data => {
        set_validation_rules()
	},{ immediate: true })

    watch(() => formData, data => {
        set_validation_rules()
	},{ immediate: true, deep:true })

    watch(() => v$.value.$invalid, data => {
        emit('ready2submit', !data)
	},{ immediate: true})

    const update = data => {
        emit('update:modelValue', formData)
        emit('ready2submit', !data)
    }

    const enter = () => {
        emit('enter')
    }

    const search = data => emit('search', data)

</script>