<template>
	<TableView 
		title="Bütçe Grupları"
		:table_titles="table_titles" 
		api_url="definition/budget-groups" 
		:empty_state="empty_state"
		:sort_by="sort_by"
		:modalProps="modalProps"
		:add_new="true"
		:add_new_titles="['Bütçe Grubunu Güncelle', 'Yeni Bütçe Grubu Ekle']"
		custom_add_new_modal
		@add_new_open = "add_new_open"
		ref="budget_group_table"
		/>

		<BudgetGroupModal :budget_group="budget_group" :isOpen="isModalOpen" @modal_closed="modal_closed"/>
</template>

<script setup>
	import { ref,computed,inject } from 'vue'
	import { useStore } from 'vuex'
	import TableView from '@/views/TableView.vue'
	import BudgetGroupModal from '@/modals/admin/definitions/budget/BudgetGroup.vue'
	import API from '@/api'

    const store = useStore()
	const emitter = inject('emitter')

	const sort_by = 'name'
	const budget_group = ref(null)
	const isModalOpen = ref(false)
	const budget_group_table = ref()
	emitter.on("load_budget_group_lists", () => budget_group_table.value?.fetch_data(true))

	const empty_state = {
        title:'Bütçe guruplarına henüz veri eklememişsin!',
        description:'Bütçe grupları ekleyerek.İşlerini hızlandırabilirsin'
    }
    
    const modalProps = computed(()=>{return {
        cols:1,
		schema: [
			{
				id: 'title',
				component: 'UIInput',
				label: 'Grup Adı',
				required: true,
                props: {
                    
                }
			}
		]
    }})

    let table_titles = [
		{
			title:'Grup Adı',
            sortable:true,
			id:'name',
            width:'w-4/5',
			class:"font-bold truncate"
		},
		{
			title:'Kategori',
            id:'category.name',
            width:'w-2/5',
			class:"truncate"
		},
		{
			title:'',
			width:'w-1/5',
			type:'action'
		},
	]

	const add_new_open = id => {
        
		if(!id) {
            isModalOpen.value = true
            return
        }

        API.get('definition/budget-groups/'+ id).then(response => {
            budget_group.value = response.data
            isModalOpen.value = true
        })
	}

	const modal_closed =() => {
		isModalOpen.value = false
        budget_group.value = null  
    }

</script>
