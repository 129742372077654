<template>
    <div class="relative bg-white border-l min-w-70 flex justify-top flex-col items-center h-screen sticky top-0 z-10">
        <ChatAlt2Icon class="hidden w-7 h-7 mt-5 stroke-[1.2px] font-medium text-gray-500 hover:text-gray-900"/>
        <ul class="mt-5" :style="{ height: chatcolumn_height }">
            <li v-for="user in users" :key="user.id">
                <UITooltip :title="user.name" position="left">
                    <img :src="user.profile_picture" class="w-10 h-10 rounded-full mb-3" @click="open_chat(user.name, user.profile_picture)">
                </UITooltip>
            </li>
        </ul>
        <div class="h-full bg-white absolute top-0 left-0 duration-300 p-4 w-[500px] hidden"
            :class="{
                'left-[-500px] shadow-[-80px_0px_100px_-60px_rgba(0,0,0,0.3)]' : isOpen
            }"
        >
            
        </div>
    </div>
    <Chat v-if="isOpen" :name="name" :picture="profile_picture"/>
</template>
<script setup>
    import { ref, computed } from 'vue'
    import { useStore } from 'vuex'
    import { ChatAlt2Icon, SearchIcon } from '@heroicons/vue/outline'
    import Chat from '@/views/Chat.vue'
    
    const store = useStore()
    const isOpen = ref(false)
    const name = ref('')
    const profile_picture = ref('')

    const chatcolumn_height = computed(() => (window.innerHeight - 90) + 'px')

    //const users = computed(() => store.state.content.users)
    const users = computed(() => store.state.chat.online_users )
    
    const open_chat = (n, p) => {
        return
        isOpen.value = !isOpen.value
        name.value = n
        profile_picture.value = p
    }

    // https://randomuser.me/api/?results=10
</script>

<style scoped>
    
</style>