<script setup>
    import { computed, onMounted, ref, watch, toRef } from "vue";
    import EmojiPicker from "vue3-emoji-picker";
    import "vue3-emoji-picker/css";
    import { onClickOutside } from '@vueuse/core'

    // PROPS & EMITS
    const props = defineProps({
        modelValue: String,
        placeholder: String,
        defaultValue: String,
        errors: Array,
        label: String,
        autogrow: {
            type: Boolean,
            default: true,
        },
        maxlength: Number,
        id: String,
        class: String,
        styled: {
            type: Boolean,
            default: true,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: "normal",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        emoji: {
            type: Boolean,
            default: false,
        },
        empty_height: String,
    });
    const emit = defineEmits(["update:modelValue", "blur"]);

    // VARS
    //const modelValue = toRef(props,"modelValue")
    const modelValue = ref(props.modelValue);
    const error = computed(() => props.errors && props.errors.length > 0);
    const isFocused = ref(false);
    const textarea = ref(null);
    const currentTextLength = ref(0);
    const rand_id = computed(
        () => "input_" + Math.floor(Math.random() * 1000000000)
    );

    onMounted(() => {
        textarea.value.value = props.modelValue || "";
        calculate_height();
    });

    watch(() => props.modelValue, data => {
        modelValue.value = data
        //textarea.value.value = data
    })

    const calculate_height = () => {
        if (!props.autogrow) return;
        textarea.value.style.height = "";
        textarea.value.style.height = textarea.value.value
            ? textarea.value.scrollHeight + "px"
            : "32px";
    };

    const keyTextarea = () => {
        if (!props.lazy) change();
        currentTextLength.value = textarea.value.value.length;

        calculate_height();
    };

    const blur = () => {
        isFocused.value = false;
        //calculate_height()
        emit("blur");
    };

    const change = () => {
        calculate_height();
        emit("update:modelValue", textarea.value.value);
    };

    const emojiOpened = ref(false);

    const onSelectEmoji = emoji => {
        console.log(emoji)
        emojiOpened.value = false
        if (!emoji || !emoji.i) return
        modelValue.value = (modelValue.value || "") + emoji.i
    };

    const el_emoji = ref()
    onClickOutside(el_emoji, () => emojiOpened.value = false)
</script>

<template>
    <UILabel
        :label="label"
        :rand_id="id || rand_id"
        :error="error"
        :isFocused="isFocused"
    />
    <div class="relative">
        <textarea
            v-model="modelValue"
            :id="id || rand_id"
            :placeholder="placeholder"
            :maxlength="maxlength"
            @input="keyTextarea"
            @change="change"
            @focus="isFocused = true"
            @blur="blur"
            class="w-full rounded-iq focus:outline-none focus:border-transparent resize-none"
            :class="[
                {
                    'border border-gray-300 bg-gray-50': styled && !error,
                    'border border-red-100 bg-red-50': styled && error,
                    'p-2': size == 'normal' && styled,
                    'p-1 text-sm': size == 'sm' && styled,
                    'text-lg': size == 'lg',
                    'text-xl': size == 'xl',
                    'text-2xl': size == '2xl',
                    'ring-2 ring-calypso rounded-iq': isFocused && styled,
                },
                props.class,
            ]"
            ref="textarea"
            :disabled="disabled"
            autocomplete="disabled"
        ></textarea>
        <span
            class="absolute bottom-0 right-0 p-2 text-sm"
            v-if="maxlength"
            :class="{
                'text-red-500': currentTextLength > maxlength - 10,
            }"
            >{{ currentTextLength }} / {{ maxlength }}</span
        >
        <div class="absolute bottom-3 left-2" v-if="emoji">
            <EmojiPicker
                :native="true"
                @select="onSelectEmoji"
                :static-texts="{ placeholder: 'Ara...' }"
                :disable-skin-tones="true"
                v-if="emojiOpened"
                ref="el_emoji"
            />
            <div class="text-gray-500 hover:bg-gray-300 cursor-pointer duration-200 rounded-full flex items-center justify-center h-5 w-5">
                <i
                    class="fa-light fa-face-smile"
                    @click="emojiOpened = !emojiOpened"
                />
            </div>
        </div>
    </div>
    <div class="validation_error" v-if="error">{{ errors[0].$message }}</div>
</template>
