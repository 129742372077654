<template>
    <Modal 
        :isOpen="isModalOpen"
        title="Bütçe Grubu Ekle"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Kaydet"
        @modal_closed="modal_closed"
        @submit="submit"
        size="4xl"
    >
        <template v-slot:modal_content>

            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>

            <div class="grid grid-cols-2">
                <div>
                    <UIFormRow>
                        <UIInput label="Kategori Adı" display="box" v-model="budget_data.name"/>
                    </UIFormRow>
                </div>
                <div>
                    <UIFormRow class="ml-2">
                        <UIInput  label="Kategori Adı (İngilizce)" display="box" v-model="budget_data.name_en"/>
                    </UIFormRow>
                </div>

                <div class="col-span-2">
                    <UIFormRow>
                        <UISelect :search_enabled="false" :fit_parent="true" :use_arrow="false" display="box" multiple :source="supplier_categories" title="İlgili Tedarikçi Kategorileri" v-model="budget_data.supplier_categories"/>
                    </UIFormRow>
                </div>
            </div>

            <UIFormRow>
                <div class="font-bold">Vergi Türleri *</div>
            </UIFormRow>
            <div class="flex space-x-3 mb-2">
                <div class="font-bold text-sm w-1/6">Vergi Adı</div>
                <div class="font-bold text-sm w-1/6">Ücret Tipi</div>
                <div class="font-bold text-sm w-1/6">Para Tipi</div>
                <div class="font-bold text-sm w-1/6">Miktar</div>
                <div class="font-bold text-sm w-1/6">KDV Oranı</div>
                <XIcon class="w-5 h-5 invisible"/>
            </div>
            <UIFormRow class="flex space-x-3 items-center" v-for="item,i in budget_data.items" :key="'item_payment_row'+(Math.floor(Math.random() * 1000000000))">
                <UIInput class="w-1/6" placeholder="Vergi Adı" v-model="item.name"/>
                <UISelect :search_enabled="false"  class="w-1/6" :source="fee_types" title="Ücret Tipi" display="box" v-model="item.price_type"/>
                <UISelect :search_enabled="false"  class="w-1/6" :source="currencies" title="Para Tipi" display="box" v-model="item.currency_code"/>
                <UICurrencyInput v-if="item.price_type=='FIXED'" class="w-1/6" :options="{ hideCurrencySymbolOnFocus:true,currency: item.currency_code }" title="Miktar" v-model.lazy="item.price"/>
                <UIInput v-else="item.price_type=='PERCENTAGE'" type="number" prepend="%" class="w-1/6" :options="{ hideCurrencySymbolOnFocus:true,currency: '' }" title="Miktar" v-model.lazy="item.price"/>
                <UISelect :search_enabled="false" class="w-1/6" :source="vat_rates_select" title="KDV %" display="box" v-model="item.vat_rate"/>
                <UITooltip title="Satırı sil" position="top" :class="{
                            'invisible' : budget_data.items.length == 1
                        }">
                    <XIcon 
                        class="w-5 h-5 opacity-70 hover:opacity-100 cursor-pointer"
                        
                        @click="delete_item(i)"/>
                </UITooltip>
            </UIFormRow>
            <UIFormRow>
                <button class="border px-2 py-1 hover:bg-slate-100" @click="add_item" v-if="budget_data.items.length < 10">Yeni Satır</button>
            </UIFormRow>
            <hr>
        </template>
	</Modal>
</template>

<script setup>
    // Imports
	import {ref, computed, watch, inject } from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'
    import { XIcon } from "@heroicons/vue/solid"
    
	const props = defineProps({
        budget_category:Object,
        isOpen:Boolean,
    })

    
    const emit = defineEmits(['modal_closed'])
    const store = useStore()
    const emitter = inject('emitter')
    const isSubmitting = ref(false)
    const isModalOpen = ref(props.isOpen);
    const submit_error = ref(false)
    const budget_category = ref({
        items:[]
    })

    const vat_rates_select = computed(() => store.getters['auth/vat_rates_select'])
    const currencies= computed(()=> store.state.auth.event_company.currencies.map(currency => { return {value : currency.shortcode, text : currency.shortcode}}))

    const fee_types = ref([
        {value:"PERCENTAGE",text: 'Yüzde'},
        {value:"FIXED",text:'Fiks'}
    ])

    const supplier_categories = computed(() => store.state.content.supplier_categories.map(category => { return {value : category.id, text : category.name}}).sort((a, b) => a.text.localeCompare(b.text)))

    watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    watch(() => props.budget_category, (data) => {
        
        budget_category.value=data;
        
		},{ immediate: true,deep:true }
    )

    const budget_data = ref({
        items:[]
    })

      
    const add_item = () => {
        budget_data.value.items.push({
            name:null,
            price_type:'PERCENTAGE',
            currency_code:currencies.length>0?currencies.value[0].text:'TRY',
            price:null,
            vat_rate:0.000
        })
    }

    watch(() => budget_category.value, (blade) => {
        
        if(!blade){
            budget_data.value={
                items:[]
            }
            add_item()
            return
        }
       
        budget_data.value={
            name:budget_category.value.name,
            name_en:budget_category.value.name_en,
            supplier_categories:budget_category.value.supplier_cats.map(supplier=>supplier.id),
            items:budget_category.value.taxes
        }

        if(budget_category.value.taxes.length==0)
        add_item()

		},{ immediate: true }
    )

    const ready2submit = computed( () => budget_data.value.name ? true : false )
    
    const modal_closed = () =>{
		isModalOpen.value = false
		emit('modal_closed')
	}

    const delete_item = id => budget_data.value.items = budget_data.value.items.filter((x,i)=> i !== id )
        
    const submit = () => {
        isSubmitting.value = false

        let request
        if(props.budget_category) {
            request = API.put('definition/budget-categories/' + props.budget_category.id, budget_data.value)
        } else {
            request = API.post('definition/budget-categories',budget_data.value)
        }

        request.then( response => {
            isSubmitting.value = false
            submit_error.value = null
            modal_closed()
            emitter.emit('load_budget_category_lists')
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description: response.data.message
            })
        }).catch( error => {
            submit_error.value = error.response?.data.message
            isSubmitting.value = false
        })
    }

</script>