<script setup>
    import { ref, watch, computed, inject, onMounted, provide } from "vue";
    import { L10n,setCulture } from '@syncfusion/ej2-base';
    
    import {
		RichTextEditorComponent as EjsRichtexteditor,
		Toolbar,
		HtmlEditor,
        QuickToolbar
	} from "@syncfusion/ej2-vue-richtexteditor";
    const richtexteditor =  [Toolbar, HtmlEditor,QuickToolbar];
    const rteInstance = ref()
    provide('richtexteditor', richtexteditor);
    setCulture("tr");

    
    const emit = defineEmits(["update:modelValue"]);
    const props = defineProps({
        modelValue      : String,
        label           : String,
        label_class     : String,
        help_text       : String,
        errors          : Array,
        id              : String,
        height          : {
            type:Number,
            default:300
        }
    });

    const rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))
    const editor_text = ref(props.modelValue)
    const error = computed(() => props.errors && props.errors.length > 0)
    const isFocused = ref(false)

    const toolbarSettings = {
            type: 'Expand',
            items: [
                'Formats', 'Alignments', '|', 
                'Bold', 'Italic', 'Underline', '|', 'OrderedList', 'UnorderedList', '|',
                'FontSize', 'FontColor'
            ]
        }
    
    
    L10n.load({
    'tr': {
        'richtexteditor': {
            "alignments": "Hizalamalar",
            "justifyLeft": "Metni sola hizala",
            "justifyCenter": "Metni ortala",
            "justifyRight": "Metni sağa hizala",
            "justifyFull": "İki yana yasla",
            "fontName": "Yazı tipi ailesini seçin",
            "fontSize": "Yazı tipi boyutunu seçin",
            "fontColor": "Renk seçin",
            "backgroundColor": "Arka plan rengi",
            "bold": "Kalın",
            "italic": "İtalik",
            "underline": "Altını çiz",
            "strikethrough": "Üstü çizili",
            "clearAll": "Hepsini temizle",
            "clearFormat": "Biçimi temizle",
            "cut": "Kes",
            "copy": "Kopyala",
            "paste": "Yapıştır",
            "unorderedList": "Sırasız liste ekle",
            "orderedList": "Sıralı liste ekle",
            "indent": "Girinti",
            "outdent": "Çıkıntı",
            "undo": "Geri al",
            "redo": "Yinele",
            "superscript": "Üst simge",
            "subscript": "Alt simge",
            "createLink": "Bağlantı ekle",
            "removeLink": "Bağlantıyı kaldır",
            "openLink": "Bağlantıyı aç",
            "editLink": "Bağlantıyı düzenle",
            "image": "Resim ekle",
            "replace": "Değiştir",
            "align": "hizala",
            "caption": "Resim yazısı",
            "formats": "Biçimler",
            "remove": "Sil",
            "insertLink": "Bağlantı ekle",
            "display": "Görüntüle",
            "alttext": "Alternatif metin",
            "dimension": "Boyut",
            "fullscreen": "Tam ekran",
            "maximize": "Büyüt",
            "minimize": "Küçült",
            "zoomIn": "Yakınlaştır",
            "zoomOut": "Uzaklaştır",
            "upperCase": "Büyük harf",
            "lowerCase": "Küçük harf",
            "print": "Yazdır",
            "sourcecode": "Kaynak kodu",
            "preview": "Önizleme",
            "viewside": "Yan görünüm",
            "insertcode": "Kod ekle",
            "linkText": "Bağlantı metni",
            "linkTooltipLabel": "Araç ipucu",
            "linkWebUrl": "Web adresi",
            "linkOpenInNewWindow": "Bağlantıyı yeni pencerede aç",
            "linkHeader": "Bağlantı ekle",
            "dialogInsert": "Ekle",
            "dialogCancel": "İptal",
            "dialogUpdate": "Güncelle",
            "imageHeader": "Resim ekle",
            "imageLinkHeader": "İnternet bağlantısı ekleyin",
            "imageUploadMessage": "Resmi buraya sürükleyin veya yüklemek için tıklayın",
            "imageDeviceUploadMessage": "Yüklemek için buraya tıklayın",
            "imageAlternateText": "Alternatif metin",
            "alternateHeader": "Alternatif metin",
            "browse": "Gözat",
            "imageUrl": "URL",
            "imageCaption": "Alt yazı",
            "imageSizeHeader": "Resim boyutu",
            "imageHeight": "Yükseklik",
            "imageWidth": "Genişlik",
            "textPlaceholder": "Metin girin",
            "inserttablebtn": "Tablo ekle",
            "tabledialogHeader": "Tablo ekle",
            "tableWidth": "Genişlik",
            "cellpadding": "Hücre dolgusu",
            "cellspacing": "Hücre aralığı",
            "columns": "Sütun sayısı",
            "rows": "Satır sayısı",
            "tableRows": "Tablo satırları",
            "tableColumns": "Tablo sütunları",
            "tableCellHorizontalAlign": "Hücre yatay hizalama",
            "tableCellVerticalAlign": "Hücre dikey hizalama",
            "createTable": "Tablo oluştur",
            "removeTable": "Tabloyu kaldır",
            "tableHeader": "Tablo başlığı",
            "tableRemove": "Tabloyu kaldır",
            "tableCellBackground": "Hücre arka planı",
            "tableEditProperties": "Tablo özelliklerini düzenle",
            "styles": "Stiller",
            "insertColumnLeft": "Sola sütun ekle",
            "insertColumnRight": "Sağa sütun ekle",
            "deleteColumn": "Sütunu sil",
            "insertRowBefore": "Önce satır ekle",
            "insertRowAfter": "Sonra satır ekle",
            "deleteRow": "Satırı sil",
            "tableEditHeader": "Tabloyu düzenle",
            "TableHeadingText": "Başlık",
            "TableColText": "Sütun",
            "imageInsertLinkHeader": "Bağlantı ekle",
            "editImageHeader": "Resmi düzenle",
            "alignmentsDropDownLeft": "Sola hizala",
            "alignmentsDropDownCenter": "Ortaya hizala",
            "alignmentsDropDownRight": "Sağa hizala",
            "alignmentsDropDownJustify": "İki yana yasla",
            "imageDisplayDropDownInline": "Satır içi",
            "imageDisplayDropDownBreak": "Kır",
            "tableInsertRowDropDownBefore": "Önce satır ekle",
            "tableInsertRowDropDownAfter": "Sonra satır ekle",
            "tableInsertRowDropDownDelete": "Satırı sil",
            "tableInsertColumnDropDownLeft": "Sola sütun ekle",
            "tableInsertColumnDropDownRight": "Sağa sütun ekle",
            "tableInsertColumnDropDownDelete": "Sütunu sil",
            "tableVerticalAlignDropDownTop": "Üst hizalama",
            "tableVerticalAlignDropDownMiddle": "Orta hizalama",
            "tableVerticalAlignDropDownBottom": "Alt hizalama",
            "tableStylesDropDownDashedBorder": "Kesikli sınırlar",
            "tableStylesDropDownAlternateRows": "Alternatif satırlar",
            "pasteFormat": "Biçimi yapıştır",
            "pasteFormatContent": "Biçimlendirme eylemini seçin",
            "plainText": "Düz metin",
            "cleanFormat": "Temizle",
            "keepFormat": "Biçimi koru",
            "formatsDropDownParagraph": "Paragraf",
            "formatsDropDownCode": "Kod",
            "formatsDropDownQuotation": "Alıntı",
            "formatsDropDownHeading1": "Başlık 1",
            "formatsDropDownHeading2": "Başlık 2",
            "formatsDropDownHeading3": "Başlık 3",
            "formatsDropDownHeading4": "Başlık 4",
            "fontNameSegoeUI": "Segoe UI",
            "fontNameArial": "Arial",
            "fontNameGeorgia": "Georgia",
            "fontNameImpact": "Impact",
            "fontNameTahoma": "Tahoma",
            "fontNameTimesNewRoman": "Times New Roman",
            "fontNameVerdana": "Verdana"
        }
    }})

    watch(() => props.modelValue, newValue => {
        editor_text.value = newValue
    })

    watch(() => editor_text.value, newValue => {
        emit('update:modelValue', newValue)
    })

    const blur = () => {
        isFocused.value = false
    }

    const focus = () => {
        isFocused.value = true
    }

</script>

<template>
    <UILabel v-if="label" :label="label" :rand_id="id || rand_id" :isFocused="isFocused" :error="error" :class="label_class" :help_text="help_text"/>
    <ejs-richtexteditor :height="height" ref="rteInstance" v-model="editor_text" :toolbarSettings="toolbarSettings"/>
</template>