<template>
    <div>
        <UILabel :label="label" :rand_id="id || rand_id" :isFocused="isFocused" :error="error" :class="label_class" :help_text="help_text"/>
        <div class="relative" :class="{
            'ring-2 rounded-iq' : isFocused && styled,
            'ring-calypso' : !error,
            'ring-red-600' : error,
        }">
            <button 
                @focus="isFocused = true"
                @blur="isFocused = false"
                type="button" 
                :tabIndex="tabIndex"
                class="flex items-center self-center rounded-iq focus:outline-none focus:border-transparent"
                :class="{ 
                    'focus:ring-red-600': error, 
                    'focus:ring-calypso': !error,
                    'border border-gray-300 bg-gray-50' : styled && !error,
                    'border border-red-100 bg-red-50' : styled && error,
                    'p-2' : size == 'normal',
                    'p-1 text-sm' : size == 'sm',
                    'text-lg' : size == 'lg',
                    'text-xl' : size == 'xl',
                    'text-2xl' : size == '2xl'
                }"
                @click="fileUploadField.click()"
            >Dosya Seç</button>
            {{filename}}
            <input 
                ref="fileUploadField"
                :id="id || rand_id"
                type="file" 
                @change="readFile" 
                class="hidden"
                :accept="file_type"
            >
        </div>
    </div>
    
</template>

<script setup>
    import {computed,ref,watch} from 'vue'
    
    const props = defineProps({
        modelValue: [String,Number,Object],
        placeholder: String,
        error:Boolean,
        label:String,
        label_class:String,
        styled:{
            type:Boolean,
            default:true
        },
        size:{
            type:String,
            default:'normal'
        },
        id:String,
        tabIndex:[String,Number],
        help_text:String,
        read_type:{
            type:String,
            default:'base64'
        },
        file_type:{
            type:String,
            default:'all'
        }
    })

    const fileUploadField = ref(null)
    const isFocused = ref(false)
    const inputVal = ref('')
    const filename = ref('')
    watch(() => props.modelValue, (data) => {
       
			inputVal.value = data
		},{ immediate: true }
    )

    const emit = defineEmits(['update:modelValue'])

    let rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))

    const readFile = () => {
        var reader = new FileReader();
        var f = fileUploadField.value.files[0];

        if(props.read_type == 'file') {
            console.log(props.read_type)
            emit('update:modelValue', f)
            return
        }

        filename.value = f.name
        emit('fileName', filename.value)
        
        reader.onload = function(e) {
            emit('update:modelValue', e.target.result)
       
        };
        reader.readAsDataURL(f);
    }

</script>

<style scoped>
    input:focus + svg {
        fill: rgba(0, 164, 189, 1)
    }
</style>
