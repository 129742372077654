<template>
	<TableView 
		title="Bütçe Taslakları"
		:table_titles="table_titles" 
		api_url="definition/budget-templates" 
		:empty_state="empty_state"
		:sort_by="sort_by"
		:modalProps="modalProps"
		@add_new_closed="add_new = false"
		:add_new_titles="['Bütçe Taslağını Güncelle', 'Yeni Bütçe Taslağı Ekle']"
		/>
</template>

<script setup>
	import { computed } from 'vue'
	import TableView from '@/views/TableView.vue'
    
    const sort_by = 'name'

	const empty_state = {
        title:'Henüz hiç bütçe taslağı oluşturmamışsınız!',
        description:''
    }
    
    const modalProps = computed(()=>{return {
        cols:1,
		schema: [
			{
				id: 'name',
				component: 'UIInput',
				label: 'Taslak Adı',
				required: true,
                props: {
                    
                }
			}
		]
    }})

    let table_titles = [
		{
			title:'Taslak Adı',
            sortable:true,
			id:'name',
            width:'w-4/5',
			class:"font-bold truncate"
		},
		{
			title:'',
			width:'w-1/5',
			type:'action'
		},
	]

</script>