import { createStore, createLogger } from 'vuex'
import auth from './modules/auth'
import content from './modules/content'
import projects from './modules/projects'
import project from './modules/project'
import tasks from './modules/tasks'
import clients from './modules/clients'
import suppliers from './modules/suppliers'
import attendees from './modules/attendees'
import floating_alerts from './modules/floating_alerts'
import accounting from './modules/accounting'
import form from './modules/form'
import webform from './modules/webform'
import budget from './modules/budget'
import page_header from './modules/page_header'
import chat from './modules/chat'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  	modules: {
		auth,
		content,
		projects,
		project,
		tasks,
		clients,
		suppliers,
		attendees,
		floating_alerts,
		accounting,
		form,
		webform,
		budget,
		page_header,
		chat
  	},
  	strict: debug,
  	plugins: debug ? [createLogger()] : []
})