<script setup>
	import { ref, inject, reactive, watch, computed} from 'vue'
	import { useStore } from 'vuex'
	import { notification } from 'ant-design-vue';

	import NavV from './components/NavV.vue'
	import PageHeader from './views/PageHeader.vue'
	import PushNotification from './views/Alerts/PushNotification.vue'
	import Breadcrumb from './components/Breadcrumb.vue'
	import FAB from './components/FAB.vue'
	import Chat from './components/Chat.vue'
	import { useNetwork } from '@vueuse/core'
	import { getMessaging, onMessage } from "firebase/messaging"
	import * as RT from '@/plugins/realtime'
	//import News from '@/modals/News.vue'

	const store = useStore()
	const emitter = inject('emitter')
	const is_scrolled = ref(false)
	const network = reactive(useNetwork())

	

	const messaging = getMessaging();
	onMessage(messaging, (payload) => {
		notification.open({
			message: payload.notification.title,
			description: payload.notification.body,
			duration: 0,
		});
	})

	const isPageLoading = computed(() => store.state.content.isPageLoading)

	const event_company = computed(() => store.state.auth.event_company)
	watch(() => event_company.value, data => {
		if(!data) return
		RT.connect(event_company.value.code)
		

	},{immediate : true})

	document.body.addEventListener('plugin_web_update_notice', (options) => {
		console.log("updateNeeded",options)
		store.commit('auth/updateNeeded',true)
	})

	watch(()=>network.isOnline, data=>{
		console.log('isOnline',network.isOnline)
	},{immediate:true})

	document.addEventListener('scroll', e => {
		is_scrolled.value = window.scrollY > 100 ? true : false
	});
	const header_bg_class = ref('bg-gradient-to-b from-calypso-light to-white')

	emitter.on('change_header_bg', data => header_bg_class.value = data)
</script>

<template>
	<FAB/>
	<div class="flex flex-row">
		<NavV/>
		
		<div class="w-full flex flex-col transition duration-500 ease-in-out content_wrapper">
			<PushNotification/>
			<div class="mb-5 sticky z-10 duration-300" :class="[header_bg_class,is_scrolled ? '-top-12':'top-0']">
				<Breadcrumb/>
				<PageHeader/>
			</div>
			<div class="mx-auto px-7 flex-grow w-full">
				<router-view v-slot="{ Component }">
					<component :is="Component" v-if="!isPageLoading"/>
					<div v-else>yükleniyor</div>
				</router-view>
			</div>
		</div>
		<Chat/>
	</div>
	
</template>


<style scoped>
	#app {
		font-family: 'Plus Jakarta Sans', Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #33475b;
	}
	.content_wrapper {
		max-width: calc(100% - 140px);
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>