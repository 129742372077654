<template>
    <div class="text-xl font-light mb-2 uppercase p-4 flex">
        <span>Bölüm Bilgilerini Güncelle</span>
        <div class="rounded-full w-7 h-7 ml-auto p-1 hover:bg-gray-400 cursor-pointer hover:bg-opacity-10" @click="hide">
            <XIcon/>
        </div>  
    </div>
    <UITabs :titles="tab_titles" class="w-full px-4 pb-16">
        <TabPanel key="1" class="py-4">
            <UIFormRow>
                <UITextarea label="Bölüm Başlığı" v-model="section.title" class="w-full p-2 bg-white border-none outline-none resize-none" :maxlength="200" :styled="false" :autogrow="false"/>
            </UIFormRow>
            <UIFormRow>
                <UIInput label="Yardım Metni" lazy v-model="section.help_text"/>
            </UIFormRow>
            
            <UIFormRow class="flex gap-6">
                <UIInput type="color" label="Başlık Rengi" v-model="section.title_colour" class="w-1/4" size="sm"/>
                <div class="flex flex-col">
                    <UILabel label="Diğer Ayarlar" class="mb-2"/>
                    <UICheckbox label = "Başlığı Gizle" inputValue="is_hidden" :checked="section.is_hidden == 1" class="mb-1" @change="onSpecChange"/>
                </div>
            </UIFormRow>
        </TabPanel>
        <TabPanel key="2" class="py-4">
            <template v-if="form_data.form_type == 'mma'">
                Bu formda kural kullanılamaz.
            </template>
            <template v-else>
                <div class="mb-4">
                    <small class="block font-bold">Yeni Kural Ekle</small>
                </div>
                <div class="grid grid-cols-2">
                    <span>Aşağıdaki kurallardan</span> <UISelect :search_enabled="false" display="inline" v-model="section.dep_scope" :source="[{value:'ALL',text:'hepsi'},{value:'ANY',text:'herhangi biri'}]"/>
                    <span>geçerli olduğunda bu bölümü</span> <UISelect :search_enabled="false" display="inline" v-model="section.dep_action" :source="[{value:'SHOW',text:'göster'},{value:'HIDE',text:'gizle'}]"/>.
                </div>
                <div>
                    <UISelect display="box" :source="db_columns" v-model="rule.target" title="Veritabanı/Form Alanı Seç"/>
                    <div class="flex gap-3 mt-3">
                        <UISelect display="box" :source="rule_operator_options" v-model="rule.operator" title="Koşul" class="w-1/4"/>
                        <UIInput v-model="rule.value" placeholder="Sabit" class="w-3/4" :hidden="['FULL', 'EMPTY'].includes(rule.operator)" v-if="['text_area', 'input_box'].includes(new_rule_type)" />
						<UISelect display="box" :source="rule_value_options" v-model="rule.value" title="Seçenek Seç" class="w-3/4" v-else />
                    </div>
                </div>
                <UIButton class="mt-3 mb-10" size="sm" type="submit" text="Kural Ekle" @click="add_rule"/>

                <div class="mb-4">
                    <small class="block font-bold">Mevcut Kurallar</small>
                </div>
                <div class="mb-10">
                    <div v-for="rule in section.rules" class="grid grid-cols-4 gap-x-3 text-sm">
                        <div class="truncate">{{ find_form_field(rule.target,'title') }}</div>
                        <div>{{ rule_operator_full_options.find(x => x.value == rule.operator).text }}</div>
                        <div class="truncate">{{ find_form_field(rule.target, "option", rule.target_value) }}</div>
                        <div><TrashIcon class="w-9 p-2 opacity-80 text-red-500  hover:bg-slate-100" @click.stop.prevent="delete_section_rule(rule.id)"/></div>
                    </div>
                </div>
            </template>
        </TabPanel>
    </UITabs>
</template>

<script setup>
	import {computed,ref,watch,toRef} from 'vue'
    import API from '@/api'
	import { XIcon } from '@heroicons/vue/outline'
	import { TabPanel } from '@headlessui/vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'
    import { TrashIcon} from '@heroicons/vue/outline'
	
	const store = useStore()
    const emit = defineEmits(['hide'])

    // PROPS & EMITS
	const props = defineProps({
        section : Object
    })

    const section = ref(_.clone(props.section))
    const section2 = toRef(props, 'section')

    const rule_operator_options = ref([
        {value:"EQUAL",text: 'eşittir'},
        {value:"NOTEQUAL",text: 'eşit değildir'},
        {value:"GREATER",text:'büyüktür'},
        {value:"LESS",text:'küçüktür'}
    ])

    const rule_operator_full_options = ref([
		{ value: "EQUAL", text: "eşittir" },
		{ value: "NOTEQUAL", text: "eşit değildir" },
		{ value: "GREATER", text: "büyüktür" },
		{ value: "LESS", text: "küçüktür" },
		{ value: "FULL", text: "dolu ise" },
		{ value: "EMPTY", text: "boş ise" },
	])
	
    const rules = ref({
        scope:'ALL',
        action:'SHOW'
    })

    const form_data = computed(() => store.state.form.active_form)
    const db_field_coloumns = ref([])
    const current_section_index = ref()

    const new_rule_type = ref()
    const rule = ref({
        type: "FORMSECTION",
        source: props.section.id,
        target:null,
        value:null,
        operator:null
    })
    const rule_value_options = ref([])

    const tab_titles = computed(() => {
        return ["Bölüm Bilgileri","Kurallar"]
    })

    const getDbColumns = () => {
        API.get("project/"+ form_data.value.project_id +"/attendees/column")
        .then((response) => {
            console.log(response.data)
            db_field_coloumns.value = response.data
		})
	}

    if(form_data.value.project_id) {
	    getDbColumns()
    }
    
    const db_columns = computed(() => {
        const above_sections = store.state.form.active_form.sections.filter((x,index) => index < current_section_index.value)
        
        if(
            db_field_coloumns.value.length == 0 || 
            above_sections.length == 0
        ) return []
        
        let options = []
        above_sections.forEach(section => {
            section.fields.forEach(field => {
                console.log(field.type)
                if(field.options.length > 0) {
                    options.push({value : "FIELD-" +field.id, text : field.title,subtext: "Form Alanı",})
                }
            })
        })

        return options

        if (db_field_coloumns.value) {
			db_field_coloumns.value.map((arr) =>
                options.push({
					value: "DB-" + arr.key,
					text: arr.text,
					subtext: "DB Alanı",
				})
			)
		}
        
        return options
	})


    watch(() => section2.value, data => {
        
        store.state.form.active_form.sections.forEach((x,y)=> {
            if(x.id == data.id) {
                current_section_index.value = y
            }
        })
        console.log("current_section_index",current_section_index.value)
    },{immediate:true})

    watch(() => rule.value.target, data => {
        if (!data) return
        console.log(data);
        
        if (data.includes("DB-")) {
            new_rule_type.value = "input_box"

            rule.value.operator = false
            rule.value.value = ""

            rule_operator_options.value = [
                { value: "EQUAL", text: "eşittir" },
                { value: "NOTEQUAL", text: "eşit değildir" },
                { value: "FULL", text: "dolu ise" },
                { value: "EMPTY", text: "boş ise" },
            ]

            return
        }

        let field_id=null;
        if (data.includes("FIELD-")) {
			field_id = data.split("-")
			field_id = field_id[1]
		}

        if (!field_id) return

        new_rule_type.value = "select_box"
        store.state.form.active_form.sections.forEach(section => {
            section.fields.forEach(field => {
                if(field.id == field_id) {
                    rule_value_options.value = field.options.map(x => {
                        return { value : x.id, text : x.option_text }
                    })
                }
            })
        })
        
    },{immediate:true})

    watch(() => db_columns.value, data => {
        
    },{immediate:true})

    
    watch(() => section.value, data => {
        
        API.put('form/' + form_data.value.code + '/section/' + section.value.id,{
            title:data.title,
            help_text:data.help_text,
            title_colour:data.title_colour,
            is_hidden:data.is_hidden,
            dep_scope:data.dep_scope,
            dep_action:data.dep_action
        })
        .then( response => {
            
            //store.dispatch('form/loadData', form_data.value.code)
            store.dispatch('form/updateSection', data)
            
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
                title:'BAŞARILI!',
				description:response.data.message
			})
        })
        .catch( error => {
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
        })
    }, { deep: true })
    




    const onSpecChange = e => section.value[e.value] = e.checked ? 1 : 0
    const hide = () => emit('hide')
    
    const find_form_field = (id, lookup = null, lookup_id = null) => {
        let select_field = false
        store.state.form.active_form.sections.forEach(section => {
            section.fields.forEach(field => {
                if(field.id == id) {
                    select_field = field
                    }
            })
        })

        if (select_field == false) {
			if (lookup == "title") return id
			if (lookup == "option") return lookup_id
		}

        if (!lookup) return select_field

        if (lookup) {
			if (lookup == "option") {
				return select_field.options.find((x) => x.id == lookup_id).option_text
			}

			return select_field[lookup]
		}

        return title
    }

    const add_rule = () => {

        rule.value.type = 'FORMSECTION'
        rule.value.source = section.value.id

        API.post('form/'+ form_data.value.code +'/rules',rule.value)
        .then(response => {
            //store.dispatch('form/loadData', form_data.value.code)
          
            rule.value = {
                target:null,
                value:null,
                operator:null
            }

             store.dispatch('form/updateSection', {id:section.value.id, section:response.data.data})
             section.value = response.data.data

             store.dispatch('floating_alerts/addAlert', {
			 	type:'tip',
                title:'BAŞARILI!',
			 	description:response.data.message
			 })
        })
        .catch( error => {
            
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
        })
    }

    const delete_section_rule = (id) => {
        
        API.delete('form/'+ form_data.value.code +'/rules/'+id).then(response => {
            //store.dispatch('form/loadData', form_data.value.code)
    
            store.dispatch('form/updateSection', {id:section.value.id, section:response.data.data})
            section.value = response.data.data

            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!', 
                description:response.data.message
            })
        })
        .catch( error => {
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
        })
    }

</script>