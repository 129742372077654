<template>
    <label :for="rand_id" v-if="props.label" class="font-bold text-sm flex w-fit whitespace-nowrap" :class="{
        'text-calypso' : isFocused,
        'text-red-600' : error,
        'mb-1' : !info
    }">{{ label }}
    <UITooltip :title="help_text" v-if="help_text" class="ml-2" :isInfoBox="true"><i class="fa-regular fa-circle-question text-gray-800"></i></UITooltip>
    <div v-if="info" class="text-sm block text-gray-500 mb-1 font-normal">{{ info }}</div>
    </label>
    
</template>


<script setup>
    import {computed} from 'vue'

    const props = defineProps({
        label : String,
        help_text: String,
        info: String,
        rand_id : String,
        isFocused : Boolean,
        error : {
            type:Boolean,
            default:false
        }
    })

</script>