<template>
	<Modal
		:title="modalProps.title"
		:isOpen="isOpen"
		:ready2submit="ready2submit"
		:isSubmitting="isSubmitting"
		:action_button_text=actionButtonText
		@modal_closed="modal_closed"
		@submit="submit"
		:size="size"
		:background_animation="background_animation"
	>
		<template v-slot:modal_content>
			<UIImage v-if="modalProps.header_image" :src="modalProps.header_image.url" external/>
			<UIAlert v-if="modalProps.header" :type="modalProps.header.type" :titleText="modalProps.header.title" class="mb-2 mt-0 flex-row">
                <p>{{ modalProps.header.text }}</p>
            </UIAlert>
			<UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>

			<FormGenerator
				:schema="modalProps.schema"
				v-model="formData"
				:cols="modalProps.cols"
				@enter="enter"
				@ready2submit="setReady2submit"
			/>
		</template>
	</Modal>
</template>

<script setup>
	// Imports
	import { ref, watch, computed, onBeforeMount } from "vue"
	import Modal from "@/components/UI/Modal.vue"
	import FormGenerator from "@/components/FormGenerator.vue"

	// Props
	const props = defineProps({
		isOpen: Boolean,
		isSubmitting: Boolean,
		submit_error: [String,Boolean],
		modelValue: {
			type: Object,
			default: {},
		},
		modalProps: {
			type: Object,
			default: {
				title: "",
				schema: [],
			},
		},
		size:{
			type:String,
			default:'md'
		},
		actionButtonText:{
			type:String,
			default:'Kaydet'
		},
		background_animation:String
	})

	// Emits
	const emit = defineEmits(["modal_closed", "submit"])

	watch(() => props.isOpen, data =>{
		if(!data) formData.value = {}
	})

	// VAR Definitions
	const formData = ref(props.modelValue)
	const modalProps = ref(props.modalProps)
	
	// Data models
	//const ready2submit = ref(true)
	const ready2submit = computed(() => {
		return props.modalProps.schema
			.filter((x) => x.required)
			.map((x) => x.id)
			.every(
				val => {
					let result = Object.keys(formData.value).includes(val) && formData.value[val]
						
					if (typeof formData.value[val] === 'object') {
						result = Object.keys(formData.value[val]).length > 0
					}
					if(typeof formData.value[val] === 'string') {
						result = formData.value[val].length > 0
					}
					return result
				}
			)
	})

	// Watchers
	watch(() => props.modelValue, data => {
		formData.value = data
	})
	watch(() => props.modalProps, data => {
		modalProps.value = data
	})
	watch(() => formData.value, data => {
		console.log(data)
	})

	// Functions

	const modal_closed = () => emit("modal_closed")
	const submit = () => {
		emit("submit", formData.value)
	}
	const enter = () => console.log("enter")
	const setReady2submit = data => {}//ready2submit.value = data
</script>
