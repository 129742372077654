<template>
    <UILabel :label="label" :rand_id="rand_id" :isFocused="isFocused"/>
    <ul v-bind="$attrs">
        <slot></slot>
    </ul>
</template>

<script setup>
    import {computed,ref} from 'vue'
    import { SearchIcon, SelectorIcon } from '@heroicons/vue/solid'
    
    const props = defineProps({
        modelValue: String,
        placeholder: String,
        defaultValue:String,
        type:String,
        error:Boolean,
        label:String,
        prepend:String
    })

    let isFocused = ref(false)

    const emit = defineEmits(['update:modelValue'])

    let rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))

    const handleInput = (e) => {
        emit('update:modelValue', e.target.value)
    }
</script>