<template>

<TableView 
		title="Bütçe Kalemleri"
		:table_titles="table_titles" 
		api_url="definition/budget-items"
        :sort_by="sort_by"
		:empty_state="empty_state"
		:modalProps="modalProps"
		:add_new="true"
		@add_new_closed="()=>add_new = false"
		:add_new_titles="['Bütçe Kalemlerini Güncelle', 'Yeni Bütçe Kalemi Ekle']"
		/>
</template>

<script setup>
	import { reactive,ref,computed } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
    import TableView from '@/views/TableView.vue'
	import API from '@/api'

    const store = useStore()
	const router = useRouter()

    const sort_by = 'name'
    const isModalOpen = ref(false);
    const budget_categories = computed(() => store.state.content.budget_categories.map(category => { return {value : category.id, text : category.name}}).sort((a, b) => a.text.localeCompare(b.text)))

    const empty_state = {
        title:'Bütçe kalemlerine henüz veri eklememişsin!',
        description:'Bütçe kalemlerini ekleyerek.İşlerini hızlandırabilirsin'
    }

    // let filters = reactive({
	// 	query:'',
	// 	page_size:10,
	// 	page:1,
	// 	sort_by:'name',
	// 	sort_order:'asc'
	// })

    const modalProps = computed(()=>{return {
        cols:1,
		schema: [
			{
				id: 'name',
				component: 'UIInput',
				label: 'Kalem Adı',
				required: true,
                props: {
                    
                }
			},
            {
				id: 'name_en',
				component: 'UIInput',
				label: 'Kalem Adı (İngilizce)',
                props: {
                    
                }
			},
            {
				id: 'category_id',
				component: 'UISelect',
				label: 'İlgili Bütçe Kategorileri',
				required: true,
                props: {
					display:'box',
					source:budget_categories.value,
					title:'Bütçe Kategorisini Seç',
					search_enabled:false
                }
			}
		]
    }})

	let table_titles = [
		{
			title:'Kalem Adı',
            id:'name',
            width:'w-2/5',
			class:"font-bold truncate",
            sortable:true,
		},
        {
			title:'Kalem Adı (İngilizce)',
            id:'name_en',
            width:'w-2/5',
			class:"truncate"
		},
        {
			title:'Kategori',
            id:'category.name',
            width:'w-2/5',
			class:"truncate"
		},
		{
			title:'',
			width:'w-1/5',
			type:'action'
		},
	]



</script>
