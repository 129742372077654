import Moment from 'moment/min/moment-with-locales'
import { extendMoment } from 'moment-range';
 
const moment = extendMoment(Moment)

import {format_two_dates} from '@/utils/dates'

export default {
    install: (app) => {
        moment.locale('tr')

        const helpers = {
            format: (amount, currency, maximumFractionDigits = 2, minimumFractionDigits = 2) => {
                //if(!amount || !currency) return
                return new Intl.NumberFormat('TR', { style: 'currency', currency: currency, maximumFractionDigits: maximumFractionDigits,minimumFractionDigits: minimumFractionDigits }).format(amount || 0);
            },
            format_number: amount => {
                if(!amount) {
                    amount = 0
                }
                return new Intl.NumberFormat('TR', {maximumFractionDigits: 2,minimumFractionDigits: 2 }).format(amount);
            },
            format_percentage: (amount, maximumFractionDigits = 3, minimumFractionDigits = 2) => {
                return new Intl.NumberFormat('TR', {style: 'percent', maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits }).format(amount);
            },
            format_two_dates : format_two_dates
        }
        
        

        app.config.globalProperties.$helpers = helpers
        app.provide('helpers', helpers)

        app.config.globalProperties.$moment = moment
        app.provide('moment', moment)
	}
}