import API from '@/api'

const state = {
    form: {
        sections: [],
        db_columns: []
    },
    error:null,
    PESFform:{}
}
  
// getters
const getters = {
    getField: (state) => (id) => {
        let searched_field
        state.form.sections.forEach((section,s) => {
            section.fields.forEach((field,f) => {
                if(field.id == id) {
                    searched_field = field
                }
            })
        })
        return searched_field
    }
}
  
// actions
const actions = {
    loadData ({ commit }, data) {
        API.getFormData(data).then(response => {
            commit('setForm', response.data)
        })
    },
    updateField ({ commit }, data) {
        commit('setFieldData',data)
    }
}
  
// mutations
const mutations = {
    setForm (state, data) {
        state.form = data
    },
    setError (state, data) {
        state.error = data
    },
    setPESFForm (state, data) {
        state.PESFform = data
    },
    setFieldData (state, data) {
        state.form.sections.forEach((section,s) => {
            let field = section.fields.findIndex(x => x.id == data.id)
            if(field) {
                state.form.sections[s].fields[field] = { ...state.form.sections[s].fields[field], ...data.field }
            }
        })
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}