<template>
    <div 
        class="flex py-3 px-2 border items-center"
        :class="{
            'bg-calypso-dark border-calypso': type == 'info',
            'bg-yellow-100 border-yellow-300': type == 'warning',
            'bg-red-100 border-red-300': type == 'danger',
            'bg-lime-100 border-lime-300': type == 'success',
            'bg-white border-calypso-dark': type == 'tip',
            
        }"
         @click="click"
    >
        <div class="flex ml-3 select-none" :class="closeable ? 'mr-auto':'mr-3'">
            <span class="font-black mr-2" v-if="titleText">{{ titleText }}</span>
            <slot></slot>
        </div>
        <div class="shrink-0 rounded-full w-7 h-7 ml-10 p-1 hover:bg-gray-400 cursor-pointer hover:bg-opacity-10" v-if="closeable" @click="close">
            <XIcon/>
        </div>
    </div>
</template>

<script setup>
    import { XIcon } from '@heroicons/vue/outline';
    
    const props = defineProps({
        titleText: String,
        type:{
            type:String,
            default:'info'
        },
        closeable:{
            type:Boolean,
            default:false
        }
    })

    const emit = defineEmits(['close','click'])
    const close = () => emit('close')
    const click = () => emit('click')

</script>

<style scoped>
    .bg-danger {
        background-image: repeating-linear-gradient(135deg,#f04b51,#f04b51 24px,#f2545b 0,#f2545b 48px);
        background-color: #f2545b;
        color: #fff;
    }
</style>