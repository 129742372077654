<template>
    <Modal 
        :isOpen="true"
        title="Kredi Kartı Talep Formu"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Onaya Gönder"
        @modal_closed="modal_closed"
        @submit="submit"
        size="lg"
        
    >
        <template v-slot:modal_content>
            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>
            <UIFormRow>
                <UISelect options_line_break label="Hangi Proje?" title="Bir proje seç" :source="projects" display="box" :errors="v$.project_id.$errors" v-model="data.project_id"/>
            </UIFormRow>
            <UIFormRow>
                <UISelect options_line_break label="Hangi Tedarikçi?" title="Bir tedarikçi seç" :source="suppliers" display="box" :errors="v$.supplier_id.$errors" v-model="data.supplier_id"/>
            </UIFormRow>
            <UIFormRow>
                <UIDatePicker label="Ödeme Yapılacak Tarih" title="Tarih Seç" display="box" :errors="v$.payment_date.$errors" v-model="data.payment_date"/>
            </UIFormRow>
            <UIFormRow class="flex space-x-3">
                <div class="w-2/5">
                    <UISelect :source="currencies_select" label="Para Birimi" title="Para Birimi" :errors="v$.currency.$errors" display="box" v-model="data.currency"/>
                </div>
                <div class="w-3/5">
                    <UICurrencyInput label="Öngörülen Harcama Tutarı" :options="{ currency: data.currency, locale:'tr' }" :errors="v$.amount.$errors" v-model.lazy="data.amount"/>
                </div>
            </UIFormRow>
            
            <UIFormRow>
                <UITextarea v-model="data.note" :maxlength="200" :autogrow="false" label="Not" placeholder="Varsa notunu buraya yazabilirsin..." class="h-24 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-slate-50 p-2"/>
            </UIFormRow>
            <hr>
            
        </template>
	</Modal>

</template>

<script setup>
    // Imports
	import {ref, computed, watch, inject } from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'

    import useVuelidate from '@vuelidate/core'
    import { required } from '@/utils/i18n-validators'
    
    const emitter = inject('emitter')

    // Props
	const props = defineProps({
        cc_request : Object
    })
    
    const store = useStore()
    const isSubmitting = ref(false)
    const submit_error = ref(false)
    
    const ready2submit = computed( () => 
        data.value.project_id && 
        data.value.supplier_id && 
        data.value.currency && 
        data.value.payment_date && 
        data.value.amount ? true : false
    )
            
    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const event_company = computed(() => store.state.auth.event_company)
    const projects = ref([])
    const currencies_select = computed(() => store.getters['content/currencies_select'])
    const suppliers = ref([])

    API.getProjectsByStatus(['LEAD','PREP','OPERATION','PRECLOSE']).then(response => {
        projects.value = response.data.map( project => { return {value : project.code, text : project.name, subtext : project.project_ref, image : project.client.logo_full } }).sort((a, b) => a.text.localeCompare(b.text))
    })
    
    // Data models
    const data = ref({
        cc_request_id : props.cc_request ? (props.cc_request.status== 'DECLINED'?null:props.cc_request.id ) : null,
        project_id : props.cc_request ? props.cc_request.project_id : null,
        supplier_id : props.cc_request ? props.cc_request.supplier_id : null,
        payment_date : props.cc_request ? props.cc_request.payment_date : '',
        note : props.cc_request ? props.cc_request.note : '',
        currency : props.cc_request ? props.cc_request.currency : event_company.value.currency,
        amount : props.cc_request ? props.cc_request.amount : ''
    })

    // Watchers
    
    watch(() => data.value.project_id, (x) => {
            if(!x) return
            if(data.value.project_id) {
                API.get('project/' + data.value.project_id + '/suppliers').then(response => {
                    suppliers.value = response.data.map(supplier => { return {value : supplier.id, text : supplier.name}}).sort((a, b) => a.text.localeCompare(b.text))
                })
            }
		},{ immediate: true, deep: true }
    )
    
    // Functions

	const modal_closed = () => emit('modal_closed')

    const rules = computed(() => ({
        supplier_id: { required },
        project_id: { required},
        currency:{required},
        payment_date:{required},
        amount:{required},
    }))

    const v$ = useVuelidate(rules, data)
        
    const submit = async () => {
        
        const result = await v$.value.$validate()
        if(!result) return

        isSubmitting.value = true

        let request
        
        if(data.value.cc_request_id) {
            request = API.put('accounting/cc-requests/' + data.value.cc_request_id,data.value)
        } else {
            request = API.post('accounting/cc-requests',data.value)
        }
        
        request.then( response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:'Kredi kartı talebin işleme alındı.'
            })
            isSubmitting.value = false
            modal_closed()
            emitter.emit('load_cc_requests')
        }).catch( error => {
            isSubmitting.value = false
            submit_error.value = error.response?.data.message
        })
    }

</script>