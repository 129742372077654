<script setup>
	import {ref, computed, watch} from 'vue'
	import { useStore } from 'vuex'
	import API from '@/api'

	const store = useStore()    
	const users = computed(() => store.getters['content/users_select'])

	const notifications = [
		'BizdevCreated',
		'BizdevUpdated',
		'BizdevDeleted',
		'ProjectStatusCreated',
		'ProjectAssigned',
		'NewAccountingTransaction',
		'PurchaseInvoiceExcessSum',
		'PurchaseInvoiceUnknownSupplier',
		'SaleInvoiceNotRequested',
		'PurchaseInvoiceLated',
		'ExpenseRequested',
		'ResetPasswordNotification',
		'BudgetCreated',
		'ClientCreated',
		'SupplierCreated',
		'NoteCreated',
		'MeetingCreated',
		'MeetingCancelled',
		'TaskCreated',
		'TaskCompleted',
	]

	const recipients = ref([])
	const recipients_data = ref({})
	let isLoaded = false

	API.get('settings/notification-recipients').then(response => {
		recipients.value = response.data
		notifications.forEach(notification => {
			recipients_data.value[notification] = response.data.filter( x => x.type == notification).map( x => x.user_id)
		})
		isLoaded = true
	})

	watch(() => recipients_data.value, data => {
		if(!isLoaded) return
		API.post('settings/notification-recipients', recipients_data.value).then(response => {
		
		})
	},{deep:true,immediate:true})
	
</script>


<template>
	<div class="grid grid-cols-4 gap-5">
		<UICard title="İş Geliştirme">
			<UIFormRow>
				<UISelect v-model="recipients_data.BizdevCreated" :tags="true" :use_arrow="false" key="participants" label="Yeni iş fırsatı yaratıldığında" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.BizdevUpdated" :tags="true" :use_arrow="false" key="participants" label="İş fırsatı güncellendiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.BizdevDeleted" :tags="true" :use_arrow="false" key="participants" label="İş fırsatı silindiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Proje">
			<UIFormRow>
				<UISelect v-model="recipients_data.ProjectStatusCreated" :tags="true" :use_arrow="false" key="participants" label="Proje durumu değiştiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.ProjectAssigned" :tags="true" :use_arrow="false" key="participants" label="Bir kişi projeyi başkasına devrettiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Muhasebe">
			<UIFormRow>
				<UISelect v-model="recipients_data.NewAccountingTransaction" :tags="true" :use_arrow="false" key="participants" label="NewAccountingTransaction" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.PurchaseInvoiceExcessSum" :tags="true" :use_arrow="false" key="participants" label="Beklenenden fazla tutara sahip alış faturası alındığında" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.PurchaseInvoiceUnknownSupplier" :tags="true" :use_arrow="false" key="participants" label="Göndericisi projeyle alakasız alış faturası alındığında" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.SaleInvoiceNotRequested" :tags="true" :use_arrow="false" key="participants" label="Talep edilmemiş bir satış faturası düzenlendiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.PurchaseInvoiceLated" :tags="true" :use_arrow="false" key="participants" label="Alış faturaları geciktiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.ExpenseRequested" :tags="true" :use_arrow="false" key="participants" label="Masraf Bildirimi" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Şifre işlemleri">
			<UIFormRow>
				<UISelect v-model="recipients_data.ResetPasswordNotification" :tags="true" :use_arrow="false" key="participants" label="Bir kullanıcı şifresini sıfırladığında" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Bütçe">
			<UIFormRow>
				<UISelect v-model="recipients_data.BudgetCreated" :tags="true" :use_arrow="false" key="participants" label="Yeni bütçe oluşturulduğunda" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Müşteri">
			<UIFormRow>
				<UISelect v-model="recipients_data.ClientCreated" :tags="true" :use_arrow="false" key="participants" label="Yeni müşteri oluşturulduğunda" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Tedarikçi">
			<UIFormRow>
				<UISelect v-model="recipients_data.SupplierCreated" :tags="true" :use_arrow="false" key="participants" label="Yeni tedarikçi oluşturulduğunda" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Not">
			<UIFormRow>
				<UISelect v-model="recipients_data.NoteCreated" :tags="true" :use_arrow="false" key="participants" label="Yeni not oluşturulduğunda" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Onay">
			<UIFormRow>
				<UISelect v-model="recipients_data.ApproveRequested" :tags="true" :use_arrow="false" key="participants" label="Onay talebi oluşturulduğunda" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.ApproveApproved" :tags="true" :use_arrow="false" key="participants" label="Onay talebi bir kullanıcı tarafından onaylandığında" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.ApproveDenied" :tags="true" :use_arrow="false" key="participants" label="Onay talebi bir kullanıcı tarafından reddedildiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.ApproveCompleted" :tags="true" :use_arrow="false" key="participants" label="Onay talebi tamamlandığında" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="Toplantı">
			<UIFormRow>
				<UISelect v-model="recipients_data.MeetingCreated" :tags="true" :use_arrow="false" key="participants" label="Toplantı oluşturulduğunda" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.MeetingCancelled" :tags="true" :use_arrow="false" key="participants" label="Toplantı iptal edildiğinde" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>

		<UICard title="To-do">
			<UIFormRow>
				<UISelect v-model="recipients_data.TaskCreated" :tags="true" :use_arrow="false" key="participants" label="To-do oluşturulduğunda" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
			<UIFormRow>
				<UISelect v-model="recipients_data.TaskCompleted" :tags="true" :use_arrow="false" key="participants" label="Bir to-do tamamlandığında" title="Kullanıcıları seç" :multiple="true" :source="users" />
			</UIFormRow>
		</UICard>
	</div>
    
</template>