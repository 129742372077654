//import ProjectsPage from '../pages/Projects.vue'
//import ProjectDetail from '../pages/ProjectDetail.vue'
//import RegformDetailPage from '../pages/form_detail/FormBuilder.vue'
//import BudgetPage from '../pages/project_detail/Budget.vue'
//import EmailTemplateBuilder from '../pages/emailing/TemplateBuilder.vue'
//import NewSalesInvoiceRequest from '../pages/project_detail/accounting/NewSalesInvoiceRequest.vue'
//import ProjectChecklist from '../pages/project_detail/Checklist.vue'
//import PresentationDetail from '../pages/project_detail/content/PresentationDetail.vue'

const routes = [
    {
		path: "/projects",
		component: () => import('../pages/Projects.vue'),
        name:'Projects',
		meta: {
			requiresAuth: true,
			layout:'normal',
			title:'Projeler',
			subtitle:'Konfirme olan projeler listesi'
		}
	},
    {
		//path: '/project/:code/:subpage(?!checklist)',
		path: '/project/:code/:subpage',
		name: "ProjectDetail",
		meta: { 
			requiresAuth: true,
			layout:'normal',
			custom_header_bg_color:true,
			breads:[
				{
					route_name:'Projects',
					title:"Projeler",
				}
			]
		},
		component: () => import('../pages/ProjectDetail.vue')
	},
	{
		path: '/project/:code/checklist',
		name: "ProjectChecklist",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			breads:[
				{
					route_name:'Projects',
					title:"Projeler",
				}
			]
		},
		component: () => import('../pages/project_detail/Checklist.vue')
	},
	{
		path: '/project/:code/rsvp-form/:form_id',
		name: "RSVPformDetail",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			title:'LCV Form Detayları'
		},
		component: () => import('../pages/form_detail/RSVPFormBuilder.vue')
	},
	{
		path: '/project/:code/reg-form/:form_id',
		name: "RegformDetail",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			title:'Kayıt Form Detayları'
		},
		component: () => import('../pages/form_detail/RegFormBuilder.vue')
	},
	{
		path: '/project/:code/budget/:bcode',
		name: "BudgetDetail",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			title:'Bütçe'
		},
		component: () => import('../pages/project_detail/Budget.vue')
	},
	{
		path: '/project/:code/email-template/:template_id',
		name: "EmailTemplateBuilder",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			title:'E-Posta Oluştur'
		},
		component: () => import('../pages/emailing/TemplateBuilder.vue')
	},
	{
		path: '/project/:code/presentation/:presentation_id',
		name: "PresentationDetail",
		meta: { 
			requiresAuth: true,
			layout:'standalone',
			title:'Sunum Detayları'
		},
		component: () => import('../pages/project_detail/content/PresentationDetail.vue')
	},
	{
		path: '/project/:code/accounting/new-sales-invoice-request',
		name: "NewSalesInvoiceRequest",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			title:'Yeni Satış Faturası Talebi'
		},
		component: () => import('../pages/project_detail/accounting/NewSalesInvoiceRequest.vue')
	},
	{
		path: '/project/:code/sales/new-sales-invoice-request',
		name: "NewProductSalesInvoiceRequest",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			title:'Yeni Satış Faturası Talebi'
		},
		component: () => import('../pages/project_detail/sales/NewSalesInvoiceRequest.vue')
	},
	{
		path: '/project/:code/manuscripts/:manuscript_id',
		name: "ManuscriptDetail",
		meta: { 
			requiresAuth: true,
			layout:'standalone',
			title:'Makale Detayları'
		},
		component: () => import('../pages/project_detail/ManuscriptDetail.vue')
	},
	
]

export default {
    routes
}