const state = {
    cc_requests:[],
    cc_requests_paginator:null,
    purchase_invoices:[],
    purchase_invoices_paginator:null,
    sales_invoices:[],
    sales_invoices_paginator:null,
    sales_invoice_requests:[],
    sales_invoice_requests_paginator:null,
    expenses:[],
    expenses_paginator:null,
    advance_payments:[],
    advance_payments_paginator:null,
    payment_requests:[],
    payment_requests_paginator:null,
    payments:[],
    payments_paginator:null,
    payments_received:[],
    payments_received_paginator:null,
}

// getters
const getters = {
    
}
  
// actions
const actions = {
    
}
  
// mutations
const mutations = {
    setData(state, data) {
        if(data.field.includes('paginator')) {
            data.response.links.splice(0,1)
            data.response.links.splice(-1,1)
        }
        state[data.field.replaceAll("-","_")] = data.response
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}