<script setup>
	import { ref, computed, watch } from "vue"
	import { useRouter } from "vue-router"
	import { useStore } from "vuex"
	import useVuelidate from '@vuelidate/core'
	import { required, email, requiredIf } from '@/utils/i18n-validators'
	import { TabPanel, Switch } from "@headlessui/vue"

	import API from "@/api"
	import TableView from "@/views/TableView.vue"
	import PostEventClientSurveyForm from "@/modals/admin/PostEventClientSurveyForm.vue"

	const store = useStore()
	const router = useRouter()
	const sort_by = null
	

	const event_company = computed(() => store.state.auth.event_company)
	const users = computed(() => store.getters['content/users_select'])
	const akin = ref()
	const isFormOpen = ref(false)
	const forms = ref([])
	const form2update = ref({})
	const ref_delete_form = ref()

	const settings_data = ref({
		email_subject : event_company.value.post_event_survey_email_subject,
		email_content : event_company.value.post_event_survey_email_content,
		sending : event_company.value.post_event_survey_sending == 1 ? true : false,
		customer_feedback_lists:[]
	})

	API.get('settings/notification-recipients?type=AfterEventCustomerFeedbackReceived').then(response => {
		settings_data.value.customer_feedback_lists= response.data.filter( x => x.type == 'AfterEventCustomerFeedbackReceived').map( x => x.user_id)
	})

	const rules = computed(() => ({
        email_subject: { required },
        email_content: { required },
		sending : { required }
    }))

    const v$ = useVuelidate(rules, settings_data)

	watch(() => v$.value, data => {
		//console.log(data)
		//console.log(v$.value.$invalid)
	},{deep:true,immediate:true})

	watch(() => settings_data.value, data => {
		//console.log(data)
		//console.log(v$.value.$validate())
	},{deep:true,immediate:true})

	const tab_titles = ref(["Anket Formları", "Ayarlar"])

	const table_titles = [
		{
			title: "Anket Adı",
			sortable: true,
			id: "name",
			width: "w-1/5",
			class: "font-bold truncate",
		},
		{
			title: "Açıklama",
			id: "description",
			width: "w-2/5",
			class: "",
		},
		{
			title: "Kullanabilecek Proje Tipleri",
			id: "post_event_survey_form_project_type",
			width: "w-1/5",
			class: "",
			pre_render: "text.map(x=>x.name).join(', ')",
		},
		{
			title: "",
			width: "w-1/5",
			type: "action",
		},
	]

	const empty_state = {
		title: "Henüz hiç anket eklenmemiş!",
		description: "Burada etkinlik tipine göre anketler oluşturabilirsin.",
	}

	const fetch_data = () => {
		API.get("event-companies/post-event-client-surveys").then((response) => {
			forms.value = response.data
		})
	}
	fetch_data()

	const go_to_survey = id => {
		router.push({
			name: "AdminPostEventClientSurveyDetail",
			params: { id: id },
		})
	}

	const add_new_open = id => {
		form2update.value = forms.value.find( x => x.code == id)
		isFormOpen.value = true
	}

	const modal_closed = () => {
		isFormOpen.value = false
		API.getProjectTypes()
		fetch_data()
	}

	const row_deleted = () => API.getProjectTypes()

	const delete_row = id => {
		ref_delete_form.value.show({
			title: 'Emin misin?',
			message: 'Bu formu silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Sil',
		}).then(result => {
			if (result) {
				API.delete('event-companies/post-event-client-surveys/' + id)
				.then(response => {
                    fetch_data()
					API.getProjectTypes()
                })
                .catch(error => {
					store.dispatch('floating_alerts/addAlert', {
						type:'warning',
						title:'HATA!',
						description:error.data.message
					})
				})
			} 
		})
		
	}

	const save_settings = () => {
		API.put("event-companies/post-event-client-survey-settings", settings_data.value).then((response) => {
			store.dispatch('floating_alerts/addAlert', {
				type:'tip',
				title:'BAŞARILI!',
				description:response.data.message
			})
		}).catch(error => {
			store.dispatch('floating_alerts/addAlert', {
				type:'warning',
				title:'HATA!',
				description:error.data.message
			})
		})
	}

</script>

<template>
	<UITabs :titles="tab_titles" class="w-full mb-auto px-2 pb-16 sm:px-0">
		<TabPanel>
			<div class="mt-5">
				<TableView
					:table_titles="table_titles"
					:data_source="forms"
					:empty_state="empty_state"
					:sort_by="sort_by"
					:add_new="true"
					:add_new_titles="['Anketi Güncelle', 'Yeni Anket Ekle']"
					:row_click="go_to_survey"
					row_click_parameter="code"
					@add_new_open="add_new_open"
					@row_deleted="row_deleted"
					@delete_row="delete_row"
					:custom_add_new_modal="true"
					:custom_delete="true"
					ref="akin"
				/>
			</div>
		</TabPanel>
		<TabPanel>
			
			<div class="mt-10">
				<dl>
					<div
						class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
					>
						<dt class="text-sm font-medium">Anket Gönderimi</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<Switch
								v-model="settings_data.sending"
								:class="settings_data.sending ? 'bg-calypso' : 'bg-gray-200'"
								class="relative inline-flex h-6 w-11 items-center rounded-full"
							>
								<span
									:class="settings_data.sending ? 'translate-x-6' : 'translate-x-1'"
									class="inline-block h-4 w-4 transform rounded-full bg-white transition"
								/>
							</Switch>
						</dd>
					</div>
					<div
						class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
					>
						<dt class="text-sm font-medium">E-Posta Konusu</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<UIInput v-model="settings_data.email_subject"/>
						</dd>
					</div>
					<div
						class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
					>
						<dt class="text-sm font-medium">E-Posta Metni</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<UITextarea :autogrow="false" class="h-40" v-model="settings_data.email_content"/> 
						</dd>
					</div>
					<div
						class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
					>
						<dt class="text-sm font-medium">Müşteri yeni bir anket gönderdiğinde kimlere bildirim gönderilsin?</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<UIFormRow>
								<UISelect display="box" v-model="settings_data.customer_feedback_lists" :tags="true"  key="participants" label="" title="Kullanıcıları seç" :multiple="true" :source="users" />
							</UIFormRow>
						</dd>
					</div>
					<div
						class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
					>
						<dt class="text-sm font-medium"></dt>
						<dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<UIButton type="submit" text="Kaydet" @click="save_settings"/>
						</dd>
					</div>
				</dl>
			</div>
			
			
		</TabPanel>
	</UITabs>
	<UIConfirm ref="ref_delete_form" :destructive="true"/>
	<PostEventClientSurveyForm
		:isOpen="isFormOpen"
		:form="form2update"
		@modal_closed="modal_closed"
	/>
</template>