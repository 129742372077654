import { getMessaging, getToken } from "firebase/messaging"
import API from '@/api'


class WebPushNotificationManager {
    
    constructor(user_id) {
        this.user_id = user_id
    }

    check() {
        return Notification.permission == "granted"
    }

    request() {
        return new Promise((resolve, reject) => {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    const messaging = getMessaging();
                    getToken(messaging, 
                        { vapidKey: 'BC5aZ45HBBxvwLxA6cj8AiaLIodki9HNwk0LMzlZog5Tk8aDKsQU_7G4ShPZ3T6XuMq_A14NZ1fJjv5XCDtvAvE' }
                    ).then(currentToken => {
                        if (currentToken) {
                            API.post('users/' + this.user_id + '/push-token', { token : currentToken, platform:'web' }).then((response) => {
                                resolve()
                            })
                        } else {
                            console.log('No registration token available. Request permission to generate one.')
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err)
                    })
                } else {
                    reject(permission)
                }
            })
        })
    }
}

export default WebPushNotificationManager