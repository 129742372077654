<template>
    <div class="flex items-start">
        <div class="w-60 shrink-0 flex-none sticky top-32">
            <div v-for="menu,k in reports_menu" :key="k" class="border-b border-gray-100">
                <router-link :class="'/admin/similarRecords/' + menu.link == route.fullPath ? 'bg-calypso-light':''" :to="'/admin/similarRecords/' + menu.link"  class="p-2 relative hover:bg-gray-50 hover:text-calypso text-gray-900 text-sm font-medium  w-full inline-flex items-center">
                    {{menu.title}}
                </router-link>
            </div>
        </div>
        <div class="ml-5 flex-grow">
            <router-view />
        </div>
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router'
	
    const route = useRoute()

    let reports_menu = [
        {
            title:"Kontak Listesi",
            link:'contacts'
        },
        {
            title:"Müşteri Listesi",
            link:'clients'
        },
        {
            title:"Tedarikçi Listesi",
            link:'suppliers'
        }
    ]
    
</script>