<template>
    <Modal :destructive="destructive" :isOpen="isModalOpen" @modal_closed="cancelled" :title="title" :showSubmitButton="false">
        <template v-slot:modal_content>
            <div class="mb-2 bg-red-100 p-2 text-red-600 font-bold hidden">DİKKAT! Bu işlem geri alınamaz!</div>
            <div class="mb-10"
                :class="{
                    'text-5xl font-black mt-5' : destructive
                }"
            >{{ message }}</div>
            <div class="mb-10"
            >{{ submessage }}</div>
        </template>
        <template v-slot:modal_footer>
            <UIButton type="danger" @click="cancelled" :text="cancelButton" class="mr-auto" v-if="cancelButton"/>
			<UIButton :type="destructive ? 'danger' : 'submit'" @click="confirmed" :text="okButton"/>
        </template>
    </Modal>
</template>

<script setup>
	import {ref} from 'vue'
    import Modal from '@/components/UI/Modal.vue'

    const props = defineProps({
        destructive : {
			type:Boolean,
			default:false
		}
	})

	const isModalOpen = ref(false);

	const title = ref('')
    const message = ref('')
    const submessage = ref('')
    const okButton = ref('')
    const cancelButton = ref('')
    const resolvePromise = ref(null)
    const rejectPromise = ref(null)

    const show = (opts = {}) => {
        
        title.value = opts.title
        message.value = opts.message
        submessage.value = opts.submessage
        okButton.value = opts.okButton
        if (opts.cancelButton) {
            cancelButton.value = opts.cancelButton
        }
        
        isModalOpen.value = true
        
        return new Promise((resolve, reject) => {
            resolvePromise.value = resolve
            rejectPromise.value = reject
        })
    }

    const confirmed = () => {
        isModalOpen.value = false
        resolvePromise.value(true)
    }

    const cancelled = () => {
        isModalOpen.value = false
        resolvePromise.value(false)
        // Or you can throw an error
        // this.rejectPromise(new Error('User cancelled the dialogue'))
    }

    defineExpose({show})
</script>

<style scoped>
	.input-title {
		@apply font-bold
	}
</style>