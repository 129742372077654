<script setup>
    import { ref,computed,watch} from 'vue'
    const props = defineProps({
        label: String,
        image: String,
        inputValue: [String,Number],
        checked:Boolean,
        disabled:{
            type:Boolean,
            default:false
        },
        interactionEnabled:{
            type:Boolean,
            default:true
        },
        fontSize: {
            type: String,
            default: 'small'
        },
    })
    const emit = defineEmits(['change','update:modelValue'])
   

    watch(() => props.checked, data => {
      
    },{ immediate: true,deep:true })

    watch(() => props.disabled, data => {
       
    },{ immediate: true,deep:true })
    
    const hasFocus = ref(false)
    
    const onchange = e => {
        emit('change',{
            value : isNaN(e.target.value) ? e.target.value : parseFloat(e.target.value),
            checked : e.target.checked
        })
        emit('update:modelValue',{
            value : isNaN(e.target.value) ? e.target.value : parseFloat(e.target.value),
            checked : e.target.checked
        })
    }
</script>

<template>
    <div class="checkbox" @click.stop>
        <label class="checkbox_label w-full duration-300 transition" :class="{'disabled' : disabled,'pointer-events-none select-none': !interactionEnabled }">
            <span class="checkbox_inner">
                <input type="checkbox" class="sr-only checkbox_input" :value="inputValue" @focus="hasFocus = true" @blur="hasFocus = false" @change="onchange" :checked="checked" :disabled="disabled">
                <span class="checkbox_indicator"  :class="{'opacity-50' : disabled }">
                    <span class="checkbox_icon checkbox_check">
                        <svg role="presentation" viewBox="0 0 15 15.343" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.013 8.11c0-.223.078-.412.234-.568l1.14-1.14c.155-.155.345-.233.568-.233s.413.077.57.233l2.46 2.47 5.492-5.5c.156-.156.346-.234.568-.234.224 0 .413.077.57.233l1.138 1.14c.156.155.234.345.234.568 0 .224-.078.414-.234.57l-6.06 6.06-1.14 1.14c-.155.155-.345.233-.568.233s-.413-.078-.57-.234l-1.138-1.14-3.03-3.03c-.156-.156-.234-.346-.234-.57z"></path>
                        </svg>
                    </span>
                    <span class="checkbox_icon checkbox_dash">
                        <svg role="presentation" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path d="M 2 6 L12 6 L12 9 L2 9 L2 6 z"></path>
                        </svg>
                    </span>
                </span>
                <span class="ml-3 whitespace-nowrap flex items-center truncate"
                    :class="{
                        'text-sm': fontSize == 'small',
                    }"
                >
                    <div class="w-6 rounded-full overflow-hidden mx-2" v-if="image">
                        <img :src="image">
                    </div>
                    <span :class="[hasFocus ? 'text-black':'']" class="truncate" v-html="label"></span>
                </span>
            </span>
        </label>
    </div>
</template>

<style scoped>
    .checkbox {
        align-items: center;
        display: flex;
        transition: all .15s ease-out;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 1rem;
        /*min-height: 40px;*/
        position: relative;
    }
    .checkbox_inner {
        align-items: center;
        display: flex;
    }
    .checkbox_label {
        cursor: pointer;
        max-width: 100%;
    }
    .checkbox_label:hover:not(.disabled)  {
        color: #000;
    }
    .checkbox_label:hover:not(.disabled) .checkbox_indicator {
        background-color: #eaf0f6;
    }
    .checkbox_input:focus+.checkbox_indicator {
        background-color: #eaf0f6;
    }
    
    .checkbox_input:checked+.checkbox_indicator  {
        border-color: #00a4bd;
    }
    .checkbox_input:checked+.checkbox_indicator .checkbox_check {
        fill: #00a4bd;
        opacity: 1;
    }
    .checkbox_icon {
        align-items: center;
        display: flex;
        justify-content: center;
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    .checkbox_icon>svg {
        
        width: 94%;
    }
    .checkbox_indicator {
        flex-shrink: 0;
        border-radius: .1875rem;
        transition: all .15s ease-out;
        background-color: #fff;
        border: 2px solid #cbd6e2;
        color: #cbd6e2;
        height: 1.25em;
        left: .0625em;
        position: relative;
        width: 1.25em;
    }
    .checkbox_text {
        font-size: 1rem;
        line-height: normal;
        max-width: 100%;
        padding-left: .75em;
        position: relative;
        top: .0625em;
    }
</style>