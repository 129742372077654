<template>
    <div>
        <UILabel :label="label" :rand_id="rand_id" :error="error"/>
        <div v-if="description" class="text-sm mb-3">{{ description }}</div>
        <div :class="{
                'w-full focus:outline-none focus:ring-calypso py-2' : display == 'box',
                '' : display == 'inline',
                'flex flex-wrap gap-2' : direction == 'horizontal'
            }">
            <UIRadio :fontSize="fontSize" :disabled="disabled" v-for="option,k in options" :key="k" :option="option" :name="rand_id" :modelValue="modelValue" @update:modelValue="update_value" :class="direction == 'horizontal' ? '':'mb-2 block'"/>
        </div>
        <div class="validation_error" v-if="error">{{errors[0].$message}}</div>
    </div>
</template>

<script setup>
    import {computed} from 'vue'

    const props = defineProps({
        modelValue:[String,Number],
        options:Object,
        label:String,
        errors : Array,
        direction:String,
        description:String,
        display : {
            type : String,
            default : 'inline'
        },
        disabled:{
            type:Boolean,
            default:false
        },
        fontSize: {
            type: String,
            default: 'normal'
        }
    })
    
    const error = computed(() => props.errors && props.errors.length > 0)
    const rand_id = computed(()=>'radio_' + (Math.floor(Math.random() * 1000000000)))

    const emit = defineEmits(['update:modelValue'])

    const update_value = value => {
        emit('update:modelValue', value)
    }

    

</script>