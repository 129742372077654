<script setup>
    import {computed, ref} from 'vue'
    import draggable from 'vuedraggable'
    import { useStore } from 'vuex'

    const store = useStore()
    const search_query = ref()
    const template_groups = computed(() => store.state.content.form_field_templates )
    
    const filtered_fields = computed(() => {
        if(!search_query.value) return template_groups.value

        const searchTerm = search_query.value.toLowerCase();

        return template_groups.value
            .map(group => {
                const filteredGroupFields = group.fields.filter(field => {
                    return (
                    field.name.toLowerCase().includes(searchTerm) ||
                    field.type.toLowerCase().includes(searchTerm) ||
                    (field.group_name && field.group_name.toLowerCase().includes(searchTerm))
                    );
                });
                
                if (filteredGroupFields.length > 0) {
                    return {
                    ...group,
                    fields: filteredGroupFields
                    };
                }
            })
            .filter(group => group);
        
    })
    
</script>

<template>
    <div class="text-xl font-light mb-5 uppercase px-4">Yeni Alan Ekle</div>
    <div class="px-4">
        <UIInput type="search" v-model="search_query" placeholder="Arama yap..." />
    </div>
    <template v-for="group in filtered_fields">
        <div class="flex justify-between w-full px-4 mt-2 text-sm font-bold text-left">{{ group.name }}</div>
        <draggable :sort="false" class="grid xl:grid-cols-6 grid-cols-4 p-4 gap-2" item-key="id" :list="group.fields" :group="{ name: 'form_fields', pull: 'clone', put: false }">
            <template #item="{element}">
                <div class="h-16 text-xs border rounded p-1 hover:bg-gray-100 cursor-pointer flex flex-col items-center bg-white" :data-type="element.type">
                    <div class="h-8 w-full flex items-center justify-center">
                        <i class="my-3" :class="`fa-xl fa-regular fa-${element.icon}`" v-if="element.icon"/>
                    </div>
                    <div class="w-full mt-auto mb-2 px-1 text-center truncate">{{element.name}}</div>
                </div>
            </template>
        </draggable>
    </template>
</template>