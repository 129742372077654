import { createApp } from "vue"
import router from "./router"
import store from "./store"
import Loader from "./Loader.vue"
import helpers from "./plugins/helpers"
import firebaseMessaging from "./plugins/firebase"
import eventIQUI from "./plugins/ui"
import errorTracking from "./plugins/error_tracking"
import "./index.css"
//import "./antd.css"
import i18n from "./plugins/I18n.js"
import VueDriver from './plugins/driver'
import EEDirectives from './plugins/directives'
//import RT from './plugins/realtime'
import mitt from "mitt"
import print from "vue3-print-nb"
//import Antd from "ant-design-vue"
import { registerLicense } from '@syncfusion/ej2-base';
import { plugin, defaultConfig } from '@formkit/vue'
import { VueQueryPlugin } from "@tanstack/vue-query";

//import 'ant-design-vue/dist/antd.css'
//import { fpjsPlugin } from "@fingerprintjs/fingerprintjs-pro-vue-v3"

//import VueVirtualScroller from 'vue-virtual-scroller'
//import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'


//import './registerServiceWorker'

window.mitt = mitt()

let app = createApp(Loader)

errorTracking(app)

registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxcc3RSQ2dYUkB+V0U='); 
// Ngo9BigBOggjHTQxAR8/V1NHaF5cWWdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxccXVWR2dZVU1wWUE= -> second key
// ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Td0ViW35adXdcQ2VY -> first key
// ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5QdEdjUHtWcXxUQGFf -> last key

app
	.use(router)
	.use(store)
	.use(helpers)
	//.use(Antd)
	.use(print)
	//.use(RT)
	//.use(firebaseMessaging)
	.use(eventIQUI)
	.use(EEDirectives)
	.use(i18n)
	.use(VueDriver)
	.use(VueQueryPlugin)
	/*.use(fpjsPlugin, {
		loadOptions: {
			apiKey: "qG7SGny62PZyEKGPumBi",
			region: "eu",
		},
	})*/
	//.use(VueVirtualScroller)
	.use(plugin, defaultConfig)
	.provide("emitter", window.mitt)
	.provide("firebaseMessaging", firebaseMessaging)
	//.provide('RT', new RT())
	.mount("#app")
