import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyAP3vSdoowtjt46T9-YL_njPeeX28cAlqg",
    authDomain: "eventiq-8b3d0.firebaseapp.com",
    projectId: "eventiq-8b3d0",
    storageBucket: "eventiq-8b3d0.appspot.com",
    messagingSenderId: "907522574004",
    appId: "1:907522574004:web:2b7c126f1802671ad5f9e1",
    measurementId: "G-JJMR5WWFQK"
}

const app = initializeApp(firebaseConfig)

export default getMessaging(app)