<template>
    <div class="overflow-auto w-full h-full">
        <div id="org_chart"></div>
    </div>
</template>

<script setup>
    import { computed,onMounted,ref,watch } from 'vue'
    import { useStore } from 'vuex'
    import {GoogleCharts} from 'google-charts'
    import { useRouter } from 'vue-router'
    import API from '@/api'
    
    const dragging_node = ref()
    const team_colors = ['#fee2e2','#ffedd5','#fef3c7','#ecfccb','#99f6e4','#bae6fd','#c7d2fe','#ede9fe','#fce7f3','#f1f5f9','#fee2e2','#ffedd5','#fef3c7','#ecfccb','#99f6e4','#bae6fd','#c7d2fe','#ede9fe','#fce7f3','#f1f5f9']
    const division_colors = ["#DAF7A6","#FFC300","#FF5733","#C70039","#900C3F","#581845"]

    const store = useStore()
    const router = useRouter()
    /*const users = computed(() => store.state.content.users.map( user => {
        return {
            id : user.id,
            parentId : user.reports_to,
            name : user.full_name,
            team_id : user.team_id,
            area: "Corporate",
            imageUrl: user.profile_picture_full,
            isLoggedUser: "false",
            office: "CTO office",
            positionName: "Chief Executive Officer ",
            profileUrl: "http://example.com/employee/profile",
            size: "",
            tags: "Ceo,tag1,manager,cto"
        }
    }))*/
    
    const user_id = ref(null)
    const division_nodes = computed(()=>document.querySelectorAll(["data-division-id"]))

    watch(()=>division_nodes.value, data => console.log(data))

    onMounted(()=> {
        GoogleCharts.load(drawChart,{packages:["orgchart"]})
    })
    
    const users = computed(() => store.state.content.users)
    const teams = computed(() => store.state.content.teams)
    const divisions = computed(() => store.state.content.divisions)

    const open_user_modal = code => {
        router.push({name: 'AdminUserProfile', params: { code: code }})
    }

    
    const drawChart = () => {
        
        var row = [], chart, chart_data = new google.visualization.DataTable()
        chart_data.addColumn('string', 'Name')
        chart_data.addColumn('string', 'Manager')

        users.value.forEach((user) => {
            var parentid = user.reports_to == 0 || !user.reports_to ? '' : 'user_'+user.reports_to    
            row.push([
                {
                    v:'user_'+user.id, 
                    f:'<span class="pointer-events-none font-bold user_id" data-division-id="'+user.division_id+'" data-team-id="'+(user.team ? user.team.id : '')+'" data-user-id="'+user.id+'">'+user.full_name+' '+(user.deleted_at?'<small class="pointer-events-none text-muted block">(Silinmiş)</small>':'')+'</span><small class="pointer-events-none block text-xs opacity-70">'+(user.position ? user.position.name : '')+'</small>'+(user.team ? '<span class="pointer-events-none block text-xs opacity-60">'+user.team.name+'</span>':'')
                }, 
                parentid
                ])
        })
        chart_data.addRows(row)
        chart_data.setRowProperty(4, 'style', 'outline: 3px solid green');
        chart = new GoogleCharts.api.visualization.OrgChart(document.getElementById('org_chart'))
            
        GoogleCharts.api.visualization.events.addListener(chart, 'ready', function() {
            let div_array = []
            Array.from(document.querySelectorAll('.node')).forEach( node => {
                const user_id_wrapper = node.querySelector('.user_id')
                node.dataset.userId = user_id_wrapper.dataset.userId
                node.style.backgroundColor = team_colors[user_id_wrapper.dataset.teamId]
                node.style.outline = '2px solid ' + division_colors[divisions.value.findIndex(x=>x.id == user_id_wrapper.dataset.divisionId)]
                if(user_id_wrapper.dataset.divisionId !== 'null' ) {
                    node.classList.add('division_' + user_id_wrapper.dataset.divisionId)
                    div_array.push(user_id_wrapper.dataset.divisionId)
                }
                div_array = [...new Set(div_array)]
                
                //user_id_wrapper.replaceWith(user_id_wrapper.innerHTML)
                node.draggable = true

                node.ondragenter = e => { 
                    e.target.closest('.node').style.backgroundColor = 'blue'
                }
                node.ondragleave = e => { 
                    e.target.closest('.node').style.backgroundColor = 'white'
                }
                node.ondragover = e => {
                    e.preventDefault()
                    e.target.closest('.node').style.backgroundColor = 'blue'
                }
                node.ondragstart = e => {
                    dragging_node.value = e.target.dataset.userId
                }
                node.ondrop = e => {
                    e.preventDefault()
                    e.stopPropagation()
                    e.target.closest('.node').style.backgroundColor = 'white'
                    const who = dragging_node.value
                    const to = e.target.dataset.userId || e.target.closest('.node').dataset.userId
                    
                    if(who != to) {
                        update_reports_to(who,to)
                    }
                }
                node.onclick = e => {
                    console.log(e.target.dataset.userId)
                    open_user_modal(e.target.dataset.userId)
                }
            })
            console.log(div_array)
        })


        chart.draw(chart_data, {
            allowHtml:true,
            nodeClass: 'node',
            selectedNodeClass: 'node1'
        })
    }
         
    const update_reports_to = (who,to) => {
        API.put('users/' + who + '', {reports_to:parseInt(to)}).then(response => {
            if(response.data.status == 'success') {
                store.commit('content/updateUser',{user:response.data.user, id:response.data.user.id})
                drawChart()
            } else {
                
            }
        })
    }
    
</script>
     
<style>
    #org_chart {
        height: 500px;
        max-width: 100%;
    }
    .node { 
        font-size: 15px; 
        background-color: #fff; 
        /*border: 1px solid #e9ebec;*/
        border-radius: 5px;
        position: relative;
        padding: 5px;
        min-width: 100px;
        white-space: nowrap;
    }
    .node1 { 
        font-size: 15px; 
        background-color: #fff; 
        position: relative;
    }
</style>