const state = {
    presence_data: {},
    online_users:[]
}

// getters
const getters = {
    online_users (state) {
        return state.online_users;
    }
}
  
// actions
const actions = {
    
}
  
// mutations
const mutations = {
    setOnlineUsers(state, data) {
        state.presence_data = data
        state.online_users = Object.values(data.members).map(member => {
            return {
                id : member.id,
                name : member.name,
                profile_picture : member.profile_picture
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}