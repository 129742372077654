<template>
    <label class="cursor-pointer mr-2">
        <span class="flex" :class="{
            'items-start' : option.subtext,
            'items-center' : !option.subtext,
            'pointer-events-none select-none': !interactionEnabled
        }">
            <input class="hidden" :disabled="disabled" :name="props.name" type="radio" value="" @click="onClick">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="w-5 h-5" :class=" isChecked ? 'checked' : '', option.subtext ? 'mt-1':'' ">
                    <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
                        <circle cx="10" cy="10" r="10" class="radio_circle" stroke-width="1.5"></circle>
                        <circle class="radio_dot" id="a" cx="10" cy="10" r="5"></circle>
                    </g>
                </svg>
            </span>
            <span class="ml-2"
                :class="{
                    'text-sm': fontSize == 'small',
                }"
            >
                {{ option.text }}
                <div v-if="option.subtext" class="text-xs text-gray-500">{{ option.subtext }}</div>
            </span>
        </span>
    </label>
</template>

<script setup>
    import {ref,watch} from 'vue'

    const props = defineProps({
        modelValue: [String,Number],
        option:Object,
        name:String,
        disabled:{
            type:Boolean,
            default:false
        },
        fontSize: {
            type: String,
            default: 'normal'
        },
        interactionEnabled:{
            type:Boolean,
            default:true
        },
    })

    const emit = defineEmits(['update:modelValue'])
    const isChecked = ref(false)

    watch(
        () => props.modelValue,
        (new_val) => {
            isChecked.value = new_val == props.option.value ? true : false
        },
        { immediate: true }
    )

    const onClick = e => {
        emit('update:modelValue', props.option.value)
    }

</script>

<style scoped>
    svg.checked .radio_circle  {
        stroke: #00a4bd;
    }
    svg.checked .radio_dot  {
        fill: #00a4bd;
    }
    .radio_circle {
        stroke: #cbd6e2;
        stroke-width: 2px;
    }
    label:hover .radio_circle {
        fill: #eaf0f6;
    }
</style>