<script setup>
    import { computed, ref, watch, onBeforeMount, onMounted } from "vue";
    import API from "@/api";
    import { useStore } from "vuex";
    import _ from "lodash";

    const store = useStore();

    const active_field = computed(() => store.state.form.active_field);
    const field = ref(_.cloneDeep(active_field.value));
    const options = ref([
        { value: "country", text: "Ülke" },
        { value: "city", text: "İl" },
        { value: "town", text: "İlçe" },
        { value: "postcode", text: "Posta Kodu" },
    ]);
    const countries = computed(
        () => store.getters["content/countries_select_with_all"]
    );
    console.log(field.value);

    const save = () => {
        API.saveFormField(field.value.id, field.value)
            .then((response) => {})
            .catch((error) => {
                store.dispatch("floating_alerts/addAlert", {
                    type: "danger",
                    title: "HATA!",
                    description: error.response.data.message,
                });
            });
    };
</script>

<template>
    <UISelect
        label="Ülke"
        title="Ülke Seç"
        display="box"
        :source="countries"
        class="mb-5"
        help_text="asdaasdaasd"
        v-model="field.props.country"
    />
    <UICheckboxGroup
        fontSize="normal"
        :options="options"
        label="Hangi alanlar gösterilsin?"
        v-model="field.props.fields"
    />
    <UIButton type="submit" text="Kaydet" class="mt-3" @click="save" />
</template>
