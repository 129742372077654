<script setup>
    import {ref,watch,computed,inject} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
    import FormModal from "@/modals/Form.vue"
    import SectionsDraggable from '@/views/form_builder/SectionsDraggable.vue'
    import NewFieldList from './NewFieldList.vue'
    import FieldDetail from './FieldDetail.vue'
    import SectionDetail from './SectionDetail.vue'
    import _ from 'lodash'

    const props = defineProps({
        form_data:{
            type:[Array,Object],
            default:{sections:[]}
        }
    })

    const form_data = ref(_.cloneDeep(props.form_data))
    
    const store = useStore()
    const emitter = inject('emitter')
    const data_to_update = ref()
    const isSubmitting = ref(false)
    const isAddSSectionModalOpen = ref(false)
    const side_column_display = ref('field_list')
    const active_field = ref({})
    const active_field_id = ref(null)
    const active_section = ref({})
    
    const submit = data => {
        isSubmitting.value = true
        API.post('form/' + props.form_data.code + '/section',data).then(response => {
            store.dispatch('form/loadData', props.form_data.code )
            isSubmitting.value = false
            isAddSSectionModalOpen.value = false
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
                title:'BAŞARILI!',
				description:response.data.message
			})
        }).catch( error => {
            isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
      })
    }

    const form_sections = ref([])
    //watch(() => props.form_data, data => form_sections.value = data.sections,{ immediate: true,deep:true })
    watch(() => props.form_data, data => {
        //console.log(data)
        form_data.value = _.cloneDeep(props.form_data)
    },{ immediate: true,deep:true })
    
    const show_form_field_detail = id => {
        side_column_display.value = 'field_detail'
        active_field_id.value = id
        return
        
        API.getFormFieldData(id).then(response=>{
            side_column_display.value = 'field_detail'
            active_field.value = response.data
        })
    }
    const show_form_section_detail = id => {
        side_column_display.value = 'section_detail'
        active_section.value = store.getters['form/getSection'](id)
    }

    emitter.on('show_form_field_detail', show_form_field_detail)
    emitter.on('show_form_section_detail', show_form_section_detail)

    const hide_field_detail = () => {
        side_column_display.value = 'field_list'
        active_field.value = {}
    }

    const hide_section_detail = () => {
        side_column_display.value = 'field_list'
        active_section.value = {}
    }

    const modalProps = computed(() => {
		return {
			title: "Bölüm Ekle",
			cols: 1,
            schema: [
				{
					id: "name",
					component: "UIInput",
					label: "Bölüm Başlığı",
                    required: false,
                    props: {
                        placeholder: ''
                    }
				},
			],
		}
	})
    
</script>

<template>
    <div class="flex gap-x-5 w-full mx-auto my-5">
        <div class="w-1/2 p-4 shadow-iq bg-slate-50 border rounded-iq mb-10 ml-10 h-fit">
            <div class="flex items-start">
                <div class="text-xl font-light mb-5 uppercase">Form Alanları</div>
                <UIButton size="sm" type="dark" text="Bölüm Ekle" @click="isAddSSectionModalOpen = true" class="ml-auto"/>
            </div>
            <UIEmptyState class="mt-10" v-if="!form_data.sections || form_data.sections.length == 0" 
                title="Henüz hiç form bölümü eklememişsin!" 
                description="Formuna alanlar eklemek için önce bölüm eklemelisin."
            />
            <SectionsDraggable v-if="form_data.sections && form_data.sections.length > 0"  key="form_sections" :active_field="active_field" :form_data="form_data"/>
            
            <div class="mb-10"></div>
        </div>
        <div class="w-1/2 sticky top-0 h-full">
            <template v-if="!form_data.sections || form_data.sections.length > 0">
                <SectionDetail :section="active_section" v-if="side_column_display == 'section_detail'" @hide="hide_section_detail"/>
                <FieldDetail :field_id="active_field_id" v-if="side_column_display == 'field_detail'" @hide="hide_field_detail"/>
                <NewFieldList v-if="side_column_display == 'field_list'"/>
            </template>
        </div>
    </div>
    <FormModal
        v-if="modalProps"
        v-model="data_to_update"
        :isSubmitting="isSubmitting"
        :modalProps="modalProps"
        :isOpen="isAddSSectionModalOpen"
        @submit="submit"
        @modal_closed="isAddSSectionModalOpen = false"
    />
</template>


<style scoped>
    .button {
        margin-top: 35px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
    }
    .list-group {
    min-height: 20px;
    }
    .list-group-item {
    cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
    .dragArea {
        min-height: 50px;
        outline: 1px dashed;
    }
</style>