<template>
    <Modal 
        :close_on_outside_click=false :isOpen="isModalOpen" 
        @modal_closed="modal_closed" 
        @submit="submit"
        :title="'Not Ekle'"
        :is_submitting="is_submitting"
        :action_button_text="note_id ? 'Güncelle' : 'Ekle'"
        :backdrop="false"
        size="xl"
        :colored_header="true"
        :ready2submit="ready2submit"
        position="bottom-right"
        :is_draggable="false"
    >
        <template v-slot:modal_content>
            <UIAlert :titleText="submit_error.title" type="danger" v-if="submit_error.title" class="mt-2 flex-row">
                <p>{{ submit_error.description }}</p>
            </UIAlert>
            <div class="py-3 border-b mb-3">
                Bu notu <b>{{ note_for_name }}</b> için yazıyorsun.
            </div>
            <UIFormRow>
                <UIInput v-model="note_title" :styled="false" placeholder="Başlık..."/>
            </UIFormRow>
            <UIFormRow>
                <ckeditor :editor="editor" v-model="note_content" :config="editorConfig"></ckeditor>
                
            </UIFormRow>
        </template>
        <template v-slot:modal_footer>
            
        </template>
    </Modal>
</template>

<script setup>
	import {ref,watch,computed,inject} from 'vue'
    import { useStore } from 'vuex'
    import Modal from '@/components/UI/Modal.vue'
    import API from '@/api'
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    //import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";

    const emitter = inject('emitter')

    const props = defineProps({
        isOpen : Boolean,
        note_id : Number,
        note_type : String,
        note_for : [String,Number],
        note_for_name : String,
    })
    
    const store = useStore()
    const emit = defineEmits(['modal_closed'])

    const ckeditor = CKEditor.component

    const is_submitting = ref(false)
    const submit_error = ref({
        title:null,
        description:null
    })

    const editor = ref(ClassicEditor)
    const editorConfig = ref({
        placeholder: "Notunu buraya yazabilirsin",
        toolbar: ['bold', 'italic','|']
    })
    
    const note_title = ref(null)
    const note_content = ref('')
    
    const ready2submit = computed(() => note_content.value && note_title.value ? true : false )

	let isModalOpen = ref(props.isOpen);

	watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    const modal_closed = () =>{
		isModalOpen.value = false
		emit('modal_closed')
	}

    const submit = () => {
        is_submitting.value = true

        if(props.note_id) {
            API.put('notes/' + props.note_id ,{
                title       : task_title.value,
                description : task_desc.value,
                deadline    : task_deadline.value,
                call_direction      : call_direction.value,
                responsible : task_responsible.value,
                project_id  : task_project.value
            }).then(response => {
                modal_closed()
                emitter.emit('load_tasks')
                store.dispatch('floating_alerts/addAlert', {
                    type:'tip',
                    title:'BAŞARILI!',
                    description:'Not güncellendi!'
                })
            })
            .catch((error) => {
                if (error.response) {
                    
                    submit_error.value = {
                        title:error.response.data.message,
                        description:error.response.data.errors.description[0]
                    }
                    is_submitting.value = false

                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            })
        } else {
            API.post('notes', {
                note_title       : note_title.value,
                note_content : note_content.value,
                note_for    : props.note_for,
                note_type      : props.note_type
            }).then(response => {
                modal_closed()
                emitter.emit('load_notes')
                
                if(props.note_type=='C')
                    emitter.emit('load_client_notes')

                if(props.note_type=='S')
                    emitter.emit('load_supplier_notes')

                store.dispatch('floating_alerts/addAlert', {
                    type:'tip',
                    title:'BAŞARILI!',
                    description:'Not eklendi!'
                })
            })
            .catch((error) => {
                if (error.response) {
                    
                    submit_error.value = {
                        title:error.response.data.message,
                        description:error.response.data.errors.description[0]
                    }
                    is_submitting.value = false

                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                
            })
        }
    }
    
</script>

<style scoped>
    :global(.ck-editor__editable) {
        min-height: 300px;
    }
</style>