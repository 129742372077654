<script setup>
    import { ref, computed, onMounted, watch } from 'vue'
    import { TabPanel } from '@headlessui/vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'
    import API from '@/api'
    import useFileDialog from '@/composables/useFileDialog'
    import TableView from '@/views/TableView.vue'

    const store = useStore()
    const route = useRoute()

    const isSubmitting = ref(false)
    const submit_error = ref(false)

    const logo_uploader = useFileDialog()
    const square_logo_uploader  = useFileDialog()
    const email_logo_uploader = useFileDialog()

    const tab_titles = ref(["Firma Bilgileri","Finansal Seçenekler","Dil Seçenekleri","Logolar","Kredi Kartları"])

    const event_company = ref(store.state.auth.event_company)
    const event_company_data = ref({
        currency:event_company.value.currency,
        language:event_company.value.language,
    })
    const cities = ref([])
    const countries = computed(() => store.state.content.countries.map( country => { return {value : country.code, text : country.name, has_states : country.has_states} }).sort( (a, b) => a.text.localeCompare(b.text) ))
    const currencies_select = computed(() => store.getters['content/currencies_select'])
    const timezones = computed(() => store.getters['content/timezones_select'])
    const languages = computed(() => store.getters['content/languages_select'])
    const fin_fee_types = ref([
        {value:"PERCENTAGE",text: 'Yüzde'},
        {value:"FIXED",text:'Fiks'}
    ])
    
    onMounted(() => {
        load_cities(event_company.value.country)
    })

    const load_cities = (data) => {
        API.get('definition/cities/' + data).then(response => {
            cities.value = response.data.map(x=>{
                return {text:x.name, value:x.name}
            })
        })
    }

    watch(() => event_company.value.country, data => {
            if(!data) return
            cities.value = []
			load_cities(data)
		}
    )

    const submit = async () => {
       
        event_company.value.currency=event_company_data.value.currency
        event_company.value.language=event_company_data.value.language

        isSubmitting.value = true
        API.put('event-companies/' + event_company.value.id,event_company.value)
        .then(response => {
            isSubmitting.value = false
            store.commit('auth/setEventCompany',response.data.event_company)
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                description:response.data.message,
                timeout:1500
            })
        }).catch(error=>{
            submit_error.value=error.response.data.message
            isSubmitting.value = false
        })
    }

    watch(()=>logo_uploader.files.value, data => {
        
        var reader = new FileReader()
        var f = data[0];
        reader.onload = e => {
            API.post('event-companies/' + event_company.value.id + '/logo', {file:e.target.result})
            .then( response => {
            
                store.commit('auth/setEventCompany',response.data.event_company)
                event_company.value = store.state.auth.event_company

                store.dispatch('floating_alerts/addAlert', {
                    type:'tip',
                    title:'BAŞARILI!',
                    description:response.data.message
                })
            })
            .catch( error => {
                console.log(error);
                store.dispatch('floating_alerts/addAlert', {
                    type:'danger',
                    title:'HATA!',
                    description:error.response.data.message
                })
            })
        }
        reader.readAsDataURL(f)
    })

    watch(()=>square_logo_uploader.files.value, data => {
        
        var reader = new FileReader()
        var f = data[0];
        reader.onload = e => {
            API.post('event-companies/' + event_company.value.id + '/square-logo', {file:e.target.result})
            .then( response => {
            
                store.commit('auth/setEventCompany',response.data.event_company)
                event_company.value = store.state.auth.event_company

                store.dispatch('floating_alerts/addAlert', {
                    type:'tip',
                    title:'BAŞARILI!',
                    description:response.data.message
                })
            })
            .catch( error => {
                console.log(error);
                store.dispatch('floating_alerts/addAlert', {
                    type:'danger',
                    title:'HATA!',
                    description:error.response.data.message
                })
            })
        }
        reader.readAsDataURL(f)
    })

    watch(()=>email_logo_uploader.files.value, data => {
        
        var reader = new FileReader()
        var f = data[0];
        reader.onload = e => {
            API.post('event-companies/' + event_company.value.id + '/email-logo', {file:e.target.result})
            .then( response => {
            
                store.commit('auth/setEventCompany',response.data.event_company)
                event_company.value = store.state.auth.event_company
                
                store.dispatch('floating_alerts/addAlert', {
                    type:'tip',
                    title:'BAŞARILI!',
                    description:response.data.message
                })
            })
            .catch( error => {
                console.log(error);
                store.dispatch('floating_alerts/addAlert', {
                    type:'danger',
                    title:'HATA!',
                    description:error.response.data.message
                })
            })
        }
        reader.readAsDataURL(f)
    })

    let cc_table_titles = [
		
		{	
			title:'Kart Adı',
			id:'name',
		},
		{
			title:'',
			type:'action'
		}
	]

    const cc_empty_state = {
        title:'Henüz hiç kredi kartı eklenmemiş',
        description:'Şirket kredi kartlarını ekleyerek özellikle masrafları daha kolay takip edebilirsiniz.'
    }

    const modalProps = computed(()=>{
        return {
            cols:1,
            schema: [
                {
                    id: 'name',
                    component: 'UIInput',
                    label: 'Kredi Kartı Adı',
                    required: true,
                }
            ]
        }
    })

</script>

<template>
    <UITabs :titles="tab_titles" class="w-full px-2 pb-16 sm:px-0">
        <TabPanel key="general" class="p-4">
            <div class="w-2/3">
                <div class="font-black text-lg mb-2">Firma Bilgileri</div>
                <p class="mb-2">Burada yapacağın değişklikler tüm şirket için geçerli olacak olup, herşeyi ve herkesi etkileyecektir.</p>
                <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 mb-2 flex-row">
                    <p>{{ submit_error }}</p>
                </UIAlert>

                <UIFormRow>
                    <UIInput label="Firma Adı/Marka" v-model="event_company.name"/>
                </UIFormRow>
                <UIFormRow>
                    <UIInput label="Firma Yasal Ünvanı" v-model="event_company.name_legal"/>
                </UIFormRow>
                <UIFormRow>
                    <UIInput label="Uygulama Adı" v-model="event_company.app_name"/>
                </UIFormRow>
                <UIFormRow>
                    <UIInput label="Firma Alan Adı" v-model="event_company.domain"/>
                </UIFormRow>
                <UIFormRow>
                    <UIInput label="Firma Lcv Domain" v-model="event_company.lcv_domain"/>
                </UIFormRow>
                <UIFormRow>
                    <UIInput label="Adres" v-model="event_company.address"/>
                </UIFormRow>
                <UIFormRow>
                    <UIInput label="Posta Kodu" v-model="event_company.postcode"/>
                </UIFormRow>
                <UIFormRow>
                    <UISelectAutoComplete :source="countries" label="Ülke" title="Ülke" display="box" v-model="event_company.country"/>
                </UIFormRow>
                <UIFormRow v-if="countries.find(x=>x.value == event_company.country).has_states">
                    <UISelectAutoComplete :source="cities" label="Eyalet" title="Eyalet" display="box" v-model="client.state"/>
                </UIFormRow>
                <UIFormRow>
                    <UISelectAutoComplete :source="cities" label="Şehir" title="Şehir" display="box" v-model="event_company.city" v-if="cities.length > 0"/>
                    <UIInput label="Şehir" v-model="event_company.city" v-if="cities.length == 0"/>
                </UIFormRow>
                <UIFormRow>
                    <UISelect :source="timezones" title="Saat Dilimi Seç" label="Saat Dilimi" display="box" v-model="event_company.timezone"/>
                </UIFormRow>

                <UIFormRow>
                    <UISelect :source="[{value:'SCHEMA','text':'Şemaya göre'},{value:'ALL','text':'Herkes'}]" title="Yeni projede sorumlu kişiler" label="Sorumlu kişiler" display="box" v-model="event_company.project_creation_responsible_assignment"/>
                </UIFormRow>
                
                <UIFormRow>
                    <UIButton :disabled="isSubmitting" :loading="isSubmitting" type="submit" text="Kaydet" @click="submit"/>
                </UIFormRow>
            </div>
        </TabPanel>
        <TabPanel key="financial" class="p-4">
            <UIFormRow>
                <UIInput type="number" :step="0.01" label="Proje Kapatma Yuvarlama Miktarı" v-model="event_company.project_closure_tolerance"/>
            </UIFormRow>
            <UIFormRow>
                <UIRadioGroup :options="currencies_select" label="Yeni Bütçeler İçin Varsayılan Para Birimi?"  v-model="event_company_data.currency" />
            </UIFormRow>
            
            <UIFormRow class="flex space-x-2">
                <UIInput class="w-1/2" label="Son Ödeme Günü" v-model="event_company.fin_due_days"/>
                <UIInput class="w-1/2" label="Ön Ödeme Oranı" v-model="event_company.fin_prepayment_rate"/>
            </UIFormRow>

            <UIFormRow class="flex space-x-2">
                <UISelect class="w-1/2"  :source="fin_fee_types" title="Ücret Tipi" label="Saat Dilimi" display="box" v-model="event_company.fin_fee_type"/>
                <UIInput class="w-1/2" type="number" :step="0.01" label="Ödeme Miktarı" v-model="event_company.fin_fee_amount"/>
            </UIFormRow>

            <UIFormRow class="flex space-x-2">
                <UIInput class="w-1/2" type="number" :step="0.01" label="Beklenen Kar Oranı" prepend="%" v-model="event_company.fin_expected_profit_ratio"/>
                <UIInput class="w-1/2" type="number" :step="0.001" label="Hizmet Bedeli Kdv Oranı" prepend="%" v-model="event_company.service_fee_vat_rate"/>
            </UIFormRow>

            <UIFormRow>
                <UIButton :disabled="isSubmitting" :loading="isSubmitting" type="submit" text="Kaydet" @click="submit"/>
            </UIFormRow>
        </TabPanel>
        <TabPanel key="language" class="p-4">
            <UIRadioGroup :options="languages" label="Yeni Kullanıcılar İçin Varsayılan Dil?"  v-model="event_company_data.language" />
            <UIFormRow>
                <UIButton :disabled="isSubmitting" :loading="isSubmitting" type="submit" text="Kaydet" @click="submit"/>
            </UIFormRow>
        </TabPanel>
        
        <TabPanel key="logos" class="p-4">
            <div class="flex space-x-2">
                <div class="w-1/2">
                    <UICard title="Şirket Logosu">
                        <UIFormRow>
                            <UIButton type="white" class="border mr-auto" text="Logo yükle" @click="logo_uploader.open"/>
                        </UIFormRow>
                        <img class="h-24" :src="event_company.logo"/>
                    </UICard>
                    
                </div>

                <div class="w-1/2">
                    <UICard title="Şirket Kare Logo">
                        <UIFormRow>
                            <UIButton type="white" class="border mr-auto" text="Kare Logo yükle" @click="square_logo_uploader.open"/>
                        </UIFormRow>
                        <img class="h-24" :src="event_company.square_logo"/>
                    </UICard>
                    
                </div>

                <div class="w-1/2">
                    <UICard title="Şirket Email Logo">
                        <UIFormRow>
                            <UIButton type="white" class="border mr-auto" text="Email Logo yükle" @click="email_logo_uploader.open"/>
                        </UIFormRow>
                        <img class="h-24" :src="event_company.email_logo"/>
                    </UICard>
                    
                </div>
            </div>
        </TabPanel>

        <TabPanel key="credit_cards" class="p-4">
            <TableView 
                title="Şirket Kredi Kartları"
                :table_titles="cc_table_titles" 
                :api_url="`event-companies/${event_company.id}/credit-cards`" 
                :empty_state="cc_empty_state"
                :modalProps="modalProps"
                :add_new="true"
                @add_new_closed="add_new = false"
                :add_new_titles="['Kart Güncelle', 'Kart Ekle']"
            />

        </TabPanel>
        
    </UITabs>
</template>