<script setup>
    import { computed, ref, watch, onBeforeMount, onMounted } from "vue"
    import API from "@/api"
    import { useStore } from "vuex"
    import _ from "lodash"
    import { useDropZone } from '@vueuse/core'

    const store = useStore();

    const active_field = computed(() => store.state.form.active_field);
    const field = ref(_.cloneDeep(active_field.value));
    if(!field.value.props.source) {
        field.value.props.source = 'url'
    }

    const isSubmitting = ref(false)
    const file_upload_field = ref()
    const temp_file = ref()
    const dropZoneRef = ref(null)
	
    const upload_types = ref([
        {value:'file',text:'Bilgisayarımdan yükle'},
        {value:'link',text:'Link olarak ekle'}
    ])
    let fileHandles;
    const options = {
        types: [
            {
                description: "PDF",
                accept: {
                    "application/*": [".pdf"],
                },
            },
        ],
    };

    const readFile = () => {
        var reader = new FileReader()
        var f = file_upload_field.value.files[0];
        reader.onload = e => {
            temp_file.value = e.target.result
        }
        reader.readAsDataURL(f)
    }

    const onDrop = files2upload => {
        console.log(files2upload[0])
        temp_file.value = files2upload[0]
    }
    
    const { isOverDropZone } = useDropZone(dropZoneRef, onDrop)

    const onUploadProgress = progressEvent => {
        //progress.value = progressEvent.loaded / progressEvent.total
    }

    const open = async () => {
        fileHandles = await window.showOpenFilePicker(options);
        fileHandles.map(async (fileHandle) => {
            temp_file.value = await fileHandle.getFile();
        })
    };
    
    const save = () => {
        isSubmitting.value = true
        let formData = new FormData()
        formData.append("file", temp_file.value)

        API.post(`form/field/${field.value.id}/upload`, formData).then(res => {
            if (res.status == 200) {
                field.value.props.url = res.data.url

                API.saveFormField(field.value.id, field.value)
                .then((response) => {
                    isSubmitting.value = false
                    temp_file.value = null
                })
                .catch((error) => {
                    store.dispatch("floating_alerts/addAlert", {
                        type: "danger",
                        title: "HATA!",
                        description: error.response.data.message,
                    });
                    isSubmitting.value = false
                });
            }
        })
        .catch(error => {
            store.dispatch('floating_alerts/addAlert', {
                type:'warning',
                title:'HATA!',
                description:error.data.message
            })
            isSubmitting.value = false
        })


        
    };

    const remove_selected_file = (index) => {
        temp_file.value = null
    }

    const update_external_file = (value) => {
        
    }
</script>

<template>
    <UIFormRow class="mb-2">
        <UIRadioGroup :options="upload_types" v-model="field.props.source" label="Dosya ekleme şekli?" direction="horizontal" />
    </UIFormRow>
    <UIFormRow v-if="field.props.source == 'file'">
        <div ref="dropZoneRef" @click="open" 
            class="w-full h-60 hover:bg-blue-50/50 duration-300 border rounded border-dashed border-calypso flex items-center justify-center flex-col"
            :class="{
                'bg-gray-50' : !isOverDropZone,
                'bg-calypso-light' : isOverDropZone,
            }"
        >
                <i class="fa-light fa-3x fa-upload"></i>
                <div class="mt-5 text-center select-none">Dosya seçmek için tıkla veya eklemek istediğin dosyaları buraya sürükleyip bırak</div>
        </div>
        <input 
            ref="file_upload_field"
            type="file" 
            @change="readFile" 
            class="hidden"
        >
    </UIFormRow>
    <UIFormRow v-else>
        <UIInput label="Link URL'si" v-model="field.props.url" placeholder="Eklemek istediğin dosya URL'sini buraya yapıştır..."/>
    </UIFormRow>
    <UIFormRow class="w-full" v-if="temp_file">
        <div class="font-semibold">Eklenecek dosya</div>
        <div class="flex my-2 py-2 border-b text-sm">
            <div class="truncate mr-5">{{temp_file.name}}</div>
            <div class="ml-auto shrink-0">{{ Math.round(temp_file.size/1024,2) + ' KB' }}</div>
            <div class="rounded-full bg-gray-100 hover:bg-gray-200 h-6 w-6 ml-3 flex item-center justify-center shrink-0" @click="remove_selected_file(index)">x</div>
        </div>
    </UIFormRow>
    <UIButton type="submit" text="Kaydet" class="mt-3" @click="save" :loading="isSubmitting" />
</template>
