<script setup>
	import { computed } from 'vue'
	import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

	const props = defineProps({
		titles : Array,
		vertical : {
			type : Boolean,
			default : false
		},
		text_size : String,
		selected_index : Number
	})
	const emit = defineEmits(['change'])

	const selected_index = computed(() => props.selected_index)
	const changeTab = index => emit('change', index)
</script>

<template>
	<div>
		<div class="flex" v-if="vertical">
			<TabGroup :selectedIndex="selected_index" vertical @change="changeTab">
				<TabList class="flex flex-col sticky top-0 self-start shrink-0">
					<Tab
						v-for="category in titles"
						as="template"
						:key="category"
						v-slot="{ selected }"
					>
						<button
							class="text-left hover:bg-teal w-full py-2.5 leading-5 font-medium rounded truncate px-5 transition duration-300 ease-in-out focus:outline-none"
							:class="{
								'bg-gray-50 text-calypso font-bold' : selected,
								'text-gray-500 hover:text-gray-900' : !selected,
								'text-sm' : props.text_size == 'sm'
							}"
						>
						{{ category }}
						</button>
					</Tab>
				</TabList>
				<TabPanels class="grow border-l">
					<slot></slot>
				</TabPanels>
			</TabGroup>
		</div>
		<TabGroup :selectedIndex="selected_index" @change="changeTab" v-if="!vertical">
			<TabList class="flex border-b-2 border-slate-100">
				<Tab
					v-for="category in titles"
					as="template"
					:key="category"
					v-slot="{ selected }"
				>
					<button
						class="py-2.5 leading-5 font-medium border-b-2 border-transparent truncate px-2 focus:outline-none"
						:class="{
							'border-calypso text-calypso xshadow' : selected,
							'text-gray-500 hover:text-gray-900' : !selected,
							'text-sm' : props.text_size == 'sm'
						}"
					>
					{{ category }}
					</button>
				</Tab>
			</TabList>

			<TabPanels>
				<slot></slot>
			</TabPanels>
		</TabGroup>
	</div>
</template>

<style scoped>
	.xshadow {
		/*text-shadow: 0px 0px 1px #00a4bd;*/
		-webkit-text-stroke-width: .6px;
  		-webkit-text-stroke-color: #00a4bd;
	}
	
</style>
