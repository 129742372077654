const state = {
    list: [],
    current_project:null,
    paginator:null,
    cities:[],
    leads:[],
    clients:[],
    users:[],
    filters:{},
    statuses1:[
        {id:0,value:"Proje Oluşturuldu",cat:"Teklif",color:'deep-orange'},
        {id:1,value:"Bütçe Hazırlanacak",cat:"Teklif",color:'deep-orange'},
        {id:2,value:"Sunum Hazırlanacak",cat:"Teklif",color:'deep-orange'},
        {id:3,value:"Bütçe ve Sunum Hazırlanacak",cat:"Teklif",color:'deep-orange'},
        {id:4,value:"Takipte",cat:"Teklif",color:'deep-orange'},
        {id:5,value:"İç Onayda",cat:"Teklif",color:'deep-orange'},
        {id:6,value:"İç Onay Tamamlandı",cat:"Teklif",color:'deep-orange'},
        {id:7,value:"Müşteri Onayında",cat:"Teklif",color:'deep-orange'},
        {id:8,value:"Hazırlık",cat:"Konfirme",color:'green-500'},
        {id:9,value:"Operasyon",cat:"Konfirme",color:'green-500'},
        {id:10,value:"Kapanış Hazırlık",cat:"Konfirme",color:'green-500'},
        {id:11,value:"Proje Kapandı",cat:"Arşiv",color:'light-green'},
        {id:12,value:"Ertelendi",cat:"Arşiv",color:'brown'},
        {id:13,value:"Etkinlik İptal Edildi",cat:"Arşiv",color:'gray-blue'},
        {id:14,value:"Başka firmaya konfirme edildi",cat:"Arşiv",color:'black'},
    ],
    statuses:[
        { value : "PREP", text : "Hazırlık" },
        { value : "OPERATION", text : "Operasyon" },
        { value : "PRECLOSE", text : "Kapanış Hazırlık" },
        { value : "CLOSED", text : "Proje Kapandı" },
        { value : "POSTPONED", text : "Ertelendi" },
        { value : "CANCELLED", text : "İptal Edildi" },
        { value : "COMPETITION", text : "Başka firmaya konfirme edildi" }
    ],
    dates:{
        /*Bu hafta
        1.Çeyrek
        2.Çeyrek
        3.Çeyrek
        4.Çeyrek
        Önümüzdeki 1 Ay
        Önümüzdeki 3 Ay
        Geçmiş 1 Ay
        Geçmiş 3 Ay*/
    }
}

// getters
const getters = {
    list (state) {
        return state.list;
    },
    list_select (state) {
        return state.list.map(project => { return {value : project.code, subtext : project.project_ref, text : project.name}}).sort((a, b) => a.text.localeCompare(b.text))
    },
    paginate: (state) => (current_page,page_size) => {
        return state.list.filter((x,k) => (k+1) >= (current_page-1) * page_size && (k+1) < current_page * page_size)
    },
    status: (state) => (status) => {
        return state.statuses.find((x) => x.id == status).value
    },
    project_statuses_select (state) {
        return state.statuses.map( status => { return {value : status.value, text : status.text } })
    },
    getProjectStatuses: (state) => data => {
        return state.statuses.map(status => { 
			return {value : status.id, text : status.value}
		})
    },
    leads(state) {
        return state.leads
    }
}
  
// actions
const actions = {
    setCurrentProject ({ commit }, data) {
        commit('setCurrentProject', data)
    }
}
  
// mutations
const mutations = {
    setCurrentProject(state, data) {
        state.current_project = data
    },
    setFilters(state, data) {
        state.filters = data
    },
    setPaginator (state, data) {
        data.links.splice(0,1)
        data.links.splice(-1,1)
        state.paginator = data
    },
    setProjects (state, data) {
        state.list = data

        state.list.forEach(element => {
            element.current_status_text = state.statuses.find((x) => x.value == element.current_status).text
            element.current_status_cat_text = state.statuses.find((x) => x.value == element.current_status).cat
            element.current_status_color = state.statuses.find((x) => x.value == element.current_status).color
            //element.
        });
        

        return
        if(state.cities.length == 0) {
            state.cities = data.map(x=>{
                return {value:x.city,text:x.city}
            }).filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i).sort(function (a, b) {
                return a.value.localeCompare(b.value);
            });
        }

        

        if(state.clients.length == 0) {
            state.clients = data.map(x=>{
                return {id:x.client.id,value:x.client.name}
            }).filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i).sort(function (a, b) {
                return a.value.localeCompare(b.value);
            });
        }
        
        if(state.users.length == 0) {
            state.users = data.map(x=>{
                return x.owner ? {id:x.owner.id,value:x.owner.firstname + ' ' + x.owner.lastname} : ''
            }).filter(x=>x !== '').filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i).sort(function (a, b) {
                return a.value.localeCompare(b.value);
            });
        }
    },
    setLeads(state, data) {
        state.leads = data
    },
    setLeadStage(state, data) {
        let lead = state.leads.find(x=>x.code == data.item_id)
        lead.current_status = data.stage_id
        //state.leads = data
    },
    setLead(state,data) {
        let leadIndex = state.leads.findIndex(x=>x.code == data.code)
        state.leads[leadIndex] = data        
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}