<template>
    <div class="relative flex bg-white border transition duration-300 hover:border-calypso hover:shadow-iq hover:bg-calypso-light border-gray-300 rounded p-4 group items-start">
        <div class="flex shrink-0 items-center justify-center w-12 h-12 rounded-full overflow-hidden">
            <img :alt="contact.name" class="object-cover w-full h-full" :src="contact.profile_picture_full || contact.profile_image"
                v-if="contact.profile_picture_full || contact.profile_image"
            >
            <img :alt="contact.name" :src="'https://ui-avatars.com/api/?background=00a4bd&color=FFF&length=2&name=' + contact.name" class="object-cover w-full h-full" v-else/>
        </div>
        <div class="min-w-0 ml-4 grow">
            <div class="font-bold leading-5 truncate select-none">{{contact.name}}</div>
            <div class="text-gray-600 text-sm select-none truncate" v-if="contact.position">{{contact.position.name}}</div>
            <div class="text-gray-600 text-sm select-none truncate" v-if="contact.company">{{contact.company.name}}</div>
            <div class="text-gray-600 text-sm select-none truncate" v-if="contact.email">{{contact.email}}</div>
            <div class="text-gray-600 text-sm select-none truncate" v-if="contact.mobile">{{contact.mobile}}</div>
        </div>
        <slot></slot>
        <div class="absolute flex right-2 bottom-2 group-hover:opacity-100 opacity-0 duration-300 hidden">
            <UITooltip title="Güncelle" position="top" class="mr-2">
                <i class="fa-light fa-edit cursor-pointer" @click="edit_contact"></i>
            </UITooltip>
            <UITooltip title="Sil" position="top">
                <i class="fa-light fa-trash cursor-pointer" @click="delete_contact"></i>
            </UITooltip>
        </div>
    </div>
</template>

<script setup>

    const props = defineProps({
        contact : Object
    })

    // Emits
    const emit = defineEmits(['delete','edit'])

    const delete_contact = () => {
        emit('delete')
    }

    const edit_contact = () => {
        emit('edit')
    }

</script>