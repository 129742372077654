<script setup>
    // Imports
	import {ref,watch,computed,inject} from 'vue'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'
    import { useStore } from 'vuex'

    import useVuelidate from '@vuelidate/core'
    import { required } from '@/utils/i18n-validators'

    const moment = inject('moment')
    const emitter = inject('emitter')
    const store = useStore()

    // Props
	const props = defineProps({
        isOpen : Boolean,
        leave : Object
    })

    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const leave_options = ref([
        {value:'annual_leave',text:'Yıllık İzin'},
        {value:'compassionate_leave',text:'Mazeret İzni'},
        {value:'medical_leave',text:'Raporlu'},
        {value:'post_event',text:'Operasyon Sonrası'},
        {value:'rest_leave',text:'İstirahat İzni'}
    ])
    const leave_date_titles = ref('Başlangıç ve Bitiş Tarihi Seç')
    const submit_error = ref(false)

    // Data models
    const title = ref(props.leave ? 'İzin Talebini Güncelle' : 'Yeni İzin Talebi')
    const leave_data = ref(props.leave || {
        leave_type : null,
        //date : moment().add(1,'week').format('YYYY-MM-DD'),
        dates : [moment().add(1,'week').format('YYYY-MM-DD'),moment().add(2,'week').format('YYYY-MM-DD')],
        note : null
    })

    if(props.leave) {
        leave_data.value.dates = [moment(props.leave.starts_at), moment(props.leave.ends_at)]
    }

    const isSubmitting = ref(false)
    const ready2submit = computed( () => leave_data.value.leave_type && leave_data.value.dates ? true : false )

    // Watchers
    
	// Functions

	const modal_closed = () => {
        emit('modal_closed')
        leave_data.value = null
    }

    const rules = computed(() => ({
        leave_type: { required },
        dates: { required}
    }))

    const v$ = useVuelidate(rules, leave_data)

	const submit = async () => {

        const result = await v$.value.$validate()
        if(!result) return

        let request
        isSubmitting.value = true
        if(props.leave && props.leave.status!='DECLINED') {
            request = API.put('leaves/' + leave_data.value.id,leave_data.value)
        } else {
            request = API.post('leaves',leave_data.value)
        }
        request.then(response => {
            isSubmitting.value = false
            modal_closed()
            emitter.emit('calendar_refetch_events')
            emitter.emit('load_annual_leaves')
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description : response.data.message
            })
            leave_data.value = {
                leave_type : null,
                dates : [moment().add(1,'week').format('YYYY-MM-DD'),moment().add(2,'week').format('YYYY-MM-DD')],
                note : null
            }
        }).catch( error => {
            isSubmitting.value = false
            submit_error.value = error.response.data.message
        })
    }

</script>

<template>
    <Modal :title="title" 
        :isOpen="isOpen" 
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Onaya Gönder"
        @modal_closed="modal_closed"
        @submit="submit"
        size="md"
    >
        <template v-slot:modal_content>
            <UIAlert type="danger" class="my-2 flex-row" v-if="submit_error">
                <p>{{submit_error}}</p>
            </UIAlert>
            <UIFormRow>
                <UIRadioGroup :options="leave_options" :errors="v$.leave_type.$errors" v-model="leave_data.leave_type" label="İzin Türü"/>
            </UIFormRow>
            <UIFormRow v-if="leave_data.leave_type !== 'compassionate_leave'">
                <UIDateRangePicker label="Hangi Tarihte?" :title="leave_date_titles" display="box" :errors="v$.dates.$errors" v-model="leave_data.dates"/>
            </UIFormRow>
            <UIFormRow v-if="leave_data.leave_type == 'compassionate_leave'">
                <UIDateTimePicker label="Hangi Tarihte?" title="Tarih Seç" display="box" :time="true" v-model="leave_data.dates"/>
            </UIFormRow>
            <UIFormRow>
                <UITextarea :autogrow="false" v-model="leave_data.note" label="Açıklama" class="h-32 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-gray-100 p-2"/>
            </UIFormRow>
        </template>
	</Modal>

</template>
