<template>
    <div class="flex items-start">
        <div class="w-60 shrink-0 flex-none sticky top-32">
            <div v-for="menu,k in reports_menu" :key="k" class="border-b border-gray-100">
                <router-link :class="'/admin/budgets/' + menu.link == route.fullPath ? 'bg-calypso-light':''" :to="'/admin/budgets/' + menu.link"  class="p-2 relative hover:bg-gray-50 hover:text-calypso text-gray-900 text-sm font-medium  w-full inline-flex items-center">
                    {{menu.title}}
                </router-link>
            </div>
        </div>
        <div class="ml-5 flex-grow">
            <router-view />
        </div>
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router'
	
    const route = useRoute()

    let reports_menu = [
        {
            title:"Bütçe Kategorileri",
            link:'budget-categories'
        },
        {
            title:"Bütçe Kalemleri",
            link:'budget-items'
        },
        {
            title:"Bütçe Grupları",
            link:'budget-groups'
        },
        {
            title:"Bütçe Taslakları",
            link:'budget-templates'
        },
        {
            title:"Bütçe Kategori Taslakları",
            link:'budget-category-templates'
        },
        {
            title:"Dışa Aktarım",
            link:'export'
        }
    ]
    
</script>