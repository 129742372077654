<template>
    <div class="mb-4">
        <UILabel :label="label" :rand_id="rand_id" :error="error"/>
        <div class="mt-2" :class="horizontal ? 'flex flex-wrap gap-x-5':''">
            <UICheckbox :disabled="disabled" v-if="show_all_option" key="alloptions" :fontSize="fontSize" :checked="allOptionsChecked" label="Hepsi" inputValue="alloptions" :name="rand_id" @change="change_alloptions" class="mb-2 block"/>
            <UICheckbox :disabled="disabled || option.disabled" v-for="option,k in options" :key="k" :fontSize="fontSize" :checked="isChecked(option.value)" :label="create_label(option)" :inputValue="option.value" :name="rand_id" @change="change" class="mb-2 block"/>
        </div>
        <div class="validation_error" v-if="error">{{errors[0].$message}}</div>
    </div>
</template>

<script setup>
    import {ref,computed,watch} from 'vue'

    const props = defineProps({
        modelValue:[String,Number,Array,Object],
        options:Object,
        label:String,
        horizontal:Boolean,
        errors : Array,
        fontSize: {
            type: String,
            default: 'small'
        },
        show_all_option: {
            type: Boolean,
            default: false
        },
        disabled:{
            type:Boolean,
            default:false
        }
    })
    
    const emit = defineEmits(['update:modelValue'])

    const create_label = option => {
        let label = option.text
        if(option.subtext) {
            label = label + '<div class="text-xs text-gray-500">' + option.subtext + '</div>'
        }
        return label
    }

    const error = computed(() => props.errors && props.errors.length > 0)
    const selectedOptions = ref(props.modelValue || [])
    
    const rand_id = computed(()=>'radio_' + (Math.floor(Math.random() * 1000000000)))
    const isChecked = value => selectedOptions.value.length > 0 && selectedOptions.value.indexOf(value) > -1

    //const allOptionsChecked = ref(false)
    const allOptionsChecked = computed(() => {
        const a = [...selectedOptions.value].sort().toString();
        const b = props.options.map(x=>x.value).sort().toString();
        return a === b
    })

    const update_value = data => {
        if(data.type == 'add') {
            selectedOptions.value.push(data.value)
        } else {
            selectedOptions.value = selectedOptions.value.filter(x=>x != data.value)
        }
        
        return
        emit('update:modelValue', selectedOptions.value)
    }

    const change = data => { 
        if(data.checked) {
            selectedOptions.value.push(data.value)
        } else {
            selectedOptions.value = selectedOptions.value.filter(x=>x != data.value)
        }
        emit('update:modelValue', selectedOptions.value)
    }

    const change_alloptions = data => { 
        if(data.checked) {
            //allOptionsChecked.value = true
            selectedOptions.value = props.options.map(x => x.value)
        } else {
            //allOptionsChecked.value = false
            selectedOptions.value = []
        }
        emit('update:modelValue', selectedOptions.value)
    }

    watch(() => props.modelValue, (data) => {
		selectedOptions.value = data || []
	},{ immediate: true })

</script>