import store from '../store/index'

export function set_interceptors(axios) {
    axios.interceptors.request.use(function (config) {
        
            if(!config.data && !config.params) return config
            
            if(config.data) {
                for (const [key, value] of Object.entries(config.data)) {
                    if(typeof value == 'object' && value !== null) {
                        //config.data[key] = value.join(',')
                        config.data[key] = JSON.stringify(value)
                    }
                    if(value == null) {
                        delete config.data[key]
                    }
                }
            }

            if(config.params) {
                for (const [key, value] of Object.entries(config.params)) {
                    if(Array.isArray(value) && value !== null) {
                        config.params[key] = value.join(',')
                        //config.params[key] = JSON.stringify(value)
                    }
                    if(value == null) {
                        delete config.params[key]
                    }
                }
            }
            
            return config;
        }, function (error) {
            return Promise.reject(error);
        }
    )

    axios.interceptors.response.use(function (response) {
            const update_needed = response.headers['eventiq-app-version'] !== __APP_VERSION__
            
            //store.commit('auth/updateNeeded',true)
            return response
        }, function (error) {
            if(error.response && (error.response.status == 401 || error.response.status == 419)) {
                localStorage.removeItem('user')
                localStorage.removeItem('manuelRSVPUser')
                window.location.href = '/login'
            }


            return Promise.reject(error)
            //console.log(error)
            //console.log(error.response)
            if(error.response.status == 422) return

            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message,
                closeable:true
            })
            return Promise.reject(error)
        }
    )
}