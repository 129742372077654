//import Rollbar from 'rollbar';

import Bugsnag from "@bugsnag/js"
import BugsnagPluginVue from "@bugsnag/plugin-vue"

/*
const rollbar = app => {
    var _rollbarConfig = {
        accessToken: 'c63fc4a8717e47259dce4b2801c631b1',
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            environment: import.meta.env.DEV ? 'local' : 'production',
            // context: 'rollbar/test'
            client: {
              javascript: {
                code_version: '1.0',
                source_map_enabled: true,
                guess_uncaught_frames: true
              }
            }
        }
    };
    
    const rollbar = new Rollbar(_rollbarConfig);

    app.config.errorHandler = (err, vm, info) => {
        rollbar.error(err);
        throw err; // rethrow
    };
    
    app.config.globalProperties.$rollbar = rollbar;
}
*/
const bugsnag = app => {
    Bugsnag.start({
        apiKey: "4b0208d8cc7b227316f296caeb5d8f8b",
        plugins: [new BugsnagPluginVue()],
    })
    const bugsnagVue = Bugsnag.getPlugin("vue")

    app.use(bugsnagVue)
}

const track = app => {
    bugsnag(app)
    //rollbar(app)
}


export default track