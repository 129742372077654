<template>
    <Modal 
        :isOpen="isModalOpen" 
        :title="title" 
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Kaydet"
        @modal_closed="modal_closed"
        @submit="submit"
    >
        <template v-slot:modal_content>
            <div class="grid grid-cols-2 gap-x-2">
                <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row col-span-2">
                    <p>{{ submit_error }}</p>
                </UIAlert>
                <UIFormRow class="col-span-2">
                    <UITextarea :autogrow="false" label="Yapılacak İş" v-model="form_data.description"  class="h-16 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-gray-100 p-2"/>
                </UIFormRow>
                <UIFormRow class="col-span-2">
                    <UISelect :search_enabled="false" display="box" :source="recurrence_options" label="Olma Şekli" v-model="form_data.recurrence"/>
                </UIFormRow>
                <UIFormRow v-if="form_data.recurrence == 'once'">
                    <UIInput type="number" label="Başlangıç Gün" v-model="form_data.starts_at"/>
                </UIFormRow>
                <UIFormRow v-if="form_data.recurrence == 'once'">
                    <UIInput type="number" label="Bitiş Gün" v-model="form_data.ends_at"/>
                </UIFormRow>
                <UIFormRow class="col-span-2" v-if="form_data.recurrence == 'interval'">
                    <UISelect :search_enabled="false" display="box" :source="recurrence_interval_options" label="Tekrarlanma şekli" v-model="form_data.interval"/>
                </UIFormRow>
                <UIFormRow class="col-span-2" v-if="is_week">
                    <UISelect :search_enabled="false" display="box" :source="week_days" label="Hangi günde?" v-model="form_data.interval_day"/>
                </UIFormRow>
                <UIFormRow class="col-span-2" v-if="is_month">
                    <UISelect :search_enabled="false" display="box" :source="month_days" label="Hangi günde?" v-model="form_data.interval_day"/>
                </UIFormRow>
                <UIFormRow class="col-span-2" v-if="form_data.recurrence == 'specific_date'">
                    <UIDatePicker :shortcuts="false" display="box" label="Hangi Tarihte?" v-model="form_data.specific_date"/>
                </UIFormRow>
                <UIFormRow class="col-span-2">
                    <UISelect display="box" :options_line_break="true" :source="items" label="Bağımlı olduğu alan" v-model="form_data.dependent_to"/>
                </UIFormRow>
            </div>
        </template>
    </Modal>
</template>
  
<script setup>
	import {computed, ref, watch} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'

	const props = defineProps({
        isOpen : Boolean,
        dataToUpdate : Object,
        active_checklist : Object,
        section_id : Number
    })

    const emit = defineEmits(['modal_closed'])
    const store = useStore()
	const isModalOpen = ref(props.isOpen)
    const isSubmitting = ref(false)
    const submit_error = ref(false)

    // Form Content Vars
    const title = ref('')
    
	// Form Value Bindings
    const form_data = ref({
        checklist_id : props.active_checklist.id,
        section_id : props.section_id
    })
    const is_week = computed(() => form_data.value.interval && form_data.value.interval.includes('week'))
    const is_month = computed(() => form_data.value.interval && form_data.value.interval.includes('month'))
    
    const recurrence_options = ref([
        {value:'once',text:'Tek Sefer'},
        {value:'interval',text:'Tekrarlayan'},
        {value:'specific_date',text:'Belirli bir tarih'}
    ])

    const week_days = ref([
        {value:'monday',text:'Pazartesi'},
        {value:'tuesday',text:'Salı'},
        {value:'wednesday',text:'Çarşamba'},
        {value:'thursday',text:'Perşembe'},
        {value:'friday',text:'Cuma'},
        {value:'saturday',text:'Cumartesi'},
        {value:'sunday',text:'Pazar'},
    ])

    const recurrence_interval_options = ref([
        {value:'daily',text:'Hergün'},
        {value:'once_every_two_days',text:'İki günde bir'},
        {value:'once_every_three_days',text:'Üç günde bir'},
        {value:'once_every_four_days',text:'Dört günde bir'},
        {value:'once_every_five_days',text:'Beş günde bir'},
        {value:'once_every_six_days',text:'Altı günde bir'},
        {value:'weekly',text:'Her hafta'},
        {value:'once_every_two_weeks',text:'İki haftada bir'},
        {value:'once_every_three_weeks',text:'Üç haftada bir'},
        {value:'monthly',text:'Her ay'}
    ])

    const month_days = [...Array(31).keys()].map( x => { return {value : x +1 , text : String(x+1).padStart(2, '0')} })
    const items = computed( () => props.active_checklist.items.map( x => { return {value : x.id , text : x.description} }) )

    const ready2submit = computed( () => form_data.value.description ? true : false )

	watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    watch(() => props.dataToUpdate, data => {
			title.value = data.id ? 'Checklist Satırını Güncelle' : 'Yeni Checklist Satırı Ekle'
            form_data.value = {...form_data.value,...data}
		},{ immediate: true }
    )

    watch(() => props.section_id, data => {
        form_data.value.section_id = data
    },{ immediate: true })

    watch(() => form_data.value.recurrence, (data) => {
       
    },{ immediate: true, deep:true })

    const modal_closed = () =>{
		isModalOpen.value = false
        isSubmitting.value = false
        emit('modal_closed')
        form_data.value = {
            checklist_id : props.active_checklist.id,
            section_id : props.section_id
        }
        
	}

    const submit = () => {
        isSubmitting.value = true

        let request

        if(props.dataToUpdate.id) {
            request = API.put('checklists/' + props.active_checklist.id + '/item/' + props.dataToUpdate.id,form_data.value)
        } else {
            request = API.post('checklists/' + props.active_checklist.id + '/item',form_data.value)
        }
        
        request.then(response => {
            isSubmitting.value = false
            modal_closed()
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:response.data.message,
            })
        })
        .catch((error) => {
            isSubmitting.value = false;
            submit_error.value = error.response.data.message
            
        })
    }
</script>