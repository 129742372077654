<script setup>
    import { ref } from 'vue'
    const emit = defineEmits(['change'])

    const props = defineProps({
        options : Array,
        selectedIndex : Number
    })

    const selectedIndex = ref(props.selectedIndex || 0 )
    
    const change_view = index => {
        selectedIndex.value = index
        emit('change',index)
    }

</script>

<template>
    <div role="radiogroup" class="button_group private-button-select-group button_group--horizontal">
        <button 
            @click="change_view(index)" 
            v-for="option,index in options" 
            :key="index" 
            aria-checked="false" 
            role="radio" 
            type="button" 
            :aria-describedby="'uiTooltip-' + index" 
            :aria-disabled="selectedIndex == index ? 'true' : 'false'" 
            class="btn uiButton private-button private-button--default private-button--tertiary-light private-button-select-group__option private-hoverable private-button--non-link" tabindex="0"
            :class="{
                'active private-button--active' : selectedIndex == index
            }"    
        ><span v-html="option"></span></button>
    </div>
</template>

<style scoped>
    .button_group.button_group--horizontal>:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    .button_group.button_group--horizontal>* {
        display: inline-block!important;
        margin-top: 0;
        position: relative;
        width: auto!important;
    }

    .private-button-select-group__option {
        background-color: #f5f8fa;
        border-color: #cbd6e2!important;
        color: #516f90;
    }
    .private-button--tertiary-light {
        background-color: #eaf0f6;
        border-color: #cbd6e2;
        color: #506e91;
    }
    .private-button--default {
        font-size: 14px;
        font-size: .875rem;
        line-height: .875rem;
        padding: 0.75rem 1.5rem;
        font-family: Avenir Next W02,Helvetica,Arial,sans-serif;
        font-weight: 500;
    }
    .private-button {
        border-radius: 0.1875rem;
        border-style: solid;
        border-width: 1px;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
        font-smoothing: auto;
        text-shadow: 0 0 1px transparent;
        font-family: Avenir Next W02,Helvetica,Arial,sans-serif;
        font-weight: 400;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: all .15s ease-out;
        cursor: pointer;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
    }
    .button_group>:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
    .button_group.button_group--horizontal>:not(:first-child) {
        margin-left: -1px!important;
    }
    .private-button-select-group__option.private-button--active {
        background-color: #e5eaf0!important;
        color: #516f90;
    }
    .button_group>.private-button--active {
        z-index: 1;
    }
    .private-button--tertiary-light.private-button--active {
        background-color: #cbd6e2!important;
        border-color: #7c98b6!important;
        color: #506e91!important;
    }
    
    .private-button+.private-button {
        margin-left: 0.75rem;
    }
    
    .button_group.button_group--horizontal>:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
    .private-button-select-group__option:hover {
        background-color: #fff;
        color: #516f90;
    }

</style>