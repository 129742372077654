<template>
    <img :src="external ? src : 'https://s3.eu-west-2.amazonaws.com/easy.events/' + src" @error="hide_image">
</template>

<script setup>

    const props = defineProps({
        src: String,
        external:Boolean
    })

    const hide_image = e => {
        e.target.style.visibility = 'hidden'
    }

</script>