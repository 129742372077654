<script setup>
    import { computed, ref, watch ,onBeforeMount, onMounted} from "vue"
    import API from "@/api"
    import { TrashIcon } from "@heroicons/vue/outline"
    import { useStore } from "vuex"
    import _ from 'lodash'

    const store = useStore()

    const form_data = computed(() => store.state.form.active_form)
    const active_field = computed(() => store.state.form.active_field)
    const field = ref({})
    const db_field_columns = ref([])
    const db_columns = computed(() => {
		
		let f = []
		if (Array.isArray(db_field_columns.value)) {
			db_field_columns.value.map( arr =>
				f.push({
					value: "DB-" + arr.key,
					text: arr.text,
					subtext: "DB Alanı",
				})
			)
		}

		return f
	})

	const new_rule_disabled = computed(() => !new_rule.value.target || !new_rule.value.operator || new_rule.value.value == undefined)



    watch(() => active_field.value, data => {
        field.value = _.cloneDeep(active_field.value)
    },{ immediate: true,deep:true })

    const new_rule = ref({
		type: "FORMFIELD",
		source: active_field.value.id,
	})

    const new_rule_type = ref()
    const rule_options = ref([])

    const full_item_rule_equation_options = ref([
		{ value: "EQUAL", text: "eşittir" },
		{ value: "NOTEQUAL", text: "eşit değildir" },
		{ value: "GREATER", text: "büyüktür" },
		{ value: "LESS", text: "küçüktür" },
		{ value: "FULL", text: "dolu ise" },
		{ value: "EMPTY", text: "boş ise" },
	])

    const db_form_columns = computed(() => {
		return [...db_columns.value, ...form_fields.value]
	})

    const rule_equation_options = ref([
		{ value: "EQUAL", text: "eşittir" },
		{ value: "NOTEQUAL", text: "eşit değildir" },
		{ value: "GREATER", text: "büyüktür" },
		{ value: "LESS", text: "küçüktür" },
	])

	const rules = ref({
		scope: "ALL",
		action: "SHOW",
	})

    const field_section = computed(() =>
		form_data.value.sections.find((x) => x.id == field.value.form_section_id)
	)

    const form_fields = computed(() => {
		let a = []
		form_data.value.sections.forEach( section  => {
			//console.log(section.fields)
            section.fields.forEach( section_field  => {
				if (field.value.id == section_field.id) return
				
                if (field_section.value.id == section.id && field.value.sort_order < section_field.sort_order)return
				if (field_section.value.sort_order < section.sort_order) return

				if (section_field.type !== 'attendance' && section_field.options.length == 0) return

				a.push({
					value: "FIELD-" + section_field.id,
					text: section_field.title,
					subtext: "Form Alanı",
				})
			})
		})
        //console.log(a)
		return a
	})

    const find_form_field = (id, lookup = null, lookup_id = null) => {
		
		if (id.includes("-")) {
			id = id.split("-")
			id = id[1]
		}
		
		let select_field = false
		form_data.value.sections.forEach((section) => {
			section.fields.forEach((section_field) => {
				if (id == section_field.id) select_field = section_field
			})
		})

		if (select_field == false) {
			if (lookup == "title") return id
			if (lookup == "option") return lookup_id
		}

		if (!lookup) return select_field

		if (lookup) {
			console.log(select_field.type,lookup_id)
			if(select_field.type == 'attendance'){
				if(lookup_id == 1) return 'Katılıyor'
				if(lookup_id == 0) return 'Katılmıyor'
			}

			if (lookup == "option") {
				return select_field.options.find((x) => x.id == lookup_id).option_text
			}

			return select_field[lookup]
		}
	}


    watch(
		() => new_rule.value.operator,
		(data) => {
			
			// if(['FULL','EMPTY'].includes(data)){
			//     //new_rule.value.target.hide
			// }
		},
		{ immediate: true }
	)

	watch(
		() => new_rule.value.target,
		(data) => {
			if (!data) return

			if (data.includes("DB-")) {
				new_rule_type.value = "input_box"

				new_rule.value.operator = false
				new_rule.value.value = ""

				rule_equation_options.value = [
					{ value: "EQUAL", text: "eşittir" },
					{ value: "NOTEQUAL", text: "eşit değildir" },
					{ value: "FULL", text: "dolu ise" },
					{ value: "EMPTY", text: "boş ise" },
				]

				return
			}

			const form_field = find_form_field(data)
			if (!form_field) return
			new_rule_type.value = form_field.type

			
			if(form_field.type == 'attendance'){
				rule_options.value = [
				{ value: 1, text: 'Katılıyor' },
				{ value: 0, text: 'Katılmıyor' }
				]
			} else {
				rule_options.value = form_field.options.map((x) => {
					return { value: x.id, text: x.option_text }
				})
			}
		},
		{ immediate: true }
	)

    const add_rule = () => {
        API.post("form/" + form_data.value.code + "/rules", new_rule.value)
			.then((response) => {
				
				store.dispatch("form/updateField", {
					id: field.value.id,
					field: response.data.data,
				})
				field.value = response.data.data

				store.dispatch("floating_alerts/addAlert", {
					type: "tip",
					title: "BAŞARILI!",
					description: response.data.message,
				})
				new_option.value = {}
			})
			.catch((error) => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
	}

    const delete_field_rule = (id) => {
		
		API.delete("form/" + form_data.value.code + "/rules/" + id)
			.then((response) => {
				store.dispatch("form/updateField", {
					id: field.value.id,
					field: response.data.data,
				})
				field.value = response.data.data

				store.dispatch("floating_alerts/addAlert", {
					type: "tip",
					title: "BAŞARILI!",
					description: response.data.message,
				})
			})
			.catch((error) => {
				
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
	}

</script>

<template>
    <template v-if="form_data.form_type == 'mma'">
				Bu formda kural kullanılamaz.
			</template>
			<template v-else>
				<div class="mb-3 text-sm">
					Aşağıdaki kurallardan
					<UISelect
						class="inline-block mx-1"
						:search_enabled="false"
						display="inline"
						v-model="field.dep_scope"
						:source="[
							{ value: 'ALL', text: 'hepsi' },
							{ value: 'ANY', text: 'herhangi biri' },
						]"
					/>
					geçerli olduğunda bu alanı
					<UISelect
						class="inline-block mx-1"
						:search_enabled="false"
						display="inline"
						v-model="field.dep_action"
						:source="[
							{ value: 'SHOW', text: 'göster' },
							{ value: 'HIDE', text: 'gizle' },
						]"
					/>
				</div>

				<div class="rounded border p-2 my-3">
					<div class="mb-4">
						<small class="block font-bold">Mevcut Kurallar</small>
					</div>
					
					<div class="mb-10" v-if="field.rules && field.rules.length">
						<!-- <div v-for="dep in field.deps" :key="dep.id" class="grid grid-cols-4 gap-x-3 text-sm">
							<div>{{ find_form_field(dep.dependent_to, 'title') }}</div>
							<div>{{ rule_equation_options.find(x => x.value == dep.operator).text }}</div>
							<div>{{ find_form_field(dep.dependent_to, 'option', dep.option_id) }}</div>
							<div><TrashIcon class="w-9 p-2 opacity-80 text-red-500  hover:bg-slate-100" @click.stop.prevent="delete_field_rule(dep.id)"/></div>
						</div> -->

						<div
							v-for="dep in field.rules"
							:key="dep.id"
							class="grid grid-cols-[1fr_100px_1fr_30px] gap-x-3 text-sm items-center"

						>
							
						
							<div>{{ find_form_field(dep.target, "title") }}</div>
							<div>
								{{
									full_item_rule_equation_options.find(
										(x) => x.value == dep.operator
									).text
								}}
							</div>
							<div>
								{{ find_form_field(dep.target, "option", dep.target_value) }}
							</div>
							<div>
								<TrashIcon
									class="w-9 p-2 opacity-80 text-red-500 hover:bg-slate-100"
									@click.stop.prevent="delete_field_rule(dep.id)"
								/>
							</div>
						</div>
					</div>
					<div v-else class="text-sm text-center mb-2">
						Henüz eklenmiş bir kural yok.
					</div>
				</div>

				<div class="rounded border p-2 my-3">
					<small class="mb-2 block font-bold">Yeni Kural Ekle</small>
					<UISelect
						display="box"
						:source="db_form_columns"
						title="Veritabanı/Form Alanı Seç"
						v-model="new_rule.target"
					/>
					<div class="flex gap-3 mt-3">
						<UISelect display="box" :source="rule_equation_options" v-model="new_rule.operator" title="Koşul" class="w-1/4" />
						<UIInput v-model="new_rule.value" placeholder="Sabit" class="w-3/4" :hidden="['FULL', 'EMPTY'].includes(new_rule.operator)" v-if="['text_area', 'input_box'].includes(new_rule_type)" />
						<UISelect display="box" :source="rule_options" v-model="new_rule.value" title="Seçenek Seç" class="w-3/4" v-else
						/>
					</div>
					<UIButton
						type="submit"
						text="Kural Ekle"
						class="mt-3"
						:disabled="new_rule_disabled"
						@click="add_rule"
					/>
				</div>

				

				
			</template>
</template>