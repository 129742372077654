<template>
	<Modal
		:title="title"
		:isOpen="isOpen"
		:ready2submit="ready2submit"
		:isSubmitting="isSubmitting"
		:action_button_text=actionButtonText
		@modal_closed="modal_closed"
		@submit="submit"
	>
		<template v-slot:modal_content>
			<UIFormRow>
                <UIInput v-model="form_data.name" label="Form Adı" :errors="v$.name.$errors" />
            </UIFormRow>
            <UIFormRow>
                <UITextarea label="Açıklama" :maxlength="200" :autogrow="false" class="h-40" v-model="form_data.description" />
            </UIFormRow>
            <UIFormRow>
                <UISelect multiple tags display="box" :source="project_types" title="Proje tipi seç" label="Ne tip projelerde bu form kullanılsın?" v-model="form_data.project_types" :errors="v$.project_types.$errors" />
            </UIFormRow>
            
		</template>
	</Modal>
</template>

<script setup>
	// Imports
	import { ref, watch, computed, onBeforeMount } from "vue"
    import { useStore } from 'vuex'
    import useVuelidate from '@vuelidate/core'
    import { required, email, requiredIf } from '@/utils/i18n-validators'
	import Modal from "@/components/UI/Modal.vue"
    import API from '@/api'

	// Props
	const props = defineProps({
		isOpen: Boolean,
		size:{
			type:String,
			default:'md'
		},
		actionButtonText:{
			type:String,
			default:'Kaydet'
		},
		background_animation:String,
		form: Object
	})

	// Emits
	const emit = defineEmits(["modal_closed", "submit"])

	// VAR Definitions
    const store = useStore()
    
    const project_types = computed(() => {
		if(props.form) {
			return store.state.content.project_types.map( type => { return {value : type.id, text : type.name} }).sort( (a, b) => a.text.localeCompare(b.text) )
		}
		return store.state.content.project_types.filter( x => !x.post_event_survey_form_id).map( type => { return {value : type.id, text : type.name} }).sort( (a, b) => a.text.localeCompare(b.text) )
	})
    const title = computed(() => props.form ? 'Müşteri Memnuniyet Anketini Güncelle' : 'Yeni Müşteri Memnuniyet Anketi')
	const isSubmitting = ref(false)
	
    const form_data = ref({
        name : null,
        project_types:[]
    })

	const rules = computed(() => ({
        name: { required },
        project_types: { required }
    }))

    const v$ = useVuelidate(rules, form_data)
	
	// Data models

	const ready2submit = computed(() => !v$.value.$invalid)

	// Watchers
	watch(() => props.form, data =>{
		if(!data) {
			form_data.value = {
				name:null,
				project_types:[]
			}
			return
		}
		form_data.value.code = props.form.code
		form_data.value.name = props.form.name
		form_data.value.description = props.form.description
		form_data.value.project_types = props.form.post_event_survey_form_project_type.map( x => x.id)
	})
	watch(() => props.isOpen, data =>{
		if(!data) form_data.value = {
            name:null,
            project_types:[]
        }
	})

	// Functions

	const modal_closed = () => emit("modal_closed")
	const submit = () => {
		isSubmitting.value = true

		let request
		if(form_data.value.code) {
			request = API.put('event-companies/post-event-client-surveys/' + form_data.value.code,form_data.value).then( response => {
				store.dispatch('floating_alerts/addAlert', {
					type:'tip',
					title:'BAŞARILI!',
					description:response.data.message
				})
			})
		} else {
			request = API.post('event-companies/post-event-client-surveys',form_data.value).then( response => {
				store.dispatch('floating_alerts/addAlert', {
					type:'tip',
					title:'BAŞARILI!',
					description:response.data.message
				})
			})
		}

        request.then(response => {
            modal_closed()
            isSubmitting.value = false
        })
	}
	const enter = () => console.log("enter")
</script>
