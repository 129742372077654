<script setup>
    // Imports
	import { ref, inject, computed, watch } from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'

    import useVuelidate from '@vuelidate/core'
    import { required } from '@/utils/i18n-validators'

    const moment = inject('moment')
    const emitter = inject('emitter')
    const store = useStore()
    
    // Props
	const props = defineProps({
        isOpen : Boolean,
        operation : Object
    })

    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const isSubmitting = ref(false)
    const ready2submit = computed( () => {
        return form_data.value.project_id && 
            form_data.value.starts_at && 
            form_data.value.ends_at && 
            form_data.value.transportation_type ? true : false ;
    })
    
    const users = computed(() => store.getters['content/users_select'])

    const inpection_transport_options = ref([
        {value:'company_car',text:'Şirket Aracı'},
        {value:'flight',text:'Uçak'},
        {value:'bus',text:'Otobüs'},
        {value:'train',text:'Tren'},
        {value:'personal_car',text:'Kendi Aracı'}
    ])

    // Data models
    const submit_error = ref(false)
    const title = ref(props.operation ? 'Operasyon Katılımını Güncelle' : 'Yeni Operasyon Katılımı')

    const operation = ref(props.operation?props.operation:null)
    const form_data = ref({
        id:operation.value? operation.value.id: null,
        project_id: operation.value? operation.value.project_id: null,
        transportation_type : operation.value? operation.value.transportation_type: 'company_car',
        hotel_name:operation.value? operation.value.hotel_name: '',
        note:operation.value? operation.value.note: '',
        starts_at : operation.value? moment(operation.value.starts_at).format('YYYY-MM-DD'):moment().add(1,'week').format('YYYY-MM-DD'),
        ends_at : operation.value? moment(operation.value.ends_at).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'),
        responsibles:operation.value? operation.value.responsibles.map(r=>r.user_id):[]
    })
    const min_date = ref(moment().format('YYYY-MM-DD'))
    const projects = ref([])
    const projects_options = ref([])
    const me = computed(() => store.state.auth.me)

    API.getProjectsByStatus(['PREP','OPERATION','PRECLOSE']).then(response => {
        projects_options.value = response.data.map( project => { return {value : project.code, text : project.name, subtext : project.project_ref, image : project.client.logo_full } }).sort((a, b) => a.text.localeCompare(b.text))
        projects.value = response.data
    })

    // Watchers
    watch(() => form_data.value.project_id, data => {
        const project = projects.value.find( x => x.code == data)
        form_data.value.starts_at = project.starts_at
        form_data.value.ends_at = project.ends_at
    })

    watch(() => form_data.value.starts_at, data => {
        form_data.value.ends_at = moment(form_data.value.starts_at).add(1,'hour').format('YYYY-MM-DD HH:mm')
        min_date.value = data
    })
    
    // Functions

    const rules = computed(() => ({
        project_id: { required },
        starts_at: { required},
        ends_at: { required},
        transportation_type:{ required}
    }))

    const v$ = useVuelidate(rules, form_data)
    
	const modal_closed = () =>emit('modal_closed')
	const submit = async() => {

        const result = await v$.value.$validate()
        if(!result) return

        let request
        isSubmitting.value = true
        if(props.operation && props.operation.status!='DECLINED') {
            request = API.put('operations/' + form_data.value.id,form_data.value)
        } else {
            request = API.post('operations',form_data.value)
        }
        
        request.then( response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description: response.data.message
            })
            isSubmitting.value = false
            modal_closed()
            emitter.emit('calendar_refetch_events')
            emitter.emit('load_operations')
        }).catch( error => {
            isSubmitting.value = false
            submit_error.value = error.response.data.message
        })

    }

</script>

<template>
    <Modal :title="title" 
        :isOpen="isOpen" 
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Onaya Gönder"
        @modal_closed="modal_closed"
        @submit="submit"
        size="md"
    >
        <template v-slot:modal_content>
            <UIAlert type="danger" class="my-2 flex-row" v-if="submit_error">
                <p>{{submit_error}}</p>
            </UIAlert>
            <UIFormRow>
                <UISelect options_line_break key="inpection_location" display="box" label="Hangi proje için katılacaksın?" title="Proje seç" :source="projects_options" :errors="v$.project_id.$errors" v-model="form_data.project_id"/>
            </UIFormRow>
            <UIFormRow>
                <UIDatePicker display="box" :minDate="moment().format('YYYY-MM-DD')" :shortcuts="false" title="Başlangıç Tarihi" label="Hangi tarihlerde katılacaksın?" :time="true" :errors="v$.starts_at.$errors" v-model="form_data.starts_at" class="mb-2"/>
                <UIDatePicker display="box" :minDate="min_date" :shortcuts="false" title="Bitiş Tarihi"  :time="true" :errors="v$.ends_at.$errors" v-model="form_data.ends_at"/>
            </UIFormRow>
            <UIFormRow>
                <UIRadioGroup :options="inpection_transport_options" :errors="v$.transportation_type.$errors" v-model="form_data.transportation_type" label="Ulaşımını nasıl sağlayacaksın?" direction="horizontal" />
            </UIFormRow>
            <UIFormRow>
                <UIInput v-model="form_data.hotel_name" label="Hangi otelde konaklayacaksın?" placeholder="Otel adını buraya yazabilirsin..."/>
            </UIFormRow>
            <UIFormRow>
                <UITextarea :autogrow="false" label="Bir notun var mı?" v-model="form_data.note" placeholder="Varsa, buraya yazabilirsin..." class="h-16"/>
            </UIFormRow>
            <UIFormRow>
                <UISelect display="box" :tags="true" key="participants" label="Senin haricinde bu operasyona katılacak kimler var?" title="Kişileri seç" :multiple="true" :source="users" v-model="form_data.responsibles"/>
            </UIFormRow>
        </template>
	</Modal>

</template>