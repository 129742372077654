<template>
	<div>
		<div ref="title_wrapper" class="cursor-pointer" @click="show_item">
			<slot name="title"></slot>
		</div>
		<teleport to="body">
			
			<div 
				:class="{
					'opacity-0 pointer-events-none translate-y-0' : !is_popover_open
				}" 
				ref="popover" 
				class="absolute top-0 transition-opacity duration-200 z-[100] bg-white rounded border shadow-iq p-4 min-w-[200px]"
			>
				<slot name="content"></slot>
				<div class="arrow" data-popper-arrow></div>
			</div>
		</teleport>
	</div>
</template>

<script setup>
	import { computed,ref,inject,watch, onMounted } from 'vue'
	import { createPopper } from '@popperjs/core'
    import { onClickOutside } from '@vueuse/core'
	import { ChevronDownIcon } from "@heroicons/vue/solid"
	import { TransitionRoot } from '@headlessui/vue'

	const props = defineProps({
		position : {
            type : String,
            default : 'center'
        },
	})

	const emit = defineEmits(["open","close"])

	let popper_instance, removeonClickOutside, opener_el

	const title_wrapper = ref(null)
	const popover = ref(null)
	const is_popover_open = ref(false)

	// Emits
	
	// Functions
	const hide_item = (event) => {
		
		if(event && event.target && opener_el == event.target) return
		
		emit("close")
		
		is_popover_open.value = false
		removeonClickOutside()
	}
	defineExpose({hide_item})

	const show_item = (event) => {
		//if(!popover.value.classList.contains("hidden")) {
		opener_el = event.target
		if(is_popover_open.value) {
			hide_item()
			return
		}
		emit("open")
		//popover.value.classList.remove("hidden")
		is_popover_open.value = true
	
		popper_instance = createPopper(title_wrapper.value, popover.value, {
			placement: 'top',
			//positionFixed: true,
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 15],
					}
				}
			],
		})
		
		const child_input = popover.value.querySelector("input")
		if(child_input) {
			setTimeout(() => child_input.focus(), 0)
		}

		removeonClickOutside = onClickOutside(popover.value, hide_item)
        
    }
	
</script>



<style scoped>
    :global(.arrow) {
        fill: none;
		stroke: #666;
		stroke-width: 1.4;
	}
    .arrow,
    .arrow::before {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #fff;
    }

    .arrow {
        visibility: hidden;
    }

    .arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    [data-popper-placement^='top']  .arrow {
        bottom: -10px;
        
    }

    [data-popper-placement^='top']  .arrow:before {
        border-bottom: 1px solid #e5e7eb;
        border-right: 1px solid #e5e7eb;
    }

    [data-popper-placement^='bottom']  .arrow {
        top: -10px;
    }

    [data-popper-placement^='bottom']  .arrow:before {
        border-top: 1px solid #e5e7eb;
        border-left: 1px solid #e5e7eb;
    }

    [data-popper-placement^='left']  .arrow {
        right: -10px;
    }
    [data-popper-placement^='left']  .arrow:before {
        border-top: 1px solid #e5e7eb;
        border-right: 1px solid #e5e7eb;
    }

    [data-popper-placement^='right']  .arrow {
        left: -10px;
    }
    [data-popper-placement^='right']  .arrow:before {
        border-bottom: 1px solid #e5e7eb;
        border-left: 1px solid #e5e7eb;
    }
</style>