<template>
    <UILabel :label="label" :rand_id="id || rand_id" :isFocused="isFocused" :error="error" :class="label_class" :help_text="help_text"/>
    <div 
        class="flex gap-4"
        :class="{
            'flex-col flex-col-reverse' : horizontal
        }"
    >
        <div :class="{
            'w-1/2' : !horizontal
        }">
            <div class="text-sm text-gray-500 mb-1" v-if="subtitles && subtitles[0]">{{subtitles[0]}}</div>
            <draggable
                class="list-group overflow-y-auto p-2 bg-gray-100 rounded border"
                :class="{
                    'h-60' : !horizontal,
                    'flex' : horizontal
                }"
                :list="options_all"
                :group="group_from"
                @change="log"
                itemKey="name"
                @start="isFocused = true"
                @end="isFocused = false"
            >
                <template #item="{ element, index }">
                <div class="bg-white rounded border px-2 py-1 mb-1 text-sm transition duration-300 hover:border-calypso hover:shadow-iq hover:bg-calypso-light cursor-grab">
                    {{ element.text }}
                </div>
                </template>
            </draggable>
        </div>
        <div :class="{
            'w-1/2' : !horizontal
        }">
            <div class="text-sm text-gray-500 mb-1" v-if="subtitles && subtitles[1]">{{subtitles[1]}}</div>
            <draggable
                class="list-group overflow-y-auto p-2 bg-gray-100 rounded border"
                :class="{
                    'h-60' : !horizontal,
                    'flex' : horizontal
                }"
                :list="options_selected"
                :group="group_to"
                @change="log"
                itemKey="name"
            >
                <template #item="{ element, index }">
                <div class="whitespace-nowrap bg-white rounded border px-2 py-1 mb-1 text-sm transition duration-300 hover:border-calypso hover:shadow-iq hover:bg-calypso-light cursor-grab">
                    {{ element.text }}
                    <i class="fa fa-times ml-2 opacity-50 text-xs" @click="removeAt(index)" v-if="horizontal"></i>
                </div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<script setup>
    import {computed,ref,watch} from 'vue'
    import draggable from 'vuedraggable'


    const props = defineProps({
        modelValue: [String,Number,Array],
        
        errors:Array,
        label:String,
        label_class:String,
        subtitles:Array,
        id:String,
        tabIndex:[String,Number],
        help_text:String,
        options:[Array,Object],
        horizontal:Boolean,
        group_from: {
            type: [String,Object],
            default: 'people',
        },
        group_to: {
            type: [String,Object],
            default: 'people',
        }
    })
    
    const emit = defineEmits(['update:modelValue','blur','enter'])

    const isFocused = ref(false)
    const options_all = ref(props.options || [])
    const options_selected = ref(props.modelValue || [])

    watch(() => props.modelValue, data => {
        return options_selected.value = data
    },{immediate : true})

    const error = computed(() => props.errors && props.errors.length > 0)
    const rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))

    const log = data => {
        emit('update:modelValue', options_selected.value)
    }

    const removeAt = idx => {
        options_selected.value.splice(idx, 1)
        emit('update:modelValue', options_selected.value)
    }

</script>