const Messages = {
    message: {
        accounting:'Muhasebe',
        hello: 'Merhaba',
        settings: 'Ayarlar',
        live: 'Canlı',
        fullscreen: 'Tam Ekran',
        type_a_message:'Mesajınızı yazın',
        happeningnow_title:'Şu Anda Neler Oluyor?',
        new:'Yeni',
        manage:'Yönetin',
        profile:{
            myprofile:'Profilim',
            myprofileinfo:'Profil Bilgilerim',
            myschedule:'Programım',
            myschedule_empty:'Şu anda programınızda bir oturum bulunmamaktadır.',
            mymeetings:'Toplantılarım',
            mymeetings_empty:'Hiç toplantınız yok.',
            myconnections:'Bağlantılarım',
            mynotes:'Notlarım',
            mynotes_empty:'Hiç not almamışsınız.',
            myexpointerests:'İlgilendiğim Firmalar',
            myexpointerests_empty:'Hiç bir firmayı eklememişsiniz.'
        },
        notifications:{
            title:'Bildirimler',
            empty:'Hiç bildiriminiz yok.'
        },
        inapp_messages:{
            title:'Mesajlar',
            empty:'Hiç mesajınız yok.',
            attendee_blocked:'Bu katılımcıyı bloklamışsınız.',
			unblock:'Bloku kaldır',
            block_this_attendee:'Buy katılımcıyı bloklayın'
        },
        session:{
            start_session:'Oturumu Başlatın',
            end_session:'Oturumu Sonlandırın',
            poll:'Anket',
            cta:'Call-to-Action',
            manage_session:'Oturumu Yönetin',
            start_broadcasting:'Yayına Başlayın',
            attendee_count:'Oturumdaki Katılımcı Sayısı',
            raise_hand:'El Kaldırın',
            moderation_view:'Moderasyon Ekranı'
        },
        validations:{
            required:'A'
        }
    },
    validations:{
        required:'Bu alan zorunludur',
        email:'Geçerli bir e-posta adresi girmelisin',
        minLength:'En az {min} karakterden oluşmalı',
        sameAsPassword:'Şifreler birbiri ile aynı olmalı',
    },
    request_types: {
        annual_leave : 'yıllık izin',
        compassionate_leave : 'mazeret izni',
        medical_leave : 'rapor izin',
        post_event : 'etkinlik sonrası izni',
        rest_leave : 'istirahat izni',
        operation : 'operasyon',
        inspection : 'keşif',
        expense : 'masraf',
        advance_payment : 'avans ödemesi',
        payment_request : 'ödeme talebi',
        cc_request : 'kredi kartı talebi',
        sales_invoice_request : 'satış faturası talebi'
    },
    notifications:{
        BizdevCreated: '%{user}, <b>%{project_name}</b> adında yeni bir iş fırsatı oluşturdu. 🔥🔥',
        BudgetCreated: '%{user}, <b>%{budget_name}</b> adında yeni bir bütçe oluşturdu.',
        ClientCreated: '%{user}, <b>%{client_name}</b> adında yeni bir müşteri oluşturdu.',
        ApprovalRequested: '%{user}, bir <b>%{source}</b> için onayını istiyor.',
        ApprovalDeclined: '%{user}, onay istediğin <b>%{source}</b> talebini reddetti.',
    }
}

export default Messages