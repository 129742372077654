<script setup>
    // Imports
	import {ref, computed, inject, watch} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'
    import { XIcon } from '@heroicons/vue/solid'

    import useVuelidate from '@vuelidate/core'
    import { required, minLength, requiredIf } from '@/utils/i18n-validators'
    
    const helpers = inject('helpers')
    const emitter = inject('emitter')

    // Props
	const props = defineProps({
        expense : Object
    })

    const isOpened = ref(false)

    const store = useStore()
    const isSubmitting = ref(false)
    const submit_error = ref(false)
    
    const ready2submit1 = computed( () => data.value.expense_source && data.value.project_id && data.value.items.length > 0 && data.value.items.filter(x =>!x.assign_to || !x.expense_date || !x.description || !x.amount || !x.vat_rate || !x.payment_type).length == 0 )
    const ready2submit = computed(() => {
         return !v$.value.$invalid
     })

    const error_messages = computed(() => {
        v$.value.$silentErrors.forEach(error => {
            console.log(error.$validator)
        })
        
        return v$.value.$silentErrors.map( x => x.$validator )
    })
            
    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const event_company = computed(() => store.state.auth.event_company)
    const projects = ref([])
    const currencies_select = computed(() => store.getters['content/currencies_select'])
    const vat_rates_select = computed(() => store.getters['auth/vat_rates_select'])
    const expense_sources_select = computed(() => store.getters['content/expense_sources_select'])

    const items_client = computed(() => data.value.items.filter(x=>x.assign_to == 'CLIENT'))
    const items_company = computed(() => data.value.items.filter(x=>x.assign_to == 'AGENCY'))
    
    const total_client = computed(() => items_client.value.reduce((sum, x) => sum + x.amount, 0))
    const total_company = computed(() => items_company.value.reduce((sum, x) => sum + x.amount, 0))
    const totals = computed(() => total_client.value + total_company.value )

    API.getProjectsByStatus(['LEAD','PREP','OPERATION','PRECLOSE']).then(response => {
        projects.value = response.data.map( project => { return {value : project.code, text : project.name, subtext : project.project_ref, image : project.client.logo_full } }).sort((a, b) => a.text.localeCompare(b.text))
    })
    
   
    const expense_sources = ref([
		...[{
			"value" : "PROJECT",
			"text" : "Proje"
		}],
        ...expense_sources_select.value
	])

    const payment_methods = computed(() => {
        return [
            ...[
                {
                    "value" : "CASH",
                    "text" : "Nakit"
                },
                {
                    "value" : "CC",
                    "text" : "Kişisel Kredi Kartı"
                }
            ],
            ...event_company.value.credit_cards.map( x => { return {value : x.name, text : x.name} })
        ]
    })
    
    const cost_source = ref([
		{
			"value" : "AGENCY",
			"text" : event_company.value.name
		},
		{
			"value" : "CLIENT",
			"text" : "Müşteri"
		}
	])

    // Data models
    const data = ref({
        expense_source : props.expense ? expense_sources.value.find(x => x.value == props.expense.source.type).value :'PROJECT',
        expense_id  : props.expense ? (props.expense.status== 'DECLINED'?null:props.expense.id ): null,
        project_id  : props.expense ? props.expense.project_id : null,
        items       : props.expense ? props.expense.items.map(item=> { return {
                id:item.id,
                assign_to:item.assign_to,
                expense_date:item.expense_date,
                description:item.description,
                amount:item.total,
                vat_rate:item.vat_rate,
                payment_type:item.payment_type,
                receipt_no:item.receipt_no
            }
        }) : [],
        note        : props.expense ? props.expense.note : '',
        currency    : props.expense ? props.expense.currency : event_company.value.currency
    })

    const rows_not_empty = (value) => data.value.items.filter(x =>!x.assign_to || !x.expense_date || !x.description || !x.amount || x.vat_rate === null || !x.payment_type).length == 0 
    const rules = computed(() => ({
        expense_source: { required },
        project_id: { required : requiredIf(value =>  data.value.expense_source == 'PROJECT') },
        currency:{required},
        //items: { minLengthValue: minLength(1), rows_not_empty }
    }))
    
    const v$ = useVuelidate(rules, data)

    // Watchers
    watch(() => data.value.expense_source, blade => {
        if(blade!='PROJECT'){
            data.value.project_id=null;
        }
    })

    // Functions

	const modal_closed = () => emit('modal_closed')
    const delete_item = id => data.value.items = data.value.items.filter((x,i)=> i !== id )
        
    const add_item = () => {
        data.value.items.push({
            assign_to:null,
            expense_date:null,
            description:null,
            amount:null,
            vat_rate:null,
            payment_type:null
        })
        
    }
    
    if(!props.expense) {
        add_item() // Add first row
    }
    
    const submit = async () => {

        const result = await v$.value.$validate()
        if(!result) return

        if (!(data.value.items.length > 0 && data.value.items.filter(x =>!x.assign_to || !x.expense_date || !x.description || !x.amount || x.vat_rate === null || !x.payment_type).length == 0)) {
            submit_error.value = 'Masraf birimleri alanlarındaki bilgileri doldurunuz';
            return;
        }

        isSubmitting.value = true
        let request
        
        if(data.value.expense_id) {
            request = API.put('accounting/expenses/' + data.value.expense_id,data.value)
        } else {
            request = API.post('accounting/expenses',data.value)
        }

        request.then( response => {
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description: response.data.message
            })
            isSubmitting.value = false
            isOpened.value = false
            modal_closed()
            emitter.emit('load_expenses')
            
        }).catch( error => {
            isSubmitting.value = false
            submit_error.value = error.response?.data.message
        })
    }

</script>

<template>
    <Modal 
        :isOpen="true"
        title="Masraf Formu"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Kaydet"
        action_button_hint="Your environment's logs are made available for review directly on AWS CloudWatch."
        @modal_closed="modal_closed"
        @submit="submit"
        size="8xl"
    >
        <template v-slot:modal_content>
            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>
            <UIFormRow class="flex space-x-3">
                <div class="w-1/5">
                    <UISelect :search_enabled="false" :source="expense_sources" label="Masraf Kaynağı" title="Seç" display="box" :errors="v$.expense_source.$errors" v-model="data.expense_source"/>
                </div>
                <div class="w-3/5">
                    <UISelect :disabled="data.expense_source != 'PROJECT'" label="Hangi Proje?" title="Bir proje seç" :source="projects" display="box" :errors="v$.project_id.$errors" v-model="data.project_id"/>
                </div>
                <div class="w-1/5">
                    <UISelect :source="currencies_select" label="Para Birimi" title="Para Birimi" display="box" :errors="v$.currency.$errors" v-model="data.currency"/>
                </div>
            </UIFormRow>
            <UIFormRow class="flex space-x-3">
                
            </UIFormRow>
            <UIFormRow>
                <div class="font-bold">Masraf Birimleri *</div>
            </UIFormRow>
            <div class="flex space-x-3 mb-2">
                <div class="font-bold text-sm w-32 shrink-0">Cari</div>
                <div class="font-bold text-sm w-2/12">Tarih</div>
                <div class="font-bold text-sm w-2/12">Fiş No.</div>
                <div class="font-bold text-sm w-6/12">Açıklama</div>
                <div class="font-bold text-sm w-1/12">KDV Oranı</div>
                <div class="font-bold text-sm w-1/12">Tutar<small class="text-xxs ml-1">KDV Dahil</small></div>
                <div class="font-bold text-sm w-[10%]">Ödeme Şekli</div>
                <XIcon class="w-5 h-5 invisible"/>
            </div>
            <UIFormRow class="flex space-x-3 items-center" v-for="item,i in data.items" :key="'item_payment_row'+(Math.floor(Math.random() * 1000000000))">
                <UISelect :search_enabled="false" :key="'item_assign'+(Math.floor(Math.random() * 1000000000))" class="w-32 grow-0 shrink-0" :source="cost_source" title="Cari" display="box" v-model="item.assign_to"/>
                <UIDatePicker title="Tarih Seç" display="box" v-model="item.expense_date" class="w-2/12"/>
                <UIInput class="w-2/12" placeholder="Fiş No." v-model="item.receipt_no"/>
                <UIInput class="w-6/12" placeholder="Yaptığın masrafın açıklaması" v-model="item.description"/>
                <UISelect :search_enabled="false" :key="'item_vat'+(Math.floor(Math.random() * 1000000000))" class="w-1/12" :source="vat_rates_select" title="KDV %" display="box" v-model="item.vat_rate"/>
                <UICurrencyInput :options="{ currency: data.currency, locale:'tr' }"  class="w-1/12" v-model.lazy="item.amount"/>
                <UISelect :search_enabled="false" :key="'item_payment'+(Math.floor(Math.random() * 1000000000))" class="w-[10%]" :source="payment_methods" title="Ödeme Şekli" display="box" v-model="item.payment_type"/>
                <UITooltip title="Satırı sil" position="top" :class="{
                            'invisible' : data.items.length == 1
                        }">
                    <XIcon 
                        class="w-5 h-5 opacity-70 hover:opacity-100 cursor-pointer"
                        
                        @click="delete_item(i)"/>
                </UITooltip>
            </UIFormRow>
            <UIFormRow>
                <button class="border px-2 py-1 hover:bg-slate-100" @click="add_item" v-if="data.items.length < 10">Yeni Satır</button>
            </UIFormRow>
            <hr>
            <UIFormRow class="flex items-start space-x-3 mt-2 ">
                <div class="w-3/5">
                    <UITextarea v-model="data.note" :maxlength="200" :autogrow="false" label="Bu Masraf ile İlgili Notun" placeholder="Açıklamalarını buraya yazabilirsin" class="h-24 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-slate-50 p-2"/>
                </div>
                <div class="w-2/5 ml-auto bg-calypso-light rounded-iq p-2 mt-6 h-24">
                    <div class="flex">
                        <span>Müşteri</span>
                        <span class="w-2/3 ml-auto text-right">{{ helpers.format(total_client,data.currency) }}</span>
                    </div>
                    <div class="flex">
                        <span>{{event_company.name}}</span>
                        <span class="w-2/3 ml-auto text-right">{{ helpers.format(total_company,data.currency) }}</span>
                    </div>
                    <div class="flex">
                        <span class="font-bold">Toplam</span>
                        <span class="font-bold w-2/3 ml-auto text-right">{{ helpers.format(totals,data.currency) }}</span>
                    </div>
                </div>
            </UIFormRow>
        </template>
	</Modal>

</template>