const state = {
    list: {}
}

// getters
const getters = {
    alerts (state) {
        return state.list;
    }
}
  
// actions
const actions = {
    addAlert ({ commit }, data) {
        data.id = new Date().getTime();
        commit('addAlert', data)
        if(!data.closeable) {
            setTimeout(()=>{
                commit('deleteAlert', data.id)
            },data.timeout || 3000)
        }
    },
    deleteAlert ({ commit }, id) {
        commit('deleteAlert', id)
    }
}
  
// mutations
const mutations = {
    addAlert(state, data) {
        state.list[data.id] = data
    },
    deleteAlert(state, id) {
        delete state.list[id]
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}