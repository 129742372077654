<template>
	<TableView 
		title="Bütçe Kategorileri"
		:table_titles="table_titles" 
		api_url="definition/budget-categories" 
		:empty_state="empty_state"
		:sort_by="sort_by"
		:add_new="true"
		@add_new_closed="()=>add_new = false"
		custom_add_new_modal
		@add_new_open = "add_new_open"
		:add_new_titles="['Bütçe Kategorisini Güncelle', 'Yeni Bütçe Kategorisi Ekle']"
		ref="budget_category_table"
		/>

		<BudgetCategoryModal :budget_category="budget_category" :isOpen="isModalOpen" @modal_closed="modal_closed"/>
</template>

<script setup>
	import { ref,computed,inject } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import API from '@/api'
	import TableView from '@/views/TableView.vue'
	import BudgetCategoryModal from '@/modals/admin/definitions/budget/BudgetCategory.vue'
    
    const store = useStore()
	const emitter = inject('emitter')

	const sort_by = 'name'
	const budget_category = ref(null)
	const isModalOpen = ref(false)
	const budget_category_table = ref()
	emitter.on("load_budget_category_lists", () => budget_category_table.value?.fetch_data(true))
	const empty_state = {
        title:'Bütçe kategorilerine henüz veri eklememişsin!',
        description:'Bütçe kategorilerini ekleyerek.İşlerini hızlandırabilirsin'
	}

    let table_titles = [
		{
			title:'Kategori Adı',
            id:'name',
            width:'w-2/5',
			class:"font-bold truncate",
			sortable:true,
		},
        {
			title:'Kategori Adı (İngilizce)',
            id:'name_en',
            width:'w-2/5',
			class:"truncate"
		},
		{
			title:'',
			width:'w-1/5',
			type:'action'
		},
	]


	const add_new_open = id => {
        
		if(!id) {
            isModalOpen.value = true
            return
        }

        API.get('definition/budget-categories/'+ id).then(response => {
            budget_category.value = response.data
            isModalOpen.value = true
        })
	}

	const modal_closed =() => {
		isModalOpen.value = false
        budget_category.value = null  
    }


    
    
</script>
