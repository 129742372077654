<template>
    <Popover v-slot="{ open }" class="fixed right-9 bottom-3 z-20">
		<PopoverButton
			:class="open ? '' : 'text-opacity-90 opacity-50 hover:opacity-100 transform hover:rotate-90 transition duration-200'"
			class="z-10 relative w-16 h-16 bg-calypso shadow-lg rounded-full text-white font-medium text-5xl flex items-center justify-center"
		>
			<span><PlusIcon class="h-7 w-7"/></span>
		</PopoverButton>
		
		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="translate-y-1 opacity-0"
			enter-to-class="translate-y-0 opacity-40"
			leave-active-class="transition duration-150 ease-in"
			leave-from-class="translate-y-0 opacity-40"
			leave-to-class="translate-y-1 opacity-0"
		>
			<PopoverOverlay
				class="bg-calypso blur-[300px] hidden"
				:class='open ? "opacity-100 fixed inset-0" : "opacity-0"'
			/>
			
		</transition>

		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="translate-y-1 opacity-0"
			enter-to-class="translate-y-0 opacity-100"
			leave-active-class="transition duration-150 ease-in"
			leave-from-class="translate-y-0 opacity-100"
			leave-to-class="translate-y-1 opacity-0"
		>
			<PopoverPanel
				class="absolute z-10 w-96 px-4 mt-3 right-20 bottom-10 sm:px-0 lg:max-w-3xl"
			>
				<div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
					<div class="relative bg-white p-4">
						<h4 class="mb-2 text-calypso text-2xl font-thin uppercase">Yeni bir ...</h4>
						<div class="grid gap-x-2 gap-y-2 lg:grid-cols-2">
						<template v-for="item,index in FABitems"
								:key="item.name">
							
							<a
								:class="{
									'col-span-2 bg-calypso-light/50' : item.size == 2
								}"
								@click="openModal(index)"
								:data-type="item.type"
								v-if="!item.disabled"
								class="hover:shadow-lg group cursor-pointer flex flex-col items-center p-2 border hover:border-calypso transition duration-300 ease-in-out rounded-lg hover:bg-calypso-light focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
							>
								<div class="text-2xl pointer-events-none flex items-center justify-center flex-shrink-0 w-8 h-8 text-calypso sm:h-8 sm:w-8">
									<i class="fa-light" :class="item.icon"/>
								</div>
								<div class="pointer-events-none text-center">
									<p class="text-sm group-hover:text-black font-medium text-gray-700">{{ item.name }}</p>
									<p class="text-xs text-gray-500 hidden">{{ item.description }}</p>
								</div>
							</a>
						
						</template>
					</div>
					</div>
				</div>
			</PopoverPanel>
		</transition>
    </Popover>
	
	<component :key="random_key" :is="whichModal" :isOpen="true" @modal_closed="closeModal" v-bind="component_props"/>
	
</template>

<script setup>
	import { ref, watch, shallowRef, inject, computed } from 'vue'
	import { useStore } from 'vuex'
	import {  PlusIcon } from '@heroicons/vue/outline'
	import { Popover, PopoverButton, PopoverPanel,PopoverOverlay } from '@headlessui/vue'

	import TaskModal from '@/modals/NewTask.vue'
	import TaskMilestoneModal from '@/modals/NewTaskMilestone.vue'
	import PhoneCallLogModal from '@/modals/PhoneCallLog.vue'
	import NoteModal from '@/modals/Note.vue'

	import InspectionModal from '@/modals/Calendar/InspectionNew.vue'
	import OperationModal from '@/modals/Calendar/OperationNew.vue'
	import MeetingModal from '@/modals/Calendar/MeetingNew.vue'
	import LeaveModal from '@/modals/Calendar/LeaveNew.vue'
	import EmployeeAdvancePaymentModal from '@/modals/accounting/AdvancePaymentEmployeeNew.vue'
	import ExpenseModal from '@/modals/accounting/ExpenseNew.vue'
	//import ProjectModal from '@/modals/project/Project.vue'
	import BizDevDealModal from '@/modals/project/BizDevDeal.vue'
	import CCRequestModal from '@/modals/accounting/CCRequestNew.vue'
	import PaymentRequestModal from '@/modals/accounting/PaymentRequestNew.vue'
	//import SalesModal from '@/modals/NewTask.vue'
	import ContactModal from '@/modals/contact/ContactForm.vue'
	

	const emitter = inject('emitter')
	const store = useStore()

	const me = computed(() => store.state.auth.me)
	const component_props = ref({})
	const whichModal = shallowRef(null)
	const openModal = index => {
		component_props.value = {}
		whichModal.value = FABitems[index].type
	}
	const closeModal = () => whichModal.value = null

	const random_key = computed(()=> Math.floor(Math.random() * 1000000000))

	emitter.on('open_new_task_modal', data => {
		component_props.value = data
		whichModal.value = TaskModal
	})
	
	emitter.on('add_new_task_milestone', data => {
		component_props.value = data
		whichModal.value = TaskMilestoneModal
	})

	emitter.on('open_meeting_modal', data => {
		component_props.value = data
		whichModal.value = MeetingModal
	})

	emitter.on('open_new_phone_call_log_modal', data => {
		component_props.value = data
		whichModal.value = PhoneCallLogModal
	})

	emitter.on('open_new_note_modal', data => {
		component_props.value = data
		whichModal.value = NoteModal
	})

	emitter.on('open_expense_modal', data => {
		component_props.value = data
		whichModal.value = ExpenseModal
	})

	emitter.on('open_advance_payment_modal', data => {
		component_props.value = data
		whichModal.value = EmployeeAdvancePaymentModal
	})

	emitter.on('open_payment_request_modal', data => {
		component_props.value = data
		whichModal.value = PaymentRequestModal
	})

	emitter.on('open_cc_request_modal', data => {	
		component_props.value = data
		whichModal.value = CCRequestModal
	})

	emitter.on('open_operation_modal', data => {
		component_props.value = data
		whichModal.value = OperationModal
	})

	emitter.on('open_inspection_modal', data => {
		component_props.value = data
		whichModal.value = InspectionModal
	})

	emitter.on('open_leave_modal', data => {
		component_props.value = data
		whichModal.value = LeaveModal
	})
	
	
	watch(() => whichModal.value,  (data) => {
        	if(data) {
				document.getElementById('app').classList.add('blur')
			} else {
				document.getElementById('app').classList.remove('blur')
			}
        },
        { immediate: true }
    )

	

	const FABitems = [
			{
				name: 'İş Fırsatı (Proje)',
				type: BizDevDealModal,
				icon: 'fa-comments-question-check',
				size: 2,
				//disabled : me.value.team.division.can_manage_projects == 0
			},
			/*{
				name: 'Proje (Konfirme)',
				description: 'Yeni bir proje ekleyin',
				type: ProjectModal,
				icon: 'fa-party-horn'
			},*/
			{
				name: 'Toplantı',
				type: MeetingModal,
				icon: 'fa-people-arrows'
			},
			{
				name: 'To-Do',
				type: TaskModal,
				icon: 'fa-list-check'
			},
			{
				name: 'İş Avansı Talebi',
				type: EmployeeAdvancePaymentModal,
				icon: 'fa-face-tongue-money'
			},
			{
				name: 'Masraf Formu',
				type: ExpenseModal,
				icon: 'fa-file-invoice'
			},
			{
				name: 'Kredi Kartı Talebi',
				type: CCRequestModal,
				icon: 'fa-credit-card'
			},
			{
				name: 'Ödeme Talebi',
				type: PaymentRequestModal,
				icon: 'fa-hand-holding-dollar'
			},
			{
				name: 'İzin Talebi',
				type: LeaveModal,
				icon: 'fa-house-person-leave'
			},
			{
				name: 'Operasyon Katılımı',
				type: OperationModal,
				icon: 'fa-joystick'
			},
			{
				name: 'Keşif & Fuar Katılımı',
				type: InspectionModal,
				icon: 'fa-user-secret'
			},
			/*{
				name: 'Satış',
				type: SalesModal,
				icon: 'fa-cash-register'
			},
			{
				name: 'Tedarikçi Anlaşmalı Fiyat',
				type: SalesModal,
				icon: 'fa-money-check-dollar-pen'
			},*/
			{
				name: 'Kontakt Kişi',
				type: ContactModal,
				icon: 'fa-address-card'
			}
		]

</script>
