<script setup>
	import {ref,watch,computed,inject, onUnmounted} from 'vue'
    import { useStore } from 'vuex'
    import Modal from '@/components/UI/Modal.vue';
    import API from '@/api'

    const emitter = inject('emitter')
    const moment = inject('moment')

    const props = defineProps({
        isOpen : Boolean,
        phone_call_log : Object
    })
    
    const store = useStore()
    const emit = defineEmits(['modal_closed'])
    
    const project = computed(() => store.state.project.current)
    const phone_call_log = ref(props.phone_call_log || {})
    const projects = ref([])
    const contacts1 = computed(() => store.state.content.contacts.filter( x => {
            return x.contact_type == 'C' && x.contact_for == project.value.client_id
        }).map( contact => { return {value : contact.id, text : contact.name  } })
    )
    console.log(contacts1.value)
    const contacts = computed(() =>
		project.value.contacts.map((x) => {
			return { value: x.id, text: x.name }
		})
	)

    onUnmounted(() => {
        phone_call_log.value = {}
    })

    API.getProjectsByStatus(['LEAD','PREP','OPERATION','PRECLOSE']).then(response => {
        projects.value = response.data.map( project => { return {value : project.code, text : project.name, image : project.client.logo_full } }).sort((a, b) => a.text.localeCompare(b.text))
    })
    
    const is_submitting = ref(false)
    const submit_error = ref({
        title:null,
        description:null
    })
    
    const labels = ref([
        {value : 'inbound', text : 'Arandım' },
        {value : 'outbound', text : 'Aradım' }
    ])
    
    const ready2submit = computed(() => phone_call_log.value && phone_call_log.value.description && phone_call_log.value.call_direction && phone_call_log.value.called_at ? true : false )

	let isModalOpen = ref(props.isOpen);

	watch(() => props.isOpen, (data) => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    const modal_closed = () =>{
		isModalOpen.value = false
		emit('modal_closed')
	}

    const submit = () => {
        is_submitting.value = true
        
        let request

        phone_call_log.value.project_id = project.value.code
        
        if(phone_call_log.id) {
            request = API.put('phone-call-logs/' + phone_call_log.value.id ,phone_call_log.value)
        } else {
            request = API.post('phone-call-logs' ,phone_call_log.value)
        }   
            
        request.then(response => {
            modal_closed()
            emitter.emit('load_phone_calls')
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description: 'Telefon görüşmesi ' + (phone_call_log.id ? 'güncellendi!' : 'eklendi!')
            })
        })
        .catch((error) => {
            if (error.response) {
                submit_error.value = {
                    title:error.response.data.message,
                    description:error.response.data.errors.description[0]
                }
                is_submitting.value = false

                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        })
    }
</script>

<style scoped>

</style>

<template>
    <Modal 
        :close_on_outside_click=false :isOpen="isModalOpen" 
        @modal_closed="modal_closed" 
        @submit="submit"
        :title="phone_call_log && phone_call_log.id ? 'Telefon Görüşmesini Güncelle' : 'Telefon Görüşmesi Kaydet'"
        :is_submitting="is_submitting"
        :action_button_text="phone_call_log && phone_call_log.id ? 'Güncelle' : 'Ekle'"
        :backdrop="false"
        size="xl"
        :colored_header="true"
        :ready2submit="ready2submit"
        position="bottom-right"
    >
        <template v-slot:modal_content>
            <UIAlert :titleText="submit_error.title" type="danger" v-if="submit_error.title" class="mt-2 flex-row">
                <p>{{ submit_error.description }}</p>
            </UIAlert>
            <div class="py-3 border-b mb-3">
                Bu arama kaydını <b>{{ project.name }}</b> için kaydediyorsun.
            </div>
            <div class="grid grid-cols-3 pb-3">
                <UIDatePicker :maxDate="moment().format('YYYY-MM-DD')" :shortcuts="false" maxDate="today" label="Arama Tarihi" title="Tarih Seç" :time="true" v-model="phone_call_log.called_at"/>
                <UISelect :search_enabled="false" label="Kim Aradı?" key="labels_filter" title="Arama yönü Seç" :source="labels" class="mr-5" v-model="phone_call_log.call_direction"/>
                <UISelect label="Kiminle Görüştün?" key="project_filter" title="Kontakt Seç" :search_enabled="false" :source="contacts" class="mr-5" v-model="phone_call_log.contact_id"/>
            </div>
            <div class="mt-2">
                <UITextarea :lazy="false" :maxlength="500" :autogrow="false" v-model="phone_call_log.description" :styled="false" placeholder="Görüşme detaylarını buraya yazabilirsin" class="h-44 w-full pb-2 border-none outline-none text-sm text-gray-500 resize-none"/>
            </div>
        </template>
        <template v-slot:modal_footer>
            
        </template>
    </Modal>
</template>