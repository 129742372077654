<template>
    <Modal 
        :isOpen="isOpen"
        :title="title"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Kaydet"
        @modal_closed="modal_closed"
        @submit="submit"
        :backdrop="false"
        size="5xl"
        :colored_header="true"
        position="bottom-right"
    >
    <!-- position="bottom-right" -->
        <template v-slot:modal_content class="overflow-y-scroll">
            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>
            <div class="mt-3 pb-2 border-b flex">
                <UIInput label="Toplantı başlığı nedir?" placeholder="Örneğin Tanışma Toplantısı" :styled="false" v-model="form_data.subject" class="grow text-sm !p-0"/>
            </div>
            
            <div class="grid grid-cols-3 mb-2 py-3 border-b gap-2">
                <div>
                    <UIFormRow>
                        <UISelect :search_enabled="false" key="meeting_type" label="Ne tip bir toplantı planlıyorsun?" title="Bir toplantı tipi seç" :source="meeting_types_select" v-model="form_data.meeting_type" :errors="v$.meeting_type.$errors" class="mr-5"/>
                    </UIFormRow>
                    <!--
                    <UIFormRow v-if="selected_meeting_type.can_be_with_client">
                        <UISelectAutoComplete :source="clients" allow_new :isLoading="isClientListLoading" label="Hangi müşteri ile?" title="Bir müşteri seç" v-model="form_data.client"/>
                    </UIFormRow>
                    <div v-if="form_data.client && is_new_client" class="border border-gray-300 rounded p-2 mb-3">
                        <div class="text-sm mb-5">
                            Bu yeni müşteri için bir kontakt kişi oluşturman gerekiyor.
                        </div>
                        <UIFormRow>
                            <UIInput size="sm" :styled="false" label="Kontakt kişinin adı ve soyadı nedir?" v-model="form_data.new_contact.name"/>
                        </UIFormRow>
                        <UIFormRow class="flex space-x-2">
                            <UIInput size="sm" :styled="false" label="E-posta adresi nedir?" v-model="form_data.new_contact.email" class="w-1/2"/>
                            <UISelect :source="contact_positions" label="Ünvanı nedir?" title="Pozisyon seç" v-model="form_data.new_contact.position_id" class="w-1/2"/>
                        </UIFormRow>
                    </div>
                    <UIFormRow v-if="selected_meeting_type.can_be_with_supplier">
                        <UISelect :source="suppliers" :isLoading="isClientListLoading" label="Hangi tedarikçi ile?" title="Bir tedarikçi seç" v-model="form_data.supplier"/>
                    </UIFormRow>
                    <UIFormRow v-if="form_data.client">
                        <UISelect :source="projects" label="Hangi proje ile ilgili?" title="Bir proje seç" v-model="form_data.project"/>
                    </UIFormRow>

                    <UIFormRow v-if="selected_meeting_type.can_be_with_sponsor">
                        <UISelect :source="projects" label="Hangi proje ile ilgili?" title="Bir proje seç" v-model="form_data.project" v-if="!form_data.client"/>
                        <UISelect :source="sponsors" label="Hangi sponsor ile ilgili?" title="Bir sponsor seç" v-model="form_data.sponsor"/>
                    </UIFormRow>
                    -->
                    <UIFormRow>
                        <UIDateTimePicker label="Hangi tarihte?" title="Tarih seç" :time="true" v-model="form_data.dates"/>
                    </UIFormRow>
                    <UIFormRow>
                        <UISelect :search_enabled="false" key="meeting_type" label="Nerede?" title="Bir toplantı lokasyonu seç" :source="meeting_locations" v-model="form_data.location"/>
                    </UIFormRow>
                </div>
                <div class="col-span-2">
                    <UIFormRow >
                        <UISelect 
                            :source="all_companies"
                            :multiple="true"
                            :tags="true"
                            label="Hangi firma(lar) ile?"
                            title="Firma(ları) seç"
                            v-model="form_data.companies"
                            :disabled="!form_data.meeting_type"
                            disabled_title="Önce toplantı tipi seç"
                            help_text="Toplantıyı yapmak istediğiniz müşteri, iş ortağı veya sponsor seçin. Toplantı tipini seçtiğinizde ilgili firmalar burada listelenecektir."
                        />
                    </UIFormRow>
                    <UIFormRow>
                        <UISelect :tags="true" :use_arrow="false" key="participants" label="Katılımcılar kimler?" title="Katılımcıları seç" :multiple="true" :source="contacts" v-model="form_data.participants"/>
                    </UIFormRow>
                    <UIFormRow>
                        <UITextarea :autogrow="false" label="Dış katılımcılar" :styled="false" v-model="form_data.external_participants" class="text-sm mt-2" placeholder="Ekip ve kontaktlar haricinde katılacak kişilerin e-posta adreslerini virgül ile ayrılmış şekilde buraya yazabilirsin" />
                    </UIFormRow>
                    <span class="flex p-1 border text-sm items-center w-fit rounded select-none"
                        :class="{
                            'border-calypso bg-calypso-light' : form_data.share
                        }"
                        v-if="has_microsoft"
                        @click="toggle_share"
                    >
                    <i class="fa-regular fa-check mr-2" v-if="form_data.share"></i>
                        <img alt="Outlook calendar logo" class="h-4 mr-2" src="/assets/images/outlook.svg"> Outlook ile Paylaş 
                    </span>
                    <span v-if="!has_microsoft" class="bg-red-100 flex p-2 border text-sm items-center w-fit rounded select-none">
                        <img alt="Outlook calendar logo" class="h-8 mr-2" src="/assets/images/outlook.svg"> 
                        Bu toplantıyı Outlook ile paylaşmak için profil sayfanda hesaplarını eşleştirmelisin.
                    </span>
                </div>
            </div>
            <div class="grid grid-cols-2 mt-3 mb-2py-3 gap-y-2">
                <UIFormRow class="mr-2">
                    <UITextarea :disabled="!form_data.note_after_disabled" :autogrow="false" v-model="form_data.note" label="Not" class="h-32 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-gray-100 p-2"/>
                </UIFormRow>
                <UIFormRow>
                    <UITextarea  :disabled="form_data.note_after_disabled" :autogrow="false" v-model="form_data.note_after" label="Toplantı Sonrası Not" class="h-32 w-full resize-none w-full rounded-iq focus:outline-none focus:border-transparent focus:ring-calypso border bg-gray-100 p-2"/>
                </UIFormRow>
            </div>
        </template>
        <template v-slot:modal_footer>
            <span class="mr-auto"></span>
        </template>
        
	</Modal>

</template>

<script setup>
    // Imports
	import {ref,watch,computed, onMounted,inject} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Modal from '@/components/UI/Modal.vue'
    import useVuelidate from '@vuelidate/core'
    import { required, requiredIf, email, url, minLength } from '@/utils/i18n-validators'
    import _ from 'lodash'
    //import ModalOps from '@/composables/MeetingModal.js'

    let temp_participants

    // Props
	const props = defineProps({
        isOpen:Boolean,
        project_code:String,
        meeting:Object,
        client_id : [Number,String]
    })

    const emitter = inject('emitter')
    const store = useStore()
    const isSubmitting = ref(false)

    const is_new_client = ref(false)

    // Emits
	const emit = defineEmits(['modal_closed'])

    // VAR Definitions
    const title = ref(props.meeting ? 'Toplantıyı Güncelle' : 'Bir Toplantı Organize Et' )
    const form_data = ref({
        meeting_type : null,
        client : props.client_id || null,
        project : props.project_code || null,
        participants : null,
        external_participants:null,
        dates : null,
        location : null,
        note : null,
        note_after : null,
        share : true,
        new_contact:{},
        note_after_disabled:true,
        companies:[]
    })

    const rules = computed(() => ({
        meeting_type: { required },
        location: { required },
        dates: { required },
        new_contact: {
            name: { required : requiredIf(value => is_new_client.value) },
            email: {email, required : requiredIf(value => is_new_client.value) },
            position_id: { required : requiredIf(value => is_new_client.value) }
        }
    }))

    const v$ = useVuelidate(rules,form_data)

    const ready2submit = computed(() => !v$.value.$invalid )
    
    watch(() => form_data.value.client, data => {
        if(!data) return
        let client = store.state.clients.list.find(client => client.id == data)
        if(!client) {
            is_new_client.value = true
            return
        }
        is_new_client.value = false
    },{ immediate: true })

    const me = computed(() => store.state.auth.me)
    const calendars = computed(()=>me.value.calendars?me.value.calendars.map( x => x.platform ):[])
    const has_microsoft = computed(() => calendars.value && calendars.value.includes('microsoft'))
    const projects = ref([])

    API.getProjects({
            statuses:'LEAD,PREP,OPERATION,PRECLOSE'
        }).then(response => {
            projects.value = response.data.map(project => { 
                return {value : project.code, text : project.name, subtext : project.project_ref }
            }).sort((a, b) => a.text.localeCompare(b.text))
        })
    const client_contacts = ref([])
    const supplier_contacts = ref([])
    const selected_meeting_type = ref({
        can_be_with_client:0,
        can_be_with_supplier:0,
        can_be_with_sponsor:0,
    })

    if(props.project_code) {
        selected_meeting_type.value.can_be_with_client = 1
    }

    const isClientListLoading = ref(false)
    const submit_error = ref(false)
    
    const event_company = computed(() => store.state.auth.event_company)
    const clients = computed(() => store.getters['clients/select'])
    const suppliers = computed(() => store.getters['suppliers/list_select'])
    const users1 = computed(() => store.getters['content/users_select'])
    const sponsors = ref([])
    
    const all_companies = computed(() => {
        let l_clients = []
        if(selected_meeting_type.value.can_be_with_client == 1) {
            l_clients = _.cloneDeep(clients.value || [])
            l_clients.forEach(element => {
                element.subtext = "Müşteri"
                element.value = "C-" + element.value
            });
        }

        let l_suppliers = []
        if(selected_meeting_type.value.can_be_with_supplier == 1) {
            l_suppliers = _.cloneDeep(suppliers.value || [])
            l_suppliers.forEach(element => {
                element.subtext = "İş Ortağı"
                element.value = "S-" + element.value
            });
        }
        
        let l_sponsors = []
        if(selected_meeting_type.value.can_be_with_sponsor == 1) {
            l_sponsors = _.cloneDeep(sponsors.value || [])
            l_sponsors.forEach(element => {
                element.subtext = "Sponsor"
                element.value = "SP-" + element.value
            });
        }

        return [...l_clients, ...l_suppliers, ...l_sponsors].sort((a, b) => a.text.localeCompare(b.text))
    })

    const users = computed(() => {
        let team = users1.value.map( x => {
            return {
                value : 'U-' + x.value,
                text : x.text,
                subtext : event_company.value.name,
                image : x.image
            }
        })

        return [...team, ...client_contacts.value, ...supplier_contacts.value].sort((a, b) => a.text.localeCompare(b.text))
    })
    
    const meeting_types_select = computed(() => store.getters['content/meeting_types_select'])
    
    const meeting_locations = ref([
        {value:1,text: event_company.value.name + ' Ofis'},
        {value:2,text:'Müşteri ofisi'},
        {value:3,text:'Farklı bir lokasyon'},
        {value:4,text:'Online'}
    ])

    // Data models
    

    // Watchers

    watch(() => props.meeting, data => {
        if(!data) return
        
        form_data.value.id = props.meeting.id
        form_data.value.meeting_type = props.meeting.meeting_type
        form_data.value.subject = props.meeting.subject
        setTimeout(() => {
            form_data.value.supplier = props.meeting.supplier_id
            form_data.value.client = props.meeting.client_id
            form_data.value.project = props.meeting.project_id
        }, 1000);
        
        form_data.value.dates = [props.meeting.meeting_date,props.meeting.meeting_end]
        form_data.value.location = props.meeting.meeting_location
        form_data.value.note = props.meeting.meeting_note
        form_data.value.note_after = props.meeting.meeting_note_after
        form_data.value.note_after_disabled=true
        form_data.value.share = true

        if((new Date().getTime())>(new Date(props.meeting.meeting_end).getTime())){
            form_data.value.note_after_disabled=false
        }

        form_data.value.external_participants = props.meeting.participants.filter( x => x.is_external).map(x => x.email).join(',')
        temp_participants = props.meeting.participants.filter( x => !x.is_external).map(x => (x.type == "C" ? 'C-' : 'U-') + x.source_id)

    },{deep:true,immediate:true})


    watch(() => form_data.value.meeting_type, data => {
        if(!data) return
        selected_meeting_type.value = store.state.content.meeting_types.find( x => x.id == data)
    },{deep:true,immediate:true})

    const contacts = computed(() => {
        let c = []
        form_data.value.companies.forEach(company => {
            let cs = store.getters['content/contactsByOwner'](company).map(x=>{
                return {text : x.name, subtext : x.position.name, value : 'C-' + x.id, image : x.profile_picture_full}
            })
            c = [...c, ...cs]
        })
        console.log(c)
        return c
    })

    watch(() => form_data.value.client, (data, old) => {
        form_data.value.contact_participants = null
        if(old && old !== null) form_data.value.project = null
        
        if(!data || data == '') {
            form_data.value.project = null
            return
        }
        
        API.getClientSupplierContacts('C',form_data.value.client).then(response => {
            let client = clients.value.find( x => x.value == form_data.value.client)
            if(!client) return
            client_contacts.value = response.data.map(x=>{
                return {text : x.name, subtext : x.position.name + ' / ' + client.text, value : 'C-' + x.id, image : x.profile_picture_full}
            })
            if(temp_participants) form_data.value.participants = temp_participants
        })

        API.getProjects({
            clients     : form_data.value.client,
            statuses:'LEAD,PREP,OPERATION,PRECLOSE'
        }).then(response => {
            projects.value = response.data.map(project => { 
                return {value : project.code, text : project.name, subtext : project.project_ref }
            }).sort((a, b) => a.text.localeCompare(b.text))
        })
	},{ immediate: true })

    watch(() => form_data.value.supplier, data => {
        form_data.value.supplier_participants = null
        
        if(!data || data == '') return
        
        API.getClientSupplierContacts('S',form_data.value.supplier).then(response => {
            let supplier = suppliers.value.find( x => x.value == form_data.value.supplier)
            supplier_contacts.value = response.data.map(x=>{
                return {text : x.name, subtext : x.position.name + ' / ' + supplier.text, value : 'S-' + x.id, image : x.profile_picture_full}
            })
        })
	},{ immediate: true, deep:true })


    watch(() => form_data.value.project, data => {
        if(!data || data == '') return
        
        API.get('project/'+data+'/sponsors').then(response => {
            sponsors.value=response.data.map(x=>{
                return {text : x.sponsor_name, value : x.id}
            })
        })
	},{ immediate: true, deep:true })
	
	// Functions

	const modal_closed = () => emit('modal_closed')
	const submit = async () => {

        const result = await v$.value.$validate()
        if(!result) return

        let request
        isSubmitting.value = true
        if(props.meeting) {
            request = API.put('meetings/' + form_data.value.id,form_data.value)
        } else {
            request = API.post('meetings',form_data.value)
        }

        request.then(response => {
                modal_closed()
                store.dispatch('floating_alerts/addAlert', {
                    type:'tip',
                    title:'BAŞARILI!',
                    description:response.data.message
                })
                emitter.emit('calendar_refetch_events')
            })
            .catch((error) => {
                isSubmitting.value = false
                if (error.response) {
                    submit_error.value = error.response.data.message
                } else if (error.request) {
                    submit_error.value = 'İşlem sırasında bir hata oluştu'
                    console.log(error.request)
                } else {
                    submit_error.value = 'İşlem sırasında bir hata oluştu'
                    console.log('Error', error.message)
                }
                
            })
    }

    const toggle_share = () => form_data.value.share = !form_data.value.share

</script>