<script setup>
	import { ref, watch } from "vue";
	import { Switch } from "@headlessui/vue";

	const emit = defineEmits(["update:modelValue"]);
	const props = defineProps({
		modelValue: [String, Number, Boolean],
		label: String,
	});

	const inputVal = ref(false);

	watch(
		() => props.modelValue,
		(data) => {
			inputVal.value = data;
		},
		{ immediate: true }
	);

	watch(
		() => inputVal.value,
		(data) => {
			emit("update:modelValue", inputVal.value);
		},
		{ immediate: true, deep: true }
	);
</script>

<template>
    <div class="py-1 flex">
		<div class="mr-auto">{{ label }}</div>
        <Switch
            v-model="inputVal"
            :class="inputVal ? 'bg-calypso' : 'bg-gray-200'"
            class="relative inline-flex h-6 w-11 items-center rounded-full"
        >
            <span class="sr-only">Enable notifications</span>
            <span
                :class="inputVal ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-4 w-4 transform rounded-full bg-white transition"
            />
        </Switch>
    </div>
</template>
