<template>
    <Aside 
        :isOpen="isModalOpen"
        :ready2submit="ready2submit"
        :isSubmitting="isSubmitting"
        action_button_text="Ekle"
        @modal_closed="modal_closed"
        @submit="submit"
        title="Yeni Kontakt Kişi Ekle" 
        size="2xl"
    >
        <template v-slot:modal_content>
            <UIAlert type="tip" v-if="props.company">Bu kontakt kişi <span class="mx-1 font-bold">{{ props.company.name }}</span> için eklenecektir.</UIAlert>
            <UIAlert titleText="HATA!" type="danger" v-if="submit_error" class="mt-2 flex-row">
                <p>{{ submit_error }}</p>
            </UIAlert>
            <div class="flex space-x-5 m-4">
                <div class="w-36 items-center flex flex-col shrink-0">
                    <UIAvatar v-model="user_data.profile_picture_full" @delete="delete_profile_image" label="Profil Fotoğrafı" clickable/>            
                </div>
                <div class="w-full">
                    <div class="grid grid-cols-2 gap-x-2">
                        <UIFormRow class="col-span-2">
                            <UIInput v-model="user_data.name" label="Ad Soyad*" :errors="v$.name.$errors"/>
                        </UIFormRow>
                        <UIFormRow class="col-span-2">
                            <!-- <UISelect options_line_break v-model="user_data.position_id" :source="contact_positions" label="Ünvan *" title="Ünvan Seç" display="box" :errors="v$.position_id.$errors"/> -->
                            <UISelectAutoComplete :allow_new="true" v-model="user_data.position_id" :source="contact_positions" label="Ünvan *" title="Ünvan Seç" display="box" :errors="v$.position_id.$errors"/>
                        </UIFormRow>
                        <UIFormRow class="col-span-2" v-if="!props.company">
                            <UISelectAutoComplete @search="search" hint="Arama yapmak için en az 3 karakter gir" v-model="user_data.company_id" :source="companies" label="Şirket *" title="Şirket Seç" display="box"/>
                        </UIFormRow>
                        <UIFormRow class="col-span-2">
                            <UIInput v-model="user_data.email" label="E-Posta Adresi *" :errors="v$.email.$errors"/>
                        </UIFormRow>
                        <UIFormRow>
                            <UIInput lazy :country="event_company.country" type="phone" label="Telefon Numarası" v-model="user_data.phone"/>
                        </UIFormRow>
                        <UIFormRow>
                            <UIInput lazy :country="event_company.country" type="phone" v-model="user_data.mobile" label="Cep Telefonu Numarası"/>
                        </UIFormRow>
                        <UIFormRow>
                            <UIInput lazy :country="event_company.country" type="phone" v-model="user_data.fax" label="Faks"/>
                        </UIFormRow>
                    </div>
                </div>
            </div>
        </template>
    </Aside>
</template>

<script setup>
	import {ref,watch,inject,computed,onUnmounted} from 'vue'
    import { useStore } from 'vuex'
    import API from '@/api'
	import Aside from '@/components/UI/Aside.vue';
    import useVuelidate from '@vuelidate/core'
    import { required, email } from '@/utils/i18n-validators'

    const props = defineProps({
        isOpen:Boolean,
        company:Object
    })

    const emitter = inject('emitter')
    const emit = defineEmits(['modal_closed'])
    
    const store = useStore()

    
    const user_data = ref({})
    const isSubmitting = ref(false)
    const submit_error = ref(false)
    //const ready2submit = ref(false)
    
	const isModalOpen = ref(props.isOpen);
    const contact_positions = computed(() => store.state.content.contact_positions.map( position => { return {value : position.id, text : position.name } }).sort((a, b) => a.text.localeCompare(b.text)))
    const event_company = computed(() => store.state.auth.event_company)
    const companies = ref([])

	watch(() => props.company, data => {
        if(!data) return
        user_data.value.company_id = data.id
	},{ immediate: true,deep:true })
    
    watch(() => props.isOpen, data => {
			isModalOpen.value = data
		},{ immediate: true }
    )

    watch(() => user_data.value, (data) => {
		},{ immediate: true }
    )

    const modal_closed = () =>{
		isModalOpen.value = false
        user_data.value = {}
		emit('modal_closed')
	}

    const submit_status = status => {
        console.log(status)
        //ready2submit.value = status
    }

    const ready2submit = computed(() => {
        return user_data.value.name && user_data.value.position_id && user_data.value.company_id  && user_data.value.email ? true : false 
    })

    const search = search_data => {
        if(search_data.length < 3) {
            companies.value = []
            return
        }
        

        API.search(search_data.data).then(response => {
            let companies_local = []
            response.data.forEach(item => {
                if(item.type == 'supplier') {
                    companies_local.push({value:'S-' + item.id, text:item.name})
                }
                if(item.type == 'client') {
                    companies_local.push({value:'C-' + item.id, text:item.name})
                }
            })
            companies.value = companies_local
        })
    }

    const delete_profile_image = () => {
        user_data.value.profile_image_full = null
    }

    const rules = computed(() => ({
        name : { required },
        position_id:{ required },
        email : { required,email }
    }))

    const v$ = useVuelidate(rules, user_data)

    const submit = async () => {
        
        const result = await v$.value.$validate()
        if(!result) return
    
        isSubmitting.value = true
        API.post('contacts', user_data.value).then( result => {

            modal_closed()
            isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:result.data.message
            })
            if (user_data.contact_type=='C') {
                emitter.emit('load_client_contacts')
            }

            if (user_data.contact_type=='S') {
                emitter.emit('load_supplier_contacts')
            }
            
            API.getContacts()
            
        }).catch( error => {
            isSubmitting.value = false
            submit_error.value = error.response.data.message
        })
    }


</script>

<style scoped>
	
</style>