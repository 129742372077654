<script setup>
import { ref, watch, useSlots, onMounted } from "vue";
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionRoot,
    TransitionChild,
} from "@headlessui/vue";

import { XIcon } from "@heroicons/vue/solid";

const slots = useSlots();

const props = defineProps({
    isOpen: Boolean,
    title: String,
    action_button_text: {
        type: String,
        default: "OK",
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    colored_header: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: "md",
    },
    close_on_outside_click: {
        type: Boolean,
        default: false,
    },
    ready2submit: {
        type: Boolean,
        default: false,
    },
    showSubmitButton: {
        type: Boolean,
        default: true,
    },
    showOnlySlot: {
        type: Boolean,
        default: false,
    },
    noPadding: {
        type: Boolean,
        default: false,
    },
    submit_button_type: {
        type: String,
        default: "submit",
    },
});

const emit = defineEmits(["modal_closed", "submit"]);

let isOpened = ref(props.isOpen);

watch(
    () => props.isOpen,
    (meta) => {
        isOpened.value = props.isOpen;
        if (isOpened.value) {
            document.getElementById("app").classList.add("blur");
        } else {
            document.getElementById("app").classList.remove("blur");
        }
    }
);

onMounted(() => {
    //document.getElementById('app').classList.add('blur')
});

const closeModal = (outside) => {
    if (outside == "out" && !props.close_on_outside_click) return;
    isOpened.value = false;
    document.getElementById("app").classList.remove("blur");
    setTimeout(() => {
        emit("modal_closed");
    }, 500);
};

const submit = () => {
    emit("submit");
    //closeModal()
};
</script>

<template>
    <TransitionRoot appear :show="isOpened" as="template">
        <Dialog as="div" @close="closeModal('out')">
            <div class="fixed inset-0 z-15 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0"
                        enter-to="opacity-40"
                        leave="duration-200 ease-in"
                        leave-from="opacity-40"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay
                            class="fixed inset-0 bg-calypso opacity-50"
                        />
                    </TransitionChild>

                    <span
                        class="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>

                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 -right-28"
                        enter-to="opacity-100 -right-1"
                        leave="duration-300 ease-in"
                        leave-from="opacity-100 -right-1"
                        leave-to="opacity-0 -right-28"
                    >
                        <div
                            class="fixed flex flex-col top-0 right-0 h-screen inline-block w-full overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl"
                            :class="{
                                'max-w-4xl': size == '4xl',
                                'max-w-3xl': size == '3xl',
                                'max-w-2xl': size == '2xl',
                                'max-w-xl': size == 'xl',
                                'max-w-lg': size == 'lg',
                            }"
                        >
                            <DialogTitle
                                as="h3"
                                class="flex-none text-lg font-semibold leading-6 p-4 flex items-center"
                                :class="{
                                    'bg-oz-gradient text-white': colored_header,
                                }"
                            >
                                <span class="truncate" v-if="title">{{
                                    title
                                }}</span>
                                <slot name="modal_header"></slot>
                                <a
                                    @click="closeModal"
                                    class="p-2 opacity-50 hover:opacity-100 rounded-full cursor-pointer transition duration-200"
                                    :class="{
                                        'hover:bg-gray-100/30': colored_header,
                                        'hover:bg-gray-100': !colored_header,
                                        'ml-4': slots.modal_header,
                                        'ml-auto': !slots.modal_header,
                                    }"
                                >
                                    <XIcon class="w-4 h-4" />
                                </a>
                            </DialogTitle>
                            <slot name="modal_header_image"></slot>
                            <div class="flex flex-col flex-grow overflow-y-auto overflow-x-hidden"
                                :class="{
                                    'px-4' : !noPadding
                                }"
                            >
                                <slot name="modal_content"></slot>
                            </div>

                            <div
                                class="bg-slate-50 pt-4 px-4 flex pb-4 flex-none"
                                v-if="showSubmitButton || showOnlySlot"
                            >
                                <slot name="modal_footer"></slot>
                                <UIButton
                                    v-if="!showOnlySlot"
                                    size="normal"
                                    class="ml-auto"
                                    :disabled="isSubmitting || !ready2submit"
                                    :loading="isSubmitting"
                                    :type="submit_button_type"
                                    @click="submit"
                                    :text="action_button_text"
                                />
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
