const state = {
    list: [],
    current_client:null,
    paginator:null,
    users:[],
    statuses:[
        {id:0,value:"Hepsi"},
        {id:1,value:"Mevcut"},
        {id:2,value:"Potansiyel"}
    ]
}

// getters
const getters = {
    list (state) {
        return state.list;
    },
    paginator (state) {
        return state.paginator;
    },
    status: (state) => (status) => {
        return state.statuses.find((x) => x.id == status).value
    }
}
  
// actions
const actions = {
    
}
  
// mutations
const mutations = {
    setCurrentClient(state, data) {
        state.current_client = data
    },
    setPaginator (state, data) {
        data.links.splice(0,1)
        data.links.splice(-1,1)
        state.paginator = data
    },
    setAttendees (state, data) {
        state.list = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}