<script setup>
    import { ref, computed } from 'vue'

    const logo = ref()
    const domain = computed(()=>window.location.hostname)

    const load_default = () => {
        logo.value.src = '/assets/images/icon_IQ.png'
    }
</script>

<template>
    <img ref="logo" :src="'https://s3.eu-west-2.amazonaws.com/easy.events/event_company/logo/'+domain+'_logo.png'" @error="load_default"/>
</template>