<script setup>
    import { ref, computed, onMounted, watch, inject } from "vue"
    import API from "@/api"
    import ChecklistItem from '@/modals/def/ChecklistItem.vue'
    import { useStore } from 'vuex'

    const store = useStore()
    const emitter = inject('emitter')
    const moment = inject('moment')

    const props = defineProps({
        active_sections_items : Array,
        active_section : Object,
        active_checklist : Object
    })
    
    const dataToUpdate = ref({}) 
    const isNewItemModalOpen = ref(false)
    const ref_delete_section = ref()

    const empty_state_actions = [
        {
            title : 'Yeni Satır Ekle',
            icon : 'plus',
            click : () => isNewItemModalOpen.value = true
        }
    ]

    const reset_section = () => {
        ref_delete_section.value.show({
			title: 'Emin misin?',
			message: 'Bu checklist bölümünde bulunan tüm satırları silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Tüm Satırları Sil',
		}).then(result => {
			if (result) {
				API.delete('checklists/' + props.active_checklist.id + '/section/' + props.active_section.id,{reset:true})
                .then(response => {
                    store.dispatch('floating_alerts/addAlert', {
                        type:'tip',
                        title:'BAŞARILI!',
                        description:response.data.message,
                        closeable:false
                    })
                    emitter.emit('load_def_checklist')
                })
				.catch(error => {
					store.dispatch('floating_alerts/addAlert', {
						type:'warning',
						title:'HATA!',
						description:error.data.message
					})
				})
			} 
		})
        
    }

    const delete_section = () => {
        ref_delete_section.value.show({
			title: 'Emin misin?',
			message: 'Bu checklist bölümünü içindeki satırlarla birlikte silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Bölümü Sil',
		}).then(result => {
			if (result) {
				API.delete('checklists/' + props.active_checklist.id + '/section/' + props.active_section.id)
                .then(response => {
                    store.dispatch('floating_alerts/addAlert', {
                        type:'tip',
                        title:'BAŞARILI!',
                        description:response.data.message,
                    })
                    emitter.emit('load_def_checklist',true)
                })
				.catch(error => {
					store.dispatch('floating_alerts/addAlert', {
						type:'warning',
						title:'HATA!',
						description:error.response.data.message,
					})
				})
			} 
		})
        
    }

    const modal_closed = () => {
        dataToUpdate.value = {}
        isNewItemModalOpen.value = false
        emitter.emit('load_def_checklist')
    }

    const update_item = id => {
        dataToUpdate.value = props.active_sections_items.find( x => x.id == id )
        if(dataToUpdate.value.recurrence == 'interval') {
            if(dataToUpdate.value.recurrence_detail.includes('|')) {
                const recurrence_detail_data = dataToUpdate.value.recurrence_detail.split('|')

                dataToUpdate.value.interval = recurrence_detail_data[0]
                dataToUpdate.value.interval_day = recurrence_detail_data[1]
            } else {
                dataToUpdate.value.interval = dataToUpdate.value.recurrence_detail
            }
        }

        if(dataToUpdate.value.recurrence == 'specific_date') {
            dataToUpdate.value.specific_date = dataToUpdate.value.recurrence_detail
        }

        isNewItemModalOpen.value = true
    }

    const delete_data = id => {
        ref_delete_section.value.show({
			title: 'Emin misin?',
			message: 'Bu checklist satırını silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Satırı Sil',
		}).then(result => {
			if (result) {
				API.delete('checklists/' + props.active_checklist.id + '/item/' + id)
                .then(response => {
                    store.dispatch('floating_alerts/addAlert', {
                        type:'tip',
                        title:'BAŞARILI!',
                        description:response.data.message,
                    })
                    emitter.emit('delete_checklist_item', id)
                })
				.catch(error => {
					store.dispatch('floating_alerts/addAlert', {
						type:'warning',
						title:'HATA!',
						description:error.data.message
					})
				})
			} 
		})
    }

    const recurrence_options = ref([
        {value:'once',text:'Tek Sefer'},
        {value:'interval',text:'Tekrarlayan'},
        {value:'specific_date',text:'Belirli bir tarih'}
    ])

    const week_days = ref([
        {value:'monday',text:'Pazartesi'},
        {value:'tuesday',text:'Salı'},
        {value:'wednesday',text:'Çarşamba'},
        {value:'thursday',text:'Perşembe'},
        {value:'friday',text:'Cuma'},
        {value:'saturday',text:'Cumartesi'},
        {value:'sunday',text:'Pazar'},
    ])

    const recurrence_interval_options = ref([
        {value:'daily',text:'Hergün'},
        {value:'once_every_two_days',text:'İki günde bir'},
        {value:'once_every_three_days',text:'Üç günde bir'},
        {value:'once_every_four_days',text:'Dört günde bir'},
        {value:'once_every_five_days',text:'Beş günde bir'},
        {value:'once_every_six_days',text:'Altı günde bir'},
        {value:'weekly',text:'Her hafta'},
        {value:'once_every_two_weeks',text:'İki haftada bir'},
        {value:'once_every_three_weeks',text:'Üç haftada bir'},
        {value:'monthly',text:'Her ay'}
    ])

    const table_titles = [
		{
			title:'Yapılacak İş'
		},
        {
			title:'Bağımlı',
            width:'w-24'
		},
        {
			title:'Başlangıç',
            width:'w-32'
		},
        {
			title:'Bitiş',
            width:'w-32'
		},
		{
			title:'Olma Sıklığı',
            width:'w-36'
		},
		{
			title:'',
            width:'w-36'
		}
	]

    const display_recurrence_detail = (recurrence,data) => {
        if(!data) return

        if(recurrence == 'specific_date') {
            return moment(data).format("DD MMMM")
        }


        data = data.split('|')
        if(!['monthly','once_every_three_weeks','once_every_two_weeks','weekly'].includes(data[0])) {
            return '<span>' + recurrence_interval_options.value.find(x=>x.value == data[0]).text + '</span>'
        }
        if(['once_every_three_weeks','once_every_two_weeks','weekly'].includes(data[0])) {
            return '<div>' + recurrence_interval_options.value.find(x=>x.value == data[0]).text + '</div><div>' + week_days.value.find(x=>x.value == data[1]).text  + '</div>'
        }
        if(data[0] == 'monthly') {
            return '<div>Her ayın ' + data[1]  + '. günü</div>'
        }
    }

    const menu_options = ref([
		{
			name:'',
			items:[
                {
					icon:'broom-wide',
                    name: 'Bölüm İçeriğini Sıfırla',
					href : reset_section
				},
                {
					icon:'trash',
                    name: 'Bölümü Sil',
					href : delete_section,
                    danger : true
				}
			]
		}
	])

    const get_dependent_info = id => props.active_checklist.items.find(x=>x.id == id).description

</script>

<template>
    <template v-if="active_section">
        <div class="flex items-start">
            <UIPageTitle :title="active_section.section_title"/>
            <UIButton type="submit" text="Satır Ekle" icon="plus" class="ml-auto border" @click="() => isNewItemModalOpen = true"/>
            <UIMenu :options="menu_options" position="right" title="Diğer İşlemler"/>
        </div>
    </template>
    <UIEmptyState
        class="mt-20"
        title="Bu checklist bölümüne henüz hiç satır eklenmemiş!"
        description=""
        :actions="empty_state_actions"
        v-if="active_sections_items.length == 0"
    />
    
    <div v-if="active_sections_items.length > 0">
        <UITable :filter_row_enabled="false" :search_enabled="false" :titles="table_titles">
            <template v-slot:rows>
                <tr v-for="item in active_sections_items" :key="item.id" class="group">
                    <UITableCell :show="item.description" class="truncate text-sm"/>
                    <UITableCell class="text-sm text-center">
                        <UITooltip :title="get_dependent_info(item.dependent_to)" v-if="item.dependent_to">
                            <i class="rounded-full fa-regular text-white fa-check justify-center items-center p-1 bg-calypso w-6 h-6 flex"/>
                        </UITooltip>
                    </UITableCell>
                    <UITableCell class="truncate text-sm text-center">{{ item.starts_at ? item.starts_at + ' gün' : '-' }}</UITableCell>
                    <UITableCell class="truncate text-sm text-center">{{ item.ends_at ? item.ends_at + ' gün' : '-' }}</UITableCell>
                    <UITableCell class="truncate text-sm text-center">
                        {{ recurrence_options.find(x=>x.value == item.recurrence).text }}
                        <div class="text-xs text-gray-500" v-html="display_recurrence_detail(item.recurrence,item.recurrence_detail)"/>
                    </UITableCell>
                    <UITableCell class="truncate text-right">
                        <UIButton size="sm" type="warning" text="Güncelle" class="ml-auto invisible group-hover:visible button--tertiary-light" @click="update_item(item.id)"/>
                        <UIButton size="sm" type="danger" text="Sil" class="ml-1 invisible group-hover:visible" @click="delete_data(item.id)"/>
                    </UITableCell>
                </tr>
            </template>
        </UITable>
        <div class="text-sm text-gray-600 p-2 border-b border-gray-100 flex hidden">
            <div class="flex-grow font-bold">Yapılacak İş</div>
            <div class="ml-auto w-24 shrink-0 font-bold">Başlangıç</div>
            <div class="w-24 shrink-0 font-bold">Bitiş</div>
            <div class="w-20 shrink-0 font-bold">Olma Sıklığı</div>
        </div>
        <div
            class="text-sm text-gray-600 p-2 border-b border-gray-100 flex hover:bg-gray-50 hidden"
            v-for="item in active_sections_items"
            :key="item.id"
            @click="update_item(item.id)"
        >
            <div class="flex-grow">{{ item.description }}</div>
            <div class="ml-auto w-24 shrink-0">{{ item.starts_at }} gün</div>
            <div class="w-24 shrink-0">{{ item.ends_at }} gün</div>
            <div class="w-20 shrink-0">{{ item.recurrence == 'once' ? 'Tek sefer' : 'Tekrarlı' }}</div>
        </div>
    </div>
    <UIConfirm ref="ref_delete_section" :destructive="true"/>
    <ChecklistItem 
        :dataToUpdate="dataToUpdate" 
        :active_checklist="active_checklist" 
        :isOpen="isNewItemModalOpen" 
        @modal_closed="modal_closed"
        :section_id="active_section.id"
    />
</template>