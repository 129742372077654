<script setup>
    import { ref, watch, toRef } from 'vue'
    import useFileDialog from '@/composables/useFileDialog'

    const props = defineProps({
        src: [String],
        instruction: [String]
    })

    const emit = defineEmits(['delete','add'])
    
    const ref_delete = ref()
    const image_uploader = useFileDialog()
    const src = toRef(props, 'src')

    const hide_image = e => {
        e.target.style.visibility = 'hidden'
    }
    
    watch(() => image_uploader.files.value, data => {
        if(!data || data.length == 0) return
        var reader = new FileReader()
        var f = data[0];
        reader.onload = e => {
            emit('add',e.target.result)
            image_uploader.reset()
        }
        reader.readAsDataURL(f)
    })

    const delete_image = () => {
        ref_delete.value.show({
			title: 'Emin misin?',
			message: 'Bu görseli silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Görseli Sil',
		}).then(result => {
			if (result) {
                emit('delete')
			} 
		})
    }

</script>

<template>
    
    <div v-if="src" class="relative">
        <div class="absolute opacity-0 hover:opacity-100 bg-calypso-light/50 w-full h-full duration-300 flex items-center justify-center">
            <UIButton type="white" class="border" text="Görseli kaldır" @click="delete_image"/>
        </div>
        <img :src="src" @error="hide_image">
        <UIConfirm ref="ref_delete" :destructive="true"/>
    </div>
    <div v-else class="h-32 border bg-gray-50 bg-calypso-light/50 duration-300 rounded p-4 flex flex-col items-center justify-center">
        <UIButton type="white" class="border" text="Görsel yükle" @click="image_uploader.open"/>
        <span class="mt-2 text-sm text-gray-500" v-if="instruction">{{ instruction }}</span>
    </div>
</template>