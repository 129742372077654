import {ref,readonly,watch,computed} from 'vue'
import { hasOwn } from '@vueuse/shared'

const DEFAULT_OPTIONS = {
    multiple: true,
    accept: '*',
};

export default function useFileDialog(options = {}) {
    //const { document = defaultDocument, } = options;
    const files = ref(null);
    let input;
    if (document) {
        input = document.createElement('input');
        input.type = 'file';
        input.onchange = event => {
            const result = event.target;
            files.value = result.files;
        }
    }
    const open = (localOptions) => {
        if (!input)
            return;
        const _options = Object.assign(Object.assign(Object.assign({}, DEFAULT_OPTIONS), options), localOptions);
        input.multiple = _options.multiple;
        input.accept = _options.accept;
        if (hasOwn(_options, 'capture'))
            input.capture = _options.capture;
        input.click();
    };
    const reset = () => {
        files.value = null;
        if (input)
            input.value = '';
    };
    return {
        files: readonly(files),
        open,
        reset,
    };
}