<template>
    <UILabel :label="label" :rand_id="id || rand_id" :error="error" :class="label_class" :help_text="help_text"/>
    <div class="mt-4 flex justify-center w-36 h-36 rounded-full group relative overflow-hidden">
        <i class="fa-thin fa-circle-user text-[9rem]" v-if="!avatarImage"/>
        <img class="w-36 h-36 rounded-full" :src="avatarImage" v-if="avatarImage"/>
        <div class="absolute w-36 flex items-center justify-center text-white bg-calypso/50 hover:bg-calypso/70 opacity-0 group-hover:opacity-100 duration-300"
            :class="{
                'h-[4.5rem]' : avatarImage,
                'h-36' : !avatarImage
            }"
        @click="fileUploadField.click()" v-if="clickable">
            <i class="fa-regular"
                :class="{
                    'fa-edit text-2xl' : avatarImage,
                    'fa-upload text-3xl' : !avatarImage
                }"
            />
        </div>
        <div class="absolute bottom-0 w-36 h-[4.5rem] flex items-center justify-center text-white bg-red-600/50 hover:bg-red-600/70 opacity-0 group-hover:opacity-100 duration-300" @click="delete_image" v-if="clickable && avatarImage"><i class="fa-regular fa-trash text-2xl"/></div>
    </div>
    <input 
        ref="fileUploadField"
        :id="id || rand_id"
        type="file" 
        @change="readFile" 
        class="hidden"
    >

    <Modal title=" " 
        :isOpen="is_open" 
        action_button_text="Kaydet"
        :ready2submit="true"
        @modal_closed="modal_closed"
        @submit="useCropperProfileImage"
        size="md"
    
    >
        <template v-slot:modal_content>
            <cropper
                    class="cropper"
                    ref="cropperRef"
                    :src="avatarImageTemp"
                    :stencil-props="{
                        handlers: {},
                        movable: false,
                        scalable: false,
                        aspectRatio: 1,
                    }"
                    :stencil-size="{
                        width: 300,
                        height: 300
                    }"
                    :resize-image="{
                        adjustStencil: false
                    }"
                    image-restriction="stencil"
                    @change="cropChange"
                />
        </template>
	</Modal>
</template>

<script setup>
    import { ref,computed,watch } from 'vue'
    import Modal from '@/components/UI/Modal.vue'
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css'

    const props = defineProps({
        modelValue: [String,Number],
        placeholder: String,
        error:Boolean,
        label:String,
        label_class:String,
        styled:{
            type:Boolean,
            default:true
        },
        size:{
            type:String,
            default:'normal'
        },
        id:String,
        tabIndex:[String,Number],
        help_text:String,
        clickable: Boolean
    })
    
    const avatarImage = ref(props.modelValue)
    const avatarImageTemp = ref()
    const fileUploadField = ref(null)
    const is_open  =ref(false)
    const rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))
    const cropperRef = ref()
    const emit = defineEmits(['update:modelValue','delete'])


    watch(() => props.modelValue, (data) => {
			avatarImage.value = data
		},{ immediate: true }
    )

    const cropChange = ({ coordinates, canvas }) => {
		
	}

    const delete_image = () => emit('delete')

    const useCropperProfileImage = () => {
        const { canvas } = cropperRef.value.getResult()
        emit('update:modelValue', canvas.toDataURL())
        is_open.value = false
        cropperRef.value = null
    }

    const modal_closed = () => is_open.value = false

    const readFile = () => {
        var reader = new FileReader()
        var f = fileUploadField.value.files[0];
        reader.onload = e => {
            avatarImageTemp.value = e.target.result
            is_open.value = true
        }
        reader.readAsDataURL(f)
    }

</script>

<style scoped>
	.cropper {
        height: 400px;
        width: 400px;
        background: #DDD;
    }
</style>