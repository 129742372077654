<script setup>
	import { computed, ref, watch, onBeforeMount, onMounted, provide } from "vue";
	
	import API from "@/api";
	import { useStore } from "vuex";
	import { L10n, setCulture } from "@syncfusion/ej2-base";
	import {
		RichTextEditorComponent as EjsRichtexteditor,
		Toolbar,
		HtmlEditor,
        QuickToolbar
	} from "@syncfusion/ej2-vue-richtexteditor";
	import _ from "lodash";

	const store = useStore();

	const isFormFiedlOptionModalOpen = ref(false);

	const active_field = computed(() => store.state.form.active_field);
	const field = ref({});

	const richtexteditor = [Toolbar, HtmlEditor,QuickToolbar];
	const rteInstance = ref();
	provide("richtexteditor", richtexteditor);
	setCulture("tr");

	watch(
		() => active_field.value,
		(data) => {
			field.value = _.cloneDeep(active_field.value);
		},
		{ immediate: true, deep: true }
	);

	const toolbarSettings = {
		type: "Expand",
		items: [
			"Formats",
			"Alignments",
			"|",
			"Bold",
			"Italic",
			"Underline"
		],
	};

    const update = () => {
        
        API.saveFormField(field.value.id, field.value)
			.then( response => {
				store.dispatch("form/updateField", {
					id: field.value.id,
					field: field.value,
				})
			})
			.catch( error => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
        }

	const form_field_option_text = (option) => {
		return;
		isFormFiedlOptionModalOpen.value = true;
		selected_option.value = option;
	};

	const new_contract_type_options = [
		{ value: "file", text: "Dosya yükleyerek", subtext:"Uzun metinler için tercih edilmeli. Metni PDF dosyası olarak ekleyebilirsiniz. Ekleyeceğiniz dosya linke tıklandığında ayrı bir pencerede açılır." },
		{ value: "text", text: "Metin girerek", subtext:"Kısa netinler için tercih edilmeli.Metni doğrudan kopyalayıp editöre yapışitrabilirsin. Linke tıklandığında metin bir pop-up penceresi ile gösterilir." }
	];
</script>

<template>
    <UIFormRow>
        <UIInput
            label="Sözleşme başlığı"
            class="grow"
            placeholder="Örneğin okudum ve kabul ettim"
            v-model="field.props.title"
        />
    </UIFormRow>
    <UIFormRow>
        <UIInput
            label="Link vermek istediğin öbek"
            class="grow"
            help_text="sasddsfdasd"
            placeholder="Örneğin okudum ve kabul ettim"
            v-model="field.props.link"
        />
    </UIFormRow>
    <UIFormRow>
        <UIRadioGroup
            class="w-fit"
            :search_enabled="false"
            :options="new_contract_type_options"
            label="Sözleşme metnini nasıl eklemek istersin?"
            display="box"
            v-model="field.props.contract_type"
        />
    </UIFormRow>
    <UIFormRow class="" v-if="field.props.contract_type == 'file'">
        <UIFileInput label="Dosya Seç" class="grow" v-model="field.props.file" />
    </UIFormRow>

    <UIFormRow
        class="gap-2"
        :class="{
            hidden: field.props.contract_type != 'text',
        }"
    >
        <ejs-richtexteditor
            ref="rteInstance"
            v-model="field.props.text"
            :toolbarSettings="toolbarSettings"
        />
    </UIFormRow>
    
    <UIFormRow>
        <UIButton
            class="w-24 h-10 items-center"
            type="submit"
            text="Kaydet"
            @click="update"
        />
    </UIFormRow>
</template>
