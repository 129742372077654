<template>
  	<div>
		<UILabel :label="label" :rand_id="id || rand_id" :isFocused="isFocused"/>
		<div class="flex items-center w-full rounded-iq focus:outline-none focus:ring-calypso"
			:class="{
				'border border-gray-300 bg-gray-50 p-2 h-[42px]' : props.display !== 'inline',
				'h-[24px]' : props.display == 'inline',
				'ring-calypso' : !error,
           		'ring-red-600' : error,
			}"
		>
			<UIDatePicker title="Tarih Seç" v-model="selected_date" display="inline" @focus="setIsFocused"/>
			<ClockIcon class="ml-2 w-4 h-4 text-calypso mr-1"/>
			<UISelect key="time_start" title="Kaçtan?" :source="start_intervals" v-model="start_time" :max_time="end_time" @focus="setIsFocused"/>
			<ArrowSmRightIcon class="w-3 h-3"/> 
			<UISelect class="ml-2" key="time_end" title="Kaça?" :source="end_intervals" :min_time="start_time" v-model="end_time" @focus="setIsFocused"/>	
		</div>
		<div class="validation_error" v-if="error">{{errors[0].$message}}</div>
	</div>
</template>

<script setup>
	import { ref,computed,inject,watch } from 'vue'
	import { ClockIcon, ArrowSmRightIcon } from '@heroicons/vue/outline'
	const moment = inject('moment')

	const props = defineProps({
        modelValue:[String,Number,Array,Object],
        title: String,
		errors:Array,
		label: String,
		id : String,
		display : {
            type : String,
            default : 'inline'
        }
    })

	const rand_id = computed(()=>'date_input_' + (Math.floor(Math.random() * 1000000000)))
	const emit = defineEmits(['update:modelValue'])

	const error = computed(() => props.errors && props.errors.length > 0)

	const selected_date = ref(props.modelValue ? moment(props.modelValue[0]).format('YYYY-MM-DD') : '')
    const start_time = ref(props.modelValue ? (moment(props.modelValue[0]).format('HH:mm') != "00:00" ? moment(props.modelValue[0]).format('HH:mm') : null) : null)
    const end_time = ref(props.modelValue ? (moment(props.modelValue[1]).format('HH:mm') != "00:00" ? moment(props.modelValue[1]).format('HH:mm') : null) : null)
	const isFocused = ref(false)

    const emit_data = () => {
        emit('update:modelValue', [selected_date.value + ' ' + start_time.value, selected_date.value + ' ' + end_time.value])
    }

	watch(() =>  [selected_date.value,start_time.value,end_time.value], emit_data )

	watch(() =>  [start_time.value], data => {
		if(moment(data[0], ['h:m a', 'H:m']).isAfter(moment(end_time.value, ['h:m a', 'H:m']))) {
			end_time.value = null
		}
	})

	watch(() =>  props.modelValue, data => {
		//console.log(data)
	}, {deep : true })
    
	let value = {
		interval: '00:15:00',
		startTime: '06:15:00',
		endTime: '22:15:00'
    }

    let tmp = moment(value.interval, "HH:mm:ss")
    let dif = tmp - moment().startOf("day")

    let startIntervalTime = moment(value.startTime, "HH:mm:ss").add(-dif, "ms")
    let finishTime = moment(value.endTime, "HH:mm:ss")

    
    let time_intervals = []

	while(startIntervalTime < finishTime) {
        let option = startIntervalTime.format("HH:mm")
        let format = {value : option, text : option}
        time_intervals.push(format)
		startIntervalTime.add(dif, "ms")
    }
	
	const end_intervals = computed(() => {
		let current_start_time = moment(start_time.value, "HH:mm")
		let options = []
		time_intervals.forEach(item => {
			if(moment(item.value, "HH:mm") <= current_start_time) return
			options.push(item)
		})
		return options
	})
    
    
    
    const start_intervals = ref(time_intervals)

	const setIsFocused = data => isFocused.value = data

</script>

<style scoped>
	.flatpickr-input {
		display: none;
	}
	:global(.flatpickr-months .flatpickr-month) {
		background-color: #fff;
	}

	:global(.flatpickr-weekdays) {
		background-color: #fff;
	}

	:global(span.flatpickr-weekday) {
		background-color: #fff;
	}

	:global(.flatpickr-days) {
		border: none;
		width: 200px;
	}

	:global(.flatpickr-day) {
		height: 28.50px;
		line-height: 26.50px;
	}

	:global(.flatpickr-calendar.inline) {
		box-shadow: none;
		width: 220px;
	}

	:global(.flatpickr-innerContainer) {
    	border: none;
		width: 200px;
		margin: 0 10px;
	}
	:global(.flatpickr-current-month span.cur-month:hover) {
    	background-color: transparent;
	}

	:global(.flatpickr-current-month .numInputWrapper) {
		width:7ch;
	}

	:global(.dayContainer) {
		width: 200px;
		min-width: 200px;
		max-width: 200px;
	}

	:global(.flatpickr-current-month){
    	font-size: 1rem;
	}

</style>