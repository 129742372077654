import API from '@/api'
import store from '../store'

class BizdevDeal {
    
    constructor(project) {
        this.project = project
        this.me = store.state.auth.me
        this.event_company = store.state.auth.event_company
    }

    setProject(project) {
        this.project = project
    }

    setLanguages() {
        if(this.project && this.project.languages.length > 0) return this.project.languages.map( x => x.code)
        return this.event_company.languages ? this.event_company.languages.map(x=>x.language_code) : []
    }

    setFinFeePercentage() {
        if(this.project) return Math.round(this.project.service_fee_amount)
        return this.event_company.fin_fee_type == 'PERCENTAGE' ? Math.round(this.event_company.fin_fee_amount * 100) : 0
    }

    setFinFeeFixed() {
        if(this.project) return this.project.service_fee_amount
        return this.event_company.fin_fee_type == 'FIXED' ? this.event_company.fin_fee_amount : 0
    }

    setValues() {
        return {
            code                : this.project ? this.project.code : null,
            name                : this.project ? this.project.name : null,
            dates               : this.project ? [this.project.starts_at,this.project.ends_at] :[],
            country             : this.project ? this.project.country.code : this.event_company.country,
            city                : this.project ? this.project.city : this.event_company.city,
            type                : this.project ? this.project.type : null,
            format              : 'inperson',
            client              : this.project ? this.project.client_id : null,
            contact             : this.project && this.project.contacts.length > 0 ? this.project.contacts[0].id : null,
            attendee_count      : this.project ? this.project.expected_attendee_count : null,
            new_contact         : {},
            fin_fee_type        : this.project ? this.project.service_fee_type : this.event_company.fin_fee_type,
            fin_fee_percentage  : this.setFinFeePercentage(),
            fin_fee_fixed_amount: this.setFinFeeFixed(),
            fin_fee_currency    : this.project ? this.project.currency : this.event_company.currency,
            profit_target       : this.project ? Math.round(this.project.expected_profit_ratio) : Math.round(this.event_company.fin_expected_profit_ratio * 100),
            languages           : this.setLanguages(),
            currency            : this.project ? this.project.service_fee_currency : this.event_company.currency,
            responsible         : this.project ? this.project.responsible : this.me.id,
            lead_close_date     : this.project ? this.project.lead_close_date : null,
            lead_value          : this.project ? this.project.lead_value : null,
            lead_currency       : this.project ? this.project.lead_currency : null,
        }
    }
}

export default BizdevDeal