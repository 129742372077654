const state = {
    image: null,
    title: null,
    subtitle: null,
    overtitle: null,
}

// getters
const getters = {
    
}
  
// actions
const actions = {
    
}
  
// mutations
const mutations = {
    setData(state, data) {
        state.image = data.image
        state.title = data.title
        state.subtitle = data.subtitle
        state.overtitle = data.overtitle
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}