<script setup>
    import {ref,watch,computed,inject,onMounted} from 'vue'
    import draggable from 'vuedraggable'
    import API from '@/api'
    import FieldWrapper from '@/views/form_builder/FieldWrapper.vue'
    import { useStore } from 'vuex'
	
	const store = useStore()
    const emitter = inject('emitter')
    const props = defineProps({
        section_id:Number,
    })

    const active_field = computed(() => store.state.form.active_field)

    const has_field_hovered = ref(false)

    const form_data = computed(() => store.state.form.active_form)
    const section_fields = ref([...[],...store.state.form.active_form.sections.filter(x=>x.id==props.section_id)[0].fields] )
    
    
    watch(() => form_data.value, data => {
        section_fields.value = [...[],...data.sections.filter(x=>x.id==props.section_id)[0].fields]
    }, {deep:true})

    const sort = () => {
        
        API.put('form/' + form_data.value.code + '/section/'+props.section_id+'/sort', {fields : section_fields.value.map( x => x.id )})
        .then(response=>{
            
        })
    }

    const ref_delete_field = ref()
    const targetElement = ref()
    
    const add = e => {

        const itemId = e.item.dataset.itemId
        const section_id = parseInt(e.target.closest("[data-section-id]").dataset.sectionId)

        let request
        if(itemId && section_id){
            request=API.put('form/'+form_data.value.code+'/field/'+itemId+'/section',{section_id:section_id,field_index:e.newDraggableIndex})
        }else{
            request = API.post('form/'+form_data.value.code+'/field',{section_id:section_id,field_type:e.item.dataset.type,field_index:e.newDraggableIndex})
        }

        request.then(response=>{
            store.dispatch('form/loadData', form_data.value.code)
            store.dispatch('floating_alerts/addAlert', {
                type:'tip',
                title:'BAŞARILI!',
                description:response.data.message
            })
            show_footer.value = section_fields.value.length == 0
        }).catch((error) => {
            store.dispatch('floating_alerts/addAlert', {
                type:'danger',
                title:'HATA!',
                description:error.response.data.message
            })
        })
    }

    const delete_form_field = id => {
        ref_delete_field.value.show({
			title: 'Emin misin?',
			message: 'Bu form alanını silmek üzeresin.',
            submessage: 'Bu işlem geri alınamaz!',
			okButton: 'Sil'
		}).then(result => {
			if (result) {
				API.delete('form/field/'+id).then(response=>{
                    store.dispatch('form/loadData', form_data.value.code)
                    
                    store.dispatch('floating_alerts/addAlert', {
                        type:'tip',
                        title:'BAŞARILI!',
                        description:response.data.message
                    })
                }).catch( error => {
                    store.dispatch('floating_alerts/addAlert', {
                        type:'danger',
                        title:'HATA!',
                        description:error.response.data.message
                    })
                })
			} 
		})
    }

    const show_form_field_detail = id => {
        emitter.emit('show_form_field_detail',id)
    }

    const show_footer = ref(section_fields.value.length == 0)

    onMounted(() => {
        
        targetElement.value.targetDomElement.addEventListener('dragenter', e => {
            show_footer.value = false
        })

        targetElement.value.targetDomElement.addEventListener('dragleave', e => {
            show_footer.value = section_fields.value.length == 0
        })
    })

    const move = e => {
        console.log(e)
    }

    const start = e => {
        console.log(e)
    }

</script>

<template>
    <draggable 
        group="form_fields" 
        @add="add"
        @sort="sort"
        item-key="id"
        v-model="section_fields"
        empty-insert-threshold="300"
        ghost-class="ghost"
        drag-class="bg-white"
        animation=300
        class="py-2"
        ref="targetElement"
    >
        <template #item="{element}">
            <FieldWrapper :key="'form_field_' + element.id" :class="active_field.id == element.id ? 'bg-calypso-light':'bg-white'" :field="element" @click="show_form_field_detail(element.id)" @delete="delete_form_field"/>
        </template>
        <template #footer>
            <div class="p-4 text-sm text-center text-gray-500" v-if="show_footer">Bu bölüm henüz boş!</div>
        </template>
    </draggable>
    <UIConfirm ref="ref_delete_field" :destructive="true"/>
</template>

<style scoped>
    .button {
        margin-top: 35px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
        height:100px;
    }
    .ghost > div {
        visibility: hidden;
    }
    .list-group {
    min-height: 20px;
    }
    .list-group-item {
    cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
    .dragArea {
        min-height: 50px;
        outline: 1px dashed;
    }
</style>