<template>
    <Teleport to="body">
    <div class="fixed top-5 inset-x-1/4 z-[100] flex flex-col items-center"
        :class="{
            'top-5' : !update_needed,
            'top-20' : update_needed,
        }"
    >
        <div v-for="alert,k in floating_alerts" :key="k">
            <UIAlert :titleText="alert.title" :type="alert.type" class="min-w-min mb-5 shadow-lg" :closeable="alert.closeable" @close="close(alert.id)">
                {{ alert.description }}
            </UIAlert>
        </div>
    </div>
    </Teleport>
</template>

<script setup>

	import { computed } from 'vue'
	import { useStore } from 'vuex'
	
	const store = useStore()

	const floating_alerts = computed(() => store.state.floating_alerts.list)
    const update_needed = computed(() => store.state.auth.updateNeeded)

    const close = (id) => {
        store.dispatch('floating_alerts/deleteAlert', id)
    }

</script>