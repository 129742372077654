<template>
	<UILabel
		:label="label"
		v-if="label"
		:error="error"
		:rand_id="rand_id"
		:isFocused="isFocused"
	/>
	<Listbox v-model="selectedOption" :multiple="multiple" v-slot="{ open }">
		<div class="relative mt-1">
			<ListboxButton
				ref="LBbutton"
				class="relative w-full py-2 pl-3 pr-10 text-left rounded-iq border focus:outline-none border-transparent focus:ring-2"
				:class="{
					'ring-2 ring-calypso': open & !error,
					'ring-2 ring-red-600': error,
					'ring-transparent': !open & !error,
					'border-gray-300 bg-gray-50 focus:ring-calypso':
						styled && !error && !open,
					'border-red-100 bg-red-50 focus:ring-red-100': styled && error,
				}"
			>
				<span
					class="block"
					:class="selectedOption.length == 0 ? 'text-gray-400' : ''"
				>
					{{
						selectedOption.length > 0
							? selectedOption.map((option) => option.text).join(", ")
							: (selectedOption.text || title)
					}}
				</span>
				<!--<span
					class="block"
					v-if="typeof selectedOption == 'object'"
					:class="selectedOption.length == 0 ? 'text-gray-400' : ''"
				>
					{{ selectedOption.text || title }}
				</span>{{selectedOption.length}}-->
				<span
					class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
				>
					<SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
				</span>
			</ListboxButton>

			<transition
				enter-active-class="transition duration-200 ease-out"
				enter-from-class="translate-y-1 opacity-0"
				enter-to-class="translate-y-0 opacity-100"
				leave-active-class="transition duration-150 ease-in"
				leave-from-class="translate-y-0 opacity-100"
				leave-to-class="translate-y-1 opacity-0"
			>
				<ListboxOptions
					class="z-10 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-iq shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
				>
					<ListboxOption
						v-slot="{ active, selected }"
						v-for="option in options"
						:key="option.value"
						:value="option"
						as="template"
					>
						<li
							:class="[
								active ? 'text-calypso bg-calypso-light' : 'text-gray-900',
								'cursor-default select-none relative py-2 px-4',
							]"
						>
							<span
								:class="[
									selected ? 'font-medium' : 'font-normal',
									'block truncate',
								]"
								>{{ option.text }}</span
							>
							<span
								v-if="selected"
								class="absolute inset-y-0 left-0 flex items-center pl-3 text-calypso"
							>
								<CheckIcon class="w-5 h-5" aria-hidden="true" />
							</span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
		</div>
	</Listbox>
	<div class="validation_error" v-if="error">{{ errors[0].$message }}</div>
</template>

<script setup>
	import {
		ref,
		computed,
		watch,
		onMounted,
		onBeforeUnmount,
		nextTick
	} from "vue"
	import {
		Listbox,
		ListboxButton,
		ListboxOptions,
		ListboxOption,
	} from "@headlessui/vue"
	import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid"

	const props = defineProps({
		modelValue: [String, Number, Array, Object],
		options: [Array, Object],
		label: String,
		title: String,
		errors: Array,
		display: {
			type: String,
			default: "inline",
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		styled: {
			type: Boolean,
			default: true,
		}
	})
	
	const error = computed(() => props.errors && props.errors.length > 0)
	const isFocused = ref(false)
	const LBbutton = ref()
	const emit = defineEmits(["update:modelValue",'enter','blur'])

	let rand_id = computed(
		() => "listbox_" + Math.floor(Math.random() * 1000000000)
	)

	const selectedOption = ref([])
	console.log(props.options)
	if (props.modelValue) {
		console.log(props.modelValue)
		if(typeof props.modelValue == 'object') {
			props.modelValue.forEach(item => {
				let option_item = props.options.find(x=>x.value == item)
				if(option_item) selectedOption.value.push(option_item)
			})
		} else {
			let option_item = props.options.find(x=>x.value == props.modelValue)
			console.log(props.options)
			console.log(option_item)
			if(option_item) selectedOption.value.push(option_item)
		}
	}

	const onClassChange = (classAttrValue) => {
		const classList = classAttrValue.split(" ")
		isFocused.value = classList.includes("ring-calypso")
	}

	let observer

	onMounted(() => {
		observer = new MutationObserver((mutations) => {
			for (const m of mutations) {
				const newValue = m.target.getAttribute(m.attributeName)
				nextTick(() => {
					onClassChange(newValue, m.oldValue)
				})
			}
		})

		observer.observe(LBbutton.value.el, {
			attributes: true,
			attributeOldValue: true,
			attributeFilter: ["class"],
		})
	})
	onBeforeUnmount(() => {
		observer.disconnect()
	})

	
	watch(
		() => selectedOption.value,
		(data) => {
			return
			console.log(data)
			if (!data || data.length == 0) return
			emit("update:modelValue", selectedOption.value)
		},
		{ immediate: true }
	)
	

	const enter = () => {
        emit('enter')
    }
</script>
