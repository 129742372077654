<template>
    <div>
        <UILabel
            :label="label"
            :rand_id="id || rand_id"
            :isFocused="isFocused"
            :error="error"
        />
        <Combobox v-model="selectedOption" nullable v-slot="{ open }">
            <div
                class="relative"
                :class="
                    isFocused && display == 'box'
                        ? 'ring-2 ring-calypso rounded-iq'
                        : ''
                "
            >
                <ComboboxInput
                    @focus="isFocused = true"
                    :placeholder="title"
                    autocomplete="off"
                    @blur="isFocused = false"
                    @change="query = $event.target.value"
                    class="w-full focus:outline-none focus:border-transparent"
                    :class="{
                        'border rounded-iq focus:ring-calypso p-2 pr-10 h-[42px]':
                            display == 'box',
                        'border-gray-300 bg-gray-50':
                            !error && display == 'box',
                        'border-red-100 bg-red-50': error && display == 'box',
                        'text-calypso text-sm': display !== 'box',
                    }"
                    :displayValue="(option) => (option ? option.text : null)"
                />
                <UIBadge
                    class="uppercase bg-red-600 absolute -top-2"
                    :class="{
                        'right-4': !isLoading,
                        'right-8': isLoading,
                    }"
                    v-if="isNew"
                >
                    YENİ
                </UIBadge>
                <LoadAnim
                    class="h-8 w-8 absolute right-8 top-0 z-20"
                    v-if="isLoading"
                />
                <ComboboxButton
                    v-if="arrow_enabled"
                    class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                    <ChevronDownIcon
                        class="h-4 w-4 text-calypso duration-300"
                        aria-hidden="true"
                        :class="{
                            'ml-auto': display == 'box',
                            'transform rotate-180': open,
                        }"
                    />
                </ComboboxButton>
                <TransitionRoot
                    enter-active-class="transition duration-200 ease-out"
                    enter-from-class="translate-y-1 opacity-0"
                    enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="transition duration-150 ease-in"
                    leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="translate-y-1 opacity-0"
                    @after-leave="query = ''"
                    class="relative"
                >
                    <div
                        v-if="
                            display == 'inline' && popover_v_position == 'down'
                        "
                        class="z-20 w-4 h-4 absolute bg-white border-r border-b border-gray-iq -rotate-135 transform arrow"
                        :class="{
                            'right-5': popover_h_position == 'right',
                            'left-5': popover_h_position == 'left',
                        }"
                    ></div>
                    <ComboboxOptions
                        class="z-10 focus:outline-none absolute max-h-60 w-full overflow-x-hidden border border-gray-iq shadow-iq rounded-iq bg-white text-base ring-0 sm:text-sm"
                        :class="{
                            'mt-2': display == 'inline',
                        }"
                    >
                        <div
                            v-if="
                                filteredOptions.length === 0 &&
                                query !== '' &&
                                !allow_new &&
                                !hint
                            "
                            class="relative cursor-default select-none py-2 px-4 text-gray-700"
                        >
                            Hiç sonuç bulunamadı.
                        </div>
                        <div
                            v-if="
                                filteredOptions.length === 0 &&
                                query !== '' &&
                                !allow_new &&
                                hint
                            "
                            class="relative cursor-default select-none py-2 px-4 text-gray-700"
                        >
                            {{ hint }}
                        </div>
                        <ComboboxOption
                            v-if="
                                filteredOptions.length === 0 &&
                                query !== '' &&
                                allow_new
                            "
                            :value="queryPerson"
                            class="flex select-none hover:bg-calypso-light duration-500 pr-10 py-2 pl-4 pr-10 text-sm flex items-center relative cursor-pointer"
                        >
                            Ekle : "{{ query }}"
                        </ComboboxOption>
                        <ComboboxOption
                            v-for="option in filteredOptions"
                            :key="option.value"
                            :value="option"
                            as="template"
                            v-slot="{ selected, active }"
                        >
                            <li
                                class="flex select-none hover:bg-calypso-light duration-500 pr-10 py-2 pl-4 pr-10 text-sm flex items-center relative cursor-pointer"
                                :class="{
                                    'bg-calypso-light': active && !selected,
                                    'text-gray-900': !active && !selected,
                                    'bg-calypso text-white': selected,
                                }"
                            >
                                <div
                                    class="w-8 rounded-full overflow-hidden mr-4 shrink-0"
                                    v-if="option.image"
                                >
                                    <img :src="option.image" />
                                </div>
                                <div>
                                    <span class="truncate">{{
                                        option.text
                                    }}</span>
                                    <div
                                        v-if="option.subtext"
                                        class="text-xs text-slate-500 truncate"
                                    >
                                        {{ option.subtext }}
                                    </div>
                                </div>
                            </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                </TransitionRoot>
            </div>
        </Combobox>
    </div>
</template>

<script setup>
	import { ref, computed, watch } from "vue";
	import {
		Combobox,
		ComboboxInput,
		ComboboxButton,
		ComboboxOptions,
		ComboboxOption,
		TransitionRoot,
	} from "@headlessui/vue";
	import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
	import LoadAnim from "@/views/LoadAnim.vue";

	const props = defineProps({
		modelValue: [String, Number, Array, Object],
		title: String,
		label: String,
		hint: String,
		error: Boolean,
		id: String,
		source: Array,
		display: {
			type: String,
			default: "inline",
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		tags: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		allow_new: {
			type: Boolean,
			default: false,
		},
		arrow_enabled: {
			type: Boolean,
			default: true,
		},
		fg: {
			type: Boolean,
			default: false,
		},
	});

	const emit = defineEmits(["update:modelValue", "search"]);

	const isFocused = ref(false);
	const isNew = ref(false);
	const rand_id = computed(
		() => "input_" + Math.floor(Math.random() * 1000000000)
	);
	const popover_v_position = ref("down");
	const popover_h_position = ref("left");

	const selectedOption = ref(
		props.source.find((x) => x.value == props.modelValue)
	);
	const query = ref("");

	const queryPerson = computed(() => {
		return query.value === ""
			? null
			: { value: query.value, text: query.value };
	});

	watch(
		() => query.value,
		(data) => {
			if (!data) return;
			if (!props.fg) {
				emit("search", data);
			} else {
				emit("search", { data: data, id: props.id });
			}
		},
		{ immediate: true }
	);

	watch(
		() => selectedOption.value,
		data => {
			console.log(data);
			if (!data) {
				//isNew.value = false;
				return;
			}
			isNew.value = data.value == query.value;

			emit("update:modelValue", data.value);
		},
		{ immediate: true }
	);

	watch(
		() => props.modelValue,
		(data) => {
			if (!data) {
				selectedOption.value = null;
				return;
			}
			selectedOption.value = props.source.find((x) => x.value == data);
		},
		{ immediate: true }
	);

	const filteredOptions = computed(() =>
		query.value === ""
			? props.source
			: props.source.filter((item) => {
				return item.text
					.toLowerCase()
					.includes(query.value.toLowerCase());
			})
	);
</script>

<style scoped>
.arrow {
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
    top: 1px;
}
</style>
