<template>
    <div :id="'tooltip-source-' + rand_id" class="tooltip_wrapper relative w-fit h-fit" @mouseover="show_tooltip" @mouseout="hide_tooltip">
        <slot></slot>
        <div :id="'tooltip-wrapper-' + rand_id" class="pointer-events-none z-10">
            <div :id="'tooltip-' + rand_id"
                class="tooltip max-w-lg relative duration-300 transition opacity-0 z-100 text-sm rounded scale-0 transform"
                :class="{
                    'origin-bottom' : position == 'top',
                    'origin-top' : position == 'bottom',
                    'origin-left' : position == 'right',
                    'origin-right' : position == 'left',
                    'min-w-[300px] bg-white text-gray-800 shadow-xl font-normal border p-4 z-10 whitespace-break-spaces' : isInfoBox,
                    'bg-black text-white px-2 py-1 whitespace-nowrap' : !isInfoBox,
                    //'bg-[#003239]' : bgColor
                }"><span v-html="title"></span><slot name="content"></slot>
                <div class="arrow" data-popper-arrow
                :class="{
                    'before:bg-white before:border-r before:border-b' : isInfoBox,
                    //'before:bg-[#003239]' : !isInfoBox
                    'before:bg-black' : !isInfoBox
                }"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { createPopper } from '@popperjs/core';
    import { computed,onMounted,onUpdated } from 'vue';

    const props = defineProps({
        title: String,
        position: {
            type:String,
            default:'top'
        },
        isInfoBox:{
            type:Boolean,
            default:false
        }
    })

    const create_popper = () => {
        return createPopper(el_source, el_tooltip_wrapper, {
            placement: props.position,
            positionFixed: true,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    }
                },
                {
      name: 'flip',
      options: {
        flipVariations: false, // true by default
      },
    },
            ],
        });
    }

    let rand_id = computed(()=>Math.floor(Math.random() * 1000000000))
    let el_source, el_tooltip, el_tooltip_wrapper, popperInstance
    onMounted(()=>{
        el_source = document.getElementById('tooltip-source-' + rand_id.value);
        el_tooltip_wrapper = document.getElementById('tooltip-wrapper-' + rand_id.value);
        el_tooltip = document.getElementById('tooltip-' + rand_id.value);
        popperInstance = create_popper()
        window.addEventListener('resize',popperInstance.update())
    })

    onUpdated(()=>{
        popperInstance.update()
    })
    

    const show_tooltip = () => {
        //popperInstance.setOptions({placement:'top'})
        el_tooltip.classList.remove('opacity-0')
        el_tooltip.classList.remove('scale-0')
        popperInstance.forceUpdate()
    }

    const hide_tooltip = () => {
        el_tooltip.classList.add('opacity-0')
        el_tooltip.classList.add('scale-0')
    }

    

</script>

<style scoped>
    /*
    .tooltip_wrapper:hover span{
        background-color: black;
        display: block;
    }
    span:after {
        content: "";
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        background: black;
        position: absolute;
        left: -4px;
        top: '50%';
        z-index: -1;
    }*/
    .arrow,
    .arrow::before {
        position: absolute;
        width: 10px;
        height: 10px;
        /*left:0px;*/
    }

    .arrow {
        visibility: hidden;
    }

    .arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    [data-popper-placement^='top']  .arrow {
        bottom: -6px;
    }

    [data-popper-placement^='bottom']  .arrow {
        top: -6px;
    }

    [data-popper-placement^='left']  .arrow {
        right: -3px;
    }

    [data-popper-placement^='right']  .arrow {
        left: -3px;
    }
</style>