

import OrganisationChart from '../pages/admin/OrganisationChartGoogle.vue'



import AccountingIntegration from '../pages/admin/AccountingIntegration.vue'


import ChecklistDetail from '../pages/admin/checklist/ChecklistDetail.vue'
import Budgets from '../pages/admin/Budgets.vue'
import EventCompany from '../pages/admin/EventCompany.vue'
import Notifications from '../pages/admin/Notifications.vue'
import Positions from '../pages/admin/organisation/Positions.vue'

import BudgetCategoriesPage from '../pages/admin/budget/BudgetCategories.vue'
import BudgetItemsPage from '../pages/admin/budget/BudgetItems.vue'
import BudgetGroupsPage from '../pages/admin/budget/BudgetGroups.vue'
import BudgetTemplatesPage from '../pages/admin/budget/BudgetTemplates.vue'
import BudgetCategoryTemplatesPage from '../pages/admin/budget/BudgetCategoryTemplates.vue'
import BudgetExportPage from '../pages/admin/budget/BudgetExport.vue'
import PostEventClientSurveyPage from '../pages/admin/PostEventClientSurvey.vue'
import PostEventClientSurveyDetailPage from '../pages/admin/PostEventClientSurveyDetail.vue'
import TargetsPage from '../pages/admin/Targets.vue'

import SimilarRecordsPage from '../pages/admin/similarRecords/index.vue'
import SimilarContactPage from '../pages/admin/similarRecords/contacts.vue'
import SimilarClientsPage from '../pages/admin/similarRecords/clients.vue'
import SimilarSuppliersPage from '../pages/admin/similarRecords/suppliers.vue'



const routes = [
    {
        path: "/admin",
        component: () => import('../pages/Admin.vue'),
        name:'Admin',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Yönetim',
            subtitle:'Platform ile ilgili herşeyi bu ekrandan ayarlayabilirsin'
        }
    },
    {
        path: "/admin/post-event-client-surveys",
        component: PostEventClientSurveyPage,
        name:'AdminPostEventClientSurvey',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Müşteri Memnuniyet Anketleri',
            subtitle:'Proje sonrası müşterilerden geri bildirim topla',
            image:'',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/post-event-client-surveys/:id",
        component: PostEventClientSurveyDetailPage,
        name:'AdminPostEventClientSurveyDetail',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Müşteri Memnuniyet Anketleri',
            subtitle:'Proje sonrası müşterilerden geri bildirim topla',
            image:'',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                },
                {
                    route_name:'AdminPostEventClientSurvey',
                    title:"Müşteri Memnuniyet Anketleri",
                }
            ]
        }
    },
    {
        path: "/admin/event-company",
        component: EventCompany,
        name:'AdminEventCompany',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Şirket Ayarları',
            subtitle:'Burada yapacağınız değişiklikler tüm kullanıcıları etkiler',
            image:'',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/email-templates",
        component: () => import('../pages/admin/EmailTemplates.vue'),
        name:'AdminEmailTemplates',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'E-Posta Taslakları',
            subtitle:'Platform ile ilgili herşeyi bu ekrandan ayarlayabilirsin',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/organization-chart",
        component: OrganisationChart,
        name:'AdminOrganisationChart',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Organizasyon Yapısı',
            subtitle:'Kim kiminle çalışıyor?',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/approval-chain",
        component: () => import('../pages/admin/ApprovalChain.vue'),
        name:'AdminApprovalChain',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Onay Yönetimi',
            subtitle:'Tüm işlemler için onay süreçlerini belirle ve onay işlemlerini görüntüle',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {   path: '/admin/definitions',
        name:'AdminDefinitions',
        redirect: { name: 'AdminDefinitionsSubpage', params: { subpage:'project-types' } } ,
    },
    {
        path: "/admin/definitions/:subpage",
        component: () => import('../pages/admin/Definitions.vue'),
        name:'AdminDefinitionsSubpage',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Tanımlamalar',
            subtitle:'Çerçeveyi ve kuralları belirleyin',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                },
                {
                    route_name:'AdminDefinitions',
                    title:"Tanımlamalar",
                }
            ]
        }
    },
    {
        path: "/admin/payment-methods",
        component: () => import('../pages/admin/PaymentMethods.vue'),
        name:'AdminPaymentMethods',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Ödeme Yöntemleri',
            subtitle:'Onay süreçlerinizi belirle.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/biz-dev",
        name:'AdminBizDev',
        redirect: { name: 'AdminBizDevSubpage', params: { subpage:'lead-phases' } } ,
    },
    {
        path: "/admin/biz-dev/:subpage",
        component: () => import('../pages/admin/BizDev.vue'),
        name:'AdminBizDevSubpage',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Teklif Yönetimi',
            subtitle:'Teklif yönetimi süreçlerinizi belirle.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                },
                {
                    route_name:'AdminBizDev',
                    title:"Teklif Yönetimi",
                }
            ]
        }
    },
    {
        path: "/admin/accounting-integration",
        component: AccountingIntegration,
        name:'AdminAccountingIntegration',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Muhasebe Entegrasyonu',
            subtitle:'İş geliştirme süreçlerinizi belirle.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/tasks",
        component: () => import('../pages/admin/Tasks.vue'),
        name:'AdminTasks',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Yapılacaklar',
            subtitle:'Kullanıcılar için gerekli ayarları yapın.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/positions",
        component: Positions,
        name:'AdminPositions',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Pozisyonlar & İzinler',
            subtitle:'Kullanıcı pozisyonlarını, erişim izinlerini ve limitleri yönet',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/checklists",
        component: () => import('../pages/admin/Checklists.vue'),
        name:'AdminChecklists',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Checklist\'ler',
            subtitle:'Projelerinizde kullanacağınız checklistlerinizi yönetin.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/checklist/:id",
        component: ChecklistDetail,
        name:'AdminChecklistDetail',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'standalone',
            title:'Checklist\'ler',
            subtitle:'Projelerinizde kullanacağınız checklistlerinizi yönetin.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/budgets",
        component: Budgets,
        name:'AdminBudgets',
        redirect: '/admin/budgets/budget-categories',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Bütçe Ayarları',
            subtitle:'Onay süreçlerinizi belirle.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        },
        children: [
			{
                path: 'budget-categories',
                component: BudgetCategoriesPage
			},
			{
				path: 'budget-items',
				component: BudgetItemsPage
			},
			{
				path: 'budget-groups',
				component: BudgetGroupsPage
			},
			{
				path: 'budget-templates',
				component: BudgetTemplatesPage
			},
            {
				path: 'budget-category-templates',
				component: BudgetCategoryTemplatesPage
			},
			{
				path: 'export',
				component: BudgetExportPage
			}
		]
    },
    {
        path: "/admin/organisation",
        component: () => import('../pages/admin/Organisation.vue'),
        name:'AdminOrganisation',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title   : 'Organizasyon Yapısı',
            subtitle: 'Kulllanıcıları, takımları ve bölümleri yönet',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/teams",
        component: () => import('../pages/admin/Teams.vue'),
        name:'AdminTeams',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Takımlar',
            subtitle:'Create new users, customize user permissions, and remove users from your account.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/user/:code",
        component: () => import('../pages/Profile.vue'),
        name:'AdminUserProfile',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Kullanıcı Profili',
            subtitle:'Kullanıcı ile ilgili tüm bilgi, yetki ve izinleri yönet.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                },
                {
                    route_name:'AdminOrganisation',
                    title:"Kullanıcılar",
                }
            ]
        }
    },
    {
        path: "/admin/notifications",
        component: Notifications,
        name:'AdminNotifications',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Bildirim Ayarları',
            subtitle:'Kimlerin gelişmelerden haberdar edileceğini ayarla',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                },
                {
                    route_name:'AdminOrganisation',
                    title:"Kullanıcılar",
                }
            ]
        }
    },
    {
        path: "/admin/targets",
        component: TargetsPage,
        name:'Targets',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Hedefler',
            subtitle:'Bölüm, takım ve kişi hedeflerini yönet',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        }
    },
    {
        path: "/admin/similarRecords",
        component: SimilarRecordsPage,
        name:'AdminSimilarRecords',
        redirect: '/admin/similarRecords/contacts',
        meta: { 
            requiresAuth: true,
            requiresAdmin: true,
            layout:'normal',
            title:'Benzer Veriler',
            subtitle:'Benzer Verilerinizi Yönetin.',
            breads:[
                {
                    route_name:'Admin',
                    title:"Yönetim",
                }
            ]
        },
        children: [
			{
                path: 'contacts',
                component: SimilarContactPage
			},
            {
                path: 'clients',
                component: SimilarClientsPage
			},
            {
                path: 'suppliers',
                component: SimilarSuppliersPage
			}
		]
    },
]

export default {
    routes
}